import { useState } from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@fluency/ui/components/ui/select';
import { Button } from '@fluency/ui/components/ui/button';
import { Separator } from '@fluency/ui/components/ui/separator';
import { withPermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { Logger } from '@fluency/ui/features/Logger';

interface DocumentVersion {
  versionId: string;
  major: number;
  minor: number;
  patch: number;
  editedBy: string;
  createdAt: string;
  changeSummary: string;
  originalDocumentationId: string;
  versionedDocumentId: string;
}

interface CreateVersionParams {
  originalDocumentationId: string;
  previousVersionedDocumentId: string;
  changeSummary: string;
  major: number;
  minor: number;
  patch: number;
  createdAt: string;
}

interface VersionCreateProps {
  originalDocumentationId: string;
  versionData: DocumentVersion[];
  createVersion: (params: CreateVersionParams) => Promise<void>;
}

function VersionCreate({
  originalDocumentationId,
  versionData,
  createVersion,
}: VersionCreateProps) {
  const currentVersion = versionData.length > 0 ? versionData[0] : null;
  const [selectedVersion, setSelectedVersion] = useState('patch');
  const [isCreating, setIsCreating] = useState(false);

  const handleCreateNewVersion = async () => {
    if (!currentVersion) return;

    let newMajor = currentVersion.major;
    let newMinor = currentVersion.minor;
    let newPatch = currentVersion.patch;

    if (selectedVersion === 'major') {
      newMajor += 1;
      newMinor = 0;
      newPatch = 0;
    } else if (selectedVersion === 'minor') {
      newMinor += 1;
      newPatch = 0;
    } else if (selectedVersion === 'patch') {
      newPatch += 1;
    }

    setIsCreating(true);
    try {
      await createVersion({
        originalDocumentationId,
        previousVersionedDocumentId: currentVersion.versionedDocumentId,
        changeSummary: 'New version',
        major: newMajor,
        minor: newMinor,
        patch: newPatch,
        createdAt: new Date().toISOString(),
      });
    } catch (error) {
      Logger.error('Failed to create new version:', error);
    } finally {
      setIsCreating(false);
    }
  };

  if (!currentVersion) {
    return null; // Or a message saying no versions available
  }

  return (
    <div>
      <Separator />
      <p className="font-medium text-sm py-2">Create new version</p>
      <div className="flex items-center gap-2">
        <Select value={selectedVersion} onValueChange={setSelectedVersion}>
          <SelectTrigger>
            <SelectValue
              className="text-xs"
              placeholder="Select version type"
            />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem className="text-xs" value="major">
                <span className="text-xs font-semibold">
                  Major (v{currentVersion.major + 1}.0.0)
                </span>
              </SelectItem>
              <SelectItem className="text-xs" value="minor">
                <span className="text-xs font-semibold">
                  Minor (v{currentVersion.major}.{currentVersion.minor + 1}.0)
                </span>
              </SelectItem>
              <SelectItem className="text-xs" value="patch">
                <span className="text-xs font-semibold">
                  Tweak (v{currentVersion.major}.{currentVersion.minor}.
                  {currentVersion.patch + 1})
                </span>
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
        <Button
          size="sm"
          variant="secondary"
          onClick={handleCreateNewVersion}
          disabled={isCreating}
        >
          {isCreating ? 'Creating...' : 'Create'}
        </Button>
      </div>
    </div>
  );
}

export default withPermissionGate(VersionCreate, ['documents:update']);
