import { Node, NodeChange } from 'reactflow';
import { BPMNElement } from '../types/workflow';

export const onDrop = (
  event: React.DragEvent<HTMLDivElement>,
  _nodes: Node[],
  onNodesChange: (changes: NodeChange[]) => void,
  bpmnElements: BPMNElement[],
  onDeleteNode: (nodeOrNodes: Node | Node[] | string) => void
) => {
  event.preventDefault();

  const reactFlowBounds = event.currentTarget.getBoundingClientRect();
  const nodeType = event.dataTransfer.getData('application/reactflow');
  const id = event.dataTransfer.getData('application/id');
  const documentId = event.dataTransfer.getData('application/documentId');
  const documentName = event.dataTransfer.getData('application/documentName');
  const vaultId = event.dataTransfer.getData('application/vaultId');
  const vaultName = event.dataTransfer.getData('application/vaultName');
  const totalSteps = event.dataTransfer.getData('application/totalSteps');
  const createdBy = event.dataTransfer.getData('application/createdBy');

  if (!nodeType) return;

  const position = {
    x: event.clientX - reactFlowBounds.left,
    y: event.clientY - reactFlowBounds.top,
  };

  const newNodeId = `${id}-${Date.now()}`;

  const commonData = {
    onDelete: () => onDeleteNode(newNodeId),
  };

  let newNode: Node;

  if (nodeType === 'documentNode') {
    // Handle both existing documents and new empty documents
    const isExistingDocument = documentId && documentName;

    newNode = {
      id: newNodeId,
      type: nodeType,
      position,
      data: {
        ...commonData,
        label: documentName || 'Empty Document',
        id: id,
        documentId: documentId || null,
        documentName: documentName || 'Empty Document',
        vaultId: vaultId || null,
        vaultName: vaultName || null,
        totalSteps: parseInt(totalSteps) || 0,
        createdBy: createdBy || null,
        isNewDocument: !isExistingDocument,
      },
    };
  } else {
    const droppedItem = bpmnElements.find((item) => item.id === id);
    if (!droppedItem) return;

    newNode = {
      id: newNodeId,
      type: nodeType,
      position,
      data: {
        ...commonData,
        label: droppedItem.title,
      },
    };
  }

  onNodesChange([{ type: 'add', item: newNode }]);
};

export const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'move';
};
