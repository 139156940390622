import React, { useState, useCallback } from 'react';
import { EdgeProps, EdgeLabelRenderer, getSmoothStepPath } from 'reactflow';
import { Button } from '@fluency/ui/components/ui/button';
import { Trash, Type } from 'lucide-react';

interface LabeledEdgeProps extends EdgeProps {
  data?: {
    label?: string;
    onEdgeLabelChange?: (id: string, label: string) => void;
    onDelete?: (id: string) => void;
    showAnimation?: boolean;
  };
}

const LabeledEdge: React.FC<LabeledEdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  style = {},
  markerEnd,
  selected,
}) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [label, setLabel] = useState(data?.label || '');
  const [isHovered, setIsHovered] = useState(false);
  const [isControlHovered, setIsControlHovered] = useState(false);

  const onLabelChange = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onBlur = useCallback(() => {
    setIsEditing(false);
    if (data?.onEdgeLabelChange) {
      data.onEdgeLabelChange(id, label);
    }
  }, [id, label, data]);

  const handleDelete = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      data?.onDelete?.(id);
    },
    [id, data]
  );

  // Show controls if either the edge is hovered or the controls themselves are hovered
  const showControls = selected || isHovered || isControlHovered;

  return (
    <>
      {/* Invisible wider path for better hover detection */}
      <path
        id={`${id}-hover`}
        className="react-flow__edge-interaction"
        d={edgePath}
        strokeWidth={20}
        fill="none"
        stroke="transparent"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => {
          // Only set hovered to false if we're not hovering the controls
          if (!isControlHovered) {
            setIsHovered(false);
          }
        }}
        style={{ pointerEvents: 'all' }}
      />

      {/* Visible edge path */}
      <path
        id={id}
        style={{
          ...style,
          strokeWidth: selected ? 2 : 1,
          stroke: selected ? '#1a192b' : style.stroke,
        }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />

      <EdgeLabelRenderer>
        <div
          className="pointer-events-none"
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            zIndex: 1,
          }}
        >
          {/* Edge Controls */}
          {showControls && (
            <div
              className="absolute left-1/2 -translate-x-1/2 -translate-y-full -top-2 pointer-events-auto"
              onMouseEnter={() => setIsControlHovered(true)}
              onMouseLeave={() => {
                setIsControlHovered(false);
                // Small delay to allow for the edge hover check
                setTimeout(() => {
                  if (!isHovered) {
                    setIsControlHovered(false);
                  }
                }, 100);
              }}
            >
              <div className="flex items-center gap-1 bg-white border shadow-sm rounded-md p-1">
                <Button
                  size="icon"
                  variant="ghost"
                  className="h-6 w-6"
                  onClick={onLabelChange}
                >
                  <Type className="h-3 w-3" />
                </Button>
                <Button
                  size="icon"
                  variant="ghost"
                  className="h-6 w-6 text-red-600 hover:text-red-700 hover:bg-red-50"
                  onClick={handleDelete}
                >
                  <Trash className="h-3 w-3" />
                </Button>
              </div>
            </div>
          )}

          {/* Edge Label */}
          {(label || isEditing) && (
            <div
              className={`
                px-2 py-1 rounded text-sm pointer-events-auto
                ${isEditing ? 'bg-white border shadow-sm' : 'bg-white'}
              `}
            >
              {isEditing ? (
                <input
                  value={label}
                  onChange={(evt) => setLabel(evt.target.value)}
                  onBlur={onBlur}
                  className="border-none bg-transparent outline-none text-center w-full min-w-[3rem]"
                  autoFocus
                />
              ) : (
                label
              )}
            </div>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default LabeledEdge;
