import React, { useRef } from 'react';
import { useOrgImage } from '../hooks/useOrgImage';
import { Button } from '@fluency/ui/components/ui/button';
import { Upload, RefreshCw, ImageIcon } from 'lucide-react';

export const OrganisationImage: React.FC = () => {
  const {
    orgImageUrl,
    isLoadingImage,
    imageError,
    uploadImage,
    isUploading,
    uploadProgress,
    uploadError,
  } = useOrgImage();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      uploadImage(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="space-y-6">
      <div className="flex items-start gap-6">
        {/* Image Preview */}
        <div className="flex-shrink-0">
          <div className="w-[180px] h-[180px] flex items-center justify-center border rounded-lg bg-muted/10">
            {isLoadingImage ? (
              <RefreshCw className="w-6 h-6 animate-spin text-muted-foreground" />
            ) : imageError ? (
              <div className="text-center px-4">
                <ImageIcon className="w-8 h-8 mx-auto text-muted-foreground mb-2" />
                <p className="text-sm text-muted-foreground">
                  Error loading image
                </p>
              </div>
            ) : orgImageUrl ? (
              <img
                src={orgImageUrl}
                alt="Organisation Logo"
                className="w-full h-full object-contain p-4"
              />
            ) : (
              <div className="text-center px-4">
                <ImageIcon className="w-8 h-8 mx-auto text-muted-foreground mb-2" />
                <p className="text-sm text-muted-foreground">
                  No logo uploaded
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Upload Controls */}
        <div className="space-y-4 flex-grow">
          <div>
            <h4 className="text-sm font-medium mb-1">Logo Requirements</h4>
            <ul className="text-sm text-muted-foreground space-y-1">
              <li>• Minimum dimensions: 400x400 pixels</li>
              <li>• Maximum file size: 5MB</li>
              <li>• Supported formats: PNG, JPG, SVG</li>
            </ul>
          </div>

          <div className="space-y-2">
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept="image/*"
              className="hidden"
            />
            <Button
              onClick={triggerFileInput}
              disabled={isUploading}
              className="w-full"
            >
              <Upload className="mr-2 h-4 w-4" />
              {isUploading ? (
                <span className="flex items-center gap-2">
                  <RefreshCw className="h-4 w-4 animate-spin" />
                  Uploading... {uploadProgress.toFixed(0)}%
                </span>
              ) : (
                'Upload New Logo'
              )}
            </Button>
            {uploadError && (
              <p className="text-sm text-red-500 mt-2">
                Upload error: {uploadError.message}
              </p>
            )}
          </div>
        </div>
      </div>

      {/* Additional Information */}
      <div className="bg-muted/50 rounded-lg p-4 text-sm text-muted-foreground">
        <p>
          Your logo will be used in exports, reports, and other branded
          materials. For best results, upload a high-resolution image with a
          transparent background.
        </p>
      </div>
    </div>
  );
};

export default OrganisationImage;
