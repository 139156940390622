/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as UnauthorizedImport } from './routes/unauthorized'
import { Route as SubscriptionImport } from './routes/subscription'
import { Route as NotificationsImport } from './routes/notifications'
import { Route as LogoutImport } from './routes/logout'
import { Route as LoginImport } from './routes/login'
import { Route as HomeImport } from './routes/home'
import { Route as DocumentsImport } from './routes/documents'
import { Route as CallbackImport } from './routes/callback'
import { Route as R404Import } from './routes/404'
import { Route as IndexImport } from './routes/index'
import { Route as WorkflowIdImport } from './routes/workflow.$id'
import { Route as VaultIdImport } from './routes/vault.$id'
import { Route as ShareDocumentImport } from './routes/share.$document'
import { Route as SettingsSettingImport } from './routes/settings.$setting'
import { Route as DocumentIdImport } from './routes/document.$id'

// Create Virtual Routes

const OrgformLazyImport = createFileRoute('/orgform')()

// Create/Update Routes

const OrgformLazyRoute = OrgformLazyImport.update({
  id: '/orgform',
  path: '/orgform',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/orgform.lazy').then((d) => d.Route))

const UnauthorizedRoute = UnauthorizedImport.update({
  id: '/unauthorized',
  path: '/unauthorized',
  getParentRoute: () => rootRoute,
} as any)

const SubscriptionRoute = SubscriptionImport.update({
  id: '/subscription',
  path: '/subscription',
  getParentRoute: () => rootRoute,
} as any)

const NotificationsRoute = NotificationsImport.update({
  id: '/notifications',
  path: '/notifications',
  getParentRoute: () => rootRoute,
} as any)

const LogoutRoute = LogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const HomeRoute = HomeImport.update({
  id: '/home',
  path: '/home',
  getParentRoute: () => rootRoute,
} as any)

const DocumentsRoute = DocumentsImport.update({
  id: '/documents',
  path: '/documents',
  getParentRoute: () => rootRoute,
} as any)

const CallbackRoute = CallbackImport.update({
  id: '/callback',
  path: '/callback',
  getParentRoute: () => rootRoute,
} as any)

const R404Route = R404Import.update({
  id: '/404',
  path: '/404',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const WorkflowIdRoute = WorkflowIdImport.update({
  id: '/workflow/$id',
  path: '/workflow/$id',
  getParentRoute: () => rootRoute,
} as any)

const VaultIdRoute = VaultIdImport.update({
  id: '/vault/$id',
  path: '/vault/$id',
  getParentRoute: () => rootRoute,
} as any)

const ShareDocumentRoute = ShareDocumentImport.update({
  id: '/share/$document',
  path: '/share/$document',
  getParentRoute: () => rootRoute,
} as any)

const SettingsSettingRoute = SettingsSettingImport.update({
  id: '/settings/$setting',
  path: '/settings/$setting',
  getParentRoute: () => rootRoute,
} as any)

const DocumentIdRoute = DocumentIdImport.update({
  id: '/document/$id',
  path: '/document/$id',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/404': {
      id: '/404'
      path: '/404'
      fullPath: '/404'
      preLoaderRoute: typeof R404Import
      parentRoute: typeof rootRoute
    }
    '/callback': {
      id: '/callback'
      path: '/callback'
      fullPath: '/callback'
      preLoaderRoute: typeof CallbackImport
      parentRoute: typeof rootRoute
    }
    '/documents': {
      id: '/documents'
      path: '/documents'
      fullPath: '/documents'
      preLoaderRoute: typeof DocumentsImport
      parentRoute: typeof rootRoute
    }
    '/home': {
      id: '/home'
      path: '/home'
      fullPath: '/home'
      preLoaderRoute: typeof HomeImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutImport
      parentRoute: typeof rootRoute
    }
    '/notifications': {
      id: '/notifications'
      path: '/notifications'
      fullPath: '/notifications'
      preLoaderRoute: typeof NotificationsImport
      parentRoute: typeof rootRoute
    }
    '/subscription': {
      id: '/subscription'
      path: '/subscription'
      fullPath: '/subscription'
      preLoaderRoute: typeof SubscriptionImport
      parentRoute: typeof rootRoute
    }
    '/unauthorized': {
      id: '/unauthorized'
      path: '/unauthorized'
      fullPath: '/unauthorized'
      preLoaderRoute: typeof UnauthorizedImport
      parentRoute: typeof rootRoute
    }
    '/orgform': {
      id: '/orgform'
      path: '/orgform'
      fullPath: '/orgform'
      preLoaderRoute: typeof OrgformLazyImport
      parentRoute: typeof rootRoute
    }
    '/document/$id': {
      id: '/document/$id'
      path: '/document/$id'
      fullPath: '/document/$id'
      preLoaderRoute: typeof DocumentIdImport
      parentRoute: typeof rootRoute
    }
    '/settings/$setting': {
      id: '/settings/$setting'
      path: '/settings/$setting'
      fullPath: '/settings/$setting'
      preLoaderRoute: typeof SettingsSettingImport
      parentRoute: typeof rootRoute
    }
    '/share/$document': {
      id: '/share/$document'
      path: '/share/$document'
      fullPath: '/share/$document'
      preLoaderRoute: typeof ShareDocumentImport
      parentRoute: typeof rootRoute
    }
    '/vault/$id': {
      id: '/vault/$id'
      path: '/vault/$id'
      fullPath: '/vault/$id'
      preLoaderRoute: typeof VaultIdImport
      parentRoute: typeof rootRoute
    }
    '/workflow/$id': {
      id: '/workflow/$id'
      path: '/workflow/$id'
      fullPath: '/workflow/$id'
      preLoaderRoute: typeof WorkflowIdImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/404': typeof R404Route
  '/callback': typeof CallbackRoute
  '/documents': typeof DocumentsRoute
  '/home': typeof HomeRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/notifications': typeof NotificationsRoute
  '/subscription': typeof SubscriptionRoute
  '/unauthorized': typeof UnauthorizedRoute
  '/orgform': typeof OrgformLazyRoute
  '/document/$id': typeof DocumentIdRoute
  '/settings/$setting': typeof SettingsSettingRoute
  '/share/$document': typeof ShareDocumentRoute
  '/vault/$id': typeof VaultIdRoute
  '/workflow/$id': typeof WorkflowIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/404': typeof R404Route
  '/callback': typeof CallbackRoute
  '/documents': typeof DocumentsRoute
  '/home': typeof HomeRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/notifications': typeof NotificationsRoute
  '/subscription': typeof SubscriptionRoute
  '/unauthorized': typeof UnauthorizedRoute
  '/orgform': typeof OrgformLazyRoute
  '/document/$id': typeof DocumentIdRoute
  '/settings/$setting': typeof SettingsSettingRoute
  '/share/$document': typeof ShareDocumentRoute
  '/vault/$id': typeof VaultIdRoute
  '/workflow/$id': typeof WorkflowIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/404': typeof R404Route
  '/callback': typeof CallbackRoute
  '/documents': typeof DocumentsRoute
  '/home': typeof HomeRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/notifications': typeof NotificationsRoute
  '/subscription': typeof SubscriptionRoute
  '/unauthorized': typeof UnauthorizedRoute
  '/orgform': typeof OrgformLazyRoute
  '/document/$id': typeof DocumentIdRoute
  '/settings/$setting': typeof SettingsSettingRoute
  '/share/$document': typeof ShareDocumentRoute
  '/vault/$id': typeof VaultIdRoute
  '/workflow/$id': typeof WorkflowIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/404'
    | '/callback'
    | '/documents'
    | '/home'
    | '/login'
    | '/logout'
    | '/notifications'
    | '/subscription'
    | '/unauthorized'
    | '/orgform'
    | '/document/$id'
    | '/settings/$setting'
    | '/share/$document'
    | '/vault/$id'
    | '/workflow/$id'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/404'
    | '/callback'
    | '/documents'
    | '/home'
    | '/login'
    | '/logout'
    | '/notifications'
    | '/subscription'
    | '/unauthorized'
    | '/orgform'
    | '/document/$id'
    | '/settings/$setting'
    | '/share/$document'
    | '/vault/$id'
    | '/workflow/$id'
  id:
    | '__root__'
    | '/'
    | '/404'
    | '/callback'
    | '/documents'
    | '/home'
    | '/login'
    | '/logout'
    | '/notifications'
    | '/subscription'
    | '/unauthorized'
    | '/orgform'
    | '/document/$id'
    | '/settings/$setting'
    | '/share/$document'
    | '/vault/$id'
    | '/workflow/$id'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  R404Route: typeof R404Route
  CallbackRoute: typeof CallbackRoute
  DocumentsRoute: typeof DocumentsRoute
  HomeRoute: typeof HomeRoute
  LoginRoute: typeof LoginRoute
  LogoutRoute: typeof LogoutRoute
  NotificationsRoute: typeof NotificationsRoute
  SubscriptionRoute: typeof SubscriptionRoute
  UnauthorizedRoute: typeof UnauthorizedRoute
  OrgformLazyRoute: typeof OrgformLazyRoute
  DocumentIdRoute: typeof DocumentIdRoute
  SettingsSettingRoute: typeof SettingsSettingRoute
  ShareDocumentRoute: typeof ShareDocumentRoute
  VaultIdRoute: typeof VaultIdRoute
  WorkflowIdRoute: typeof WorkflowIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  R404Route: R404Route,
  CallbackRoute: CallbackRoute,
  DocumentsRoute: DocumentsRoute,
  HomeRoute: HomeRoute,
  LoginRoute: LoginRoute,
  LogoutRoute: LogoutRoute,
  NotificationsRoute: NotificationsRoute,
  SubscriptionRoute: SubscriptionRoute,
  UnauthorizedRoute: UnauthorizedRoute,
  OrgformLazyRoute: OrgformLazyRoute,
  DocumentIdRoute: DocumentIdRoute,
  SettingsSettingRoute: SettingsSettingRoute,
  ShareDocumentRoute: ShareDocumentRoute,
  VaultIdRoute: VaultIdRoute,
  WorkflowIdRoute: WorkflowIdRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/404",
        "/callback",
        "/documents",
        "/home",
        "/login",
        "/logout",
        "/notifications",
        "/subscription",
        "/unauthorized",
        "/orgform",
        "/document/$id",
        "/settings/$setting",
        "/share/$document",
        "/vault/$id",
        "/workflow/$id"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/404": {
      "filePath": "404.tsx"
    },
    "/callback": {
      "filePath": "callback.tsx"
    },
    "/documents": {
      "filePath": "documents.tsx"
    },
    "/home": {
      "filePath": "home.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/logout": {
      "filePath": "logout.tsx"
    },
    "/notifications": {
      "filePath": "notifications.tsx"
    },
    "/subscription": {
      "filePath": "subscription.tsx"
    },
    "/unauthorized": {
      "filePath": "unauthorized.tsx"
    },
    "/orgform": {
      "filePath": "orgform.lazy.tsx"
    },
    "/document/$id": {
      "filePath": "document.$id.tsx"
    },
    "/settings/$setting": {
      "filePath": "settings.$setting.tsx"
    },
    "/share/$document": {
      "filePath": "share.$document.tsx"
    },
    "/vault/$id": {
      "filePath": "vault.$id.tsx"
    },
    "/workflow/$id": {
      "filePath": "workflow.$id.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
