import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { VaultIcon } from '@fluency/ui/components/DashboardShell/VaultIcon';
import { Building2, Lock, Unlock, Plus } from 'lucide-react';
import { useToast } from '@fluency/ui/components/ui/use-toast';
import { useNavigate } from '@tanstack/react-router';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@fluency/ui/components/ui/form';
import { Input } from '@fluency/ui/components/ui/input';
import { Button } from '@fluency/ui/components/ui/button';
import { Switch } from '@fluency/ui/components/ui/switch';
import { Textarea } from '@fluency/ui/components/ui/textarea';
import { CreateVaultResponse } from '../hooks/useVaultOperations';
import {
  createVaultSchema,
  CreateVaultFormData,
} from '../../utils/vaultSchema';
import { Logger } from '@fluency/ui/features/Logger';

interface VaultSwitchProps {
  id: keyof Pick<CreateVaultFormData, 'isPublic' | 'isGlobal' | 'isLocked'>;
  label: string;
  description: string;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  icon: React.ReactNode;
}

const VaultSwitch = ({
  id,
  label,
  description,
  checked,
  onCheckedChange,
  icon,
}: VaultSwitchProps) => (
  <div className="items-center mb-4 flex justify-between">
    <div className="flex gap-4 items-center min-w-[200px]">
      <Switch id={id} checked={checked} onCheckedChange={onCheckedChange} />
      <div className="flex items-start justify-start flex-col gap-1">
        <FormLabel className="font-medium" htmlFor={id}>
          {label}
        </FormLabel>
        <FormDescription className="text-sm text-slate-500">
          {description}
        </FormDescription>
      </div>
    </div>
    <div className={checked ? 'text-fluency-500' : 'text-muted-foreground'}>
      {icon}
    </div>
  </div>
);

const getVaultSwitches = (isLocked: boolean) => [
  {
    id: 'isGlobal' as const,
    label: 'Add entire organisation',
    description: 'Grant access to everyone in your organisation automatically',
    icon: <Building2 className="h-5 w-5" />,
  },
  {
    id: 'isLocked' as const,
    label: isLocked ? 'Vault is locked' : 'Vault is unlocked',
    description: isLocked
      ? 'Vault will be locked. All changes are prevented.'
      : 'Changes can be made to the vault and its resources',
    icon: isLocked ? (
      <Lock className="h-5 w-5" />
    ) : (
      <Unlock className="h-5 w-5" />
    ),
  },
];

interface CreateVaultDialogProps {
  onCreateVault: (data: CreateVaultFormData) => Promise<CreateVaultResponse>;
}

export const CreateVaultDialog = ({
  onCreateVault,
}: CreateVaultDialogProps) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<CreateVaultFormData>({
    resolver: zodResolver(createVaultSchema),
    defaultValues: {
      name: '',
      description: '',
      isPublic: false,
      isGlobal: false,
      isLocked: false,
    },
    mode: 'onChange',
  });

  const isLocked = form.watch('isLocked');

  const onSubmit = async (data: CreateVaultFormData) => {
    setIsLoading(true);
    try {
      const response = await onCreateVault(data);
      setIsOpen(false);
      form.reset();
      toast({
        title: 'Success',
        description: 'Vault created successfully',
      });
      navigate({
        to: '/vault/$id',
        params: { id: response.vault.id },
      });
    } catch (error) {
      Logger.error(error);
      toast({
        title: 'Error',
        description: 'Failed to create vault',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    form.reset();
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button size="sm">
          <Plus className="mr-2 h-4 w-4" /> Create Vault
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[525px] p-6">
        <DialogHeader className="mb-2">
          <DialogTitle className="flex gap-2 items-center text-xl font-semibold">
            <VaultIcon className="h-5 w-5" />
            Create New Vault
          </DialogTitle>
          <DialogDescription className="text-slate-500">
            Create a secure space to store and share your resources
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel
                    className={`flex items-center justify-between ${
                      form.formState.errors.name ? 'text-red-500' : ''
                    }`}
                  >
                    <div>Vault Name</div>
                    <div className="text-sm text-slate-500">
                      {field.value.length}/50
                    </div>
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="e.g., Project Documentation, Team Resources..."
                      disabled={isLoading}
                      className={
                        form.formState.errors.name
                          ? 'border-red-500 focus-visible:ring-red-500'
                          : ''
                      }
                    />
                  </FormControl>
                  <FormMessage className="mt-1 text-sm text-red-500" />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="flex items-center justify-between">
                    <div
                      className={
                        form.formState.errors.description ? 'text-red-500' : ''
                      }
                    >
                      Description
                    </div>
                    <div className="text-sm text-slate-500">
                      {field.value?.length || 0}/200
                    </div>
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder="What will this vault be used for?"
                      disabled={isLoading}
                      className={`
                       resize-none ${
                         form.formState.errors.description
                           ? 'border-red-500 focus-visible:ring-red-500'
                           : ''
                       }
                      `}
                    />
                  </FormControl>

                  <FormMessage className="mt-1 text-sm text-red-500" />
                </FormItem>
              )}
            />

            <div className="space-y-3 pt-2">
              {getVaultSwitches(isLocked).map(
                ({ id, label, description, icon }) => (
                  <FormField
                    key={id}
                    control={form.control}
                    name={id}
                    render={({ field }) => (
                      <VaultSwitch
                        id={id}
                        label={label}
                        description={description}
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        icon={icon}
                      />
                    )}
                  />
                )
              )}
            </div>

            <DialogFooter className="gap-2 sm:gap-2">
              <DialogClose asChild>
                <Button
                  variant="outline"
                  disabled={isLoading}
                  onClick={handleClose}
                  className="flex-1"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button
                type="submit"
                disabled={!form.formState.isValid || isLoading}
                className={`flex-1 ${
                  !form.formState.isValid
                    ? 'opacity-50 bg-fluency-600'
                    : 'bg-fluency-600'
                }`}
              >
                {isLoading ? 'Creating...' : 'Create Vault'}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
