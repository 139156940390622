import { useQuery } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { handleApiError } from '../utils/api';
import { Logger } from '@fluency/ui/features/Logger';

// Types based on actual API responses
interface DocumentResponse {
  id: string;
  name: string;
  createdDate: string;
}

interface WorkflowResponse {
  workflowId: string;
  workflowName: string;
  createdAt: string;
}

interface DocumentsApiResponse {
  documents: DocumentResponse[];
}

interface WorkflowsApiResponse {
  workflows: WorkflowResponse[];
}

// Normalized types for use in the application
export interface RecentDocument {
  id: string;
  name: string;
  createdDate: string;
  type: 'document';
}

export interface RecentWorkflow {
  id: string;
  name: string;
  createdDate: string;
  type: 'workflow';
}

export type RecentItem = RecentDocument | RecentWorkflow;

interface UseRecentAssetsOptions {
  vaultId?: string;
}

export const useRecentAssets = (options?: UseRecentAssetsOptions) => {
  const { accessToken } = useAuth();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const { vaultId } = options || {};

  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  });

  const requestOptions: RequestInit = {
    method: 'POST',
    headers,
    redirect: 'follow',
    body: JSON.stringify(vaultId ? { vaultId } : {}),
  };

  return useQuery<RecentItem[], Error>({
    queryKey: ['recentAssets', vaultId],
    queryFn: async () => {
      try {
        // Construct base URLs
        const documentsUrl = new URL(`${apiBaseUrl}/documents/recent`);
        const workflowsUrl = new URL(`${apiBaseUrl}/workflows/recent`);

        Logger.log('Fetching documents from:', documentsUrl.toString());
        Logger.log('Fetching workflows from:', workflowsUrl.toString());
        Logger.log('Request headers:', Object.fromEntries(headers.entries()));
        Logger.log('Request body:', requestOptions.body);

        // Fetch both recent documents and workflows in parallel
        const results = await Promise.allSettled([
          fetch(documentsUrl.toString(), requestOptions),
          fetch(workflowsUrl.toString(), requestOptions),
        ]);

        let documents: RecentDocument[] = [];
        let workflows: RecentWorkflow[] = [];

        // Handle documents request
        if (results[0].status === 'fulfilled' && results[0].value.ok) {
          try {
            const rawResponse = await results[0].value.text();
            const docsData: DocumentsApiResponse = JSON.parse(rawResponse);

            documents = docsData.documents.map((doc) => ({
              id: doc.id,
              name: doc.name,
              createdDate: doc.createdDate,
              type: 'document' as const,
            }));
          } catch (error) {
            Logger.error('Error processing documents response:', error);
          }
        } else {
          Logger.log('RESPONSE', results[0]);
        }

        // Handle workflows request
        if (results[1].status === 'fulfilled' && results[1].value.ok) {
          try {
            const rawResponse = await results[1].value.text();
            const workflowsData: WorkflowsApiResponse = JSON.parse(rawResponse);

            workflows = workflowsData.workflows.map((workflow) => ({
              id: workflow.workflowId,
              name: workflow.workflowName,
              createdDate: workflow.createdAt,
              type: 'workflow' as const,
            }));
          } catch (error) {
            Logger.error('Error processing workflows response:', error);
          }
        } else {
          Logger.error('Workflows request failed:');
        }

        // Combine and sort by date
        const allItems: RecentItem[] = [...documents, ...workflows].sort(
          (a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateB.getTime() - dateA.getTime();
          }
        );
        return allItems;
      } catch (error) {
        Logger.error('Error in useRecentAssets:', error);
        return handleApiError(error, 'Failed to fetch recent items');
      }
    },
  });
};

// Type guard for use in components
export function isDocument(item: RecentItem): item is RecentDocument {
  return item.type === 'document';
}
