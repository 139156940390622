import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DocumentationResponse } from '../../../hooks/documentation/types/master';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useToast } from '@fluency/ui/components/ui/use-toast';
import { Logger } from '@fluency/ui/features/Logger';

interface DeleteStepProps {
  stepIds: string[];
}

interface DeleteStepResponse {
  message: string;
  stepsDeleted: {
    stepId: string;
    stepNumber: number;
    stepDescription: string;
    stepType: string;
    documentationId: string;
  }[];
}

async function deleteStep({
  stepIds,
  accessToken,
}: DeleteStepProps & {
  accessToken: string;
}): Promise<DeleteStepResponse> {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const endpoint = `/step/delete`;
  const fullUrl = apiBaseUrl + endpoint;

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${accessToken}`);

  const raw = JSON.stringify({ stepIds });

  const requestOptions: RequestInit = {
    method: 'DELETE',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  try {
    const response = await fetch(fullUrl, requestOptions);
    if (!response.ok) {
      const error = await response.json();
      Logger.error('Error in deleteStep:', error);
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result: DeleteStepResponse = await response.json();
    return result;
  } catch (error) {
    Logger.error('Error in deleteStep:', error);
    throw error;
  }
}

interface UseDeleteStepProps {
  documentationId: string;
}

const useDeleteStep = ({ documentationId }: UseDeleteStepProps) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();
  const { toast } = useToast();

  return useMutation<
    DeleteStepResponse,
    Error,
    DeleteStepProps,
    { previousDocInfo: DocumentationResponse }
  >({
    mutationFn: async (args) => {
      return deleteStep({
        ...args,
        accessToken: accessToken ?? '',
      });
    },
    mutationKey: ['delete-step'],
    onError: (error) => {
      Logger.error('Error deleting step:', error);
      toast({
        variant: 'destructive',
        title: 'Something went wrong.',
        description: 'Please try again later.',
      });
    },
    onSuccess: () => {
      toast({
        title: 'Success',
        description: `Successfully deleted step`,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['docInfo', documentationId],
      });
    },
  });
};

export default useDeleteStep;
