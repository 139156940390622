import { Dialog, DialogContent } from '@fluency/ui/components/ui/dialog';
import { RefreshCw, User2, UserPlus } from 'lucide-react';
import { HideIfViewer } from '@fluency/ui/features/AuthGuard';
import useSelectTeam from '../hooks/useSelectTeam';
import { useUpdateTeam } from '../hooks/useUpdateTeam';
import useTeamUserAssociation from '../hooks/useTeamUserAssociation';
import { useDeleteTeam } from '../hooks/useDeleteTeam';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@fluency/ui/components/ui/form';
import { Input } from '@fluency/ui/components/ui/input';
import { Textarea } from '@fluency/ui/components/ui/textarea';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@fluency/ui/components/ui/popover';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@fluency/ui/components/ui/command';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@fluency/ui/components/ui/alert-dialog';
import { Badge } from '@fluency/ui/components/ui/badge';
import { ScrollArea } from '@fluency/ui/components/ui/scroll-area';
import useGetOrgUsers from '@fluency/ui/hooks/organisation/useGetOrgUsers';

const teamSchema = z.object({
  teamName: z
    .string()
    .min(1, 'Team name is required')
    .max(30, 'Team name must be 30 characters or less'),
  teamDescription: z
    .string()
    .max(240, 'Description must be 240 characters or less')
    .optional(),
});

type TeamFormData = z.infer<typeof teamSchema>;

interface UnifiedTeamDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  teamId: string;
}

const UnifiedTeamDialog = ({
  isOpen,
  onOpenChange,
  teamId,
}: UnifiedTeamDialogProps) => {
  const { data: teamData, isLoading: isLoadingTeam } = useSelectTeam(teamId);
  const { data: orgData, isLoading: isLoadingOrg } = useGetOrgUsers();
  const { mutate: updateTeam, isPending: isUpdating } = useUpdateTeam();
  const { associateUsersToTeam, disassociateUsersFromTeam, isDisassociating } =
    useTeamUserAssociation();
  const { mutate: deleteTeam } = useDeleteTeam();
  const { user: currentUser } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddingMember, setIsAddingMember] = useState(false);

  const teamForm = useForm<TeamFormData>({
    resolver: zodResolver(teamSchema),
    defaultValues: {
      teamName: '',
      teamDescription: '',
    },
  });

  useEffect(() => {
    if (teamData?.team) {
      teamForm.reset({
        teamName: teamData.team.teamName,
        teamDescription: teamData.team.teamDescription || '',
      });
    }
  }, [teamData?.team, teamForm]);

  const onSubmitTeam = async (formData: TeamFormData) => {
    updateTeam(
      {
        teamId,
        teamName: formData.teamName,
        teamDescription: formData.teamDescription,
      },
      {
        onSuccess: () => {
          onOpenChange(false);
        },
      }
    );
  };

  const handleAddMember = (userId: string) => {
    setIsAddingMember(true);
    try {
      associateUsersToTeam({
        teamId,
        userIds: [userId],
      });
    } finally {
      setIsAddingMember(false);
      setSearchTerm('');
    }
  };

  const handleRemoveUser = async (userId: string, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    disassociateUsersFromTeam({
      teamId,
      userIds: [userId],
    });
  };

  const handleDeleteTeam = () => {
    deleteTeam({ teamId });
    onOpenChange(false);
  };

  if (isLoadingTeam || isLoadingOrg) {
    return (
      <Dialog open={isOpen} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[600px]">
          <div className="flex items-center justify-center text-muted-foreground py-8">
            <RefreshCw className="w-4 h-4 mr-2 animate-spin" />
            <span className="text-sm">Loading team details...</span>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  const availableUsers =
    orgData?.memberships.filter(
      (membership) =>
        !teamData?.usersInTeam.some(
          (teamUser) => teamUser.userId === membership.user.id
        )
    ) || [];

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <Form {...teamForm}>
          <form
            onSubmit={teamForm.handleSubmit(onSubmitTeam)}
            className="space-y-4"
          >
            {/* Team Details Section */}
            <div className="space-y-4">
              <div>
                <h3 className="text-md font-medium mb-2">Team Details</h3>
                <FormField
                  control={teamForm.control}
                  name="teamName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={teamForm.control}
                name="teamDescription"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea
                        {...field}
                        className="resize-none h-20"
                        placeholder="Add a description for your team..."
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {/* Team Members Section */}
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <h3 className="text-md font-medium">Team Members</h3>
                <HideIfViewer>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant="secondary"
                        size="sm"
                        className="gap-2"
                        disabled={isAddingMember}
                      >
                        {isAddingMember ? (
                          <RefreshCw className="h-4 w-4 animate-spin" />
                        ) : (
                          <UserPlus className="h-4 w-4" />
                        )}
                        Add Member
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-[300px] p-0" align="end">
                      <Command>
                        <CommandInput
                          placeholder="Search members..."
                          value={searchTerm}
                          onValueChange={setSearchTerm}
                        />
                        <CommandEmpty>No members found</CommandEmpty>
                        <CommandGroup className="max-h-[200px] overflow-auto">
                          {availableUsers
                            .filter(
                              (membership) =>
                                membership.user.email
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase()) ||
                                `${membership.user.firstName} ${membership.user.lastName}`
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                            )
                            .map((membership) => (
                              <CommandItem
                                key={membership.user.id}
                                onSelect={() =>
                                  handleAddMember(membership.user.id)
                                }
                                className="flex items-center gap-2 py-2 cursor-pointer"
                              >
                                <User2 className="h-4 w-4 text-muted-foreground flex-shrink-0" />
                                <div className="flex flex-col flex-1 overflow-hidden">
                                  <p className="truncate  text-sm">
                                    {membership.user.firstName}{' '}
                                    {membership.user.lastName}
                                  </p>
                                  <p className="text-sm text-muted-foreground truncate">
                                    {membership.user.email}
                                  </p>
                                </div>
                              </CommandItem>
                            ))}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                </HideIfViewer>
              </div>

              <div className="border rounded-lg">
                <ScrollArea className="max-h-[200px] h-[200px]">
                  {teamData?.usersInTeam.map((user) => (
                    <div
                      key={user.userId}
                      className="flex items-center justify-between p-2 hover:bg-muted/50"
                    >
                      <div className="flex items-center gap-2 min-w-0">
                        <User2 className="h-5 w-5 text-muted-foreground flex-shrink-0" />
                        <div className="flex flex-col min-w-0">
                          <div className="flex items-center gap-2">
                            <span className="font-medium truncate text-sm">
                              {user.firstName} {user.lastName}
                            </span>
                            {currentUser?.id === user.userId && (
                              <Badge>You</Badge>
                            )}
                          </div>
                          <span className="text-sm text-muted-foreground truncate">
                            {user.email}
                          </span>
                        </div>
                      </div>
                      <HideIfViewer>
                        <Button
                          size="sm"
                          variant="ghost"
                          className="text-muted-foreground hover:text-destructive hover:bg-destructive/10"
                          onClick={(e) => handleRemoveUser(user.userId, e)}
                          disabled={isDisassociating}
                        >
                          {isDisassociating ? (
                            <RefreshCw className="h-4 w-4 animate-spin" />
                          ) : (
                            'Remove'
                          )}
                        </Button>
                      </HideIfViewer>
                    </div>
                  ))}
                  {teamData?.usersInTeam.length === 0 && (
                    <div className="p-4 text-center text-sm text-muted-foreground">
                      No team members yet
                    </div>
                  )}
                </ScrollArea>
              </div>
            </div>

            {/* Footer Actions */}
            <div className="flex items-center justify-between pt-2">
              <HideIfViewer>
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button type="button" variant="destructive">
                      Delete Team
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Delete Team</AlertDialogTitle>
                      <AlertDialogDescription>
                        This will permanently delete the team and remove all
                        associated data. This action cannot be undone.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction
                        onClick={handleDeleteTeam}
                        className="bg-destructive hover:bg-destructive/90"
                      >
                        Delete Team
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </HideIfViewer>
              <Button type="submit" disabled={isUpdating}>
                {isUpdating && (
                  <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
                )}
                Save Changes
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default UnifiedTeamDialog;
