import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  DocumentationResponse,
  Step,
  ScreenshotType,
  AnnotationType,
} from '../../../hooks/documentation/types/master';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useToast } from '@fluency/ui/components/ui/use-toast';
import { Logger } from '@fluency/ui/features/Logger';

interface UpdateDocumentAnnotationsColorProps {
  documentId: string;
  color: string;
}

interface ToggleDocumentAnnotationsVisibilityProps {
  documentId: string;
  visible: boolean;
}

async function updateDocumentAnnotationsColor(
  { documentId, color }: UpdateDocumentAnnotationsColorProps,
  accessToken: string
): Promise<void> {
  const endpoint = `/documents/updateAnnotationColors`;
  const apiUrl = import.meta.env.VITE_SERVER_API_URL;
  const fullUrl = apiUrl + endpoint;

  const response = await fetch(fullUrl, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ documentId, color }),
  });

  if (!response.ok) {
    const body = await response.json();
    Logger.error('Error updating document annotation colors', body);
    throw new Error(`HTTP error! status: ${response.status}`);
  }
}

async function toggleDocumentAnnotationsVisibility(
  { documentId, visible }: ToggleDocumentAnnotationsVisibilityProps,
  accessToken: string
): Promise<void> {
  const endpoint = `/documents/toggleAnnotationsVisibility`;
  const apiUrl = import.meta.env.VITE_SERVER_API_URL;
  const fullUrl = apiUrl + endpoint;

  const response = await fetch(fullUrl, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ documentId, visible }),
  });

  if (!response.ok) {
    const body = await response.json();
    Logger.error('Error toggling document annotations visibility', body);
    throw new Error(`HTTP error! status: ${response.status}`);
  }
}

const useUpdateDocumentAnnotations = (documentId: string) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  const updateColorMutation = useMutation<
    void,
    Error,
    UpdateDocumentAnnotationsColorProps,
    { previousDocInfo: DocumentationResponse | undefined }
  >({
    mutationFn: (props) => {
      if (!accessToken) {
        throw new Error('Access token is missing');
      }
      return updateDocumentAnnotationsColor(props, accessToken);
    },
    onMutate: async ({ color }) => {
      await queryClient.cancelQueries({ queryKey: ['docInfo', documentId] });
      const previousDocInfo = queryClient.getQueryData<DocumentationResponse>([
        'docInfo',
        documentId,
      ]);

      if (previousDocInfo) {
        queryClient.setQueryData<DocumentationResponse>(
          ['docInfo', documentId],
          (old) => {
            if (!old) return old;
            const updatedSteps = old.documentation.steps.map((step: Step) => ({
              ...step,
              screenshots: step.screenshots.map(
                (screenshot: ScreenshotType) => ({
                  ...screenshot,
                  annotations: screenshot.annotations.map(
                    (annotation: AnnotationType) => ({
                      ...annotation,
                      color,
                    })
                  ),
                })
              ),
            }));
            return {
              ...old,
              documentation: {
                ...old.documentation,
                steps: updatedSteps,
              },
            };
          }
        );
      }

      return { previousDocInfo };
    },
    onError: (err, _newColor, context) => {
      if (context?.previousDocInfo) {
        queryClient.setQueryData<DocumentationResponse>(
          ['docInfo', documentId],
          context.previousDocInfo
        );
      }
      Logger.error('Error updating annotation color', err);
      toast({
        title: 'Error',
        description: 'Failed to update annotation color. Please try again.',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['docInfo', documentId] });
    },
  });

  const toggleVisibilityMutation = useMutation<
    void,
    Error,
    ToggleDocumentAnnotationsVisibilityProps,
    { previousDocInfo: DocumentationResponse | undefined }
  >({
    mutationFn: (props) => {
      if (!accessToken) {
        throw new Error('Access token is missing');
      }
      return toggleDocumentAnnotationsVisibility(props, accessToken);
    },
    onMutate: async ({ visible }) => {
      await queryClient.cancelQueries({ queryKey: ['docInfo', documentId] });
      const previousDocInfo = queryClient.getQueryData<DocumentationResponse>([
        'docInfo',
        documentId,
      ]);

      if (previousDocInfo) {
        queryClient.setQueryData<DocumentationResponse>(
          ['docInfo', documentId],
          (old) => {
            if (!old) return old;
            const updatedSteps = old.documentation.steps.map((step: Step) => ({
              ...step,
              screenshots: step.screenshots.map(
                (screenshot: ScreenshotType) => ({
                  ...screenshot,
                  annotation: visible,
                })
              ),
            }));
            return {
              ...old,
              documentation: {
                ...old.documentation,
                steps: updatedSteps,
              },
            };
          }
        );
      }

      return { previousDocInfo };
    },
    onError: (err, _newVisibility, context) => {
      if (context?.previousDocInfo) {
        queryClient.setQueryData<DocumentationResponse>(
          ['docInfo', documentId],
          context.previousDocInfo
        );
      }
      Logger.error('Error toggling annotation visibility', err);
      toast({
        title: 'Error',
        description:
          'Failed to toggle annotation visibility. Please try again.',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['docInfo', documentId] });
    },
  });

  return {
    updateAnnotationColor: updateColorMutation.mutate,
    toggleAnnotationVisibility: toggleVisibilityMutation.mutate,
    isUpdatingColor: updateColorMutation.isPending,
    isTogglingVisibility: toggleVisibilityMutation.isPending,
  };
};

export default useUpdateDocumentAnnotations;
