import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@fluency/ui/components/ui/dialog';
import { Label } from '@fluency/ui/components/ui/label';
import { Slider } from '@fluency/ui/components/ui/slider';
import { useState } from 'react';
import useBulkCropScreenshots, { CropInfo } from '../hooks/useBulkCrop';
import { Logger } from '@fluency/ui/features/Logger';

interface BulkCropModalProps {
  documentId: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export default function BulkCropModal({
  documentId,
  open,
  onOpenChange,
}: BulkCropModalProps) {
  const [scaleFactor, setScaleFactor] = useState(1);
  const bulkCropMutation = useBulkCropScreenshots(documentId);

  const handleScaleChange = (value: number[]) => {
    if (value[0] !== undefined) {
      setScaleFactor(value[0]);
    }
  };

  const handleCrop = async () => {
    try {
      const cropInfo: CropInfo = {
        scaleFactor: Number(scaleFactor),
      };
      await bulkCropMutation.mutateAsync(cropInfo);
      onOpenChange(false);
    } catch (error) {
      Logger.error('Error during bulk crop:', error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[750px] max-h-[90vh] overflow-hidden">
        <DialogHeader className="pb-4">
          <DialogTitle>Batch Screenshot Adjustment</DialogTitle>
          <DialogDescription>
            Optimise all screenshots by focusing on the relevant content.
            Perfect for high-resolution displays or ultrawide monitors.
          </DialogDescription>
        </DialogHeader>
        <div className="grid grid-cols-2 gap-8 h-full overflow-hidden">
          {/* Left column - Settings */}
          <div className="space-y-6 overflow-y-auto pr-4 max-h-[calc(90vh-12rem)]">
            <div className="grid gap-2">
              <Label htmlFor="scale-factor">
                Zoom Level: {scaleFactor.toFixed(2)}x
              </Label>
              <Slider
                id="scale-factor"
                min={1}
                max={4}
                step={0.1}
                value={[scaleFactor]}
                onValueChange={handleScaleChange}
              />
            </div>

            <div className="rounded-lg border bg-card p-4">
              <p className="text-sm text-muted-foreground">
                The zoom level determines how much to focus on the clicked area
                of each screenshot. A value of 1.0x maintains the original size,
                while higher values zoom in to emphasize the selected content.
                This is particularly useful for screenshots from high-resolution
                or ultrawide displays.
              </p>
            </div>
          </div>

          {/* Right column - Preview */}
          <div className="flex flex-col max-h-[calc(90vh-12rem)]">
            <Label className="mb-2">Preview</Label>
            <div className="relative h-full">
              <div className="bg-muted rounded relative overflow-hidden w-full h-full">
                <div
                  className="absolute inset-0 flex items-center justify-center text-muted-foreground"
                  style={{
                    transform: `scale(${scaleFactor})`,
                  }}
                >
                  Preview Area
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end space-x-2 pt-4 border-t">
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button onClick={handleCrop} disabled={bulkCropMutation.isPending}>
            {bulkCropMutation.isPending ? 'Processing...' : 'Apply to All'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
