import { useState, useRef, useEffect } from 'react';
import { Step } from '../../../hooks/documentation/types/master';
import { useUpdateStepDescription, useDeleteStep } from '../hooks';

export const useStepManagement = (docId: string, initialSteps: Step[]) => {
  const [editStepDescription, setEditStepDescription] = useState<string>('');
  const [editingId, setEditingId] = useState<string | null>(null);
  const [filteredSteps, setFilteredSteps] = useState<Step[]>(initialSteps);

  const editingStateRef = useRef<{ id: string | null; description: string }>({
    id: null,
    description: '',
  });

  const { mutateAsync: updateStepDescription } = useUpdateStepDescription({
    documentationId: docId,
  });

  const { mutateAsync: deleteStep } = useDeleteStep({
    documentationId: docId,
  });

  const handleEditStepDescription = ({
    stepId,
    description,
  }: {
    stepId: null | string;
    description: string;
  }) => {
    setEditingId(stepId);
    setEditStepDescription(description);
    editingStateRef.current = { id: stepId, description };
  };

  const handleUpdateStepDescription = async ({
    stepId,
    description,
  }: {
    stepId: string;
    description: string;
  }) => {
    if (stepId) {
      await updateStepDescription({
        stepId,
        description,
      });
    }
  };

  const handleSaveStepDescription = (editingId: string | null) => {
    if (editingId) {
      handleUpdateStepDescription({
        stepId: editingId,
        description: editStepDescription,
      });

      const updatedSteps = filteredSteps.map((step) =>
        step.stepId === editingId
          ? { ...step, stepDescription: editStepDescription }
          : step
      );
      setFilteredSteps(updatedSteps);

      handleEditStepDescription({
        stepId: null,
        description: '',
      });
    }
  };

  const handleDeleteStep = async (stepId: string) => {
    setFilteredSteps((steps) => steps.filter((step) => step.stepId !== stepId));
    if (stepId) {
      await deleteStep({
        stepIds: [stepId],
      });
    }
  };

  useEffect(() => {
    setFilteredSteps(initialSteps);
    if (editingStateRef.current.id) {
      setEditingId(editingStateRef.current.id);
      setEditStepDescription(editingStateRef.current.description);
    }
  }, [initialSteps]);

  return {
    editStepDescription,
    editingId,
    filteredSteps,
    handleEditStepDescription,
    handleUpdateStepDescription,
    handleSaveStepDescription,
    handleDeleteStep,
  };
};
