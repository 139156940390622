import React from 'react';

interface StepRendererProps {
  stepNumber: number;
  title: string;
  description: string;
  action: React.ReactNode;
  isActive: boolean;
}

export const StepRenderer: React.FC<StepRendererProps> = ({
  stepNumber,
  title,
  description,
  action,
  isActive,
}) => {
  return (
    <div
      className={`flex w-full justify-between items-center ${
        isActive ? 'opacity-100' : 'opacity-50'
      }`}
    >
      <div>
        <h3
          className={`font-semibold ${
            isActive ? 'text-black' : 'text-gray-500'
          }`}
        >
          {stepNumber}. {title}
        </h3>
        <p
          className={`text-sm ${
            isActive ? 'text-muted-foreground' : 'text-gray-400'
          }`}
        >
          {description}
        </p>
      </div>
      {action}
    </div>
  );
};
