import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { toast } from '@fluency/ui/components/ui/use-toast';
import {
  ResourceRequest,
  PaginatedResponse,
  CreateRequestInput,
} from '../types/types';
import { useDocumentPermissions } from '@fluency/ui/hooks/fga/useDocumentPermissions';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import useGetOrgUsers from '@fluency/ui/hooks/organisation/useGetOrgUsers';
import { Logger } from '@fluency/ui/features/Logger';

const getHumanReadableError = (error: Error): string => {
  const message = error.message.toLowerCase();

  if (message.includes('unauthorized')) {
    return 'You are not authorized to perform this action';
  }

  if (message.includes('not found')) {
    return 'The requested resource could not be found';
  }

  // Add specific error cases
  if (message.includes('recipient')) {
    return 'There was an issue with the selected recipients';
  }

  if (message.includes('due date')) {
    return 'Please select a valid due date';
  }

  if (message.includes('already exists')) {
    return 'A review request already exists for one or more recipients';
  }

  // Default message
  return 'Something went wrong. Please try again later';
};

export const useResourceRequests = (documentId: string) => {
  const { accessToken, user } = useAuth();
  const queryClient = useQueryClient();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const { isManager, isLoading: isLoadingPermissions } =
    useDocumentPermissions(documentId);

  const { data: orgUsersData } = useGetOrgUsers();

  const fetchRequests = async ({
    pageParam = 1,
  }): Promise<PaginatedResponse> => {
    if (!accessToken || !documentId) {
      throw new Error('Missing required parameters');
    }

    try {
      const url = new URL(`${apiBaseUrl}/resourceRequests`);
      const params = {
        page: pageParam.toString(),
        pageSize: '10',
        resourceId: documentId,
        resourceType: 'DOCUMENTATION',
        // role: isManager ? 'requester' : 'recipient',
      };

      Object.entries(params).forEach(([key, value]) => {
        if (value) url.searchParams.append(key, value);
      });

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message || 'Failed to fetch resource requests'
        );
      }

      return await response.json();
    } catch (error) {
      Logger.error('Fetch error:', error);
      throw error;
    }
  };

  const updateRequestsInCache = (
    updater: (requests: ResourceRequest[]) => ResourceRequest[]
  ) => {
    queryClient.setQueryData<{
      pages: PaginatedResponse[];
      pageParams: number[];
    }>(['resourceRequests', documentId, isManager], (old) => {
      if (!old?.pages) return old;

      return {
        ...old,
        pages: old.pages.map((page) => ({
          ...page,
          requests: updater(page.requests),
        })),
      };
    });
  };

  const completeRequest = useMutation({
    mutationFn: async (requestId: string) => {
      if (!accessToken) throw new Error('No access token available');

      const response = await fetch(
        `${apiBaseUrl}/resourceRequests/${requestId}/status`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ status: 'COMPLETED' }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to complete request');
      }

      return response.json();
    },
    onMutate: async (requestId) => {
      await queryClient.cancelQueries({
        queryKey: ['resourceRequests', documentId, isManager],
      });

      const previousData = queryClient.getQueryData<{
        pages: PaginatedResponse[];
        pageParams: number[];
      }>(['resourceRequests', documentId, isManager]);

      updateRequestsInCache((requests) =>
        requests.map((request) => {
          if (request.id === requestId) {
            return {
              ...request,
              assignments: request.assignments.map((assignment) =>
                assignment.recipientId === user?.id
                  ? {
                      ...assignment,
                      status: 'COMPLETED',
                      completedAt: new Date().toISOString(),
                      dueDate: request.dueDate,
                    }
                  : assignment
              ),
            };
          }
          return request;
        })
      );

      return { previousData };
    },
    onError: (error, _, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(
          ['resourceRequests', documentId, isManager],
          context.previousData
        );
      }
      toast({
        title: 'Unable to Complete Review',
        description: getHumanReadableError(error),
        variant: 'destructive',
      });
    },
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Document review marked as completed',
      });
    },
  });

  const cancelRequest = useMutation({
    mutationFn: async (requestId: string) => {
      if (!accessToken || !isManager) {
        throw new Error('Unauthorized to cancel requests');
      }

      const response = await fetch(
        `${apiBaseUrl}/resourceRequests/${requestId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to cancel request');
      }
    },
    onMutate: async (requestId) => {
      await queryClient.cancelQueries({
        queryKey: ['resourceRequests', documentId, isManager],
      });

      const previousData = queryClient.getQueryData<{
        pages: PaginatedResponse[];
        pageParams: number[];
      }>(['resourceRequests', documentId, isManager]);

      updateRequestsInCache((requests) =>
        requests.filter((request) => request.id !== requestId)
      );

      return { previousData };
    },
    onError: (error, _, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(
          ['resourceRequests', documentId, isManager],
          context.previousData
        );
      }
      toast({
        title: 'Unable to Cancel Request',
        description: getHumanReadableError(error),
        variant: 'destructive',
      });
    },
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Request cancelled successfully',
      });
    },
  });

  const createRequest = useMutation({
    mutationFn: async (input: CreateRequestInput): Promise<ResourceRequest> => {
      if (!accessToken || !isManager) {
        throw new Error('Unauthorized to create requests');
      }

      const response = await fetch(`${apiBaseUrl}/resourceRequests`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          resourceId: documentId,
          resourceType: 'DOCUMENTATION',
          ...input,
          dueDate: input.dueDate.toISOString(),
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create request');
      }

      const data = await response.json();
      return data.request;
    },
    onMutate: async (newRequest) => {
      await queryClient.cancelQueries({
        queryKey: ['resourceRequests', documentId, isManager],
      });

      const previousData = queryClient.getQueryData<{
        pages: PaginatedResponse[];
        pageParams: number[];
      }>(['resourceRequests', documentId, isManager]);

      const optimisticRequest: ResourceRequest = {
        id: `temp-${Date.now()}`,
        resourceId: documentId,
        resourceType: 'DOCUMENTATION',
        requesterId: user!.id,
        message: newRequest.message,
        dueDate: newRequest.dueDate.toISOString(),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        assignments: newRequest.recipientIds.map((recipientId) => {
          const recipientData = orgUsersData?.memberships.find(
            (member) => member.userId === recipientId
          )?.user;

          return {
            id: `temp-assignment-${recipientId}`,
            recipientId,
            status: 'PENDING',
            dueDate: newRequest.dueDate.toISOString(), // Add this line
            recipient: {
              id: recipientId,
              firstName: recipientData?.firstName || '',
              lastName: recipientData?.lastName || '',
              email: recipientData?.email || '',
            },
            request: {
              id: `temp-${Date.now()}`,
              resourceId: documentId,
              resourceType: 'DOCUMENTATION',
              requesterId: user!.id,
              message: newRequest.message,
              dueDate: newRequest.dueDate.toISOString(),
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              assignments: [],
              requester: {
                id: user!.id,
                firstName: user!.firstName,
                lastName: user!.lastName,
                email: user!.email,
              },
            },
          };
        }),
        requester: {
          id: user!.id,
          firstName: user!.firstName,
          lastName: user!.lastName,
          email: user!.email,
        },
      };

      updateRequestsInCache((requests) => [optimisticRequest, ...requests]);

      return { previousData };
    },
    onError: (error, _, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(
          ['resourceRequests', documentId, isManager],
          context.previousData
        );
      }

      Logger.log('isManager', isManager);

      toast({
        title: 'Unable to Create Request',
        description: getHumanReadableError(error),
        variant: 'destructive',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['resourceRequests', documentId, isManager],
      });
      toast({
        title: 'Success',
        description: 'Document review request created successfully',
      });
    },
  });

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    error,
    isLoading: isLoadingRequests,
    ...queryResults
  } = useInfiniteQuery({
    queryKey: ['resourceRequests', documentId, isManager],
    queryFn: fetchRequests,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const totalPages = Math.ceil(lastPage.total / lastPage.pageSize);
      return lastPage.page < totalPages ? lastPage.page + 1 : undefined;
    },
    enabled: !!documentId && !!accessToken && !isLoadingPermissions,
  });

  const allRequests = data?.pages.flatMap((page) => page.requests) ?? [];
  const userRequest = allRequests.find((request) =>
    request.assignments.some(
      (assignment) => assignment.recipientId === user?.id
    )
  );
  const userAssignment = userRequest?.assignments.find(
    (assignment) => assignment.recipientId === user?.id
  );

  return {
    data: allRequests,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    error,
    isLoading: isLoadingRequests || isLoadingPermissions,
    ...queryResults,

    createRequest: createRequest.mutate,
    cancelRequest: cancelRequest.mutate,
    completeRequest: completeRequest.mutate,

    isCreating: createRequest.isPending,
    isCancelling: cancelRequest.isPending,
    isCompleting: completeRequest.isPending,

    userRequest,
    userAssignment,
    isManager,
  };
};
