import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '@fluency/ui/components/ui/dialog';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@fluency/ui/components/ui/select';
import { useResourceAccessControl } from '@fluency/ui/hooks/fga/useResourceAccessControl';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { ResourceType } from '@fluency/ui/hooks/fga/useFineGrainedAccess';
import { RoleName } from '@fluency/ui/hooks/fga/types/fga';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { usePermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { Logger } from '@fluency/ui/features/Logger';

interface UpdateRoleDialogProps {
  id: string;
  resourceId: string;
  resourceType: ResourceType;
  name: string;
  currentRole: RoleName;
  type: 'user' | 'team';
}

const ROLE_OPTIONS: RoleName[] = ['MANAGER', 'EDITOR', 'VIEWER'];

export const UpdateRoleDialog = ({
  id,
  resourceId,
  resourceType,
  name,
  currentRole,
  type,
}: UpdateRoleDialogProps) => {
  const { hasPermissions } = usePermissionGate();
  const [selectedRole, setSelectedRole] = useState<RoleName | null>(null);
  const { deleteAccessRecord, createAccessRecord } = useResourceAccessControl();

  // If user doesn't have read permission, don't render anything
  if (!hasPermissions(['fga:read'])) {
    return null;
  }

  const canUpdate = hasPermissions(['fga:update']);

  const handleUpdateRole = async () => {
    if (!selectedRole || !canUpdate) return;

    try {
      // First, revoke the existing permission
      if (type === 'user') {
        await deleteAccessRecord.mutateAsync({
          userId: id,
          resourceId,
          resourceType,
        });
      } else {
        await deleteAccessRecord.mutateAsync({
          teamId: id,
          resourceId,
          resourceType,
        });
      }

      // Then, create the new permission with the same user/team type
      if (type === 'user') {
        await createAccessRecord.mutateAsync({
          userId: id,
          resourceId,
          resourceType,
          roleName: selectedRole,
        });
      } else {
        await createAccessRecord.mutateAsync({
          teamId: id,
          resourceId,
          resourceType,
          roleName: selectedRole,
        });
      }

      setSelectedRole(null);
    } catch (error) {
      Logger.error('Failed to update role:', error);
    }
  };

  const isLoading =
    deleteAccessRecord.isPending || createAccessRecord.isPending;

  const updateButton = (
    <Button variant="secondary" size="sm" disabled={!canUpdate}>
      Update Role
    </Button>
  );

  return (
    <Dialog>
      {canUpdate ? (
        <DialogTrigger asChild>{updateButton}</DialogTrigger>
      ) : (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>{updateButton}</TooltipTrigger>
            <TooltipContent>
              <p>You don't have permission to update roles</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Update Role Access</DialogTitle>
          <DialogDescription>
            Update access role for {type === 'team' ? 'team' : ''} {name}.<br />
            Current role:{' '}
            {currentRole.charAt(0) + currentRole.slice(1).toLowerCase()}
          </DialogDescription>
        </DialogHeader>

        <div className="pb-2">
          <Select
            value={selectedRole || ''}
            onValueChange={(value) => setSelectedRole(value as RoleName)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select new role" />
            </SelectTrigger>
            <SelectContent>
              {ROLE_OPTIONS.map((role) => (
                <SelectItem
                  key={role}
                  value={role}
                  disabled={role === currentRole}
                >
                  {role.charAt(0) + role.slice(1).toLowerCase()}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <DialogFooter>
          <DialogClose disabled={isLoading} asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button
            onClick={handleUpdateRole}
            disabled={!selectedRole || isLoading || !canUpdate}
          >
            {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            Update Role
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
