import { History } from 'lucide-react';
import {
  DropdownMenuSub,
  DropdownMenuPortal,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuShortcut,
} from '@fluency/ui/components/ui/dropdown-menu';
import VersionControl from './VersionControl';
import { cn } from '@fluency/ui/lib/utils';

interface VersionHistoryMenuProps {
  documentId: string;
}

const VersionHistoryMenu = ({ documentId }: VersionHistoryMenuProps) => {
  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger
        className={cn(
          'flex justify-between items-center [&>svg]:hidden' // This hides the chevron
        )}
      >
        Version history
        <DropdownMenuShortcut>
          <History className="w-4" />
        </DropdownMenuShortcut>
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent className="mr-2">
          <VersionControl id={documentId} isEditMode={false} />
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};

export default VersionHistoryMenu;
