import { Step, EnhancedStep } from '../../../hooks/documentation/types/master';

export const getStepDetails = ({
  step,
  stepNumberCounter,
}: {
  step: Step;
  stepNumberCounter: number;
}): EnhancedStep => {
  const screenshotAnnotations = step.screenshots[0]?.annotations || [];
  const isStepTypeStep = step.stepType === 'STEP';
  const stepLabel = isStepTypeStep ? `Step ${stepNumberCounter}` : '';
  const firstScreenshot = step.screenshots[0];

  return {
    ...step,
    screenshotAnnotations,
    isStepTypeStep,
    stepLabel,
    screenshotId: firstScreenshot?.screenshotId ?? '',
    screenshotName: firstScreenshot?.screenshotKey,
    screenshotUrl: firstScreenshot?.downloadUrl ?? '',
    isAnnotationVisible: firstScreenshot?.annotation ?? false,
  };
};
