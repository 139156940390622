import { useState, useCallback, useEffect } from 'react';
import {
  Node,
  Edge,
  Connection,
  NodeChange,
  EdgeChange,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  MarkerType,
} from 'reactflow';
import { useWorkflowDatabase } from '../hooks/useWorkflowDatabase';

const useLocalWorkflow = (workflowId: string) => {
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const { workflowData, isLoading, saveWorkflow } =
    useWorkflowDatabase(workflowId);

  // TEMPORARY: add local storage nodes and edges to update workflow
  useEffect(() => {
    localStorage.setItem('nodes', JSON.stringify(nodes));
  }, [nodes]);

  useEffect(() => {
    localStorage.setItem('edges', JSON.stringify(edges));
  }, [edges]);

  useEffect(() => {
    if (workflowData && !isLoading) {
      setNodes(workflowData.nodes);
      setEdges(workflowData.edges);
    }
  }, [workflowData, isLoading]);

  const onNodesChange = useCallback((changes: NodeChange[]) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);

  const onEdgesChange = useCallback((changes: EdgeChange[]) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  const onConnect = useCallback((connection: Connection) => {
    if (connection.source && connection.target) {
      const newEdge: Edge = {
        id: `edge-${Date.now()}`,
        source: connection.source,
        target: connection.target,
        type: 'labeled',
        sourceHandle: connection.sourceHandle ?? null,
        targetHandle: connection.targetHandle ?? null,
        data: { label: '' },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          color: '000000',
        },
        style: {
          strokeWidth: 2,
          stroke: '#000000',
        },
      };
      setEdges((eds) => addEdge(newEdge, eds));
    }
  }, []);

  const onEdgeLabelChange = useCallback((edgeId: string, newLabel: string) => {
    setEdges((eds) =>
      eds.map((edge) =>
        edge.id === edgeId
          ? { ...edge, data: { ...edge.data, label: newLabel } }
          : edge
      )
    );
  }, []);

  const clearWorkflow = useCallback(() => {
    setNodes([]);
    setEdges([]);
    saveWorkflow({ nodes: [], edges: [] });
  }, [nodes, edges, saveWorkflow]);

  const onDeleteNode = useCallback((nodeOrNodes: Node | Node[] | string) => {
    let nodeIds: Set<string>;
    if (typeof nodeOrNodes === 'string') {
      nodeIds = new Set([nodeOrNodes]);
    } else if (Array.isArray(nodeOrNodes)) {
      nodeIds = new Set(nodeOrNodes.map((n) => n.id));
    } else {
      nodeIds = new Set([nodeOrNodes.id]);
    }

    setNodes((nds) => nds.filter((node) => !nodeIds.has(node.id)));
    setEdges((eds) =>
      eds.filter(
        (edge) => !nodeIds.has(edge.source) && !nodeIds.has(edge.target)
      )
    );
  }, []);

  const onDeleteEdge = useCallback((edgeId: string) => {
    setEdges((eds) => eds.filter((edge) => edge.id !== edgeId));
  }, []);

  const saveToDatabase = useCallback(() => {
    saveWorkflow({ nodes, edges });
  }, [nodes, edges, saveWorkflow]);

  return {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onEdgeLabelChange,
    clearWorkflow,
    saveToDatabase,
    isLoading,
    onDeleteNode,
    onDeleteEdge,
  };
};

export default useLocalWorkflow;
