import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { DocumentationResponse } from '@fluency/ui/hooks/documentation/types/master';

interface ShiftScreenshotsPayload {
  documentationId: string;
  shiftAmount: number;
}

async function shiftScreenshots(
  payload: ShiftScreenshotsPayload,
  token: string
): Promise<DocumentationResponse> {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  const response = await fetch(`${apiBaseUrl}/documents/shiftScreenshots`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
    body: new URLSearchParams({
      documentationId: payload.documentationId,
      shiftAmount: payload.shiftAmount.toString(),
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to shift screenshots');
  }

  return response.json();
}

interface MutationContext {
  previousDoc?: DocumentationResponse;
}

export function useShiftScreenshots() {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation<
    DocumentationResponse,
    Error,
    ShiftScreenshotsPayload,
    MutationContext
  >({
    mutationFn: async (payload) => {
      return shiftScreenshots(payload, accessToken as string);
    },
    onMutate: async (payload) => {
      await queryClient.cancelQueries({
        queryKey: ['docInfo', payload.documentationId],
      });

      const previousDoc = queryClient.getQueryData<DocumentationResponse>([
        'docInfo',
        payload.documentationId,
      ]);

      return { previousDoc };
    },
    onError: (_error, variables, context) => {
      toast({
        variant: 'destructive',
        title: 'Error syncing screenshots',
        description: 'Failed to sync screenshots with steps. Please try again.',
      });

      if (context?.previousDoc) {
        queryClient.setQueryData(
          ['docInfo', variables.documentationId],
          context.previousDoc
        );
      }
    },
    onSuccess: () => {
      toast({
        title: 'Screenshots synced',
        description: 'Successfully synchronized screenshots with steps',
      });
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['docInfo', variables.documentationId],
      });
    },
  });
}
