import type { ReactNode } from 'react';
import { cn } from '../lib/utils';

interface PageHeaderProps {
  title: string;
  description?: string;
  titleMetadata?: ReactNode;
  headerActions?: ReactNode;
  backButton?: {
    to: string;
    label?: string;
  };
  breadcrumbs?: {
    items: Array<{
      label: string;
      to?: string;
    }>;
  };
}

interface PageFilterBarProps {
  left?: ReactNode;
  right?: ReactNode;
  className?: string;
}

interface PageContentProps {
  children: ReactNode;
  className?: string;
}

interface PageFooterProps {
  children: ReactNode;
  className?: string;
}

interface PageLayoutProps {
  header?: PageHeaderProps;
  filterBar?: PageFilterBarProps;
  content: PageContentProps;
  footer?: PageFooterProps;
  maxWidth?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '5xl' | 'full';
  noPadding?: boolean;
  className?: string;
  stickyHeader?: boolean;
}

const PageHeader = ({
  title,
  description,
  titleMetadata,
  headerActions,
}: PageHeaderProps) => {
  return (
    <div>
      <div className="flex justify-between items-start">
        <div className="space-y-1">
          <div className="flex items-center gap-3 min-w-96 max-w-[calc(100vw-500px)]">
            <h1 className="text-2xl font-semibold tracking-tight truncate overflow-clip">
              {title}
            </h1>
            {titleMetadata && (
              <div className="flex items-center">{titleMetadata}</div>
            )}
          </div>
          {description && (
            <p className="text-sm text-muted-foreground">{description}</p>
          )}
        </div>
        {headerActions && (
          <div className="flex items-center gap-3">{headerActions}</div>
        )}
      </div>
    </div>
  );
};

const PageLayout = ({
  header,
  filterBar,
  content,
  footer,
  maxWidth = '5xl',
  noPadding = false,
  className,
  stickyHeader = false,
}: PageLayoutProps) => {
  const maxWidthClasses = {
    sm: 'max-w-sm',
    md: 'max-w-md',
    lg: 'max-w-lg',
    xl: 'max-w-xl',
    '2xl': 'max-w-2xl',
    '5xl': 'max-w-5xl',
    full: 'max-w-full w-full',
  };

  const headerContent = (header || filterBar) && (
    <div
      className={cn(
        'bg-background w-full border-b border-border',
        stickyHeader && 'sticky top-0 z-20'
      )}
    >
      <div
        className={cn(
          'container mx-auto',
          maxWidthClasses[maxWidth],
          !noPadding && 'px-6 py-4'
        )}
      >
        {header && <PageHeader {...header} />}
        {filterBar && (
          <div
            className={cn(
              'flex items-center justify-between gap-4 mt-6',
              filterBar.className
            )}
          >
            <div className="flex-1 w-full sm:w-auto">{filterBar.left}</div>
            <div className="flex gap-2 w-full sm:w-auto">{filterBar.right}</div>
          </div>
        )}
      </div>
    </div>
  );

  const contentWrapper = (
    <div
      className={cn(
        'container mx-auto',
        maxWidthClasses[maxWidth],
        !noPadding && 'px-6'
      )}
    >
      <div className={cn('', className)}>
        <div className={content.className}>{content.children}</div>
        {footer && (
          <div className={cn('mt-6', footer.className)}>{footer.children}</div>
        )}
      </div>
    </div>
  );

  return (
    <div className="h-full flex flex-col">
      {headerContent}
      <div className="flex-1 overflow-auto min-h-0">{contentWrapper}</div>
    </div>
  );
};

export default PageLayout;
