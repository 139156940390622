import { useQuery } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import type {
  DetailedPermissionResponse,
  UseResourcePermissionsParams,
} from '@fluency/ui/hooks/fga/types/types';

export const useResourcePermissions = ({
  resourceType,
  resourceId,
}: UseResourcePermissionsParams) => {
  const { accessToken } = useAuth();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  return useQuery<DetailedPermissionResponse>({
    queryKey: ['resource-permissions', resourceType, resourceId],
    queryFn: async () => {
      const response = await fetch(
        `${apiBaseUrl}/fga/permissions?${new URLSearchParams({
          resourceType,
          resourceId,
        })}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to get resource permissions');
      }

      return response.json();
    },
    enabled: Boolean(resourceType && resourceId),
  });
};
