import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import OrganisationImage from './components/OrganisationImage';

function BrandingSettings() {
  return (
    <div className="space-y-6">
      {/* Header */}
      <div>
        <h2 className="text-lg font-medium mb-1">Branding</h2>
        <p className="text-sm text-muted-foreground">
          Update your branding settings which reflects in your exports.
        </p>
      </div>

      {/* Branding Card */}
      <Card className="border">
        <CardHeader className="px-6 py-4 border-b">
          <div>
            <CardTitle className="text-base font-medium">
              Company Logo
            </CardTitle>
            <p className="text-sm text-muted-foreground mt-1">
              Your logo will appear on all exported documents and reports.
            </p>
          </div>
        </CardHeader>
        <CardContent className="p-6">
          <OrganisationImage />
        </CardContent>
      </Card>
    </div>
  );
}

export default BrandingSettings;
