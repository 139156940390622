import { ReactNode } from 'react';
import { SidebarProvider } from '@fluency/ui/components/ui/sidebar';
import AppSidebar from './breadcrumb/AppSidebar';

const DashboardShell = ({ children }: { children: ReactNode }) => (
  <SidebarProvider className="bg-muted/50">
    <AppSidebar>{children}</AppSidebar>
  </SidebarProvider>
);

export default DashboardShell;
