import React, { useState } from 'react';
import { NodeProps, useReactFlow } from 'reactflow';
import { X } from 'lucide-react';
import { createNodeHandles } from '../../utils/createNodeHandles';
import Resizer from './Resizer';

interface DecisionNodeData {
  label: string;
  onDelete: () => void;
  isViewMode?: boolean;
}

const DecisionNode: React.FC<NodeProps<DecisionNodeData>> = ({ id, data }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { getNode } = useReactFlow();

  const node = getNode(id);
  const width = node?.width ?? 80;
  const height = node?.height ?? 80;

  // Choose the bigger between width and height to set the const size
  const containerSize = width > height ? width : height;
  const rotatedSquareSize = containerSize / Math.sqrt(2);

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    data.onDelete();
  };

  return (
    <div
      className="relative flex items-center justify-center cursor-move"
      onMouseEnter={() => !data.isViewMode && setIsHovered(true)}
      onMouseLeave={() => !data.isViewMode && setIsHovered(false)}
      style={{
        width: containerSize,
        height: containerSize,
      }}
    >
      {isHovered && !data.isViewMode && (
        <button
          onClick={handleDelete}
          className="absolute -top-4 -right-4 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors z-10"
        >
          <X size={16} />
        </button>
      )}
      {/* Node Resizer */}
      {isHovered && !data.isViewMode && (
        <Resizer className="absolute bottom-1 right-1" />
      )}

      {/* Rotated Square */}
      <div
        style={{
          width: rotatedSquareSize,
          height: rotatedSquareSize,
        }}
      >
        <div className="w-full h-full rotate-45 font-semibold border-4 border-black flex items-center justify-center" />
      </div>
      {createNodeHandles({
        id,
        isViewMode: data.isViewMode,
        isHovered,
      })}
    </div>
  );
};

export default DecisionNode;
