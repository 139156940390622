import clsx from 'clsx';
import { NodeResizeControl } from 'reactflow';

const controlStyle = {
  right: 0,
  bottom: 0,
  background: 'transparent',
  border: 'none',
};

interface ResizerProps {
  className?: string;
}

const Resizer = ({ className }: ResizerProps) => {
  return (
    <NodeResizeControl style={controlStyle}>
      <div className="h-8 w-8">
        <div
          className={clsx(
            'relative h-4 w-4 border-r-4 border-b-4 border-primary',
            className
          )}
        ></div>
      </div>
    </NodeResizeControl>
  );
};

export default Resizer;
