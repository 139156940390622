import { useRef } from 'react';
import { ScrollArea } from '@fluency/ui/components/ui/scroll-area';
import useDeleteStep from '@fluency/ui/features/ViewDocument/hooks/useDeleteStep';
import { useReorderSteps } from '@fluency/ui/features/ViewDocument/hooks/useReorderSteps';
import { StepActionButtons } from './components/StepActionButtons';
import { StepList } from './components/StepList';
import { useStepManagement } from './hooks/useStepManagement';
import { useScrollSync } from './hooks/useScrollSync';
import type { SummaryProps, Step } from './types';

export const BigSummary = ({ documentId, handleJumpToStep }: SummaryProps) => {
  const {
    steps,
    setSteps,
    selectedSteps,
    setSelectedSteps,
    draggedItem,
    setDraggedItem,
    dragOverIndex,
    setDragOverIndex,
    activeStepId,
    setActiveStepId,
    isLoading,
    error,
  } = useStepManagement(documentId);

  const listRef = useRef<HTMLDivElement>(null);
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const deleteStepMutation = useDeleteStep({ documentationId: documentId });
  const reorderStepsMutation = useReorderSteps();

  useScrollSync(scrollAreaRef, setActiveStepId);

  const handleToggleStep = (stepId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    if (draggedItem) return;

    setSelectedSteps((prev) =>
      prev.includes(stepId)
        ? prev.filter((id) => id !== stepId)
        : [...prev, stepId]
    );
  };

  const handleDeleteSelected = () => {
    deleteStepMutation.mutate(
      { stepIds: selectedSteps },
      {
        onSuccess: () => {
          setSelectedSteps([]);
        },
      }
    );
  };

  const handleDeselectAll = () => {
    setSelectedSteps([]);
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, item: Step) => {
    setDraggedItem(item);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/plain', item.stepId);

    const target = e.target as HTMLElement;
    requestAnimationFrame(() => {
      target.style.opacity = '0.5';
      target.style.transform = 'scale(1.02)';
    });
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleStepDragOver = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (!draggedItem) return;

    const boundingRect = e.currentTarget.getBoundingClientRect();
    const mouseY = e.clientY - boundingRect.top;
    const threshold = boundingRect.height / 2;

    // If mouse is in the top half of the item, place before; if bottom half, place after
    setDragOverIndex(mouseY < threshold ? index : index + 1);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!draggedItem || dragOverIndex === null) return;

    const draggedIndex = steps.findIndex(
      (step) => step.stepId === draggedItem.stepId
    );

    if (draggedIndex === dragOverIndex || draggedIndex + 1 === dragOverIndex) {
      setDraggedItem(null);
      setDragOverIndex(null);
      return;
    }

    const newSteps = [...steps];
    newSteps.splice(draggedIndex, 1);
    newSteps.splice(
      dragOverIndex > draggedIndex ? dragOverIndex - 1 : dragOverIndex,
      0,
      draggedItem
    );

    const updatedSteps = newSteps.map((step, index) => ({
      ...step,
      stepNumber: index + 1,
    }));

    setSteps(updatedSteps);

    reorderStepsMutation.mutate({
      documentationId: documentId,
      stepIds: updatedSteps.map((step) => ({
        stepId: step.stepId,
        stepNumber: step.stepNumber,
      })),
    });

    setDraggedItem(null);
    setDragOverIndex(null);
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    target.style.opacity = '1';
    target.style.transform = 'scale(1)';
    setDraggedItem(null);
    setDragOverIndex(null);
  };

  return (
    <div className="h-[calc(100vh-85px)]">
      <ScrollArea ref={scrollAreaRef} className="h-full px-4 pt-2">
        <div ref={listRef}>
          <StepList
            steps={steps}
            isLoading={isLoading}
            error={error}
            activeStepId={activeStepId}
            selectedSteps={selectedSteps}
            draggedItem={draggedItem}
            dragOverIndex={dragOverIndex}
            onSelect={handleToggleStep}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onStepDragOver={handleStepDragOver}
            onJump={handleJumpToStep}
          />
        </div>
      </ScrollArea>
      <StepActionButtons
        selectedCount={selectedSteps.length}
        onDelete={handleDeleteSelected}
        onDeselectAll={handleDeselectAll}
        isDeleteDisabled={deleteStepMutation.isPending}
      />
    </div>
  );
};

export default BigSummary;
