import { XCircle } from 'lucide-react';
import { Button } from '@fluency/ui/components/ui/button';
import { useNavigate } from '@tanstack/react-router';

const DocumentNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-[calc(100vh-85px)] flex items-center justify-center px-4">
      <div className="w-full max-w-md text-center">
        <div className="mb-6 flex justify-center">
          <XCircle className="h-12 w-12 text-destructive" />
        </div>

        <h2 className="text-lg font-semibold mb-2">Document Not Found</h2>

        <p className="text-muted-foreground mb-8">
          We couldn't find the document you're looking for. It may have been
          deleted or you might not have permission to view it.
        </p>

        <div className="flex justify-center gap-3">
          <Button
            variant="outline"
            onClick={() => navigate({ to: '/' })}
            className="min-w-[100px]"
          >
            Return to Home
          </Button>
        </div>
      </div>
    </div>
  );
};
export default DocumentNotFound;
