export function isElementInViewport(
  element: HTMLElement,
  container: HTMLElement,
  offset: number = 0
): boolean {
  const containerRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  return (
    elementRect.top >= containerRect.top + offset &&
    elementRect.bottom <= containerRect.bottom - offset
  );
}

export function scrollIntoViewIfNeeded(
  element: HTMLElement,
  container: HTMLElement,
  offset: number = 0
): void {
  if (!isElementInViewport(element, container, offset)) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }
}
