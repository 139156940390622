import { Button } from '@fluency/ui/components/ui/button';
import { Rocket } from 'lucide-react';
import { useParams } from '@tanstack/react-router';
import useAutoImagePii from '@fluency/ui/features/ViewDocument/hooks/useAutoImagePii';
import { useState, useEffect } from 'react';

interface AutoRedactButtonProps {
  imageKey: string;
}

export const AutoRedactButton: React.FC<AutoRedactButtonProps> = ({
  imageKey,
}) => {
  const { id: docId } = useParams({ from: '/document/$id' });
  const autoImagePii = useAutoImagePii(docId);
  const [isAutoRedacting, setIsAutoRedacting] = useState(false);

  useEffect(() => {
    if (autoImagePii.isSuccess || autoImagePii.isError) {
      setIsAutoRedacting(false);
    }
  }, [autoImagePii.isSuccess, autoImagePii.isError]);

  const handleAutoRedact = () => {
    setIsAutoRedacting(true);
    autoImagePii.mutate({ imageKey });
  };

  return (
    <Button
      variant="outline"
      className="w-full group"
      onClick={handleAutoRedact}
      disabled={isAutoRedacting}
      size="sm"
    >
      <Rocket className="w-4 h-4 mr-2" />
      {isAutoRedacting ? 'Auto-redacting...' : 'Auto-redact'}
    </Button>
  );
};
