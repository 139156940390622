import { Logger } from '@fluency/ui/features/Logger';
import { WorkOSResponse } from '../types/WorkOSResponse';

const url = import.meta.env.VITE_SERVER_API_URL;

export const refreshTokens = async (
  refreshToken: string,
  organizationId?: string
): Promise<WorkOSResponse> => {
  try {
    const requestBody = {
      refreshToken,
      ...(organizationId && { organisationId: organizationId }),
    };

    const response = await fetch(`${url}/auth/rotateRefreshToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: WorkOSResponse = await response.json();
    return data;
  } catch (error) {
    Logger.error('Error refreshing token:', error);
    throw error;
  }
};
