enum LogLevel {
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  LOG = 'log',
}

const isProduction = (): boolean => {
  return import.meta.env.NODE_ENV === 'production';
};

const logging = (
  level: LogLevel,
  message: any,
  ...optionalParams: any[]
): void => {
  // Don't log anything in production
  if (isProduction()) {
    return;
  }

  switch (level) {
    case LogLevel.INFO:
      console.info(`[INFO]: ${message}`, ...optionalParams);
      break;
    case LogLevel.WARN:
      console.warn(`[WARN]: ${message}`, ...optionalParams);
      break;
    case LogLevel.ERROR:
      console.error(`[ERROR]: ${message}`, ...optionalParams);
      break;
    case LogLevel.LOG:
      console.log(`[LOG]: ${message}`, ...optionalParams);
      break;
  }
};

export const Logger = {
  info: (message: any, ...optionalParams: any[]): void => {
    logging(LogLevel.INFO, message, ...optionalParams);
  },
  warn: (message: any, ...optionalParams: any[]): void => {
    logging(LogLevel.WARN, message, ...optionalParams);
  },
  error: (message: any, ...optionalParams: any[]): void => {
    logging(LogLevel.ERROR, message, ...optionalParams);
  },
  log: (message: any, ...optionalParams: any[]): void => {
    logging(LogLevel.LOG, message, ...optionalParams);
  },
} as const;
