import { EdgeTypes } from 'reactflow';
import LabeledEdge from '../components/edges/LabeledEdge';
import ViewLabeledEdge from '../components/edges/ViewLabeledEdge';

const edgeTypes: EdgeTypes = {
  labeled: LabeledEdge,
};

const viewEdgeTypes: EdgeTypes = {
  labeled: ViewLabeledEdge,
};

export { viewEdgeTypes, edgeTypes };
