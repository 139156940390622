import { useGetDocumentData } from '@fluency/ui/features/ViewDocument/hooks';

export const useDocumentDetails = (docId: string) => {
  // Only fetch if we have a valid docId
  const { data: documentData, isLoading } = useGetDocumentData(
    docId ? docId : null // or however your hook handles disabled states
  );

  const getDocumentName = (id: string): string => {
    if (!docId || isLoading) return '';
    return documentData?.documentation?.documentationName || `Document ${id}`;
  };

  const getDocumentVaultId = (): string | undefined => {
    if (!docId) return undefined;
    return documentData?.documentation?.vaultId;
  };

  return { getDocumentName, getDocumentVaultId, isLoading };
};
