import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useMutation } from '@tanstack/react-query';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { Logger } from '@fluency/ui/features/Logger';

interface UpdateUserProfileData {
  firstName?: string;
  lastName?: string;
}

async function updateUserProfile(
  data: UpdateUserProfileData,
  token: string
): Promise<UpdateUserProfileData> {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const url = `${apiBaseUrl}/user/updateUserProfile`;

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const response = await fetch(url, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const error = await response.json();
    Logger.error(error);
    throw new Error('Failed to update user profile');
  }

  return response.json();
}

export function useUpdateUserProfile() {
  const { accessToken, setUser, user } = useAuth();

  return useMutation<UpdateUserProfileData, Error, UpdateUserProfileData>({
    mutationFn: (data: UpdateUserProfileData) =>
      updateUserProfile(data, accessToken!),
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'An error occurred',
        description: 'There was a problem updating the user profile.',
      });
    },
    onSuccess: (updatedData) => {
      toast({
        variant: 'default',
        title: 'Success',
        description: 'User profile updated successfully.',
      });
      if (user) {
        const updatedUser = {
          ...user,
          firstName: updatedData.firstName ?? user.firstName,
          lastName: updatedData.lastName ?? user.lastName,
        };
        setUser(updatedUser);
      }
    },
  });
}
