import React, { useEffect, useState } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import {
  AlertCircle,
  ArrowUpRightFromSquare,
  RefreshCw,
  Check,
  Copy,
} from 'lucide-react';
import { Input } from '@fluency/ui/components/ui/input';
import { Button } from '@fluency/ui/components/ui/button';
import logo from '../../assets/icon-blue.svg';
import { Separator } from '@fluency/ui/components/ui/separator';
import { Alert, AlertDescription } from '@fluency/ui/components/ui/alert';
import { useElectron } from '@fluency/ui/providers/electron/ElectronContext';
import { Logger } from '@fluency/ui/features/Logger';

export const SignIn = () => {
  const navigate = useNavigate();
  const {
    getLoginUrl,
    isLoading,
    isAuthenticated,
    handleManualTokenLogin,
    justLoggedOut,
    authError,
    setAuthError,
  } = useAuth();
  const { isElectron } = useElectron();
  const [token, setToken] = useState('');
  const [showTokenInput, setShowTokenInput] = useState(false);
  const [copied, setCopied] = useState(false);
  const [loginUrl, setLoginUrl] = useState<string | null>(null);

  useEffect(() => {
    if (isAuthenticated) {
      navigate({
        to: '/home',
        replace: true,
      });
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async () => {
    try {
      const url = await getLoginUrl();
      setLoginUrl(url);

      if (!isElectron) {
        // Redirect immediately for web environment
        window.location.href = url;
      } else {
        // Open in new tab for Electron environment
        window.open(url, '_blank', 'noopener,noreferrer');
      }
    } catch (error) {
      Logger.error('Error getting login URL:', error);
      setAuthError('Failed to get login URL. Please try again.');
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    Logger.log('Submitted token:', token);
    handleManualTokenLogin(token);
  };

  const copyToClipboard = () => {
    if (loginUrl) {
      navigator.clipboard.writeText(loginUrl).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
    }
  };

  return (
    <div className="mx-auto my-auto max-w-full flex flex-col items-center text-left justify-center h-[100vh] bg-slate-100">
      <img src={logo} className="h-20 mb-4" alt="Fluency Logo" />
      {!isLoading ? (
        <Card className="bg-white w-full items-center text-center p-8 max-w-[440px] border-[1px]">
          <CardHeader>
            <CardTitle className="text-2xl font-semibold text-[#202020]">
              Welcome to Fluency
            </CardTitle>
          </CardHeader>
          <CardContent>
            <Button
              onClick={handleLogin}
              className="w-full mb-4 text-md border-2"
            >
              {isElectron && <ArrowUpRightFromSquare className="w-4 mr-2 " />}
              Enter
            </Button>
            {isElectron && loginUrl && (
              <div className="mt-4 mb-4">
                <button
                  onClick={copyToClipboard}
                  className="text-fluency-600 hover:underline hover:cursor-pointer my-2 text-sm flex items-center w-full justify-center gap-2"
                >
                  {copied ? (
                    <Check className="w-4" />
                  ) : (
                    <Copy className="w-4" />
                  )}
                  {copied ? 'Copied!' : 'Copy Link'}
                </button>
                <div className="bg-gray-100 p-2 rounded-md text-xs break-all">
                  {loginUrl}
                </div>
              </div>
            )}
            <Separator />
            {showTokenInput ? (
              <form onSubmit={handleSubmit} className="flex gap-2 mt-4 mb-2">
                <Input
                  type="text"
                  placeholder="Enter your authentication token"
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                  className="w-full"
                />
                <Button type="submit" className="w-24">
                  Enter
                </Button>
              </form>
            ) : (
              <p className="text-sm text-gray-500 mt-4">
                Have an authentication key?{' '}
                <button
                  onClick={() => setShowTokenInput(true)}
                  className="text-fluency-600 hover:underline hover:cursor-pointer"
                >
                  Enter it here
                </button>
                .
              </p>
            )}
            {authError && (
              <Alert variant="destructive" className="mt-4">
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>{authError}</AlertDescription>
              </Alert>
            )}
            {justLoggedOut && (
              <Alert className="mt-4">
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>
                  You have been logged out successfully.
                </AlertDescription>
              </Alert>
            )}
          </CardContent>
        </Card>
      ) : (
        <div className="flex justify-center items-center font-semibold text-gray-600">
          <RefreshCw className="animate-spin h-5 w-5 mr-2" /> Loading
        </div>
      )}
    </div>
  );
};

export default SignIn;
