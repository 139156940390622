import { InfoCircledIcon } from '@radix-ui/react-icons';
import {
  CheckCircleIcon,
  CircleAlert,
  PencilLineIcon,
  TriangleAlert,
} from 'lucide-react';

export const stepTypeColors = {
  ERROR: 'bg-red-100',
  WARNING: 'bg-yellow-100',
  INFO: 'bg-blue-100',
  NOTE: 'bg-yellow-50',
  TIP: 'bg-teal-100',
  EXAMPLE: 'bg-purple-100',
  CODE: 'bg-gray-100',
  IMAGE: 'bg-gray-100',
  SUCCESS: 'bg-green-100',
  HEADLINE: 'bg-slate-100',
  SUBHEADLINE: 'bg-slate-100',
};

export const stepTypeIconColors = {
  ERROR: 'text-red-500',
  WARNING: 'text-yellow-600',
  INFO: 'text-blue-500',
  SUCCESS: 'text-green-500',
  NOTE: 'text-yellow-500',
};

export const getStepTypeIcon = (stepType: string) => {
  const iconColorClass =
    stepTypeIconColors[stepType as keyof typeof stepTypeIconColors] ||
    'text-gray-700';
  switch (stepType) {
    case 'ERROR':
      return <CircleAlert className={`h-5 w-5 mr-2 ${iconColorClass}`} />;
    case 'WARNING':
      return <TriangleAlert className={`h-5 w-5 mr-2 ${iconColorClass}`} />;
    case 'INFO':
      return <InfoCircledIcon className={`h-5 w-5 mr-2 ${iconColorClass}`} />;
    case 'SUCCESS':
      return <CheckCircleIcon className={`h-5 w-5 mr-2 ${iconColorClass}`} />;
    case 'NOTE':
      return <PencilLineIcon className={`h-5 w-5 mr-2 ${iconColorClass}`} />;
    default:
      return null;
  }
};

export const getStepSummaryTypeIcon = (stepType: string) => {
  const iconColorClass =
    stepTypeIconColors[stepType as keyof typeof stepTypeIconColors] ||
    'text-gray-700';
  switch (stepType) {
    case 'ERROR':
      return <CircleAlert className={`h-4 w-4 mr-2 ${iconColorClass}`} />;
    case 'WARNING':
      return <TriangleAlert className={`h-4 w-4 mr-2 ${iconColorClass}`} />;
    case 'INFO':
      return <InfoCircledIcon className={`h-4 w-4 mr-2 ${iconColorClass}`} />;
    case 'SUCCESS':
      return <CheckCircleIcon className={`h-4 w-4 mr-2 ${iconColorClass}`} />;
    case 'NOTE':
      return <PencilLineIcon className={`h-4 w-4 mr-2 ${iconColorClass}`} />;
    default:
      return null;
  }
};
