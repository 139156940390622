import { Button } from '@fluency/ui/components/ui/button';
import { PenLine } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import { useInsertStep } from '../hooks/index';
import { Logger } from '@fluency/ui/features/Logger';

interface WriteManuallyButtonProps {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  docId: string;
  setIsEditMode: (value: boolean) => void;
}

export default function WriteManuallyButton({
  onMouseEnter,
  onMouseLeave,
  docId,
  setIsEditMode,
}: WriteManuallyButtonProps) {
  const insertStepsMutation = useInsertStep();

  const handleClick = async () => {
    const stepId = uuidv4();
    const insertStepData = {
      documentationId: docId,
      stepBeforeId: null, // Insert at the beginning
      insertedSteps: [
        {
          stepId: stepId,
          stepDescription: 'Enter description...',
          stepType: 'STEP',
          newIndex: 0,
        },
      ],
    };

    try {
      await insertStepsMutation.mutateAsync(insertStepData);
      setIsEditMode(true); // Enable edit mode
    } catch (error) {
      Logger.error('Error inserting step:', error);
    }
  };

  return (
    <Button
      variant="secondary"
      className="flex flex-col items-center justify-center w-28 h-28 p-2 hover:scale-105 transition-transform text-gray-500 hover:text-black"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleClick}
    >
      <PenLine className="h-10 w-10 mb-2" />
      <span className="text-xs text-center font-medium">
        Write
        <br />
        Manually
      </span>
    </Button>
  );
}
