import React, { useState, useRef, useEffect, useCallback } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { RedactionRectangle } from '../../types';

interface RedactionOverlayProps {
  imageRef: React.RefObject<HTMLImageElement>;
  rectangles: RedactionRectangle[];
  onRectanglesChange: (rectangles: RedactionRectangle[]) => void;
  selectedRectangles: number[];
  onSelectedRectanglesChange: (selected: number[]) => void;
  currentColor: string;
}

export const RedactionOverlay: React.FC<RedactionOverlayProps> = ({
  imageRef,
  rectangles,
  onRectanglesChange,
  selectedRectangles,
  onSelectedRectanglesChange,
  currentColor,
}) => {
  const [draggedRectangle, setDraggedRectangle] = useState<number | null>(null);
  const [resizingRectangle, setResizingRectangle] = useState<number | null>(
    null
  );
  const [resizeHandle, setResizeHandle] = useState<string | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const dragStartPos = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
  const drawStartPos = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
  const drawingRectIndex = useRef<number | null>(null);

  const getMousePosition = useCallback(
    (e: React.MouseEvent | MouseEvent) => {
      if (!imageRef.current) return { x: 0, y: 0 };
      const rect = imageRef.current.getBoundingClientRect();
      return {
        x: ((e.clientX - rect.left) / rect.width) * 100,
        y: ((e.clientY - rect.top) / rect.height) * 100,
      };
    },
    [imageRef]
  );

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      if (e.target === e.currentTarget) {
        const pos = getMousePosition(e);
        drawStartPos.current = pos;
        setIsDrawing(true);

        // Create initial rectangle
        const newRect: RedactionRectangle = {
          x: pos.x,
          y: pos.y,
          width: 0,
          height: 0,
          type: currentColor === 'blur' ? 'blur' : 'draw',
          colour: currentColor === 'blur' ? undefined : currentColor,
        };

        const newRectangles = [...rectangles, newRect];
        onRectanglesChange(newRectangles);
        drawingRectIndex.current = newRectangles.length - 1;
      }
    },
    [getMousePosition, rectangles, currentColor, onRectanglesChange]
  );

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDrawing && drawingRectIndex.current !== null) {
        const pos = getMousePosition(e);
        const newRects = [...rectangles];
        const rectIndex = drawingRectIndex.current;

        if (rectIndex >= 0 && rectIndex < newRects.length) {
          const updatedRect: RedactionRectangle = {
            ...newRects[rectIndex],
            width: Math.abs(pos.x - drawStartPos.current.x),
            height: Math.abs(pos.y - drawStartPos.current.y),
            x: Math.min(pos.x, drawStartPos.current.x),
            y: Math.min(pos.y, drawStartPos.current.y),
            type: newRects[rectIndex]?.type ?? 'draw',
          };
          newRects[rectIndex] = updatedRect;
          onRectanglesChange(newRects);
        }
      } else if (draggedRectangle !== null) {
        const pos = getMousePosition(e);
        const deltaX = pos.x - dragStartPos.current.x;
        const deltaY = pos.y - dragStartPos.current.y;

        onRectanglesChange(
          rectangles.map((rect, index) => {
            if (selectedRectangles.includes(index)) {
              return {
                ...rect,
                x: Math.max(0, Math.min(100 - rect.width, rect.x + deltaX)),
                y: Math.max(0, Math.min(100 - rect.height, rect.y + deltaY)),
              };
            }
            return rect;
          })
        );
        dragStartPos.current = pos;
      } else if (resizingRectangle !== null && resizeHandle) {
        const pos = getMousePosition(e);
        const originalRect = rectangles[resizingRectangle];

        if (!originalRect) return;

        let newRect: RedactionRectangle = { ...originalRect };

        switch (resizeHandle) {
          case 'nw':
            newRect = {
              ...newRect,
              width: originalRect.width + (originalRect.x - pos.x),
              height: originalRect.height + (originalRect.y - pos.y),
              x: pos.x,
              y: pos.y,
            };
            break;
          case 'ne':
            newRect = {
              ...newRect,
              width: pos.x - originalRect.x,
              height: originalRect.height + (originalRect.y - pos.y),
              y: pos.y,
            };
            break;
          case 'sw':
            newRect = {
              ...newRect,
              width: originalRect.width + (originalRect.x - pos.x),
              height: pos.y - originalRect.y,
              x: pos.x,
            };
            break;
          case 'se':
            newRect = {
              ...newRect,
              width: pos.x - originalRect.x,
              height: pos.y - originalRect.y,
            };
            break;
        }

        // Constrain dimensions
        newRect.width = Math.max(5, newRect.width);
        newRect.height = Math.max(5, newRect.height);
        newRect.x = Math.max(0, Math.min(100 - newRect.width, newRect.x));
        newRect.y = Math.max(0, Math.min(100 - newRect.height, newRect.y));

        onRectanglesChange(
          rectangles.map((r, index) =>
            index === resizingRectangle ? newRect : r
          )
        );
      }
    },
    [
      isDrawing,
      draggedRectangle,
      resizingRectangle,
      resizeHandle,
      rectangles,
      selectedRectangles,
      onRectanglesChange,
      getMousePosition,
    ]
  );

  const handleMouseUp = useCallback(() => {
    if (isDrawing && drawingRectIndex.current !== null) {
      const rect = rectangles[drawingRectIndex.current];
      if (rect && (rect.width < 1 || rect.height < 1)) {
        onRectanglesChange(
          rectangles.filter((_, i) => i !== drawingRectIndex.current)
        );
      }
    }
    setIsDrawing(false);
    setDraggedRectangle(null);
    setResizingRectangle(null);
    setResizeHandle(null);
    drawingRectIndex.current = null;
  }, [isDrawing, rectangles, onRectanglesChange]);

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  const handleRectangleMouseDown = (e: React.MouseEvent, index: number) => {
    e.stopPropagation();
    setDraggedRectangle(index);
    dragStartPos.current = getMousePosition(e);
    if (!selectedRectangles.includes(index)) {
      onSelectedRectanglesChange([index]);
    }
  };

  const handleResizeStart = (
    e: React.MouseEvent,
    index: number,
    handle: string
  ) => {
    e.stopPropagation();
    setResizingRectangle(index);
    setResizeHandle(handle);
  };

  return (
    <div
      className="absolute inset-0 cursor-crosshair"
      onMouseDown={handleMouseDown}
    >
      {rectangles.map((rect, index) => (
        <div
          key={index}
          className={`absolute cursor-move ${
            selectedRectangles.includes(index)
              ? 'ring-2 ring-blue-500'
              : 'ring-2 ring-white'
          }`}
          style={{
            left: `${rect.x}%`,
            top: `${rect.y}%`,
            width: `${rect.width}%`,
            height: `${rect.height}%`,
            backgroundColor:
              rect.type === 'blur' ? 'rgba(255, 255, 255, 0.5)' : rect.colour,
            backdropFilter: rect.type === 'blur' ? 'blur(10px)' : 'none',
          }}
          onMouseDown={(e) => handleRectangleMouseDown(e, index)}
        >
          {/* Resize handles - positioned outside the rectangle */}
          <div
            className="absolute -top-1.5 -left-1.5 w-3 h-3 bg-white border border-gray-400 rounded-full cursor-nw-resize"
            onMouseDown={(e) => handleResizeStart(e, index, 'nw')}
          />
          <div
            className="absolute -top-1.5 -right-1.5 w-3 h-3 bg-white border border-gray-400 rounded-full cursor-ne-resize"
            onMouseDown={(e) => handleResizeStart(e, index, 'ne')}
          />
          <div
            className="absolute -bottom-1.5 -left-1.5 w-3 h-3 bg-white border border-gray-400 rounded-full cursor-sw-resize"
            onMouseDown={(e) => handleResizeStart(e, index, 'sw')}
          />
          <div
            className="absolute -bottom-1.5 -right-1.5 w-3 h-3 bg-white border border-gray-400 rounded-full cursor-se-resize"
            onMouseDown={(e) => handleResizeStart(e, index, 'se')}
          />
          <button
            className="absolute -top-2 -right-2 text-white bg-gray-500 hover:bg-red-500 rounded-full p-0.5 transition-colors"
            onClick={(e) => {
              e.stopPropagation();
              onRectanglesChange(rectangles.filter((_, i) => i !== index));
              onSelectedRectanglesChange(
                selectedRectangles.filter(
                  (selectedIndex) => selectedIndex !== index
                )
              );
            }}
          >
            <XMarkIcon className="h-4 w-4" />
          </button>
        </div>
      ))}
    </div>
  );
};
