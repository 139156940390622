import * as Sentry from '@sentry/react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';

interface VaultCreator {
  id: string;
  metadata: {
    teamSize: string;
    storageMethod: string;
    documentationMethod: string;
  };
}

interface EffectiveRole {
  role: string;
  source: string;
  details: {
    organisationRole: string;
  };
}

interface Vault {
  id: string;
  name: string;
  description: string;
  tenantId: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  isPublic: boolean;
  isLocked: boolean;
  isGlobal: boolean;
  effectiveRole: EffectiveRole;
  creator: VaultCreator;
}

interface VaultsResponse {
  message: string;
  vaults: Vault[];
}

const useGetVaults = (): UseQueryResult<VaultsResponse, Error> => {
  const { accessToken } = useAuth();

  return useQuery<VaultsResponse, Error>({
    queryKey: ['vaults'],
    queryFn: async (): Promise<VaultsResponse> => {
      const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', `Bearer ${accessToken}`);

      const requestOptions: RequestInit = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      const response = await fetch(`${apiBaseUrl}/vaults`, requestOptions);

      if (!response.ok) {
        Sentry.captureException('Failed to get vaults');
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result as VaultsResponse;
    },
  });
};

export default useGetVaults;
