import { useEffect } from 'react';
import { scrollIntoViewIfNeeded } from '../utils/scrollUtils';

export function useScrollSync(
  scrollAreaRef: React.RefObject<HTMLDivElement>,
  setActiveStepId: (id: string) => void
) {
  useEffect(() => {
    const handleStepInView = (event: CustomEvent<{ stepId: string }>) => {
      setActiveStepId(event.detail.stepId);

      if (scrollAreaRef.current && event.detail.stepId) {
        const viewport = scrollAreaRef.current.querySelector(
          '[data-radix-scroll-area-viewport]'
        );
        const activeElement = scrollAreaRef.current.querySelector(
          `[data-step-id="${event.detail.stepId}"]`
        );

        if (viewport && activeElement) {
          scrollIntoViewIfNeeded(
            activeElement as HTMLElement,
            viewport as HTMLElement,
            40
          );
        }
      }
    };

    window.addEventListener('stepInView', handleStepInView as EventListener);
    return () => {
      window.removeEventListener(
        'stepInView',
        handleStepInView as EventListener
      );
    };
  }, [scrollAreaRef, setActiveStepId]);
}
