import { AlertCircle } from 'lucide-react';
import { Button } from '@fluency/ui/components/ui/button';

interface ErrorStateProps {
  onRetry: () => void;
}

export function ErrorState({ onRetry }: ErrorStateProps) {
  return (
    <div className="flex flex-col items-center justify-center py-8 text-[#44546F]">
      <AlertCircle className="h-6 w-6 text-[#CA3521] mb-2" />
      <p className="text-sm mb-4">Failed to load notifications</p>
      <Button
        variant="ghost"
        size="sm"
        onClick={onRetry}
        className="text-xs hover:bg-[#F3F6F8]"
      >
        Try again
      </Button>
    </div>
  );
}
