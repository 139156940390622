import { useState, useEffect } from 'react';

const colors = [
  '#ef4444',
  '#f97316',
  '#fbbf24',
  '#a3e635',
  '#4ade80',
  '#22d3ee',
  '#2563eb',
  '#8b5cf6',
  '#d946ef',
  '#f9a8d4',
  '#ffffff',
  '#000000',
];

interface ColorSelectorProps {
  initialColor: string;
  onColorChange: (color: string) => void;
}

export const ColorSelector = ({
  initialColor,
  onColorChange,
}: ColorSelectorProps) => {
  const [selectedColor, setSelectedColor] = useState(initialColor);

  const handleColorClick = (color: string) => {
    setSelectedColor(color);
    onColorChange(color);
  };

  useEffect(() => {
    setSelectedColor(initialColor);
  }, [initialColor]);

  return (
    <div className="space-y-2">
      <div className="grid grid-cols-6 gap-2">
        {colors.map((color) => (
          <div
            key={color}
            className={`w-6 h-6 rounded-sm cursor-pointer hover:scale-105 ${
              selectedColor === `${color}` ? 'ring-2 ring-gray-400' : ''
            }`}
            style={{
              backgroundColor: `${color}`,
              border: color === 'ffffff' ? '1px solid #ccc' : 'none',
            }}
            onClick={() => handleColorClick(`${color}`)}
          />
        ))}
      </div>
    </div>
  );
};

export default ColorSelector;
