import { Fragment, useState } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { Trash2Icon } from 'lucide-react';
import ScreenshotDialog from './ScreenshotDialog';

interface EditStepActionsProps {
  editingId: string | null;
  handleEditStepDescription: (params: {
    stepId: string | null;
    description: string;
  }) => void;
  stepId: string;
  stepDescription: string;
  screenshotName: string;
  stepNumber: number | string;
  screenshotId: string;
  isStepTypeStep: boolean;
  handleDeleteStep: (stepId: string) => void;
  handleSaveStepDescription: (stepId: string | null) => void;
  isEditButtonPressed: boolean;
  documentId: string;
  hasScreenshot: boolean;
}

const EditStepActions = ({
  stepId,
  isStepTypeStep,
  handleDeleteStep,
  isEditButtonPressed,
  documentId,
  hasScreenshot,
}: EditStepActionsProps) => {
  const [isSingleModalOpen, setIsSingleModalOpen] = useState(false);

  return (
    <Fragment>
      {!isEditButtonPressed && (
        <Fragment>
          <div>
            {!hasScreenshot && (
              <ScreenshotDialog
                stepId={stepId}
                documentId={documentId}
                mode="add"
              />
            )}
            <Dialog
              open={isSingleModalOpen}
              onOpenChange={setIsSingleModalOpen}
            >
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <DialogTrigger asChild>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => setIsSingleModalOpen(true)}
                        className="group"
                      >
                        <Trash2Icon className="h-4 w-4 transition-colors group-hover:text-red-500" />
                      </Button>
                    </DialogTrigger>
                  </TooltipTrigger>
                  <TooltipContent>Delete Step</TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <DialogContent className="sm:max-w-md">
                <DialogHeader>
                  <DialogTitle>
                    {isStepTypeStep ? 'Delete Step' : 'Delete'}
                  </DialogTitle>
                  <DialogDescription>
                    {isStepTypeStep
                      ? 'This action cannot be undone. This will permanently delete this step from this process. Are you sure?'
                      : 'This action cannot be undone. Are you sure?'}
                  </DialogDescription>
                </DialogHeader>
                <DialogFooter className="mt-2 w-full">
                  <div className="flex flex-col sm:flex-row w-full justify-end gap-2">
                    <div className="flex justify-end gap-2">
                      <DialogClose asChild>
                        <Button type="button" variant="secondary">
                          Close
                        </Button>
                      </DialogClose>

                      <DialogClose asChild>
                        <Button
                          type="button"
                          onClick={() => handleDeleteStep(stepId ?? '')}
                          variant="destructive"
                        >
                          Yes, delete
                        </Button>
                      </DialogClose>
                    </div>
                  </div>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditStepActions;
