export enum NotificationType {
  RESOURCE_REQUEST_OVERDUE = 'RESOURCE_REQUEST_OVERDUE',
  RESOURCE_REQUEST_ASSIGNED = 'RESOURCE_REQUEST_ASSIGNED',
  RESOURCE_REQUEST_CANCELLED = 'RESOURCE_REQUEST_CANCELLED',
  RESOURCE_REQUEST_UPDATED = 'RESOURCE_REQUEST_UPDATED',
  RESOURCE_REQUEST_COMPLETED = 'RESOURCE_REQUEST_COMPLETED',
}

export enum NotificationChannel {
  IN_APP = 'IN_APP',
  EMAIL = 'EMAIL',
}

export interface NotificationCategory {
  name: string;
  description: string;
  types: {
    type: NotificationType;
    label: string;
    description: string;
  }[];
}

export interface NotificationPreference {
  notificationType: NotificationType;
  channels: NotificationChannel[];
  isDefault: boolean;
}

export interface NotificationPreferenceResponse {
  message: string;
  preferences: NotificationPreference[];
}

export interface UpdatePreferenceResponse {
  message: string;
  preference: NotificationPreference;
}

export const NOTIFICATION_CATEGORIES: NotificationCategory[] = [
  {
    name: 'Resource Requests',
    description:
      'Notifications related to resource requests and their status changes',
    types: [
      {
        type: NotificationType.RESOURCE_REQUEST_ASSIGNED,
        label: 'Assignment',
        description: 'When a resource request is assigned to you or your team',
      },
      {
        type: NotificationType.RESOURCE_REQUEST_UPDATED,
        label: 'Updates',
        description: 'When there are changes or updates to a resource request',
      },
      {
        type: NotificationType.RESOURCE_REQUEST_OVERDUE,
        label: 'Overdue Alerts',
        description:
          'When a resource request becomes overdue or requires immediate attention',
      },
      {
        type: NotificationType.RESOURCE_REQUEST_CANCELLED,
        label: 'Cancellations',
        description: 'When a resource request is cancelled or withdrawn',
      },
      {
        type: NotificationType.RESOURCE_REQUEST_COMPLETED,
        label: 'Completion',
        description:
          'When a resource request is fulfilled and marked as complete',
      },
    ],
  },
];
