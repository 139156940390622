type DocSkeletonProps = {
  stepNum: number;
};

const DocSkeleton = ({ stepNum }: DocSkeletonProps) => (
  <div className="flex w-full max-w-5xl mx-auto mt-8 px-6">
    <div className="animate-pulse w-full">
      <div className="relative isolate w-full my-4">
        <div className="mx-auto w-full">
          <div className="mx-auto flex w-full flex-col items-start justify-between gap-x-8 lg:mx-0 lg:w-full">
            <div className="flex w-full justify-between">
              {/* Skeleton for Document Title */}
              <div className="flex-grow gap-2">
                <div className="bg-gray-200 rounded-md w-48 h-9 mb-2"></div>
                <div className="bg-gray-200 rounded-md w-full h-9"></div>
              </div>
              {/* Placeholder Buttons */}
              <div className="inline-flex items-center rounded-md bg-gray-200 h-8 w-10"></div>
              <div className="ml-2 inline-flex items-center rounded-md bg-gray-200 h-8 w-10"></div>
              <div className="ml-2 inline-flex items-center rounded-md bg-gray-200 h-8 w-10"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto w-full pb-8">
        <div className="mx-auto w-full items-start gap-x-8 gap-y-8">
          {/* Skeleton for Document Summary */}
          <dl className="animate-pulse flex gap-2 my-8">
            {Array.from({ length: 7 }).map((_, i) => (
              <div
                key={`skeleton-${i}`}
                className="w-20 h-6 bg-gray-200 rounded-full"
              />
            ))}
          </dl>

          <div className="-mx-4 px-6 py-8 ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:pb-20 w-full border border-gray-200">
            {Array.from({ length: stepNum }).map((_, index) => (
              <div
                key={index}
                className="animate-pulse flex flex-col space-y-4 border-b border-gray-200 py-5 w-full"
              >
                {/* Skeleton for Title */}
                <div className="flex justify-between">
                  <div className="h-9 bg-gray-200 rounded-md w-1/4" />
                  <div className="flex gap-2">
                    <div className="h-9 bg-gray-200 rounded-md w-12 " />
                    <div className="h-9 bg-gray-200 rounded-md w-12" />
                  </div>
                </div>
                {/* Skeleton for Description */}
                <div className="h-9 bg-gray-200 rounded-md w-full" />

                {/* Skeleton for Image */}
                <div className="h-96 bg-gray-200 rounded-md w-full" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default DocSkeleton;
