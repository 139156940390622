import { useState } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from '@fluency/ui/components/ui/dropdown-menu';
import {
  ArrowLeftRight,
  KeyRound,
  Lock,
  Settings2,
  Trash,
  Unlock,
} from 'lucide-react';
import LockToggleDialog from './LockToggle';
import { Workflow } from '@fluency/ui/hooks/workflows/types/types';
import DeleteWorkflowModal from './DeleteWorkflowModal';
import { PermissionsDialog } from '@fluency/ui/features/ManagePermissions';
import MoveWorkflowDialog from './MoveWorkflow';

interface FileSettingsProps {
  data: Workflow;
  isManager: boolean;
  isVaultLocked: boolean;
}

export function FileSettings({
  data,
  isManager,
  isVaultLocked,
}: FileSettingsProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showPermissionsDialog, setShowPermissionsDialog] = useState(false);
  const [showLockDialog, setShowLockDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showMoveDialog, setShowMoveDialog] = useState(false);

  const handleModalOpen = (modalSetter: (value: boolean) => void) => {
    setIsDropdownOpen(false);
    modalSetter(true);
  };

  const isLocked = data.isLocked;

  return (
    <>
      <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
        <DropdownMenuTrigger asChild>
          <Button size="sm" variant="secondary">
            <Settings2 className="w-4 h-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem
              onSelect={(e) => {
                e.preventDefault();
                handleModalOpen(setShowPermissionsDialog);
              }}
            >
              Permissions
              <DropdownMenuShortcut>
                <KeyRound className="w-4" />
              </DropdownMenuShortcut>
            </DropdownMenuItem>
            {isManager && !isVaultLocked && (
              <>
                <DropdownMenuItem
                  onSelect={(e) => {
                    e.preventDefault();
                    handleModalOpen(setShowLockDialog);
                  }}
                >
                  {isLocked ? 'Unlock' : 'Lock'}
                  <DropdownMenuShortcut>
                    {!isLocked ? (
                      <Lock className="w-4" />
                    ) : (
                      <Unlock className="w-4" />
                    )}
                  </DropdownMenuShortcut>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={(e) => {
                    e.preventDefault();
                    handleModalOpen(setShowMoveDialog);
                  }}
                >
                  Move
                  <DropdownMenuShortcut>
                    <ArrowLeftRight className="w-4" />
                  </DropdownMenuShortcut>
                </DropdownMenuItem>
              </>
            )}
          </DropdownMenuGroup>
          {!isVaultLocked && !data.isLocked && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem
                  className="text-destructive"
                  onSelect={(e) => {
                    e.preventDefault();
                    handleModalOpen(setShowDeleteDialog);
                  }}
                >
                  Delete
                  <DropdownMenuShortcut>
                    <Trash className="w-4" />
                  </DropdownMenuShortcut>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      {/* Modals rendered outside the dropdown */}
      <PermissionsDialog
        resourceId={data.id}
        resourceType="WORKFLOW"
        showTooltip={false}
        trigger={<></>}
        open={showPermissionsDialog}
        onOpenChange={setShowPermissionsDialog}
      />

      {isManager && (
        <>
          <LockToggleDialog
            data={data}
            isOpen={showLockDialog}
            onOpenChange={setShowLockDialog}
          />
          <MoveWorkflowDialog
            workflowId={data.id}
            currentVaultId={data.vault.id}
            isOpen={showMoveDialog}
            onOpenChange={setShowMoveDialog}
          />
        </>
      )}

      <DeleteWorkflowModal
        workflowId={data.id}
        isOpen={showDeleteDialog}
        onOpenChange={setShowDeleteDialog}
      />
    </>
  );
}
