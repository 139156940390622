import { useVaultName } from './vaultUtils';
import { useTeamName } from './teamUtils';
import { useDocumentDetails } from './documentUtils';
import { useWorkflowName } from './workflowUtils';
import { navItems } from '@fluency/ui/features/Settings/utils/navItems';

export type BreadcrumbConfig = {
  pattern: string;
  useHooks?: (params: Record<string, string>) => Record<string, any>;
  breadcrumbs: Array<{
    label:
      | string
      | ((
          params: Record<string, string>,
          hooks?: Record<string, any>
        ) => string);
    path:
      | string
      | ((
          params: Record<string, string>,
          hooks?: Record<string, any>
        ) => string);
    linkTo?: string;
  }>;
};

// Helper function to get dynamic value with proper typing
export const getDynamicValue = (
  value:
    | string
    | ((params: Record<string, string>, hooks?: Record<string, any>) => string),
  params: Record<string, string>,
  hooks?: Record<string, any>
): string => {
  if (typeof value === 'function') {
    return value(params, hooks) ?? '';
  }
  return value;
};

export const breadcrumbConfigs: BreadcrumbConfig[] = [
  // Home route
  {
    pattern: '/home',
    breadcrumbs: [
      {
        label: 'Home',
        path: '/home',
      },
    ],
  },

  // Teams routes
  {
    pattern: '/teams/manage',
    breadcrumbs: [
      {
        label: 'Teams',
        path: '/teams',
        linkTo: '/teams/manage',
      },
      {
        label: 'Manage Teams',
        path: '/teams/manage',
      },
    ],
  },
  {
    pattern: '/teams/:id',
    useHooks: (_params: Record<string, string>) => {
      const { getTeamName, isLoading } = useTeamName();
      return {
        getTeamName,
        isLoading,
      };
    },
    breadcrumbs: [
      {
        label: 'Teams',
        path: '/teams',
        linkTo: '/teams/manage',
      },
      {
        label: (
          params: Record<string, string>,
          hooks?: Record<string, any>
        ): string => {
          if (hooks?.isLoading) return 'Loading...';
          const getTeamName = hooks?.getTeamName;
          return getTeamName ? getTeamName(params.id) : '';
        },
        path: (params: Record<string, string>): string => `/teams/${params.id}`,
      },
    ],
  },

  // Document routes
  {
    pattern: '/document/:id',
    useHooks: (params: Record<string, string>) => {
      const {
        getDocumentName,
        getDocumentVaultId,
        isLoading: docLoading,
      } = useDocumentDetails(params.id ?? '');
      const { getVaultName, isLoading: vaultLoading } = useVaultName();

      return {
        getDocumentName,
        getDocumentVaultId,
        getVaultName,
        isLoading: docLoading || vaultLoading,
      };
    },
    breadcrumbs: [
      {
        label: (
          _params: Record<string, string>,
          hooks?: Record<string, any>
        ): string => {
          if (hooks?.isLoading) return 'Loading...';
          const vaultId = hooks?.getDocumentVaultId?.();
          const getVaultName = hooks?.getVaultName;
          if (!vaultId) return 'Loading...';
          return getVaultName?.(vaultId) ?? 'Loading...';
        },
        path: (
          _params: Record<string, string>,
          hooks?: Record<string, any>
        ): string => {
          const vaultId = hooks?.getDocumentVaultId?.();
          return vaultId ? `/vault/${vaultId}` : '/vault/manage';
        },
      },
      {
        label: (
          params: Record<string, string>,
          hooks?: Record<string, any>
        ): string => {
          if (hooks?.isLoading) return 'Loading...';
          const getDocumentName = hooks?.getDocumentName;
          if (!getDocumentName) return '';
          return getDocumentName(params.id);
        },
        path: (params: Record<string, string>): string =>
          `/document/${params.id}`,
      },
    ],
  },
  // Workflow routes
  {
    pattern: '/workflow/:id',
    useHooks: (params: Record<string, string>) => {
      const {
        getWorkflowName,
        getWorkflowVaultId,
        isLoading: workflowLoading,
      } = useWorkflowName(params.id || '');
      const { getVaultName, isLoading: vaultLoading } = useVaultName();

      return {
        getWorkflowName,
        getWorkflowVaultId,
        getVaultName,
        isLoading: workflowLoading || vaultLoading,
      };
    },
    breadcrumbs: [
      {
        label: (
          _params: Record<string, string>,
          hooks?: Record<string, any>
        ): string => {
          if (hooks?.isLoading) return 'Loading...';
          const vaultId = hooks?.getWorkflowVaultId?.();
          const getVaultName = hooks?.getVaultName;
          if (!vaultId) return 'Loading...';
          return getVaultName?.(vaultId) ?? 'Loading...';
        },
        path: (
          _params: Record<string, string>,
          hooks?: Record<string, any>
        ): string => {
          const vaultId = hooks?.getWorkflowVaultId?.();
          return vaultId ? `/vault/${vaultId}` : '/vault/manage';
        },
      },
      {
        label: (
          _params: Record<string, string>,
          hooks?: Record<string, any>
        ): string => {
          if (hooks?.isLoading) return 'Loading...';
          const getWorkflowName = hooks?.getWorkflowName;
          if (!getWorkflowName) return '';
          return getWorkflowName();
        },
        path: (params: Record<string, string>): string =>
          `/workflow/${params.id}`,
      },
    ],
  },

  // Profile routes
  {
    pattern: '/settings',
    breadcrumbs: [
      {
        label: 'Settings',
        path: '/settings',
        linkTo: '/settings/general',
      },
    ],
  },
  {
    pattern: '/settings/:setting',
    breadcrumbs: [
      {
        label: 'Settings',
        path: '/settings',
        linkTo: '/settings/general',
      },
      {
        label: (params: Record<string, string>): string => {
          const navItem = navItems.find(
            (item) => item.setting === params.setting
          );
          return navItem?.label ?? params.setting ?? '';
        },
        path: (params: Record<string, string>): string =>
          `/settings/${params.setting}`,
      },
    ],
  },

  // Vault routes
  {
    pattern: '/vault/manage',
    breadcrumbs: [
      {
        label: 'Vault',
        path: '/vault/manage',
      },
      {
        label: 'Manage Vaults',
        path: '/vault/manage',
      },
    ],
  },
  {
    pattern: '/vault/:id',
    useHooks: (_params: Record<string, string>) => {
      const { getVaultName, isLoading } = useVaultName();
      return {
        getVaultName,
        isLoading,
      };
    },
    breadcrumbs: [
      {
        label: 'Vault',
        path: '/vault/manage',
      },
      {
        label: (
          params: Record<string, string>,
          hooks?: Record<string, any>
        ): string => {
          if (hooks?.isLoading) return 'Loading...';
          const getVaultName = hooks?.getVaultName;
          return getVaultName ? getVaultName(params.id) : '';
        },
        path: (params: Record<string, string>): string => `/vault/${params.id}`,
      },
    ],
  },
];

// Helper function to match URL pattern
export const matchUrlPattern = (
  pathname: string
): { config: BreadcrumbConfig; params: Record<string, string> } | null => {
  const pathSegments = pathname.split('/').filter(Boolean);

  for (const config of breadcrumbConfigs) {
    const patternSegments = config.pattern.split('/').filter(Boolean);

    if (pathSegments.length !== patternSegments.length) continue;

    const params: Record<string, string> = {};
    let isMatch = true;

    for (let i = 0; i < patternSegments.length; i++) {
      const patternSegment = patternSegments[i];
      const pathSegment = pathSegments[i];

      if (patternSegment && patternSegment.startsWith(':')) {
        if (pathSegment) {
          params[patternSegment.slice(1)] = pathSegment;
        } else {
          isMatch = false;
          break;
        }
      } else if (patternSegment !== pathSegment) {
        isMatch = false;
        break;
      }
    }

    if (isMatch) {
      return { config, params };
    }
  }

  return null;
};
