import * as z from 'zod';

export const createVaultSchema = z.object({
  name: z
    .string()
    .min(1, { message: 'Vault name is required' })
    .max(50, { message: 'Vault name cannot exceed 50 characters' })
    .regex(/^[a-zA-Z0-9\s\-_'"]+$/, {
      message:
        'Name can only contain letters, numbers, spaces, hyphens and underscores',
    }),
  description: z
    .string()
    .max(200, { message: 'Description cannot exceed 200 characters' })
    .min(1, { message: 'Vault description is required' }),
  isPublic: z.boolean().default(false),
  isGlobal: z.boolean().default(false),
  isLocked: z.boolean().default(false),
});

export type CreateVaultFormData = z.infer<typeof createVaultSchema>;

export const updateVaultSchema = createVaultSchema.extend({
  id: z.string(),
});

export type UpdateVaultFormData = z.infer<typeof updateVaultSchema>;
