import { ReactElement, ReactNode } from 'react';
import { FileText, Text } from 'lucide-react';
import { ResultStyles } from '../types/search';

const RESOURCE_LABELS: Record<string, string> = {
  DOC: 'Document',
  STEP: 'Step',
  // Add more mappings as needed
};

const MATCH_FIELD_LABELS: Record<string, string> = {
  docName: 'Name',
  docDescription: 'Description',
  stepDescription: 'Step',
  // Add more mappings as needed
};

export const getResourceLabel = (resource: string): string => {
  return RESOURCE_LABELS[resource] || resource;
};

export const getMatchFieldLabel = (matchField: string): string => {
  return MATCH_FIELD_LABELS[matchField] || matchField;
};

export const getResultIcon = (matchField: string): ReactElement => {
  if (matchField === 'stepDescription') {
    return <Text className="h-5 w-5 text-gray-500 flex-shrink-0" />;
  }
  return <FileText className="h-5 w-5 text-gray-500 flex-shrink-0" />;
};

export const getResultStyles = (matchField: string): ResultStyles => {
  if (matchField === 'stepDescription') {
    return {
      container: 'bg-gray-50 border-gray-200',
      title: 'text-gray-800',
      description: 'text-gray-600',
      match: 'text-gray-900 bg-gray-100 p-2 rounded',
    };
  }
  return {
    container: 'bg-white border-gray-200',
    title: 'text-gray-900',
    description: 'text-gray-600',
    match: 'text-gray-700',
  };
};

export const highlightMatch = (text: string, query: string): ReactNode => {
  if (!query.trim() || !text) return text;

  try {
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, i) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={i} className="bg-yellow-200 text-gray-900 px-1 rounded">
          {part}
        </span>
      ) : (
        part
      )
    );
  } catch (error) {
    // If there's any issue with the regex, return the original text
    return text;
  }
};
