import { Button } from '@fluency/ui/components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@fluency/ui/components/ui/card';
import { Logger } from '@fluency/ui/features/Logger';
import useSubscription from '@fluency/ui/hooks/subscription/useSubscription';
import { AlertTriangle } from 'lucide-react';

interface CancelSubscriptionConfirmationProps {
  onCancel: () => void;
  onConfirm: () => void;
}

export default function CancelSubscriptionConfirmation({
  onCancel,
  onConfirm,
}: CancelSubscriptionConfirmationProps) {
  const {
    cancelSubscription,
    isCancelingSubscription,
    cancelSubscriptionError,
  } = useSubscription();

  const handleConfirm = async () => {
    try {
      await cancelSubscription();
      onConfirm();
    } catch (error) {
      Logger.error('Failed to cancel subscription:', error);
    }
  };

  return (
    <Card className="w-full border-0 p-0">
      <CardHeader className="p-0 pb-4">
        <CardTitle>
          Are you sure you want to cancel your subscription?
        </CardTitle>
        <CardDescription className="text-sm text-gray-600">
          You will lose access to all premium features.
        </CardDescription>
      </CardHeader>
      <CardContent className="justify-center items-center flex flex-col">
        <AlertTriangle className="w-24 h-24 text-red-400 mb-6 mt-4" />
        <div className="flex w-full justify-center gap-2 -mb-6">
          <Button
            variant="outline"
            onClick={onCancel}
            disabled={isCancelingSubscription}
          >
            No, keep subscription
          </Button>
          <Button
            variant="destructive"
            onClick={handleConfirm}
            disabled={isCancelingSubscription}
          >
            {isCancelingSubscription
              ? 'Canceling...'
              : 'Yes, cancel subscription'}
          </Button>
        </div>
        {cancelSubscriptionError && (
          <p className="text-red-500 mt-4">
            Error: {cancelSubscriptionError.message}
          </p>
        )}
      </CardContent>
    </Card>
  );
}
