import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { Team } from '../../../../../hooks/teams/types/types';

interface DeleteTeamResponse {
  message: string;
  success: boolean;
}

async function deleteTeam(
  teamIds: string[],
  accessToken: string
): Promise<DeleteTeamResponse> {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  const response = await fetch(`${apiBaseUrl}/teams/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ teamIds }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export function useDeleteTeam() {
  const { accessToken, orgId } = useAuth();
  const queryClient = useQueryClient();

  return useMutation<
    DeleteTeamResponse,
    Error,
    { teamId: string },
    { previousTeams: Team[] | undefined }
  >({
    mutationKey: ['deleteTeam'],
    mutationFn: async ({ teamId }) => {
      return deleteTeam([teamId], accessToken || '');
    },
    onMutate: async ({ teamId }) => {
      await queryClient.cancelQueries({ queryKey: ['teams', orgId] });
      const previousTeams = queryClient.getQueryData<Team[]>(['teams', orgId]);

      if (previousTeams) {
        queryClient.setQueryData<Team[]>(
          ['teams', orgId],
          (old) => old?.filter((team) => team.teamId !== teamId)
        );
      }

      return { previousTeams };
    },
    onError: (_error, _variables, context) => {
      if (context?.previousTeams) {
        queryClient.setQueryData(['teams', orgId], context.previousTeams);
      }
      toast({
        variant: 'destructive',
        title: 'Something went wrong.',
        description: 'Unable to delete the team. Please try again.',
      });
    },
    onSuccess: (data) => {
      toast({
        variant: 'default',
        title: 'Success',
        description: data.message || 'Team deleted successfully.',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['teams', orgId] });
    },
  });
}
