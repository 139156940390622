import { BPMNElement } from '../types/workflow';

export const bpmnElements: BPMNElement[] = [
  { id: 'start', title: 'Start', type: 'startNode' },
  { id: 'end', title: 'End', type: 'endNode' },
  { id: 'decision', title: 'Decision', type: 'decisionNode' },
  {
    id: 'activity',
    title: 'Activity',
    label: '',
    content: null,
    type: 'activityNode',
  },
  { id: 'text', title: 'Text', label: '', type: 'text' },
  { id: 'swimlane', title: 'Swimlane', type: 'swimlaneNode' },
];
