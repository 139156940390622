import { TRANSITION_DURATION } from './constant';

export const transitionStyles = {
  base: {
    transition: `all ${TRANSITION_DURATION}ms ease-in-out`,
  },
  container: {
    transition: `transform ${TRANSITION_DURATION}ms ease-in-out`,
  },
  zoom: (scale: number) => ({
    transform: `scale(${scale})`,
  }),
};
