import { createFileRoute } from '@tanstack/react-router';
import { Button } from '@fluency/ui/components/ui/button';
import { TreePalm, Sun, ArrowLeft } from 'lucide-react';
import logo from '@fluency/ui/assets/icon-blue.svg';

export const Route = createFileRoute('/404')({
  component: NotFoundPage,
});

function NotFoundPage() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen w-full bg-white">
      <img src={logo} className="h-14 w-14 mb-6" alt="Fluency Logo" />
      <div className="border rounded-lg p-8 max-w-md w-full">
        <h1 className="text-2xl font-semibold mb-4">Page Not Found</h1>

        <div className="relative h-64 bg-blue-200 overflow-hidden rounded-md mb-6">
          {/* Sky */}
          <div className="absolute inset-0 bg-gradient-to-b from-blue-300 to-blue-100"></div>

          {/* Sun */}
          <Sun className="absolute top-4 right-4 h-16 w-16 text-yellow-400" />

          {/* Island */}
          <div className="absolute bottom-12 left-1/3 transform -translate-x-1/2 w-1/3 h-8 bg-yellow-200 rounded-full z-10"></div>

          {/* Palm Tree */}
          <TreePalm className="absolute bottom-16 left-1/3 transform -translate-x-1/2 h-24 w-24 text-green-800 z-20" />

          {/* Ocean */}
          <div className="absolute bottom-0 left-0 right-0 h-1/3 bg-blue-400"></div>
        </div>

        <p className="text-gray-600 mb-6">
          Please check the URL or go back to the homepage.
        </p>

        <Button
          onClick={() => (window.location.href = '/')}
          className="w-full mb-4 text-md"
          variant="outline"
        >
          Go to Homepage
        </Button>
      </div>

      <Button
        variant="ghost"
        className="mt-6 flex items-center text-sm text-gray-600"
        onClick={() => (window.location.href = '/login')}
      >
        <ArrowLeft className="w-4 h-4 mr-1" /> Sign in with a different account
      </Button>
    </div>
  );
}
