import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import { HelpCircle, FileText, WorkflowIcon } from 'lucide-react';

export function VaultHelpDialog() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <HelpCircle className="text-gray-400 hover:text-fluency-500 hover:cursor-pointer w-5 h-5 transition-colors" />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-xl font-semibold">
            What are Vaults?
          </DialogTitle>
          <div className="space-y-4 pt-4">
            <DialogDescription className="text-gray-600 text-base">
              Vaults are dedicated spaces within Fluency for storing and
              organising your resources.
            </DialogDescription>

            <div>
              <div className="text-gray-700 font-medium mb-2">
                Within vaults, you can store and manage:
              </div>
              <ul className="list-none space-y-2">
                <li className="flex items-center gap-2">
                  <div className="w-5 h-5 rounded flex items-center justify-center">
                    <FileText className="w-4 h-4 text-primary" />
                  </div>
                  <span className="text-gray-700">Process Documentation</span>
                </li>
                <li className="flex items-center gap-2">
                  <div className="w-5 h-5 rounded flex items-center justify-center">
                    <WorkflowIcon className="w-4 h-4 text-purple-500" />
                  </div>
                  <span className="text-gray-700">Process Maps</span>
                </li>
              </ul>
            </div>

            <div>
              <div className="text-gray-700 font-medium mb-2">
                To foster collaboration, you can invite:
              </div>
              <ul className="list-none space-y-2">
                <li className="flex items-center gap-2">
                  <div className="w-5 h-5 rounded  flex items-center justify-center">
                    <svg
                      className="w-4 h-4 text-gray-700"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx="12" cy="7" r="4" />
                    </svg>
                  </div>
                  <span className="text-gray-700">Individual team members</span>
                </li>
                <li className="flex items-center gap-2">
                  <div className="w-5 h-5 rounded  flex items-center justify-center">
                    <svg
                      className="w-4 h-4 text-gray-700"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                      <circle cx="9" cy="7" r="4" />
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                      <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    </svg>
                  </div>
                  <span className="text-gray-700">Entire teams</span>
                </li>
              </ul>
            </div>

            <DialogDescription className="text-gray-700 text-base border-t pt-4">
              This makes vaults the perfect place to centralise and share
              resources, helping teams work better together.
            </DialogDescription>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
