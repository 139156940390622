import React, { useState, useRef } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import { Input } from '@fluency/ui/components/ui/input';
import { Label } from '@fluency/ui/components/ui/label';
import { LoaderCircle, ImageIcon } from 'lucide-react';
import { convertToPng } from '../../utils/convertToPng';
import { Logger } from '@fluency/ui/features/Logger';
import useReplaceScreenshot from '@fluency/ui/features/ViewDocument/hooks/useReplaceScreenshot';

interface ChangeScreenshotSettingsProps {
  stepId: string;
  documentId: string;
  onClose?: () => void;
}

export const ChangeScreenshotSettings: React.FC<
  ChangeScreenshotSettingsProps
> = ({ stepId, documentId, onClose }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const { mutate: replaceScreenshot, isPending } =
    useReplaceScreenshot(documentId);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile =
      event.target.files && event.target.files[0]
        ? event.target.files[0]
        : null;

    if (newFile) {
      setFile(newFile);
      // Create preview URL
      const url = URL.createObjectURL(newFile);
      setPreviewUrl(url);
    } else {
      handleCancel();
    }
  };

  const handleSubmit = async (event: React.MouseEvent) => {
    event.preventDefault();

    if (!file) return;

    try {
      const pngFile = await convertToPng(file);
      await replaceScreenshot(
        {
          file: pngFile,
          stepId,
          fileType: 'png',
        },
        {
          onSuccess: () => {
            handleCancel();
            if (onClose) {
              onClose();
            }
          },
        }
      );
    } catch (error) {
      Logger.error('Error replacing screenshot:', error);
    }
  };

  const handleCancel = () => {
    setFile(null);
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
      setPreviewUrl(null);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className="space-y-4">
      <div className="grid gap-4">
        <div className="space-y-2">
          <Label htmlFor="screenshot">Screenshot</Label>
          <Input
            id="screenshot"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            ref={fileInputRef}
            className="cursor-pointer"
          />
        </div>

        {previewUrl && (
          <div className="mt-4 relative rounded-lg overflow-hidden">
            <img
              src={previewUrl}
              alt="Preview"
              className="max-h-48 w-full object-contain bg-slate-100"
            />
          </div>
        )}

        {file && (
          <div className="flex gap-2">
            <Button
              onClick={handleSubmit}
              disabled={isPending || !file}
              className="flex-1"
              size="sm"
            >
              {isPending ? (
                <>
                  <LoaderCircle className="animate-spin w-4 h-4 mr-2" />
                  Uploading...
                </>
              ) : (
                <>
                  <ImageIcon className="w-4 h-4 mr-2" />
                  Upload Screenshot
                </>
              )}
            </Button>
            <Button
              variant="outline"
              onClick={handleCancel}
              size="sm"
              disabled={isPending}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
