import { useCallback, useRef, useState } from 'react';
import { Node, useReactFlow, XYPosition } from 'reactflow';

interface AlignmentLine {
  id: string;
  type: 'horizontal' | 'vertical';
  position: number;
}

interface UseNodeAlignmentOptions {
  threshold?: number;
  enabled?: boolean;
}

export const useNodeAlignment = (options: UseNodeAlignmentOptions = {}) => {
  const { threshold = 5, enabled = true } = options;
  const [alignmentLines, setAlignmentLines] = useState<AlignmentLine[]>([]);
  const { getNodes, setNodes } = useReactFlow();
  const draggedNodeRef = useRef<string | null>(null);

  const clearAlignmentLines = useCallback(() => {
    setAlignmentLines([]);
  }, []);

  const findAlignments = useCallback(
    (nodeId: string, dragPosition: XYPosition): XYPosition => {
      if (!enabled) return dragPosition;

      const nodes = getNodes();
      const draggedNode = nodes.find((n) => n.id === nodeId);
      if (!draggedNode) return dragPosition;

      const otherNodes = nodes.filter((n) => n.id !== nodeId);
      let newPosition = { ...dragPosition };
      let newAlignmentLines: AlignmentLine[] = [];

      // Get draggedNode dimensions
      const draggedWidth = draggedNode.width || 0;
      const draggedHeight = draggedNode.height || 0;
      const draggedCenterX = dragPosition.x + draggedWidth / 2;
      const draggedCenterY = dragPosition.y + draggedHeight / 2;

      // Track if we found any alignments
      let foundVerticalAlignment = false;
      let foundHorizontalAlignment = false;

      otherNodes.forEach((node) => {
        const nodeWidth = node.width || 0;
        const nodeHeight = node.height || 0;
        const nodeCenterX = node.position.x + nodeWidth / 2;
        const nodeCenterY = node.position.y + nodeHeight / 2;

        // Check vertical alignment (x-axis)
        if (
          !foundVerticalAlignment &&
          Math.abs(draggedCenterX - nodeCenterX) < threshold
        ) {
          newPosition.x = node.position.x + (nodeWidth - draggedWidth) / 2;
          foundVerticalAlignment = true;
          newAlignmentLines.push({
            id: `vertical-${node.id}`,
            type: 'vertical',
            position: nodeCenterX,
          });
        }

        // Check horizontal alignment (y-axis)
        if (
          !foundHorizontalAlignment &&
          Math.abs(draggedCenterY - nodeCenterY) < threshold
        ) {
          newPosition.y = node.position.y + (nodeHeight - draggedHeight) / 2;
          foundHorizontalAlignment = true;
          newAlignmentLines.push({
            id: `horizontal-${node.id}`,
            type: 'horizontal',
            position: nodeCenterY,
          });
        }
      });

      setAlignmentLines(newAlignmentLines);
      return newPosition;
    },
    [enabled, getNodes, threshold]
  );

  const onNodeDragStart = useCallback(
    (_event: React.MouseEvent, node: Node) => {
      draggedNodeRef.current = node.id;
    },
    []
  );

  const onNodeDrag = useCallback(
    (_event: React.MouseEvent, node: Node) => {
      if (draggedNodeRef.current === node.id) {
        const snappedPosition = findAlignments(node.id, node.position);

        // Update node position if it changed due to snapping
        if (
          snappedPosition.x !== node.position.x ||
          snappedPosition.y !== node.position.y
        ) {
          setNodes((nds) =>
            nds.map((n) => {
              if (n.id === node.id) {
                return {
                  ...n,
                  position: snappedPosition,
                };
              }
              return n;
            })
          );
        }
      }
    },
    [findAlignments, setNodes]
  );

  const onNodeDragStop = useCallback(() => {
    draggedNodeRef.current = null;
    clearAlignmentLines();
  }, [clearAlignmentLines]);

  return {
    alignmentLines,
    onNodeDragStart,
    onNodeDrag,
    onNodeDragStop,
  };
};

// Alignment Lines Component
export const AlignmentLines: React.FC<{ alignmentLines: AlignmentLine[] }> = ({
  alignmentLines,
}) => {
  const { getViewport } = useReactFlow();
  const viewport = getViewport();

  return (
    <>
      {alignmentLines.map((line) => {
        const isVertical = line.type === 'vertical';
        const style = {
          position: 'absolute' as const,
          backgroundColor: '#ff0071',
          [isVertical ? 'height' : 'width']: '100%',
          [isVertical ? 'width' : 'height']: '1px',
          [isVertical ? 'left' : 'top']: `${
            line.position * viewport.zoom + viewport[isVertical ? 'x' : 'y']
          }px`,
          pointerEvents: 'none' as const,
          zIndex: 1000,
        };

        return <div key={line.id} style={style} />;
      })}
    </>
  );
};
