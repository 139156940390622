import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '../../../components/ui/dropdown-menu';
import {
  DocumentMinusIcon,
  DocumentPlusIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from '../../../components/ui/dialog';
import { Button } from '../../../components/ui/button';
import { useState } from 'react';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useGetDocumentData } from '../hooks/index';
import { Logger } from '@fluency/ui/features/Logger';

type RecordDropdownProps = {
  stepBeforeId: string | null;
  documentationId: string;
  originalDocumentId: string;
  versionMajor: string;
  versionMinor: string;
  versionPatch: string;
};

const RecordDropdown = ({
  stepBeforeId,
  documentationId,
  originalDocumentId,
}: RecordDropdownProps) => {
  const { refetch } = useGetDocumentData(documentationId);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingType, setRecordingType] = useState<'insert' | 'overwrite'>();
  const { accessToken } = useAuth();

  const startRecord = async (type: 'insert' | 'overwrite') => {
    if (!isRecording) {
      if (window.electron) {
        try {
          await window.electron.ipcRenderer.invoke('start-record');
          setIsRecording(true);
          setRecordingType(type);
        } catch (error) {
          toast({
            variant: 'destructive',
            title: 'Error starting recording',
            description: 'Please try again.',
          });
          Logger.error('Error starting recording:', error);
        }
      }
    }
  };

  const stopRecord = async () => {
    setIsRecording(false);
    setLoading(true);
    if (window.electron) {
      try {
        const result = await window.electron.ipcRenderer.invoke(
          'run-steps-existing',
          {
            documentationId,
            stepBeforeId,
            token: accessToken,
            insertType: recordingType,
            originalDocumentId,
          }
        );
        if (
          result &&
          typeof result === 'object' &&
          'uploadSuccess' in result &&
          result.uploadSuccess &&
          typeof result.uploadSuccess === 'object' &&
          'success' in result.uploadSuccess
        ) {
          if (result.uploadSuccess.success) {
            toast({
              title: 'Recording processed successfully!',
              description: `The document has been updated.`,
            });
            refetch();
          } else {
            throw new Error('Recording processing failed');
          }
        } else {
          throw new Error('Unexpected result format');
        }
      } catch (error) {
        Logger.error('Error processing recording:', error);
        setError(true);
        toast({
          variant: 'destructive',
          title: 'Error processing recording',
          description: 'Please try again.',
        });
      } finally {
        setLoading(false);
      }
    }
  };
  const closeErrorDialog = () => {
    setError(false);
    setLoading(false);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div
            title="Record from Here"
            className="flex items-center justify-center cursor-pointer rounded-full bg-slate-100 p-1 w-6 h-6"
          >
            <VideoCameraIcon className="w-6 h-6 text-slate-500" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuLabel className="cursor-default">
            Record from Here
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          {/* {isLast ? (
            <DropdownMenuItem
              onSelect={() => startRecord('overwrite')}
              className="cursor-pointer"
            >
              <div className="inline-flex items-center text-center justify-center">
                <DocumentPlusIcon className="w-4 h-4 mr-1" />
                <span>Append after final step</span>
              </div>
            </DropdownMenuItem>
          ) : ( */}
          <>
            <DropdownMenuItem
              onSelect={() => startRecord('insert')}
              className="cursor-pointer"
            >
              <div className="inline-flex items-center text-center justify-center">
                <DocumentPlusIcon className="w-4 h-4 mr-1" />
                <span>Insert between existing steps</span>
              </div>
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => startRecord('overwrite')}
              className="cursor-pointer"
            >
              <div className="inline-flex items-center text-center justify-center">
                <DocumentMinusIcon className="w-4 h-4 mr-1" />
                <span>Overwrite following steps</span>
              </div>
            </DropdownMenuItem>
          </>
          {/* )} */}
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog open={isRecording}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Recording in Progress</DialogTitle>
            <DialogDescription>
              Click the Stop Recording button to stop the current recording.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button onClick={stopRecord}>Stop Recording</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={loading}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {error ? 'Error' : 'Processing new steps'}
            </DialogTitle>
            <DialogDescription>
              {error
                ? 'No steps were recorded. Please try again.'
                : 'Please wait while the recording is being processed.'}
            </DialogDescription>
          </DialogHeader>
          {error && (
            <DialogFooter>
              <Button onClick={closeErrorDialog}>Close</Button>
            </DialogFooter>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RecordDropdown;
