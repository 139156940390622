import {
  LifeBuoy,
  Mail,
  CalendarDays,
  ExternalLink,
  Phone,
} from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import { Button } from '@fluency/ui/components/ui/button';

const SupportDialog = () => {
  return (
    <Dialog>
      <DialogTrigger className="w-full flex gap-2 text-sm items-center px-2 hover:bg-muted/60 py-1.5 rounded justify-between">
        Support <LifeBuoy className="h-4 w-4" />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Need Help?</DialogTitle>
          <DialogDescription>
            Our support team is here to help. Choose how you'd like to reach us:
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-8 py-4">
          <Card className="border-0">
            <CardHeader className="p-0 pb-3">
              <CardTitle className="flex items-center gap-2 text-base font-medium">
                <Mail className="h-4 w-4" />
                Email the team
              </CardTitle>
              <CardDescription>
                Get in touch with our support team directly
              </CardDescription>
            </CardHeader>
            <CardContent className="p-0">
              <Button
                variant="secondary"
                className="w-full"
                onClick={() =>
                  (window.location.href = 'mailto:support@usefluency.com')
                }
              >
                <Mail className="mr-2 h-4 w-4" />
                support@usefluency.com
              </Button>
            </CardContent>
          </Card>
          <Card className="border-0">
            <CardHeader className="p-0 pb-3">
              <CardTitle className="flex items-center gap-2 text-base font-medium">
                <CalendarDays className="h-4 w-4" />
                Book a Meeting
              </CardTitle>
              <CardDescription>
                Schedule a call with our team for personalised assistance
              </CardDescription>
            </CardHeader>
            <CardContent className="p-0">
              <Button
                className="w-full"
                onClick={() =>
                  window.open(
                    'https://meetings.hubspot.com/lroubos/fluency',
                    '_blank'
                  )
                }
              >
                <CalendarDays className="mr-2 h-4 w-4" />
                Schedule a Call
                <ExternalLink className="ml-2 h-4 w-4" />
              </Button>
            </CardContent>
          </Card>
          <Card className="border-0">
            <CardHeader className="p-0">
              <CardTitle className="flex items-center gap-2 text-base font-medium">
                <Phone className="h-4 w-4" />
                Call Finn
              </CardTitle>
              <CardDescription>
                Contact Finn at +61 411 076 922 with any questions{' '}
              </CardDescription>
            </CardHeader>
          </Card>
        </div>

        <DialogDescription className="text-xs text-muted-foreground">
          Our support team typically responds within 24 business hours.
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
};

export default SupportDialog;
