import { useState } from 'react';
import { Label } from '@fluency/ui/components/ui/label';
import { Separator } from '@fluency/ui/components/ui/separator';
import { Switch } from '@fluency/ui/components/ui/switch';
import { Button } from '@fluency/ui/components/ui/button';
import { ListOrdered, Crop } from 'lucide-react';
import useUpdateDocumentAnnotations from '@fluency/ui/features/ViewDocument/hooks/useUpdateDocumentAnnotations';
import BulkCropModal from './BulkCrop';
import SyncSteps from './SyncSteps';
import { ColorSelector } from './editor/components/features/ColorSelector';

interface EditAllDropdownProps {
  documentId: string;
}

const EditAllDropdown = ({ documentId }: EditAllDropdownProps) => {
  const [isAnnotationVisible, setIsAnnotationVisible] = useState(true);
  const [showSyncSteps, setShowSyncSteps] = useState(false);
  const [showBulkCrop, setShowBulkCrop] = useState(false);

  const { updateAnnotationColor, toggleAnnotationVisibility } =
    useUpdateDocumentAnnotations(documentId);

  const handleColorChange = (newColor: string) => {
    updateAnnotationColor({ documentId, color: newColor });
  };

  const handleVisibilityToggle = (checked: boolean) => {
    setIsAnnotationVisible(checked);
    toggleAnnotationVisibility({ documentId, visible: checked });
  };

  return (
    <>
      <div className="w-60 p-2">
        <div className="space-y-1">
          {/* Visibility Toggle */}
          <div className="flex items-center justify-between py-2 px-2">
            <Label
              htmlFor="show-click-locations"
              className="text-sm font-normal"
            >
              Show click locations
            </Label>
            <Switch
              id="show-click-locations"
              checked={isAnnotationVisible}
              onCheckedChange={handleVisibilityToggle}
            />
          </div>

          <Separator className="my-1" />

          {/* Color Picker */}
          <div className="py-1">
            <Label className="text-sm font-normal px-2 mb-2 block">
              Click location colour
            </Label>

            <div className="px-2">
              <ColorSelector
                initialColor="ff0000"
                onColorChange={handleColorChange}
              />
            </div>
          </div>

          <Separator className="my-1" />

          {/* Bulk Crop Button */}
          <Button
            variant="ghost"
            className="w-full flex items-center justify-start px-2 py-2 h-8 hover:bg-slate-50 text-sm font-normal text-slate-900"
            onClick={() => setShowBulkCrop(true)}
          >
            <Crop className="w-4 h-4 mr-2" />
            <span>Bulk Crop</span>
          </Button>

          <Separator className="my-1" />

          {/* Sync Steps Button */}
          <Button
            variant="ghost"
            className="w-full flex items-center justify-start px-2 py-2 h-8 hover:bg-slate-50 text-sm font-normal text-slate-900"
            onClick={() => setShowSyncSteps(true)}
          >
            <ListOrdered className="w-4 h-4 mr-2" />
            <span>Sync Steps</span>
          </Button>
        </div>
      </div>

      {/* Modals */}
      <BulkCropModal
        documentId={documentId}
        open={showBulkCrop}
        onOpenChange={setShowBulkCrop}
      />

      <SyncSteps
        documentId={documentId}
        isOpen={showSyncSteps}
        onOpenChange={setShowSyncSteps}
      />
    </>
  );
};

export default EditAllDropdown;
