import { useState } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@fluency/ui/components/ui/select';
import { AlertTriangle } from 'lucide-react';
import { withPermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { toast } from '@fluency/ui/components/ui/use-toast';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@fluency/ui/components/ui/alert';
import useOrgInvites from '@fluency/ui/hooks/organisation/useOrgInvites';
import useGetOrgUsers from '@fluency/ui/hooks/organisation/useGetOrgUsers';

export default withPermissionGate(TransferOwnership, ['org:delete']);

function TransferOwnership() {
  const [open, setOpen] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState('');
  const [isTransferring, setIsTransferring] = useState(false);
  const { transferOwnership } = useOrgInvites();
  const { data } = useGetOrgUsers();

  const adminUsers =
    data?.memberships.filter((m) => m.role.slug === 'admin') || [];

  const handleTransfer = async () => {
    if (!isConfirming) {
      setIsConfirming(true);
      return;
    }

    if (selectedAdmin) {
      setIsTransferring(true);
      try {
        await transferOwnership(selectedAdmin);
        toast({
          title: 'Ownership Transferred',
          description:
            'Organization ownership has been successfully transferred.',
        });
        setOpen(false);
      } catch (error) {
        toast({
          title: 'Transfer Failed',
          description: error instanceof Error ? error.message : 'Unknown error',
          variant: 'destructive',
        });
      } finally {
        setIsTransferring(false);
      }
    }
  };

  return (
    <div className="space-y-3">
      <div>
        <h3 className="text-sm font-medium">Transfer Ownership</h3>
        <p className="text-sm text-muted-foreground">
          Transfer organisation ownership to another admin user.
        </p>
      </div>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button variant="outline" size="sm">
            Transfer Ownership
          </Button>
        </DialogTrigger>

        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Transfer Organization Ownership</DialogTitle>
            <DialogDescription>
              This action will transfer all owner privileges to the selected
              admin. You will become an admin after the transfer.
            </DialogDescription>
          </DialogHeader>

          <div className="space-y-4 py-4">
            {adminUsers.length === 0 ? (
              <Alert>
                <AlertDescription>
                  There are no admins available. Please add an admin first.
                </AlertDescription>
              </Alert>
            ) : (
              <Select
                value={selectedAdmin}
                onValueChange={setSelectedAdmin}
                disabled={isConfirming}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select an admin" />
                </SelectTrigger>
                <SelectContent>
                  {adminUsers.map((admin) => (
                    <SelectItem key={admin.id} value={admin.id}>
                      {admin.user.firstName && admin.user.lastName
                        ? `${admin.user.firstName} ${admin.user.lastName}`
                        : admin.user.email}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}

            {isConfirming && (
              <Alert variant="destructive">
                <AlertTriangle className="h-4 w-4" />
                <AlertTitle>Confirm Transfer</AlertTitle>
                <AlertDescription>
                  This action cannot be undone. Are you sure you want to
                  proceed?
                </AlertDescription>
              </Alert>
            )}
          </div>

          <DialogFooter>
            {isConfirming ? (
              <>
                <Button
                  variant="outline"
                  onClick={() => setIsConfirming(false)}
                  disabled={isTransferring}
                >
                  Cancel
                </Button>
                <Button
                  variant="destructive"
                  onClick={handleTransfer}
                  disabled={!selectedAdmin || isTransferring}
                >
                  {isTransferring ? 'Transferring...' : 'Confirm Transfer'}
                </Button>
              </>
            ) : (
              <Button
                variant="destructive"
                onClick={handleTransfer}
                disabled={!selectedAdmin}
              >
                Transfer Ownership
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
