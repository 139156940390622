import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  Edge,
  Connection,
  MarkerType,
  useReactFlow,
  Node,
  NodeChange,
  EdgeChange,
} from 'reactflow';
import { Button } from '@fluency/ui/components/ui/button';
import 'reactflow/dist/style.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Sidebar from './components/sidebar/Sidebar';
import nodeTypes from './utils/nodeTypes';
import { edgeTypes } from './utils/edgeTypes';
import CustomConnectionLine from './components/edges/CustomConnectionLine';
import { onDragOver } from './utils/workflowHelpers';
import { BPMNElement, WorkflowModeType } from './types/workflow';
import useLocalWorkflow from './hooks/useLocalWorkflow';
import { Trash, RefreshCw } from 'lucide-react';
import type { Documentation } from '@fluency/ui/hooks/documentation/types/GetDocs';
import { useNodeAlignment, AlignmentLines } from './hooks/useNodeAlignment';
import {
  TooltipContent,
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { useCreateEmptyDoc } from '@fluency/ui/hooks/documentation/useCreateEmptyDoc';
import { Logger } from '@fluency/ui/features/Logger';

interface WorkflowData {
  nodes: Node[];
  edges: Edge[];
  onNodesChange: (changes: NodeChange[]) => void;
  onEdgesChange: (changes: EdgeChange[]) => void;
  onConnect: (connection: Connection) => void;
  onEdgeLabelChange: (edgeId: string, newLabel: string) => void;
  clearWorkflow: () => void;
  saveToDatabase: () => void;
  isLoading: boolean;
  onDeleteNode: (nodeOrNodes: Node | Node[] | string) => void;
  onDeleteEdge: (edgeId: string) => void;
}

interface BPMNWorkflowBuilderProps {
  bpmnElements: BPMNElement[];
  workflowId: string;
  setMode: (mode: WorkflowModeType) => void;
  vaultId: string;
}

export interface BPMNWorkflowBuilderRef {
  saveToDatabase: () => Promise<void>;
}

const BPMNWorkflowBuilder = forwardRef<
  BPMNWorkflowBuilderRef,
  BPMNWorkflowBuilderProps
>(({ bpmnElements, workflowId, vaultId }, ref) => {
  const createEmptyDoc = useCreateEmptyDoc({ disableRedirect: true });
  const workflow: WorkflowData | null = useLocalWorkflow(workflowId);

  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const { getViewport } = useReactFlow();

  useImperativeHandle(ref, () => ({
    saveToDatabase: async () => {
      if (workflow) {
        await workflow.saveToDatabase();
      }
    },
  }));

  const createDocumentNode = useCallback(
    async (position: { x: number; y: number }) => {
      if (!workflow) return;

      try {
        const payload = {
          duration: 0,
          name: 'New Process',
          description: 'Enter description...',
          createdDate: new Date().toISOString(),
          vaultId: vaultId,
        };

        const response = await createEmptyDoc.mutateAsync(payload);
        const { document } = response;

        const newNode: Node = {
          id: `doc-${document.documentationId}`,
          type: 'documentNode',
          position: position,
          data: {
            label: document.documentationName,
            id: document.documentationId,
            documentId: document.documentationId,
            documentName: document.documentationName,
            vaultId: vaultId,
            vaultName: null, // You might want to pass vault name through props
            totalSteps: document.totalSteps || 0,
            createdBy: document.createdBy,
          },
        };

        workflow.onNodesChange([{ type: 'add', item: newNode }]);
      } catch (error) {
        Logger.error('Failed to create document:', error);
      }
    },
    [workflow, vaultId, createEmptyDoc]
  );

  const handleDrop = useCallback(
    async (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const reactFlowBounds = event.currentTarget.getBoundingClientRect();
      const nodeType = event.dataTransfer.getData('application/reactflow');

      if (!nodeType || !workflow) return;

      const position = {
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      };

      if (nodeType === 'documentNode') {
        // Check if this is a new empty document or an existing one
        const documentId = event.dataTransfer.getData('application/documentId');

        if (!documentId) {
          // This is a new empty document
          await createDocumentNode(position);
        } else {
          // This is an existing document, handle normally
          const newNode: Node = {
            id: `doc-${documentId}`,
            type: 'documentNode',
            position,
            data: {
              label: event.dataTransfer.getData('application/documentName'),
              id: documentId,
              documentId: documentId,
              documentName: event.dataTransfer.getData(
                'application/documentName'
              ),
              vaultId: event.dataTransfer.getData('application/vaultId'),
              vaultName: event.dataTransfer.getData('application/vaultName'),
              totalSteps:
                parseInt(
                  event.dataTransfer.getData('application/totalSteps')
                ) || 0,
              createdBy: event.dataTransfer.getData('application/createdBy'),
            },
          };

          workflow.onNodesChange([{ type: 'add', item: newNode }]);
        }
      } else {
        // Handle other node types (BPMN elements)
        const id = event.dataTransfer.getData('application/id');
        const droppedItem = bpmnElements.find((item) => item.id === id);
        if (!droppedItem) return;

        const newNode: Node = {
          id: `${id}-${Date.now()}`,
          type: nodeType,
          position,
          data: {
            label: droppedItem.title,
            onDelete: () => workflow.onDeleteNode(`${id}-${Date.now()}`),
          },
        };

        workflow.onNodesChange([{ type: 'add', item: newNode }]);
      }
    },
    [workflow, bpmnElements, createDocumentNode]
  );

  const { alignmentLines, onNodeDragStart, onNodeDrag, onNodeDragStop } =
    useNodeAlignment({
      threshold: 5,
      enabled: true,
    });

  const handleConnect = useCallback(
    (params: Connection) => {
      if (workflow && params.source && params.target) {
        const newEdge: Edge = {
          id: `edge-${Date.now()}`,
          source: params.source,
          target: params.target,
          type: 'label',
          sourceHandle: params.sourceHandle ?? null,
          targetHandle: params.targetHandle ?? null,
          data: { label: '' },
          markerEnd: {
            type: MarkerType.ArrowClosed,
            color: '000000',
          },
          style: {
            strokeWidth: 2,
            stroke: '#000000',
          },
        };
        workflow.onConnect(newEdge as Connection);
      }
    },
    [workflow]
  );

  // Click handler for BPMN element nodes
  const handleElementClick = useCallback(
    (element: BPMNElement) => {
      if (workflow && reactFlowWrapper.current) {
        const bounds = reactFlowWrapper.current.getBoundingClientRect();
        const { zoom, x: panX, y: panY } = getViewport();

        const x = (bounds.width / 2 - panX) / zoom; // Center x
        const y = (bounds.height / 2 - panY) / zoom; // Center y

        // Create random id for the new node
        const id = `${element.id}-${Date.now()}`;

        const newNode: Node = {
          id: id,
          type: element.type,
          position: { x, y },
          data: { label: element.label ?? element.title },
        };

        workflow.onNodesChange([{ type: 'add', item: newNode }]);
      }
    },
    [workflow, getViewport]
  );

  // Click handler for document nodes
  const handleDocNodeClick = useCallback(
    (doc: Documentation) => {
      if (workflow && reactFlowWrapper.current) {
        const bounds = reactFlowWrapper.current.getBoundingClientRect();
        const { zoom, x: panX, y: panY } = getViewport();

        const x = (bounds.width / 2 - panX) / zoom; // Center x
        const y = (bounds.height / 2 - panY) / zoom; // Center y

        const newNode: Node = {
          id: `${doc.versionInfo.originalDocumentationId}`,
          type: 'documentNode',
          position: { x, y },
          data: {
            label: doc.documentationName,
            id: doc.versionInfo.originalDocumentationId,
            documentName: doc.documentationName,
            documentId: doc.documentationId,
            vaultId: doc.vaultId,
            vaultName: doc.vault.name,
            totalSteps: doc.totalSteps,
            createdBy: doc.createdBy,
          },
        };

        workflow.onNodesChange([{ type: 'add', item: newNode }]);
      }
    },
    [workflow, getViewport]
  );

  // Click handler for empty document node ("New Process")
  const handleEmptyDocClick = useCallback(() => {
    if (workflow && reactFlowWrapper.current) {
      const bounds = reactFlowWrapper.current.getBoundingClientRect();
      const { zoom, x: panX, y: panY } = getViewport();

      const position = {
        x: (bounds.width / 2 - panX) / zoom,
        y: (bounds.height / 2 - panY) / zoom,
      };

      createDocumentNode(position);
    }
  }, [workflow, getViewport, createDocumentNode]);

  if (!workflow) {
    return <div>Loading workflow...</div>;
  }

  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onEdgeLabelChange,
    clearWorkflow,
    isLoading,
    onDeleteNode,
    onDeleteEdge,
  } = workflow;

  const edgesWithCallbacks = edges.map((edge: Edge) => ({
    ...edge,
    data: {
      ...edge.data,
      onEdgeLabelChange,
      onDelete: onDeleteEdge,
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 30,
      height: 30,
      color: 'black',
    },
  }));

  const nodesWithDeleteHandler = nodes.map((node) => ({
    ...node,
    // Set swimlane nodes to be behind other nodes
    ...(node.type === 'swimlaneNode' && { style: { zIndex: -10 } }),
    data: {
      ...node.data,
      onDelete: () => onDeleteNode(node.id),
    },
  }));

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex h-[calc(100vh-200px)] px-6">
        <Sidebar
          bpmnElements={bpmnElements}
          onElementClick={handleElementClick}
          onDocNodeClick={handleDocNodeClick}
          onEmptyDocClick={handleEmptyDocClick}
          vaultId={vaultId}
        />

        <div className="flex-1 flex flex-col">
          {isLoading ? (
            <div className="flex items-center justify-center h-full w-full bg-gray-100 rounded animate-pulse">
              <RefreshCw className="w-5 h-5 text-gray-500 animate-spin" />
            </div>
          ) : (
            <div
              ref={reactFlowWrapper}
              className="flex-1 border relative"
              onDrop={handleDrop}
              onDragOver={onDragOver}
            >
              <div className="absolute top-4 right-4 z-10">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => clearWorkflow()}
                      >
                        <Trash className="h-4 w-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>Clear workflow</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>

              <ReactFlow
                nodes={nodesWithDeleteHandler}
                edges={edgesWithCallbacks}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={handleConnect}
                nodesDraggable={true}
                nodesConnectable={true}
                elementsSelectable={true}
                edgeTypes={edgeTypes}
                fitView
                nodeTypes={nodeTypes}
                connectionLineComponent={CustomConnectionLine}
                onNodeDragStart={onNodeDragStart}
                onNodeDrag={onNodeDrag}
                onNodeDragStop={onNodeDragStop}
                defaultEdgeOptions={{
                  type: 'labeled',
                }}
                proOptions={{ hideAttribution: true }}
              >
                <Background />
                <Controls showInteractive={false} />
                <MiniMap />
                <AlignmentLines alignmentLines={alignmentLines} />
              </ReactFlow>
            </div>
          )}
        </div>
      </div>
    </DndProvider>
  );
});

BPMNWorkflowBuilder.displayName = 'BPMNWorkflowBuilder';

export default BPMNWorkflowBuilder;
