import React, { useState } from 'react';
import { NodeProps, useReactFlow } from 'reactflow';
import { X } from 'lucide-react';
import { TextNodeData } from '@fluency/ui/hooks/workflows/types/types';
import clsx from 'clsx';
import { AutoResizeTextArea } from '@fluency/ui/components/common/AutoResizeTextArea';
import Resizer from './Resizer';

const BRACKET_WIDTH = 5;
const MIN_HEIGHT = 16; // text-xs height
const LINE_HEIGHT = 1.2;
const OFFSET_WIDTH = 12;
const OFFSET_HEIGHT = 12;

const TextNode: React.FC<NodeProps<TextNodeData>> = ({ data, id }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: MIN_HEIGHT,
  });
  const { setNodes, getNode } = useReactFlow();
  const text = data.label || '';
  const node = getNode(id);

  const width = node?.width ?? 80;
  const height = node?.height ?? OFFSET_HEIGHT;

  const containerWidth = Math.max(width, dimensions.width + OFFSET_WIDTH);
  const containerHeight = Math.max(height, dimensions.height + OFFSET_HEIGHT);

  const updateText = (newText: string) => {
    setNodes((prevNodes) =>
      prevNodes.map((n) =>
        n.id === id ? { ...n, data: { ...n.data, label: newText } } : n
      )
    );
  };

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    data.onDelete?.();
  };

  return (
    <div
      className={`relative flex items-center px-[6px] py-[6px]`}
      onMouseEnter={() => !data.isViewMode && setIsHovered(true)}
      onMouseLeave={() => !data.isViewMode && setIsHovered(false)}
      style={{
        width: `${containerWidth}px`,
        height: `${containerHeight}px`,
        minWidth: `48px`,
      }}
    >
      {/* Delete button */}
      {isHovered && !data.isViewMode && (
        <button
          onClick={handleDelete}
          className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors z-10"
        >
          <X size={16} />
        </button>
      )}

      {/* Left bracket */}
      <div
        className="absolute -left-1 top-0 bottom-0 border-l border-t border-b border-black"
        style={{ width: `${BRACKET_WIDTH}px` }}
      />

      {/* Right bracket */}
      <div
        className="absolute -right-1 top-0 bottom-0 border-r border-t border-b border-black"
        style={{ width: `${BRACKET_WIDTH}px` }}
      />

      {/* Node Resizer */}
      {isHovered && !data.isViewMode && (
        <Resizer className="absolute bottom-1 right-0" />
      )}

      <AutoResizeTextArea
        className={clsx('text-xs')}
        placeholder="Enter text..."
        text={text}
        onChange={updateText}
        minHeight={MIN_HEIGHT}
        lineHeight={LINE_HEIGHT}
        onDimensionsChange={setDimensions}
        readOnly={data.isViewMode}
      />
    </div>
  );
};

export default TextNode;
