import { PaymentMethod, Plan, Invoices } from './components';
import { PartyPopper } from 'lucide-react';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@fluency/ui/components/ui/alert';
import { Seats } from './components/Seats';
import useSubscription from '@fluency/ui/hooks/subscription/useSubscription';

export default function Component() {
  const { subscriptionStatus } = useSubscription();

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div>
        <h2 className="text-lg font-medium mb-1">Billing & Subscription</h2>
        <p className="text-sm text-muted-foreground">
          Manage your billing information and subscription
        </p>
      </div>

      {subscriptionStatus?.status === 'trialing' &&
        subscriptionStatus.subscription?.trialEnd && (
          <Alert>
            <PartyPopper className="h-4 w-4" />
            <AlertTitle>Welcome to Fluency!</AlertTitle>
            <AlertDescription>
              Enjoy all features during your free trial until{' '}
              {new Date(
                subscriptionStatus.subscription.trialEnd
              ).toLocaleDateString()}
              .
            </AlertDescription>
          </Alert>
        )}

      <div className="grid grid-cols-5 gap-6 min-w-[600px]">
        <div className="col-span-2">
          <Plan />
        </div>
        <div className="col-span-3">
          <PaymentMethod />
        </div>
      </div>
      <Seats />
      <Invoices />
    </div>
  );
}
