import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { createHeaders, showErrorToast, showSuccessToast } from './utils/utils';
import { InfiniteQueryResponse, WorkflowResource } from '../vaults/types/types';
import { ResourceType } from '../fga/types/fga';

interface DeleteWorkflowContext {
  previousVaultResources?: InfiniteQueryResponse;
  vaultId: string;
}

export const useDeleteWorkflow = () => {
  const { accessToken } = useAuth();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const queryClient = useQueryClient();

  return useMutation<void, Error, string, DeleteWorkflowContext>({
    mutationFn: async (workflowId: string): Promise<void> => {
      const response = await fetch(`${apiBaseUrl}/workflows/${workflowId}`, {
        method: 'DELETE',
        headers: createHeaders(accessToken),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(
          errorData.message || `HTTP error! status: ${response.status}`
        );
      }
    },

    onMutate: async (workflowId: string) => {
      // Get the workflow to get vault ID
      const workflow = queryClient.getQueryData<WorkflowResource>([
        'workflow',
        workflowId,
      ]);

      const vaultId = workflow?.vault?.id;
      if (!vaultId) {
        throw new Error('Vault ID not found');
      }

      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey: ['workflow', workflowId],
      });
      await queryClient.cancelQueries({
        queryKey: ['vaultResources', vaultId, undefined],
      });

      // Store previous vault resources state
      const previousVaultResources =
        queryClient.getQueryData<InfiniteQueryResponse>([
          'vaultResources',
          vaultId,
          undefined,
        ]);

      // Remove item optimistically
      queryClient.setQueryData<InfiniteQueryResponse>(
        ['vaultResources', vaultId, undefined],
        (old) => {
          if (!old?.pages) return old;

          const newPages = old.pages.map((page) => ({
            ...page,
            items: page.items.filter(
              (item) =>
                !(
                  item.type === ResourceType.WORKFLOW &&
                  (item.resource as WorkflowResource).id === workflowId
                )
            ),
          }));

          return {
            ...old,
            pages: newPages,
          };
        }
      );

      // Remove from workflow cache
      queryClient.removeQueries({
        queryKey: ['workflow', workflowId],
      });

      return { previousVaultResources, vaultId };
    },

    onSuccess: (_, _workflowId, context) => {
      if (context?.vaultId) {
        queryClient.invalidateQueries({
          queryKey: ['vaultResources', context.vaultId, undefined],
        });
      }
      showSuccessToast('Workflow deleted successfully.');
    },

    onError: (_error, _workflowId, context) => {
      // Restore vault resources on error
      if (context?.vaultId && context?.previousVaultResources) {
        queryClient.setQueryData(
          ['vaultResources', context.vaultId, undefined],
          context.previousVaultResources
        );
      }
      showErrorToast('Failed to delete workflow. Please try again.');
    },

    onSettled: (_, _error, _workflowId, context) => {
      if (context?.vaultId) {
        queryClient.invalidateQueries({
          queryKey: ['vaultResources', context.vaultId, undefined],
        });
      }
    },
  });
};
