import { Badge } from '@fluency/ui/components/ui/badge';
import { Button } from '@fluency/ui/components/ui/button';
import { Input } from '@fluency/ui/components/ui/input';
import {
  ArrowRightCircle,
  ChevronDown,
  RefreshCw,
  Search,
  X,
} from 'lucide-react';
import { useState } from 'react';
import useSearch from '../hooks/useSearch';
import SearchResultItem from './SearchResult';

const INITIAL_RESULTS_COUNT = 7;

const SearchComponent = () => {
  const { query, setQuery, results, isLoading, error, handleSearch } =
    useSearch();
  const [showResults, setShowResults] = useState(true);
  const [displayCount, setDisplayCount] = useState(INITIAL_RESULTS_COUNT);

  const handleCancel = () => {
    setShowResults(false);
    setQuery('');
    setDisplayCount(INITIAL_RESULTS_COUNT);
  };

  const handleSeeMore = () => {
    setDisplayCount((prev) => prev + INITIAL_RESULTS_COUNT);
  };

  const shouldShowResults = results.length > 0 && showResults;
  const visibleResults = results.slice(0, displayCount);
  const hasMoreResults = results.length > displayCount;

  return (
    <div className="w-full max-w-3xl mx-auto">
      <div className="z-10 py-4">
        <div className="relative w-[700px] mx-auto">
          <Input
            placeholder="Search documents, workflows, and vaults..."
            className="px-10 rounded-full"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              setShowResults(true);
              setDisplayCount(INITIAL_RESULTS_COUNT);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
                setShowResults(true);
                setDisplayCount(INITIAL_RESULTS_COUNT);
              }
            }}
          />
          <Search className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
          <div className="absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center gap-2">
            {isLoading ? (
              <RefreshCw className="h-5 w-5 text-gray-400 animate-spin" />
            ) : (
              <ArrowRightCircle
                className="h-6 w-6 text-gray-400 hover:text-primary cursor-pointer"
                onClick={() => {
                  handleSearch();
                  setShowResults(true);
                  setDisplayCount(INITIAL_RESULTS_COUNT);
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div className="min-h-0">
        {error && (
          <div className="text-center text-sm">
            Search failed. Please try again.
          </div>
        )}
        {shouldShowResults && (
          <div className="mt-4">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <h2 className="text-xl font-semibold">Search Results</h2>
                <Badge variant="secondary">{results.length} results</Badge>
              </div>
              <button
                onClick={handleCancel}
                className="text-gray-500 hover:text-gray-700 flex items-center gap-1 text-sm"
              >
                <X className="h-4 w-4" />
              </button>
            </div>
            <div className="space-y-4 pb-4">
              {visibleResults.map((result, index) => (
                <SearchResultItem
                  key={`${result.docId}-${index}`}
                  result={result}
                  query={query}
                />
              ))}

              {hasMoreResults && (
                <div className="flex justify-center mt-6">
                  <Button
                    variant="outline"
                    onClick={handleSeeMore}
                    className="gap-2"
                  >
                    See more
                    <ChevronDown className="h-4 w-4" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchComponent;
