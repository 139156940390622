import { useState } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from '@fluency/ui/components/ui/dialog';
import ChangeSeats from './ChangeSeats';
import CancelSubscriptionConfirmation from './CancelSubscription';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { RefreshCw } from 'lucide-react';
import useSubscription from '@fluency/ui/hooks/subscription/useSubscription';
import { Logger } from '@fluency/ui/features/Logger';

export default function ManageSubscription() {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { createCustomerPortalSession } = useSubscription();

  const handleCancelSubscription = () => {
    Logger.log('Subscription cancelled');
    setShowConfirmation(false);
  };

  const handleSaveChanges = async (changes: {
    newSeats: number;
    plan: string;
    product: string;
    prorationResponse: any;
  }) => {
    setIsUpdating(true);

    const { newSeats, plan, product } = changes;
    const isAnnual = plan === 'annual';
    const subscriptionRequest = {
      productName: product,
      isAnnual,
      seats: newSeats,
    };

    try {
      // Convert to Promise to ensure proper async/await handling
      await new Promise((resolve, reject) => {
        createCustomerPortalSession(subscriptionRequest, {
          onSuccess: () => {
            toast({
              title: 'Successfully updated subscription',
              description:
                'Your changes will be reflected in the billing screen',
            });
            setIsOpen(false);
            resolve(true);
          },
          onError: (error) => {
            Logger.error('Error opening customer portal:', error);
            toast({
              title: 'Error updating subscription',
              description: 'Please try again later',
              variant: 'destructive',
            });
            reject(error);
          },
        });
      });
    } catch (error) {
      Logger.error('Error in handleSaveChanges:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const renderContent = () => {
    if (isUpdating) {
      Logger.log('Rendering loading state');
      return (
        <div className="flex flex-col items-center justify-center py-12">
          <RefreshCw className="h-8 w-8 animate-spin text-gray-400 mb-4" />
          <p className="text-sm text-gray-500">Updating subscription...</p>
        </div>
      );
    }

    if (showConfirmation) {
      return (
        <CancelSubscriptionConfirmation
          onCancel={() => setShowConfirmation(false)}
          onConfirm={handleCancelSubscription}
        />
      );
    }

    return (
      <>
        <DialogHeader>
          <DialogTitle>Manage subscription</DialogTitle>
          <DialogDescription>
            Make changes to your billing information here. Click save when
            you're done.
          </DialogDescription>
        </DialogHeader>
        <ChangeSeats onSaveChanges={handleSaveChanges} />
        <DialogFooter>
          <div className="flex justify-between w-full -mt-20 mr-36">
            <Button
              variant="ghost"
              onClick={() => setShowConfirmation(true)}
              disabled={isUpdating}
              className="opacity-50"
            >
              Cancel subscription
            </Button>
          </div>
        </DialogFooter>
      </>
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="secondary">Manage subscription</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px]">
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
}
