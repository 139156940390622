import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@fluency/ui/components/ui/dialog';
import { RefreshCw } from 'lucide-react';
import { useNavigate } from '@tanstack/react-router';
import { useDeleteWorkflow } from '@fluency/ui/hooks/workflows/useDeleteWorkflow';

interface DeleteWorkflowModalProps {
  workflowId: string;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

export default function DeleteWorkflowModal({
  workflowId,
  isOpen,
  onOpenChange,
}: DeleteWorkflowModalProps) {
  const navigate = useNavigate();
  const { mutateAsync: deleteWorkflow, isPending: isDeletingWorkflow } =
    useDeleteWorkflow();

  const handleDelete = async () => {
    await deleteWorkflow(workflowId);
    onOpenChange(false);
    navigate({ to: '/home' });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Delete workflow?</DialogTitle>
          <DialogDescription>
            This action cannot be undone. This will permanently delete this
            workflow and all associated data. Are you sure?
          </DialogDescription>
        </DialogHeader>

        <DialogFooter className="sm:justify-end">
          <DialogClose asChild>
            <Button
              disabled={isDeletingWorkflow}
              type="button"
              variant="secondary"
            >
              Cancel
            </Button>
          </DialogClose>
          <Button
            disabled={isDeletingWorkflow}
            type="button"
            variant="destructive"
            onClick={handleDelete}
            className="align-middle"
          >
            {isDeletingWorkflow ? (
              <>
                <RefreshCw className="h-4 w-4 mr-2 animate-spin" />
                Deleting...
              </>
            ) : (
              'Yes, delete'
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
