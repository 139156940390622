import { ResourceType } from '@fluency/ui/hooks/fga/types/fga';
import {
  ResourceItem,
  DocumentationResource,
  WorkflowResource,
} from '../../../hooks/vaults/types/types';

export const searchResources = (
  items: ResourceItem[],
  searchTerm: string
): ResourceItem[] => {
  if (!searchTerm.trim()) return items;

  const normalizedSearch = searchTerm.toLowerCase().trim();

  return items.filter((item) => {
    const resource = item.resource as DocumentationResource | WorkflowResource;
    const name =
      item.type === ResourceType.DOCUMENTATION
        ? (resource as DocumentationResource).documentationName
        : (resource as WorkflowResource).name;
    const description = resource.description || '';

    return (
      name?.toLowerCase().includes(normalizedSearch) ||
      description.toLowerCase().includes(normalizedSearch)
    );
  });
};
