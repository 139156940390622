import { Button } from '@fluency/ui/components/ui/button';
import { CropData } from '../../types';

interface CropSettingsProps {
  cropData: CropData | null;
  aspectRatio: number | null;
  onAspectRatioChange: (ratio: number | null) => void;
  onSave: () => void;
}

export const CropSettings: React.FC<CropSettingsProps> = ({
  cropData,
  aspectRatio,
  onAspectRatioChange,
  onSave,
}) => {
  const aspectRatios = [
    { label: 'Free', value: null },
    { label: '16:9', value: 16 / 9 },
    { label: '4:3', value: 4 / 3 },
    { label: '1:1', value: 1 },
  ];

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <label className="text-sm font-medium">Aspect Ratio</label>
        <div className="flex flex-wrap gap-2">
          {aspectRatios.map((ratio) => (
            <Button
              key={ratio.label}
              variant={aspectRatio === ratio.value ? 'default' : 'outline'}
              size="sm"
              onClick={() => onAspectRatioChange(ratio.value)}
            >
              {ratio.label}
            </Button>
          ))}
        </div>
      </div>

      <Button className="w-full" onClick={onSave} disabled={!cropData}>
        Apply Crop
      </Button>
    </div>
  );
};
