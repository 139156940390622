import React from 'react';
import { AlertDescription } from '@fluency/ui/components/ui/alert';
import { CheckCircle2, X } from 'lucide-react';
import { SyncStatus } from './types';

interface StatusAlertProps {
  syncStatus: SyncStatus;
}

export const StatusAlert: React.FC<StatusAlertProps> = ({ syncStatus }) => {
  switch (syncStatus) {
    case 'success':
      return (
        <div className="flex gap-2 p-2 items-center bg-green-50 rounded">
          <CheckCircle2 className="h-4 w-4 text-green-600" />
          <AlertDescription className="text-green-800">
            Extension connected. You can now start using Fluency.
          </AlertDescription>
        </div>
      );
    case 'failed':
      return (
        <div className="flex gap-2 p-2 items-center bg-red-50 rounded">
          <X className="h-4 w-4 text-red-600" />
          <AlertDescription className="text-red-800">
            Connection failed. Please refresh the page and try again.
          </AlertDescription>
        </div>
      );
    default:
      return null;
  }
};
