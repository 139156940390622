import { ArrowDown, ArrowUp, Check, Info, X } from 'lucide-react';
import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@fluency/ui/components/ui/dialog';
import { Button } from '@fluency/ui/components/ui/button';
import { Badge } from '@fluency/ui/components/ui/badge';
import useGetDocumentData from '@fluency/ui/hooks/documentation/useGetDocumentData';
import { useShiftScreenshots } from '../hooks/useShiftScreenshots';

interface SyncStepsProps {
  documentId: string;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

const ITEM_HEIGHT = 160;
const ITEM_GAP = 16;
const TOTAL_ITEM_HEIGHT = ITEM_HEIGHT + ITEM_GAP;
const VISIBLE_ITEMS = 3;
const MAX_SHIFT = 2;

const SyncSteps = ({ documentId, isOpen, onOpenChange }: SyncStepsProps) => {
  const { data: docData } = useGetDocumentData(documentId);
  const shiftScreenshots = useShiftScreenshots();
  const [previewShift, setPreviewShift] = useState<number>(0);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const steps =
    docData?.documentation.steps.slice(0, VISIBLE_ITEMS + MAX_SHIFT) || [];

  const handleShiftClick = (direction: 'up' | 'down') => {
    setPreviewShift((prev) => {
      if (direction === 'up' && prev > -MAX_SHIFT) return prev - 1;
      if (direction === 'down' && prev < MAX_SHIFT) return prev + 1;
      return prev;
    });
  };

  const handleApplyShift = () => {
    shiftScreenshots.mutate(
      { documentationId: documentId, shiftAmount: previewShift },
      {
        onSuccess: () => {
          setPreviewShift(0);
          onOpenChange(false);
        },
      }
    );
  };

  const getScreenshot = (position: number) => {
    if (position < 0 || position >= steps.length) return null;
    return steps[position]?.screenshots[0];
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[800px] h-[85vh] flex flex-col overflow-hidden p-6">
          {/* Fixed Header */}
          <DialogHeader className="flex-shrink-0 space-y-4 mb-6">
            <DialogTitle className="text-xl font-semibold">
              Adjust Screenshot Positions
            </DialogTitle>
            <DialogDescription className="text-base text-gray-600">
              Align your screenshots with their corresponding steps by moving
              them up or down.
            </DialogDescription>
            <div className="flex items-start gap-2 p-3 bg-blue-50 border border-fluency-100 rounded-md text-sm text-fluency-700">
              <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
              <p>
                All screenshots are preserved when adjusting positions. If a
                screenshot moves beyond existing steps, a new step will be
                created.
              </p>
            </div>
          </DialogHeader>

          {/* Main Content Area */}
          <div className="flex-grow min-h-0 overflow-y-auto">
            <div className="grid grid-cols-[1fr_1fr_auto] gap-x-8">
              {/* Steps Column */}
              <div className="flex flex-col min-h-0">
                <div className="flex-shrink-0 border rounded-md px-4 py-2 mb-4 bg-gray-50">
                  <span className="text-sm font-medium">Steps</span>
                </div>
                <div className="space-y-4 pb-4">
                  {steps.map((step, index) => (
                    <div
                      key={step.stepId}
                      className="h-[160px] p-4 border rounded-lg bg-white shadow-sm"
                    >
                      <div className="flex items-center gap-2 mb-3">
                        <Badge variant="secondary" className="px-3 py-1">
                          Step {index + 1}
                        </Badge>
                      </div>
                      <div className="text-sm text-gray-700 line-clamp-5">
                        {step.stepDescription}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Screenshots Column */}
              <div className="flex flex-col min-h-0">
                <div className="flex-shrink-0 border rounded-md px-4 py-2 mb-4 bg-gray-50 z-50">
                  <span className="text-sm font-medium">Screenshots</span>
                </div>
                <div className="relative">
                  <div className="absolute -top-4 left-0 right-0 h-8 bg-gradient-to-b from-white to-transparent z-10" />
                  <div
                    className="space-y-4 pb-4"
                    style={{
                      transform: `translateY(${
                        previewShift * TOTAL_ITEM_HEIGHT
                      }px)`,
                      transition: 'transform 300ms ease-in-out',
                    }}
                  >
                    {steps.map((_step, index) => {
                      const screenshot = getScreenshot(index);
                      return (
                        <div
                          key={`screenshot-${index}`}
                          className="h-[160px] p-4 border rounded-lg bg-white shadow-sm"
                        >
                          <div
                            className="w-full h-[128px] bg-gray-50 rounded-md overflow-hidden cursor-pointer hover:ring-2 hover:ring-blue-200 transition-all"
                            onClick={() =>
                              screenshot?.downloadUrl &&
                              setPreviewImage(screenshot.downloadUrl)
                            }
                          >
                            {screenshot?.downloadUrl ? (
                              <img
                                src={screenshot.downloadUrl}
                                alt={`Screenshot ${index + 1}`}
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <div className="w-full h-full flex items-center justify-center text-gray-400">
                                No screenshot
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="absolute -bottom-4 left-0 right-0 h-8 bg-gradient-to-t from-white to-transparent z-10" />
                </div>
              </div>

              {/* Controls Column */}
              <div className="w-32 flex-shrink-0">
                <div className="border rounded-md px-4 py-2 mb-4 bg-gray-50">
                  <span className="text-sm font-medium">Controls</span>
                </div>
                <div className="flex flex-col items-center gap-4">
                  <Button
                    variant="outline"
                    size="lg"
                    onClick={() => handleShiftClick('up')}
                    disabled={
                      previewShift <= -MAX_SHIFT || shiftScreenshots.isPending
                    }
                    className="w-full h-16 flex flex-col items-center justify-center gap-2 border-[1px]"
                  >
                    <ArrowUp className="h-5 w-5" />
                    <span className="text-xs font-medium">Move Up</span>
                  </Button>

                  <Button
                    variant="outline"
                    size="lg"
                    onClick={() => handleShiftClick('down')}
                    disabled={
                      previewShift >= MAX_SHIFT || shiftScreenshots.isPending
                    }
                    className="w-full h-16 flex flex-col items-center justify-center gap-2 border-[1px]"
                  >
                    <ArrowDown className="h-5 w-5" />
                    <span className="text-xs font-medium">Move Down</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {/* Fixed Footer */}
          <DialogFooter className="flex-shrink-0 mt-6 gap-3">
            <Button
              variant="outline"
              onClick={() => onOpenChange(false)}
              className="gap-2"
            >
              <X className="h-4 w-4" />
              Cancel
            </Button>
            <Button
              onClick={handleApplyShift}
              disabled={previewShift === 0 || shiftScreenshots.isPending}
              className="gap-2 min-w-[120px]"
            >
              <Check className="h-4 w-4" />
              {previewShift === 0
                ? 'No Changes'
                : `Move ${Math.abs(previewShift)} Step${
                    Math.abs(previewShift) > 1 ? 's' : ''
                  } ${previewShift > 0 ? 'Down' : 'Up'}`}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Screenshot Preview Modal */}
      {previewImage && (
        <Dialog
          open={!!previewImage}
          onOpenChange={() => setPreviewImage(null)}
        >
          <DialogContent className="sm:max-w-[85vw] w-fit max-h-[85vh]">
            <DialogHeader>
              <DialogTitle className="text-xl">Screenshot Preview</DialogTitle>
            </DialogHeader>
            <div className="relative w-full h-full flex items-center justify-center bg-gray-50 rounded-lg p-4">
              <img
                src={previewImage}
                alt="Screenshot preview"
                className="max-w-full max-h-[65vh] object-contain shadow-lg rounded"
              />
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={() => setPreviewImage(null)}>
                Close Preview
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default SyncSteps;
