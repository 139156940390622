import { AlertTriangle } from 'lucide-react';
import { useNavigate } from '@tanstack/react-router';
import { Button } from '@fluency/ui/components/ui/button';

interface ErrorStateProps {
  message?: string;
  retry?: () => void;
}

export const ErrorState: React.FC<ErrorStateProps> = ({
  message = 'Failed to fetch resources',
  retry,
}) => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-col items-center justify-center py-16 px-4">
      <div className="w-full max-w-md text-center">
        <div className="mb-6 flex justify-center">
          <div className="h-12 w-12 rounded-full bg-red-50 flex items-center justify-center">
            <AlertTriangle className="h-6 w-6 text-red-500" />
          </div>
        </div>

        <h2 className="text-lg font-semibold mb-2">Failed to fetch</h2>

        <p className="text-muted-foreground mb-6">{message}</p>

        <div className="flex justify-center gap-3">
          {retry && (
            <Button variant="default" onClick={retry} className="min-w-[100px]">
              Try Again
            </Button>
          )}
          <Button
            variant="outline"
            onClick={() => navigate({ to: '/' })}
            className="min-w-[100px]"
          >
            Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorState;
