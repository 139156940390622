import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@fluency/ui/components/ui/dialog';
import useDeleteDocs from '@fluency/ui/hooks/documentation/useDeleteDocs';
import { ReloadIcon } from '@radix-ui/react-icons';
import { useNavigate } from '@tanstack/react-router';

interface DeleteDocModalProps {
  baseDocumentId: string;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

export default function DeleteDocModal({
  baseDocumentId,
  isOpen,
  onOpenChange,
}: DeleteDocModalProps) {
  const navigate = useNavigate();
  const { mutateAsync: deleteDoc, isPending: isDeletingDoc } = useDeleteDocs();

  const handleDelete = async () => {
    await deleteDoc([baseDocumentId]);
    onOpenChange(false);
    navigate({ to: '/documents' });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Delete document?</DialogTitle>
          <DialogDescription>
            This action cannot be undone. This will permanently delete this
            process. Are you sure?
          </DialogDescription>
        </DialogHeader>

        <DialogFooter className="sm:justify-end">
          <DialogClose asChild>
            <Button disabled={isDeletingDoc} type="button" variant="secondary">
              Close
            </Button>
          </DialogClose>
          <Button
            disabled={isDeletingDoc}
            type="button"
            variant="destructive"
            onClick={handleDelete}
            className="align-middle"
          >
            {isDeletingDoc ? (
              <>
                <ReloadIcon className="h-4 w-4 mr-2 animate-spin" />
                Deleting...
              </>
            ) : (
              'Yes, delete'
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
