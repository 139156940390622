import React from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import { Clock, AlertCircle } from 'lucide-react';
import moment from 'moment';
import { ResourceRequestAssignment, ResourceRequest } from '../types/types';

interface RequestBannerProps {
  assignment: ResourceRequestAssignment | undefined;
  request: ResourceRequest | undefined;
  onManageClick: () => void;
}

const RequestBanner: React.FC<RequestBannerProps> = ({
  assignment,
  request,
  onManageClick,
}) => {
  if (!assignment || !request || assignment.status === 'COMPLETED') return null;

  const isOverdue = assignment.status === 'OVERDUE';
  const dueDate = new Date(assignment.dueDate);
  const requesterName = request.requester
    ? `${request.requester.firstName} ${request.requester.lastName}`
    : 'Someone';

  return (
    <div
      className={`w-full p-4 ${
        isOverdue
          ? 'bg-red-50 border-red-200'
          : 'bg-fluency-50 border-fluency-200'
      } border rounded-lg mb-4`}
    >
      <div className="flex items-start justify-between gap-4">
        <div className="flex-1">
          <div className="flex gap-2 items-center mb-2">
            {isOverdue ? (
              <AlertCircle className="w-4 h-4 text-red-600 flex-shrink-0" />
            ) : (
              <Clock className="w-4 h-4 text-fluency-600 flex-shrink-0" />
            )}
            <p
              className={`text-sm ${
                isOverdue ? 'text-red-600' : 'text-fluency-600'
              }`}
            >
              <span className="font-semibold">{requesterName}</span> has
              requested you to complete this document by{' '}
              <span className="font-medium">
                {moment(dueDate).format('D/MM/YYYY, h:mm a')}
              </span>
              {isOverdue && ' (Overdue)'}
            </p>
          </div>

          {request.message && (
            <div className="bg-white rounded border-l-2 border-fluency-200 px-3 py-2">
              <p
                className={`text-sm italic ${
                  isOverdue ? 'text-red-600' : 'text-gray-600'
                }`}
              >
                {request.message}
              </p>
            </div>
          )}
        </div>

        <Button
          onClick={onManageClick}
          variant={isOverdue ? 'destructive' : 'default'}
          size="sm"
          className="flex-shrink-0"
        >
          Complete
        </Button>
      </div>
    </div>
  );
};

export default RequestBanner;
