import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { AlertCircle, AlertTriangle, Check, Loader2 } from 'lucide-react';
import { forwardRef, useState } from 'react';
import { ExtensionStatusDialog } from './ExtensionStatusDialog';
import { useExtensionStatus } from './hooks/useExtensionStatus';
import { useSyncStatus } from './hooks/useSyncStatus';
import { StatusInfo } from './types';
import { SidebarMenuButton } from '@fluency/ui/components/ui/sidebar';

const ExtensionStatusButton = forwardRef<HTMLDivElement, Record<string, never>>(
  (_props, ref) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { isAuthenticated } = useAuth();
    const { extensionStatus, checkExtensionStatus } = useExtensionStatus();
    const { syncStatus, sendAuthData } = useSyncStatus(
      extensionStatus,
      checkExtensionStatus
    );

    const handleButtonClick = () => {
      setIsDialogOpen(true);
    };

    const handleDownload = () => {
      window.open(
        'https://chromewebstore.google.com/detail/fluency/nhadgomabaphpfplmbfemfnagnhhghei',
        '_blank'
      );
    };

    const getStatusInfo = (): StatusInfo => {
      switch (extensionStatus) {
        case 'checking':
          return {
            text: 'Checking Extension...',
            icon: <Loader2 className="h-4 w-4 animate-spin" />,
            customClass: '',
          };
        case 'not_installed':
          return {
            text: 'Extension not found',
            icon: <AlertTriangle className="h-4 w-4" />,
            customClass: '',
          };
        case 'installed_not_connected':
          return {
            text: 'Sync Extension',
            icon: <AlertCircle className="h-4 w-4" />,
            customClass: '',
          };
        case 'connected':
          return {
            text: 'Extension Connected',
            icon: <Check className="h-4 w-4" />,
            customClass: '',
          };
      }
    };

    const { text, icon } = getStatusInfo();

    if (!isAuthenticated) {
      return null;
    }

    return (
      <div ref={ref}>
        <SidebarMenuButton asChild>
          <button
            onClick={handleButtonClick}
            className="relative flex items-center gap-3 text-xs rounded-lg py-2 transition-all text-muted-foreground hover:text-primary w-full"
          >
            {icon}
            <span>{text}</span>
            {extensionStatus === 'not_installed' && (
              <div className="absolute -top-1.5 -right-1.5">
                <div className="bg-red-500 w-2 h-2 rounded-full" />
              </div>
            )}
          </button>
        </SidebarMenuButton>

        <ExtensionStatusDialog
          isOpen={isDialogOpen}
          onOpenChange={setIsDialogOpen}
          extensionStatus={extensionStatus}
          syncStatus={syncStatus}
          handleDownload={handleDownload}
          sendAuthData={sendAuthData}
        />
      </div>
    );
  }
);

ExtensionStatusButton.displayName = 'ExtensionStatusButton';

export default ExtensionStatusButton;
