import { Logger } from '@fluency/ui/features/Logger';

export const createHeaders = (accessToken?: string): HeadersInit => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  };

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  return headers;
};

export const handleApiError = (
  error: unknown,
  defaultMessage: string
): never => {
  if (error instanceof Error) {
    // Handle specific error types
    if ('status' in error) {
      const statusCode = (error as { status: number }).status;
      switch (statusCode) {
        case 401:
          throw new Error('Unauthorized access');
        case 403:
          throw new Error('Forbidden access');
        case 404:
          throw new Error('Resource not found');
        default:
          throw new Error(`API Error: ${error.message}`);
      }
    }
    throw error;
  }

  // Handle unknown errors
  Logger.error('API Error:', error);
  throw new Error(defaultMessage);
};
