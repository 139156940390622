import React from 'react';
import EditableInput from './EditableInput';
import { useState, useEffect } from 'react';
import RequestBanner from './requests/components/RequestBanner';
import RequestDialog from './requests/components/RequestDialog';
import useUpdateDoc from '@fluency/ui/hooks/documentation/useUpdateDoc';
import { DocumentationResponse } from '@fluency/ui/hooks/documentation/types/master';
import { useFetchResourceRequests } from './requests/hooks/useFetchResourceRequests';

interface DocumentTitleProps {
  data: DocumentationResponse | undefined;
  id: string;
  isMostRecentVersion: boolean;
  isEditMode: boolean;
}

const DocumentTitle: React.FC<DocumentTitleProps> = ({
  data,
  id,
  isMostRecentVersion,
  isEditMode,
}) => {
  const { mutateAsync: updateDocTitle } = useUpdateDoc({
    id,
    type: 'title',
  });
  const { mutateAsync: updateDocDescription } = useUpdateDoc({
    id,
    type: 'description',
  });

  const [isEditTitle, setIsEditTitle] = useState(false);
  const [currentTitleValue, setCurrentTitleValue] = useState(
    data?.documentation.documentationName ?? ''
  );
  const [isEditDescription, setIsEditDescription] = useState(false);
  const [currentDescriptionValue, setCurrentDescriptionValue] = useState(
    data?.documentation.description ?? ''
  );
  const [isRequestDialogOpen, setIsRequestDialogOpen] = useState(false);

  useEffect(() => {
    if (!isEditMode) {
      setIsEditTitle(false);
      setIsEditDescription(false);
    }
  }, [isEditMode]);

  useEffect(() => {
    setCurrentTitleValue(data?.documentation.documentationName ?? '');
    setCurrentDescriptionValue(data?.documentation.description ?? '');
  }, [data]);

  const { userAssignment, userRequest } = useFetchResourceRequests(id);

  return (
    <div className="flex flex-col">
      {userAssignment && userAssignment.status !== 'COMPLETED' && (
        <RequestBanner
          assignment={userAssignment}
          request={userRequest}
          onManageClick={() => setIsRequestDialogOpen(true)}
        />
      )}
      <div className="flex justify-between">
        <EditableInput
          isEdit={isEditTitle && isEditMode}
          currentValue={currentTitleValue}
          updateDoc={updateDocTitle}
          setCurrentValue={setCurrentTitleValue}
          setIsEdit={setIsEditTitle}
          isTitle={true}
          isMostRecentVersion={isMostRecentVersion && isEditMode}
        />
      </div>

      <EditableInput
        isEdit={isEditDescription && isEditMode}
        currentValue={currentDescriptionValue}
        updateDoc={updateDocDescription}
        setCurrentValue={setCurrentDescriptionValue}
        setIsEdit={setIsEditDescription}
        isTitle={false}
        isMostRecentVersion={isMostRecentVersion && isEditMode}
      />

      <RequestDialog
        isOpen={isRequestDialogOpen}
        onOpenChange={setIsRequestDialogOpen}
        documentId={id}
        isManager={false}
      />
    </div>
  );
};

export default DocumentTitle;
