import { useEffect } from 'react';
import { TRANSITION_DURATION } from '../constant';

const useCropTransition = () => {
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
      }
      
      .screenshot-transition {
        transition: all ${TRANSITION_DURATION}ms ease-in-out;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);
};

export default useCropTransition;
