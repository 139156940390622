import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@fluency/ui/components/ui/form';
import { Input } from '@fluency/ui/components/ui/input';
import { Textarea } from '@fluency/ui/components/ui/textarea';
import { useCreateTeam } from '../hooks/useCreateTeam';
import { Plus, Loader2 } from 'lucide-react';

const teamSchema = z.object({
  teamName: z
    .string()
    .min(1, 'Team name is required')
    .max(30, 'Team name must be 30 characters or less'),
  description: z
    .string()
    .min(1, 'Team description is required')
    .max(240, 'Description must be 240 characters or less'),
});

type TeamFormData = z.infer<typeof teamSchema>;

interface CreateTeamDialogProps {
  onTeamCreated: (teamId: string) => void;
}

const CreateTeamDialog: React.FC<CreateTeamDialogProps> = ({
  onTeamCreated,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { mutate, isPending } = useCreateTeam();

  const form = useForm<TeamFormData>({
    resolver: zodResolver(teamSchema),
    defaultValues: {
      teamName: '',
      description: '',
    },
  });

  const onSubmit = (data: TeamFormData) => {
    mutate(data, {
      onSuccess: (response) => {
        setIsOpen(false);
        form.reset();
        onTeamCreated(response.createdTeam.teamId);
      },
    });
  };

  const handleCloseModal = () => {
    if (!isPending) {
      setIsOpen(false);
      form.reset();
    }
  };

  const handleOpenChange = (open: boolean) => {
    if (!isPending) {
      if (!open) {
        handleCloseModal();
      } else {
        setIsOpen(open);
      }
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button className="rounded-md h-9">
          <Plus className="w-5 mr-2" />
          Create Team
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create Team</DialogTitle>
          <DialogDescription>
            Enter team name and description to create a new team.
          </DialogDescription>
        </DialogHeader>
        <FormProvider {...form}>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="teamName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Team Name</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="Team Name"
                        disabled={isPending}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea
                        {...field}
                        placeholder="Brief description of the team"
                        maxLength={240}
                        disabled={isPending}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="w-full flex justify-end">
                <Button type="submit" disabled={isPending} size="sm">
                  {isPending && (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  Create Team
                </Button>
              </div>
            </form>
          </Form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default CreateTeamDialog;
