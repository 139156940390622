import { Skeleton } from '@fluency/ui/components/ui/skeleton';

export const LoadingSkeleton: React.FC = () => (
  <div className="space-y-2">
    {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
      <div key={i} className="border rounded-lg bg-background px-4 py-3">
        <div className="flex items-start gap-3">
          <Skeleton className="w-5 h-5 mt-1 rounded" />
          <div className="flex-1 min-w-0">
            <div className="flex items-start justify-between">
              <div className="flex-1 min-w-0 space-y-1.5">
                <Skeleton className="h-4 w-[60%]" />
                <Skeleton className="h-4 w-[75%]" />
                <div className="flex items-center gap-2">
                  <Skeleton className="h-3.5 w-28" />
                  <div className="flex gap-1.5">
                    <Skeleton className="h-3.5 w-16" />
                    <Skeleton className="h-3.5 w-14" />
                  </div>
                </div>
              </div>
              <Skeleton className="h-8 w-8 rounded" />
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default LoadingSkeleton;
