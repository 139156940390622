// DisplayName.tsx
import React, { useState } from 'react';
import { Label } from '@fluency/ui/components/ui/label';
import { Input } from '@fluency/ui/components/ui/input';
import { Button } from '@fluency/ui/components/ui/button';
import { useUpdateUserProfile } from '@fluency/ui/features/Settings/hooks';
import { PenSquare } from 'lucide-react';
import { WorkOSUser } from '@fluency/ui/providers/auth/types/WorkOSUser';

interface DisplayNameProps {
  user: WorkOSUser | null;
}

export function DisplayName({ user }: DisplayNameProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');

  const { mutate: updateProfile, isPending } = useUpdateUserProfile();

  const displayName =
    user?.firstName && user?.lastName
      ? `${user.firstName} ${user.lastName}`
      : user?.email;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateProfile(
      { firstName, lastName },
      {
        onSuccess: () => setIsEditing(false),
      }
    );
  };

  if (isEditing) {
    return (
      <div className="grid gap-4">
        <Label>Display Name</Label>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid gap-4 sm:grid-cols-2">
            <div className="grid gap-1.5">
              <Label
                htmlFor="firstName"
                className="text-sm text-muted-foreground"
              >
                First Name
              </Label>
              <Input
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
              />
            </div>
            <div className="grid gap-1.5">
              <Label
                htmlFor="lastName"
                className="text-sm text-muted-foreground"
              >
                Last Name
              </Label>
              <Input
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
              />
            </div>
          </div>
          <div className="flex gap-2">
            <Button type="submit" disabled={isPending}>
              {isPending ? 'Saving...' : 'Save changes'}
            </Button>
            <Button
              type="button"
              variant="outline"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className="grid gap-1">
      <Label>Display Name</Label>
      <div className="flex items-center justify-between">
        <p className="text-sm text-muted-foreground">{displayName}</p>
        <Button
          variant="ghost"
          size="sm"
          className="h-8 w-8 p-0"
          onClick={() => setIsEditing(true)}
        >
          <PenSquare className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
}
