import { useInfiniteQuery } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import {
  VaultResourcesResponse,
  PageResponse,
  InfiniteQueryResponse,
} from './types/types';
import { ResourceType } from '@fluency/ui/hooks/fga/types/fga';

interface UseVaultResourcesParams {
  vaultId: string;
  pageSize?: number;
  resourceType?: ResourceType;
  enabled?: boolean;
}

export async function fetchVaultResources(
  vaultId: string,
  token: string,
  params: {
    page: number;
    pageSize: number;
    resourceType?: ResourceType;
  }
): Promise<VaultResourcesResponse> {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const queryParams = new URLSearchParams({
    page: params.page.toString(),
    pageSize: params.pageSize.toString(),
    ...(params.resourceType && { resourceType: params.resourceType }),
  });

  const endpoint = `${apiBaseUrl}/vaults/resources/${vaultId}?${queryParams}`;

  const response = await fetch(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(
      errorData.message || `HTTP error! status: ${response.status}`
    );
  }

  return response.json();
}

export function useVaultResources({
  vaultId,
  pageSize = 25,
  resourceType,
  enabled = true,
}: UseVaultResourcesParams) {
  const { accessToken } = useAuth();

  return useInfiniteQuery<
    PageResponse,
    Error,
    InfiniteQueryResponse,
    [string, string, ResourceType | undefined],
    number
  >({
    queryKey: ['vaultResources', vaultId, resourceType],
    queryFn: async ({ pageParam }) => {
      const response = await fetchVaultResources(vaultId, accessToken ?? '', {
        page: pageParam,
        pageSize,
        resourceType,
      });

      const { items } = response.data;

      return {
        items,
        nextPage: pageParam + 1,
        hasMore: pageParam < response.data.pagination.totalPages,
      };
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage: PageResponse) =>
      lastPage.hasMore ? lastPage.nextPage : undefined,
    enabled: enabled && !!accessToken && !!vaultId,
  });
}
