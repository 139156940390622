import { Logger } from '@fluency/ui/features/Logger';

const url = import.meta.env.VITE_SERVER_API_URL;

export const getUserProfile = async (accessToken: string): Promise<any> => {
  try {
    const response = await fetch(`${url}/user/getUserProfile`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    Logger.error('Error fetching user profile:', error);
    throw error;
  }
};
