import React, { useState } from 'react';
import { NodeProps } from 'reactflow';
import { Card } from '@fluency/ui/components/ui/card';
import { Badge } from '@fluency/ui/components/ui/badge';
import { FileText, X } from 'lucide-react';
import { createNodeHandles } from '../../utils/createNodeHandles';

interface DocumentData {
  label: string;
  id: string;
  documentId: string | null;
  documentName: string;
  vaultId: string | null;
  vaultName: string | null;
  totalSteps: number;
  createdBy: string | null;
  onDelete?: (id: string) => void;
  onView?: () => void;
  isViewMode?: boolean;
  isActive?: boolean;
  isNewDocument?: boolean;
}

const DocumentNode: React.FC<NodeProps<DocumentData>> = ({ data, id }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    data.onDelete?.(id);
  };

  const handleClick = () => {
    if (data.isViewMode) {
      data.onView?.();
    }
  };

  return (
    <Card
      className={`relative w-fit min-w-40 px-3 py-2 rounded-md border-2 flex gap-4 items-center
        ${data.isViewMode ? 'cursor-pointer hover:bg-gray-50' : ''}
        ${data.isActive ? 'border-fluency-500' : 'border-black'}
        ${data.isNewDocument ? 'border-dashed' : ''}
      `}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
    >
      {isHovered && !data.isViewMode && (
        <button
          onClick={handleDelete}
          className="absolute -top-3 -right-3 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors"
        >
          <X size={16} />
        </button>
      )}

      <div className="flex flex-col space-y-2 w-full">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-2">
            <FileText className="w-5 h-5" />
            <h3 className="font-semibold mr-4">{data.documentName}</h3>
          </div>
        </div>
        <div className="flex w-full justify-between items-center gap-4">
          {data.totalSteps > 0 && (
            <Badge variant="secondary">{data.totalSteps} steps</Badge>
          )}
          {data.vaultName && (
            <Badge variant="outline" className="text-xs">
              {data.vaultName}
            </Badge>
          )}
        </div>
      </div>

      {/* Handles */}
      {createNodeHandles({
        id,
        isViewMode: data.isViewMode,
        isHovered,
      })}
    </Card>
  );
};

export default DocumentNode;
