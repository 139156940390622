import { Button } from '@fluency/ui/components/ui/button';
import { MinusIcon, PlusIcon } from 'lucide-react';

interface SeatsInputProps {
  seats: number;
  setSeats: React.Dispatch<React.SetStateAction<number>>;
  pricePerSeat: number;
  isAnnual: boolean;
}

export const SeatsInput: React.FC<SeatsInputProps> = ({
  seats,
  setSeats,
  pricePerSeat,
  isAnnual,
}) => {
  const totalCost = seats * pricePerSeat;

  return (
    <div
      className="text-center text-sm font-semibold leading-6 w-full"
      data-hs-input-number
    >
      <div className="w-full flex justify-between items-center gap-x-3">
        <div>
          <span className="block font-medium text-sm text-left">
            Number of seats
          </span>
          <span className="block text-xs text-left">
            ${totalCost.toFixed(0)}{' '}
            {isAnnual ? 'billed annually' : 'billed monthly'}
          </span>
        </div>
        <div className="flex items-center gap-x-1.5">
          <Button
            size="sm"
            variant="secondary"
            onClick={() => setSeats(Math.max(1, seats - 1))}
            disabled={seats <= 1}
          >
            <MinusIcon className="h-5 w-5" />
          </Button>
          <input
            className="p-0 w-8 bg-transparent border-0 text-center focus:ring-0"
            value={seats}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (!isNaN(value)) {
                setSeats(Math.max(1, Math.min(100, value)));
              }
            }}
            min="1"
            max="100"
          />
          <Button
            size="sm"
            variant="secondary"
            onClick={() => setSeats(Math.min(100, seats + 1))}
          >
            <PlusIcon className="h-5 w-5" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SeatsInput;
