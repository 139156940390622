import { useToast } from '@fluency/ui/components/ui/use-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  DocumentationResponse,
  ScreenshotType,
  Step,
} from '@fluency/ui/hooks/documentation/types/master';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { Logger } from '@fluency/ui/features/Logger';

interface AnnotationRequest {
  screenshotAnnotationId: string;
  screenshotId: string;
  shapeType: 'RECTANGLE' | 'CIRCLE' | 'ARROW' | 'LINE';
  x1Coordinate: number;
  y1Coordinate: number;
  x2Coordinate?: number;
  y2Coordinate?: number;
  color: string;
}

interface AnnotationResponse {
  message: string;
  success: boolean;
  annotations: AnnotationRequest[];
}

interface UseScreenshotAnnotationV2Props {
  documentationId: string;
}

async function updateAnnotations(
  request: { annotations: AnnotationRequest[] },
  accessToken: string
): Promise<AnnotationResponse> {
  const endpoint = '/screenshot/v2/annotate';
  const apiUrl = import.meta.env.VITE_SERVER_API_URL;

  const response = await fetch(`${apiUrl}${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to update annotations');
  }

  return response.json();
}

export const useScreenshotAnnotationV2 = ({
  documentationId,
}: UseScreenshotAnnotationV2Props) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  const updateAnnotationMutation = useMutation<
    AnnotationResponse,
    Error,
    { annotations: AnnotationRequest[] },
    { previousDocInfo: DocumentationResponse | undefined }
  >({
    mutationFn: (request) => {
      if (!accessToken) throw new Error('Access token is missing');
      return updateAnnotations(request, accessToken);
    },
    onMutate: async ({ annotations }) => {
      await queryClient.cancelQueries({
        queryKey: ['docInfo', documentationId],
      });

      const previousDocInfo = queryClient.getQueryData<DocumentationResponse>([
        'docInfo',
        documentationId,
      ]);

      if (previousDocInfo) {
        queryClient.setQueryData<DocumentationResponse>(
          ['docInfo', documentationId],
          (old) => {
            if (!old) return old;

            const updatedSteps = old.documentation.steps.map((step: Step) => {
              const updatedScreenshots = step.screenshots.map(
                (screenshot: ScreenshotType) => {
                  const screenshotAnnotations = annotations.filter(
                    (a) => a.screenshotId === screenshot.screenshotId
                  );

                  if (screenshotAnnotations.length > 0) {
                    return {
                      ...screenshot,
                      annotations: screenshotAnnotations.map((a, index) => ({
                        screenshotAnnotationId: a.screenshotAnnotationId,
                        screenshotId: a.screenshotId,
                        shapeType: a.shapeType,
                        x1Coordinate: a.x1Coordinate,
                        y1Coordinate: a.y1Coordinate,
                        x2Coordinate: a.x2Coordinate ?? 0,
                        y2Coordinate: a.y2Coordinate ?? 0,
                        color: a.color,
                        annotationIndex: index,
                        createdAt:
                          screenshot.annotations[index]?.createdAt ||
                          new Date().toISOString(),
                        updatedAt: new Date().toISOString(),
                      })),
                    };
                  }
                  return screenshot;
                }
              );

              return {
                ...step,
                screenshots: updatedScreenshots,
              };
            });

            return {
              ...old,
              documentation: {
                ...old.documentation,
                steps: updatedSteps,
              },
            };
          }
        );
      }

      return { previousDocInfo };
    },
    onError: (err, _newAnnotations, context) => {
      if (context?.previousDocInfo) {
        queryClient.setQueryData(
          ['docInfo', documentationId],
          context.previousDocInfo
        );
      }
      Logger.error('Error updating annotations:', err);
      toast({
        title: 'Error',
        description: 'Failed to update annotations. Please try again.',
        variant: 'destructive',
      });
    },
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Annotations updated successfully.',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['docInfo', documentationId] });
    },
  });

  return {
    updateAnnotations: updateAnnotationMutation.mutate,
    isPending: updateAnnotationMutation.isPending,
    isError: updateAnnotationMutation.isError,
    error: updateAnnotationMutation.error,
  };
};

export default useScreenshotAnnotationV2;
