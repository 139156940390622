import { Search } from 'lucide-react';
import { Input } from '@fluency/ui/components/ui/input';

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChange,
}) => (
  <div className="relative w-full sm:w-[300px]">
    <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
    <Input
      placeholder="Search resources..."
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="pl-9"
    />
  </div>
);

export default SearchInput;
