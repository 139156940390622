import { useGetDocumentData } from '../hooks';
import InsertStep from './InsertStep';
import { useStepManagement } from '../hooks/useStepManagement';
import { StepContent } from './StepContent';
import { getStepDetails } from '../util/getStepDetails';
import type { Step } from '../../../hooks/documentation/types/master';
import { Fragment, useEffect } from 'react';

interface StepRecordProps {
  steps: Step[];
  isEditMode: boolean;
  stepRefs: React.MutableRefObject<{ [key: string]: HTMLElement }>;
  docId: string;
}

function StepRecord({ steps, isEditMode, stepRefs, docId }: StepRecordProps) {
  const { data } = useGetDocumentData(docId);
  const isLatestVersion = data?.documentation.versionInfo.isLatestVersion;

  const {
    editStepDescription,
    editingId,
    filteredSteps,
    handleEditStepDescription,
    handleUpdateStepDescription,
    handleSaveStepDescription,
    handleDeleteStep,
  } = useStepManagement(docId, steps);

  // Set up intersection observer
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '-20% 0px -60% 0px', // Consider element in view when it's in the middle
      threshold: 0,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const stepId = entry.target.getAttribute('data-step-id');
          if (stepId) {
            window.dispatchEvent(
              new CustomEvent('stepInView', {
                detail: { stepId },
              })
            );
          }
        }
      });
    }, options);

    // Observe all step elements
    Object.entries(stepRefs.current).forEach(([stepId, element]) => {
      if (element) {
        element.setAttribute('data-step-id', stepId);
        observer.observe(element);
      }
    });

    return () => observer.disconnect();
  }, [stepRefs, steps]);

  let stepNumberCounter = 0;

  return (
    <div className="pb-12 w-full">
      <table className="w-full whitespace-nowrap text-left text-sm leading-6">
        <tbody>
          {isLatestVersion && isEditMode && (
            <tr className="relative">
              <td colSpan={100} className="text-center">
                <InsertStep docId={docId} stepBeforeId={null} />
              </td>
            </tr>
          )}
          {filteredSteps.map((step) => {
            if (step.stepType === 'STEP') {
              stepNumberCounter++;
            }
            const enhancedStep = getStepDetails({
              step,
              stepNumberCounter,
            });

            return (
              <Fragment key={step.stepId}>
                <StepContent
                  ref={(el) => {
                    if (el) stepRefs.current[step.stepId] = el;
                  }}
                  step={enhancedStep}
                  isLatestVersion={isLatestVersion || false}
                  docId={docId}
                  editingId={editingId}
                  editStepDescription={editStepDescription}
                  handleSaveStepDescription={handleSaveStepDescription}
                  handleEditStepDescription={handleEditStepDescription}
                  handleUpdateStepDescription={handleUpdateStepDescription}
                  handleDeleteStep={handleDeleteStep}
                  filteredSteps={filteredSteps}
                  isEditMode={isEditMode}
                />
                {isLatestVersion && isEditMode && (
                  <tr className="relative">
                    <td colSpan={100} className="text-center">
                      <InsertStep docId={docId} stepBeforeId={step.stepId} />
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default StepRecord;
