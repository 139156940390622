import { Check } from 'lucide-react';

interface CheckItemProps {
  text: string;
}

export const CheckItem: React.FC<CheckItemProps> = ({ text }) => (
  <div className="flex gap-2">
    <Check size={18} className="my-auto text-fluency-400" />
    <p className="pt-0.5 text-sm font-medium">{text}</p>
  </div>
);

export default CheckItem;
