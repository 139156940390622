import { Logger } from '@fluency/ui/features/Logger';

const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

export const logout = async (sessionId: string | null): Promise<string> => {
  try {
    if (!sessionId) {
      Logger.warn('No sessionId provided for logout');
    }

    const response = await fetch(
      `${apiBaseUrl}/auth/logout?sessionId=${sessionId || ''}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const { logoutUrl } = await response.json();

    if (!logoutUrl) {
      throw new Error('Logout URL not received from server');
    }

    Logger.log('LOGOUT URL ', logoutUrl);
    return logoutUrl;
  } catch (error) {
    Logger.error('Error during logout:', error);
    throw error; // Re-throw the error so it can be handled by the caller
  }
};
