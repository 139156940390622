import { Logger } from '@fluency/ui/features/Logger';

export const loginUtil = async (
  redirectImmediately: boolean = true
): Promise<string | void> => {
  try {
    const url = import.meta.env.VITE_SERVER_API_URL;
    let state: string;

    // Check if we're in a preview environment
    if (window.location.hostname.includes('-pr-')) {
      state = 'web-preview';
    } else if (window.electron) {
      state = 'electron';
    } else if (window.location.hostname === 'localhost') {
      state = 'web-local';
    } else {
      state = 'web';
    }

    let redirectUri = `https://api.usefluency.com/auth/authenticateWithCode`;
    if (state === 'web-local' || state === 'electron') {
      redirectUri = `${url}/auth/authenticateWithCode`;
    }

    const response = await fetch(
      `${url}/auth/getAuthUrl?redirectUrl=${encodeURIComponent(
        redirectUri
      )}&state=${state}&origin=${encodeURIComponent(window.location.origin)}`
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const authUrl = data.authUrl;

    if (redirectImmediately) {
      if (window.electron) {
        window.open(authUrl, '_blank', 'noopener,noreferrer');
      } else {
        window.location.href = authUrl;
      }
    } else {
      return authUrl;
    }
  } catch (error) {
    Logger.error('Login error:', error);
    throw error;
  }
};
