import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DocumentationResponse } from '../../../hooks/documentation/types/master';
import { Logger } from '@fluency/ui/features/Logger';

interface ToggleSharingResponse {
  message: string;
  shareId: string;
  isPublic: boolean;
}

interface ToggleLockingResponse {
  message: string;
  isLocked: boolean;
}

interface ToggleGlobalResponse {
  message: string;
  isGlobal: boolean;
}

interface ToggleParams {
  documentationId: string;
  value: boolean;
}

// Base toggle function creator with generic type for different responses
const createToggleFn = <T,>(endpoint: string) => {
  return async (
    { documentationId, value }: ToggleParams,
    accessToken: string
  ): Promise<T> => {
    const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
    const url = `${apiBaseUrl}/documents/${endpoint}`;

    // Create the appropriate request body based on the endpoint
    const body = {
      documentationId,
      [endpoint === 'toggleSharing'
        ? 'isPublic'
        : endpoint === 'toggleLocking'
        ? 'isLocked'
        : 'isGlobal']: value,
    };

    Logger.log('Sending toggle request:', {
      endpoint,
      body,
    });

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${
          errorData?.message || 'Unknown error'
        }`
      );
    }

    const data = await response.json();
    Logger.log('Toggle response:', data);
    return data;
  };
};

// Hook for toggling sharing status
export const useToggleSharing = () => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: ToggleParams) =>
      createToggleFn<ToggleSharingResponse>('toggleSharing')(
        params,
        accessToken!
      ),
    onSuccess: (response, { documentationId }) => {
      queryClient.setQueryData<DocumentationResponse>(
        ['docInfo', documentationId],
        (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            documentation: {
              ...oldData.documentation,
              isPublic: response.isPublic,
              shareId: response.shareId,
            },
          };
        }
      );
    },
  });
};

// Hook for toggling locking status
export const useToggleLocking = () => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: ToggleParams) =>
      createToggleFn<ToggleLockingResponse>('toggleLocking')(
        params,
        accessToken!
      ),
    onSuccess: (response, { documentationId }) => {
      queryClient.setQueryData<DocumentationResponse>(
        ['docInfo', documentationId],
        (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            documentation: {
              ...oldData.documentation,
              isLocked: response.isLocked,
            },
          };
        }
      );
    },
  });
};

// Hook for toggling global status
export const useToggleGlobal = () => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: ToggleParams) =>
      createToggleFn<ToggleGlobalResponse>('toggleGlobal')(
        params,
        accessToken!
      ),
    onSuccess: (response, { documentationId }) => {
      queryClient.setQueryData<DocumentationResponse>(
        ['docInfo', documentationId],
        (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            documentation: {
              ...oldData.documentation,
              isGlobal: response.isGlobal,
            },
          };
        }
      );
    },
  });
};
