import { DashboardShell } from '@fluency/ui/components';
import useUploadDocs from '@fluency/ui/features/Vaults/hooks/useUploadDocs';
import InProgress from './components/InProgress';
import ViewDocument from './ViewDocument';

interface DocumentViewPageProps {
  id: string;
}

const DocumentViewPage = ({ id }: DocumentViewPageProps) => {
  const { docsInProgress } = useUploadDocs();
  const isDocumentUploading = id in docsInProgress;

  return (
    <DashboardShell>
      {isDocumentUploading ? (
        <InProgress docId={id} />
      ) : (
        <ViewDocument id={id} />
      )}
    </DashboardShell>
  );
};

export default DocumentViewPage;
