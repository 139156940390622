import * as Sentry from '@sentry/react';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { Logger } from '@fluency/ui/features/Logger';

export const createHeaders = (accessToken: string | null): Headers => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (accessToken) {
    headers.append('Authorization', `Bearer ${accessToken}`);
  }
  return headers;
};

export const handleApiError = (error: unknown, errorMessage: string): never => {
  Sentry.captureException(error);
  Logger.error(errorMessage, error);
  throw new Error(
    `${errorMessage}: ${
      error instanceof Error ? error.message : 'Unknown error'
    }`
  );
};

export const showErrorToast = (message: string) => {
  toast({
    variant: 'destructive',
    title: 'Error',
    description: message,
  });
};

export const showSuccessToast = (message: string) => {
  toast({
    variant: 'default',
    title: 'Success',
    description: message,
  });
};
