import React, { useState } from 'react';
import { CircleIcon } from '@radix-ui/react-icons';
import { ScreenshotType } from '@fluency/ui/hooks/documentation/types/master';

interface AnnotationOverlayProps {
  imageRef: React.RefObject<HTMLImageElement>;
  screenshot: ScreenshotType;
  documentationId: string;
  onAnnotationChange: (annotations: ScreenshotType['annotations']) => void;
}

type DragPoint = 'start' | 'end' | 'body' | 'none';

const DEFAULT_SHAPE_SIZE = 20;
const INITIAL_POSITION = 50;

export const AnnotationOverlay: React.FC<AnnotationOverlayProps> = ({
  imageRef,
  screenshot,
  onAnnotationChange,
}) => {
  const [dragPoint, setDragPoint] = useState<DragPoint>('none');
  const [dragStart, setDragStart] = useState<{ x: number; y: number } | null>(
    null
  );
  const [activeAnnotationId, setActiveAnnotationId] = useState<string | null>(
    null
  );
  const [localCoords, setLocalCoords] = useState({
    x1: INITIAL_POSITION,
    y1: INITIAL_POSITION,
    x2: INITIAL_POSITION + DEFAULT_SHAPE_SIZE,
    y2: INITIAL_POSITION + DEFAULT_SHAPE_SIZE,
  });

  if (!screenshot.annotations.length) return null;

  const updateAnnotations = (
    newCoords: typeof localCoords,
    annotationId: string
  ) => {
    onAnnotationChange(
      screenshot.annotations.map((ann) =>
        ann.screenshotAnnotationId === annotationId
          ? {
              ...ann,
              x1Coordinate: Math.round(newCoords.x1),
              y1Coordinate: Math.round(newCoords.y1),
              x2Coordinate: Math.round(newCoords.x2),
              y2Coordinate: Math.round(newCoords.y2),
            }
          : ann
      )
    );
  };

  const getMousePosition = (e: React.MouseEvent) => {
    if (!imageRef.current) return null;
    const rect = imageRef.current.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    return {
      x: Math.max(0, Math.min(100, x)),
      y: Math.max(0, Math.min(100, y)),
    };
  };

  const handleMouseDown =
    (point: DragPoint, annotationId: string) => (e: React.MouseEvent) => {
      e.stopPropagation();
      setDragPoint(point);
      setActiveAnnotationId(annotationId);
      const pos = getMousePosition(e);
      if (pos) {
        setDragStart(pos);
        const annotation = screenshot.annotations.find(
          (ann) => ann.screenshotAnnotationId === annotationId
        );
        if (annotation) {
          setLocalCoords({
            x1: annotation.x1Coordinate,
            y1: annotation.y1Coordinate,
            x2:
              annotation.x2Coordinate ??
              annotation.x1Coordinate + DEFAULT_SHAPE_SIZE,
            y2:
              annotation.y2Coordinate ??
              annotation.y1Coordinate + DEFAULT_SHAPE_SIZE,
          });
        }
      }
    };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (dragPoint === 'none' || !dragStart || !activeAnnotationId) return;

    const pos = getMousePosition(e);
    if (!pos) return;

    const dx = pos.x - dragStart.x;
    const dy = pos.y - dragStart.y;

    let newCoords = { ...localCoords };

    switch (dragPoint) {
      case 'start':
        newCoords = { ...newCoords, x1: pos.x, y1: pos.y };
        break;
      case 'end':
        newCoords = { ...newCoords, x2: pos.x, y2: pos.y };
        break;
      case 'body':
        newCoords = {
          x1: Math.max(0, Math.min(100, localCoords.x1 + dx)),
          y1: Math.max(0, Math.min(100, localCoords.y1 + dy)),
          x2: Math.max(0, Math.min(100, localCoords.x2 + dx)),
          y2: Math.max(0, Math.min(100, localCoords.y2 + dy)),
        };
        setDragStart(pos);
        break;
    }

    setLocalCoords(newCoords);
    updateAnnotations(newCoords, activeAnnotationId);
  };

  const handleMouseUp = () => {
    setDragPoint('none');
    setDragStart(null);
    setActiveAnnotationId(null);
  };

  const renderShape = (annotation: ScreenshotType['annotations'][0]) => {
    const coords =
      activeAnnotationId === annotation.screenshotAnnotationId
        ? localCoords
        : {
            x1: annotation.x1Coordinate,
            y1: annotation.y1Coordinate,
            x2:
              annotation.x2Coordinate ??
              annotation.x1Coordinate + DEFAULT_SHAPE_SIZE,
            y2:
              annotation.y2Coordinate ??
              annotation.y1Coordinate + DEFAULT_SHAPE_SIZE,
          };

    const { x1, y1, x2, y2 } = coords;

    switch (annotation.shapeType) {
      case 'CIRCLE':
        return (
          <CircleIcon
            className="absolute w-7 h-7 cursor-move"
            style={{
              left: `${x1}%`,
              top: `${y1}%`,
              transform: 'translate(-50%, -50%)',
              color: annotation.color,
            }}
            onMouseDown={handleMouseDown(
              'body',
              annotation.screenshotAnnotationId
            )}
          />
        );

      case 'LINE':
      case 'ARROW': {
        const id = `arrowhead-${annotation.screenshotAnnotationId}`;

        return (
          <svg
            width="100%"
            height="100%"
            className="absolute inset-0"
            style={{ overflow: 'visible', pointerEvents: 'none' }}
          >
            {annotation.shapeType === 'ARROW' && (
              <defs>
                <marker
                  id={id}
                  markerWidth="10"
                  markerHeight="7"
                  refX="9"
                  refY="3.5"
                  orient="auto"
                >
                  <polygon points="0 0, 10 3.5, 0 7" fill={annotation.color} />
                </marker>
              </defs>
            )}
            <line
              x1={`${x1}%`}
              y1={`${y1}%`}
              x2={`${x2}%`}
              y2={`${y2}%`}
              stroke={annotation.color}
              strokeWidth="2"
              markerEnd={
                annotation.shapeType === 'ARROW' ? `url(#${id})` : undefined
              }
              vectorEffect="non-scaling-stroke"
            />
          </svg>
        );
      }

      case 'RECTANGLE': {
        const width = Math.abs(x2 - x1);
        const height = Math.abs(y2 - y1);
        const left = Math.min(x1, x2);
        const top = Math.min(y1, y2);

        return (
          <div
            className="absolute border-2 cursor-move hover:bg-black/5"
            style={{
              left: `${left}%`,
              top: `${top}%`,
              width: `${width}%`,
              height: `${height}%`,
              borderColor: annotation.color,
              zIndex: 10,
            }}
            onMouseDown={handleMouseDown(
              'body',
              annotation.screenshotAnnotationId
            )}
          />
        );
      }

      default:
        return null;
    }
  };

  const renderHandles = (annotation: ScreenshotType['annotations'][0]) => {
    if (annotation.shapeType === 'CIRCLE') return null;

    const handleStyle =
      'w-3 h-3 rounded-full bg-white border-2 absolute cursor-move';

    const coords =
      activeAnnotationId === annotation.screenshotAnnotationId
        ? localCoords
        : {
            x1: annotation.x1Coordinate,
            y1: annotation.y1Coordinate,
            x2:
              annotation.x2Coordinate ??
              annotation.x1Coordinate + DEFAULT_SHAPE_SIZE,
            y2:
              annotation.y2Coordinate ??
              annotation.y1Coordinate + DEFAULT_SHAPE_SIZE,
          };

    const { x1, y1, x2, y2 } = coords;

    return (
      <>
        <div
          className={handleStyle}
          style={{
            left: `${x1}%`,
            top: `${y1}%`,
            transform: 'translate(-50%, -50%)',
            borderColor: annotation.color,
            zIndex: 20,
          }}
          onMouseDown={handleMouseDown(
            'start',
            annotation.screenshotAnnotationId
          )}
        />
        <div
          className={handleStyle}
          style={{
            left: `${x2}%`,
            top: `${y2}%`,
            transform: 'translate(-50%, -50%)',
            borderColor: annotation.color,
            zIndex: 20,
          }}
          onMouseDown={handleMouseDown(
            'end',
            annotation.screenshotAnnotationId
          )}
        />
      </>
    );
  };

  if (!screenshot.annotation) return null;

  return (
    <div
      className="absolute inset-0"
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {screenshot.annotations.map((annotation) => (
        <React.Fragment key={annotation.screenshotAnnotationId}>
          {renderShape(annotation)}
          {renderHandles(annotation)}
        </React.Fragment>
      ))}
    </div>
  );
};

export default AnnotationOverlay;
