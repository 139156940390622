import { Building2, Lock } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';

interface Vault {
  isPublic: boolean;
  isGlobal: boolean;
  isLocked: boolean;
}

const VaultStatusIcons = ({ vault }: { vault: Vault }) => {
  const activeIconClass = 'h-5 w-5 text-fluency-600';

  return (
    <TooltipProvider>
      <div className="flex gap-4 justify-end items-center h-full">
        {/* {vault.isPublic && (
          <Tooltip delayDuration={100}>
            <TooltipTrigger asChild>
              <div>
                <Globe className={activeIconClass} />
              </div>
            </TooltipTrigger>
            <TooltipContent side="top">
              <p>Public sharing enabled</p>
            </TooltipContent>
          </Tooltip>
        )} */}

        {vault.isGlobal && (
          <Tooltip delayDuration={100}>
            <TooltipTrigger asChild>
              <div>
                <Building2 className={activeIconClass} />
              </div>
            </TooltipTrigger>
            <TooltipContent side="top">
              <p>Organisation-wide Vault</p>
            </TooltipContent>
          </Tooltip>
        )}
        {vault.isLocked && (
          <Tooltip delayDuration={100}>
            <TooltipTrigger asChild>
              <Lock className={activeIconClass} />
            </TooltipTrigger>
            <TooltipContent side="top">
              <p>Locked for editing</p>
            </TooltipContent>
          </Tooltip>
        )}
        <div className="h-8 border-r ml-2" />
      </div>
    </TooltipProvider>
  );
};

export default VaultStatusIcons;
