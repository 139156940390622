import React, { useState, useMemo } from 'react';
import { BPMNElement } from '../../types/workflow';
import DocumentItem from './DocumentItem';
import type { Documentation } from '@fluency/ui/hooks/documentation/types/GetDocs';
import { ScrollArea } from '@fluency/ui/components/ui/scroll-area';
import { Input } from '@fluency/ui/components/ui/input';
import { Button } from '@fluency/ui/components/ui/button';
import { ArrowUpDown, File, Grip } from 'lucide-react';
import {
  StartIcon,
  EndIcon,
  DecisionIcon,
  TextIcon,
  DefaultIcon,
  ActivityIcon,
  SwimlaneIcon,
} from './BPMNIcons';
import useGetDocs from '@fluency/ui/hooks/documentation/useGetDocs';

type SidebarProps = {
  bpmnElements: BPMNElement[];
  onElementClick: (element: BPMNElement) => void;
  onDocNodeClick: (doc: Documentation) => void;
  onEmptyDocClick: () => void;
  vaultId: string;
};

const Sidebar: React.FC<SidebarProps> = ({
  bpmnElements,
  onElementClick,
  onDocNodeClick,
  onEmptyDocClick,
  vaultId,
}) => {
  const { data: documents, isLoading } = useGetDocs({
    page: 1,
    pageSize: 200,
    vaultId: vaultId,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const filteredAndSortedDocs = useMemo(() => {
    if (!documents?.documentation) return [];

    return documents.documentation
      .filter((doc: Documentation) =>
        doc.documentationName.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a: Documentation, b: Documentation) => {
        const comparison = a.documentationName.localeCompare(
          b.documentationName
        );
        return sortOrder === 'asc' ? comparison : -comparison;
      });
  }, [documents, searchTerm, sortOrder]);

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleEmptyDocDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('application/reactflow', 'documentNode');
    // Don't set any other data - this will signal that it's a new document
    event.dataTransfer.effectAllowed = 'move';
  };

  const renderElementItem = (element: BPMNElement) => {
    const onDragStart = (
      event: React.DragEvent<HTMLDivElement>,
      element: BPMNElement
    ) => {
      event.dataTransfer.setData('application/reactflow', element.type);
      event.dataTransfer.setData('application/title', element.title);
      event.dataTransfer.setData('application/id', element.id);
      event.dataTransfer.effectAllowed = 'move';
    };

    const getIconComponent = (title: string) => {
      const lowerTitle = title.toLowerCase();
      if (lowerTitle.includes('start')) return StartIcon;
      if (lowerTitle.includes('end')) return EndIcon;
      if (lowerTitle.includes('decision')) return DecisionIcon;
      if (lowerTitle.includes('text')) return TextIcon;
      if (lowerTitle.includes('activity')) return ActivityIcon;
      if (lowerTitle.includes('swimlane')) return SwimlaneIcon;
      return DefaultIcon;
    };

    const IconComponent = getIconComponent(element.title);

    return (
      <div
        key={element.id}
        className="cursor-pointer mb-2 flex flex-col items-center gap-1 font-semibold text-xs"
        draggable
        onDragStart={(event) => onDragStart(event, element)}
        onClick={() => onElementClick(element)}
      >
        <IconComponent />
      </div>
    );
  };

  return (
    <div className="pr-4 h-[calc(100vh-390px)]">
      <div className="flex flex-wrap gap-2 justify-between">
        {bpmnElements.map(renderElementItem)}
      </div>
      <div
        className="mb-4 p-2 rounded flex justify-between cursor-grab  items-center border border-input bg-background hover:bg-accent hover:text-accent-foreground"
        draggable
        onDragStart={handleEmptyDocDragStart}
        onClick={onEmptyDocClick}
      >
        <div className="flex items-center text-sm gap-2">
          <File className="w-5" strokeWidth={1.5} />
          <div className="flex-col items-stert gap-2">
            <p className="font-semibold">New Document</p>
            <p className="text-xs italic text-gray-600">
              Record or request steps
            </p>
          </div>
        </div>
        <Grip className="w-4 text-gray-500" />
      </div>
      <p className="font-medium text-sm py-2">Existing Documents:</p>
      <div className="mb-4 flex gap-2">
        <Input
          type="text"
          placeholder="Search documents..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="flex-grow"
        />
        <Button onClick={toggleSortOrder} variant="outline">
          <ArrowUpDown className="mr-2 h-4 w-4" />
          {sortOrder === 'asc' ? 'A-Z' : 'Z-A'}
        </Button>
      </div>
      <ScrollArea className="h-full">
        {isLoading ? (
          <>
            {[...Array(5)].map((_, index) => (
              <div
                key={index}
                className="mb-2 h-10 rounded-lg bg-gray-100 animate-pulse"
              />
            ))}
          </>
        ) : (
          filteredAndSortedDocs.map((doc: Documentation) => (
            <div key={doc.documentationId} onClick={() => onDocNodeClick(doc)}>
              <DocumentItem
                key={doc.documentationId}
                doc={doc}
                searchTerm={searchTerm}
              />
            </div>
          ))
        )}
      </ScrollArea>
    </div>
  );
};

export default Sidebar;
