import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@fluency/ui/components/ui/table';

const PermissionsSkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
      {/* User Access Card Skeleton */}
      <Card className="border-0">
        <CardHeader className="pb-3 pt-0 px-0">
          <CardTitle className="text-base font-semibold">Your Access</CardTitle>
        </CardHeader>
        <CardContent className="p-0 bg-gray-50 border rounded-lg">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="font-medium flex items-center gap-4">
                  <div className="h-8 w-8 bg-gray-200 rounded-full animate-pulse" />
                  <div className="flex flex-col gap-2">
                    <div className="h-4 w-32 bg-gray-200 rounded animate-pulse" />
                    <div className="h-3 w-48 bg-gray-200 rounded animate-pulse" />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {/* Permissions Table Skeleton */}
      <Card className="border-0">
        <CardHeader className="pb-3 p-0">
          <CardTitle className="text-base font-semibold">Permissions</CardTitle>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="h-10 w-[300px] bg-gray-200 rounded animate-pulse" />
              <div className="h-10 w-[120px] bg-gray-200 rounded animate-pulse" />
            </div>
            <div className="h-10 w-32 bg-gray-200 rounded animate-pulse" />
          </div>
        </CardHeader>
        <CardContent className="p-0 border-t border-b mt-4">
          <Table>
            <TableBody>
              {[...Array(4)].map((_, index) => (
                <TableRow
                  key={index}
                  className="flex items-center justify-between"
                >
                  <TableCell className="flex items-center gap-2">
                    <div className="h-4 w-4 bg-gray-200 rounded animate-pulse" />
                    <div className="h-4 w-40 bg-gray-200 rounded animate-pulse" />
                    <div className="h-6 w-20 bg-gray-200 rounded-full animate-pulse" />
                    <div className="h-6 w-16 bg-gray-200 rounded-full animate-pulse" />
                  </TableCell>
                  <TableCell className="flex items-center gap-2">
                    <div className="h-8 w-8 bg-gray-200 rounded animate-pulse" />
                    <div className="h-8 w-8 bg-gray-200 rounded animate-pulse" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default PermissionsSkeleton;
