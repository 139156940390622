import { Button } from '@fluency/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@fluency/ui/components/ui/dropdown-menu';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import EditAllDropdown from './EditAllDropdown';
import { Check, PenLine } from 'lucide-react';

interface BulkDocActionsProps {
  documentId: string;
  isEditMode: boolean;
  setIsEditMode: (value: boolean) => void;
  documentName: string;
  isPreview?: boolean;
}

const commonButtonStyles =
  'h-9 px-3 gap-2 flex items-center justify-center font-medium text-sm';
const commonIconStyles = 'w-4 h-4';
const headerSpacing = 'px-6';

const BulkDocActions = ({
  documentId,
  isEditMode,
  setIsEditMode,
}: BulkDocActionsProps) => {
  if (!isEditMode) return null;

  return (
    <div className={`fixed  top-0 right-0 ${headerSpacing} pt-5 pb-2`}>
      <div className="mx-auto flex items-center justify-end">
        <div className="flex items-center gap-3">
          <div className="flex items-center ">
            <TooltipProvider>
              <DropdownMenu>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" className={commonButtonStyles}>
                        <PenLine className={commonIconStyles} />
                        Bulk Edit
                      </Button>
                    </DropdownMenuTrigger>
                  </TooltipTrigger>
                  <TooltipContent side="top">
                    <p>Modify entire document</p>
                  </TooltipContent>
                </Tooltip>
                <DropdownMenuContent align="center" className="w-fit">
                  <EditAllDropdown documentId={documentId} />
                </DropdownMenuContent>
              </DropdownMenu>
            </TooltipProvider>
          </div>
          <Button
            onClick={() => setIsEditMode(false)}
            variant="default"
            className={`${commonButtonStyles} w-24`}
          >
            <Check className={commonIconStyles} />
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BulkDocActions;
