import { Badge } from '@fluency/ui/components/ui/badge';
import { Button } from '@fluency/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from '@fluency/ui/components/ui/dropdown-menu';
import {
  ArrowLeftRight,
  ClipboardList,
  Code,
  Copy,
  KeyRound,
  Link,
  Lock,
  Settings2,
  Share,
  Trash,
  Unlock,
} from 'lucide-react';
import { useState } from 'react';
import ShareModal from './CopyLink';
import DeleteDocModal from './DeleteDocModal';
import EmbedModal from './EmbedOptions';
import ExportModal from './ExportModal';
import LockToggleDialog from './LockToggle';
import RequestDialog from './requests/components/RequestDialog';
import { useResourceRequests } from './requests/hooks/useResourceRequests';
import VersionHistoryMenu from './VersionControlMenu';
import { usePermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { PermissionsDialog } from '../../ManagePermissions';
import { DocumentationResponse } from '@fluency/ui/hooks/documentation/types/master';
import MoveDocumentDialog from './MoveDocument';
import DuplicateDocumentDialog from './DuplicateDocumentDialog';

interface FileSettingsProps {
  documentId: string;
  data: DocumentationResponse;
  isManager: boolean;
  isVaultLocked: boolean;
}

export function FileSettings({
  documentId,
  data,
  isManager,
  isVaultLocked,
}: FileSettingsProps) {
  const { hasPermissions } = usePermissionGate();
  const canRead = hasPermissions(['documents:read']);
  const canUpdate = hasPermissions(['documents:update']);
  const canDelete = hasPermissions(['documents:delete']) && isManager;
  const canRequestResource =
    hasPermissions(['resource-requests:create']) && isManager;
  const canReadFga = hasPermissions(['fga:read']);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showPermissionsDialog, setShowPermissionsDialog] = useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [showRequestDialog, setShowRequestDialog] = useState(false);
  const [showLockDialog, setShowLockDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEmbedDialog, setShowEmbedDialog] = useState(false);
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);

  const { data: requests = [] } = useResourceRequests(documentId);

  const handleModalOpen = (modalSetter: (value: boolean) => void) => {
    setIsDropdownOpen(false);
    modalSetter(true);
  };

  const handleModalClose = (modalSetter: (value: boolean) => void) => {
    modalSetter(false);
    setIsDropdownOpen(false);
  };

  const activeRequests = requests.reduce(
    (acc, req) =>
      acc + req.assignments.filter((a) => a.status !== 'COMPLETED').length,
    0
  );

  const isLocked = data.documentation.isLocked;

  // Section visibility checks
  const hasRequestsSection = !isVaultLocked && canRequestResource;
  const hasVersionSection = canUpdate;
  const hasTopSection = hasRequestsSection || hasVersionSection;

  const hasShareSection = canRead;

  const hasPermissionsSection = canReadFga;
  const hasLockSection = canUpdate && isManager && !isVaultLocked;
  const hasManageSection = hasPermissionsSection || hasLockSection;

  const hasDeleteSection = !isVaultLocked && canDelete;

  return (
    <>
      <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            size="sm"
            variant="ghost"
            className="h-9 px-3 gap-2 flex items-center justify-center font-medium text-sm"
          >
            <Settings2 className="w-4 h-4" />
            Settings
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end">
          {/* Top Section */}
          {hasTopSection && (
            <DropdownMenuGroup>
              {hasRequestsSection && (
                <DropdownMenuItem
                  onSelect={(e) => {
                    e.preventDefault();
                    handleModalOpen(setShowRequestDialog);
                  }}
                >
                  Requests
                  <DropdownMenuShortcut className="flex items-center gap-2">
                    {activeRequests > 0 && (
                      <Badge
                        variant="secondary"
                        className="ml-2 bg-fluency-700 text-white hover:bg-fluency-700"
                      >
                        {activeRequests}
                      </Badge>
                    )}

                    <ClipboardList className="w-4" />
                  </DropdownMenuShortcut>
                </DropdownMenuItem>
              )}
              {hasVersionSection && (
                <VersionHistoryMenu documentId={documentId} />
              )}
            </DropdownMenuGroup>
          )}

          {/* First Separator */}
          {hasTopSection && hasShareSection && <DropdownMenuSeparator />}

          {/* Share Section */}
          {hasShareSection && (
            <DropdownMenuGroup>
              <DropdownMenuItem
                onSelect={(e) => {
                  e.preventDefault();
                  handleModalOpen(setShowShareDialog);
                }}
              >
                Copy link
                <DropdownMenuShortcut>
                  <Link className="w-4" />
                </DropdownMenuShortcut>
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={(e) => {
                  e.preventDefault();
                  handleModalOpen(setShowEmbedDialog);
                }}
              >
                Embed
                <DropdownMenuShortcut>
                  <Code className="w-4" />
                </DropdownMenuShortcut>
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={(e) => {
                  e.preventDefault();
                  handleModalOpen(setShowExportDialog);
                }}
              >
                Export
                <DropdownMenuShortcut>
                  <Share className="w-4" />
                </DropdownMenuShortcut>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          )}

          {/* Second Separator */}
          {hasShareSection && hasManageSection && <DropdownMenuSeparator />}

          {/* Manage Section */}
          {hasManageSection && (
            <DropdownMenuGroup>
              {hasPermissionsSection && (
                <DropdownMenuItem
                  onSelect={(e) => {
                    e.preventDefault();
                    handleModalOpen(setShowPermissionsDialog);
                  }}
                >
                  Permissions
                  <DropdownMenuShortcut>
                    <KeyRound className="w-4" />
                  </DropdownMenuShortcut>
                </DropdownMenuItem>
              )}
              {hasLockSection && (
                <>
                  <DropdownMenuItem
                    onSelect={(e) => {
                      e.preventDefault();
                      handleModalOpen(setShowLockDialog);
                    }}
                  >
                    {isLocked ? 'Unlock' : 'Lock'}
                    <DropdownMenuShortcut>
                      {!isLocked ? (
                        <Lock className="w-4" />
                      ) : (
                        <Unlock className="w-4" />
                      )}
                    </DropdownMenuShortcut>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onSelect={(e) => {
                      e.preventDefault();
                      handleModalOpen(setShowMoveDialog);
                    }}
                  >
                    Move
                    <DropdownMenuShortcut>
                      <ArrowLeftRight className="w-4" />
                    </DropdownMenuShortcut>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onSelect={(e) => {
                      e.preventDefault();
                      handleModalOpen(setShowDuplicateDialog);
                    }}
                  >
                    Duplicate
                    <DropdownMenuShortcut>
                      <Copy className="w-4" />
                    </DropdownMenuShortcut>
                  </DropdownMenuItem>
                </>
              )}
            </DropdownMenuGroup>
          )}

          {/* Third Separator */}
          {hasManageSection && hasDeleteSection && <DropdownMenuSeparator />}

          {/* Delete Section */}
          {hasDeleteSection && (
            <DropdownMenuGroup>
              <DropdownMenuItem
                className="text-destructive"
                onSelect={(e) => {
                  e.preventDefault();
                  handleModalOpen(setShowDeleteDialog);
                }}
              >
                Delete document
                <DropdownMenuShortcut>
                  <Trash className="w-4" />
                </DropdownMenuShortcut>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      {/* Modals */}
      {canReadFga && (
        <PermissionsDialog
          resourceId={documentId}
          resourceType="DOCUMENTATION"
          showTooltip={false}
          trigger={<></>}
          open={showPermissionsDialog}
          onOpenChange={setShowPermissionsDialog}
        />
      )}

      {canRead && (
        <>
          <ShareModal
            data={data}
            isOpen={showShareDialog}
            onOpenChange={setShowShareDialog}
          />

          <EmbedModal
            isOpen={showEmbedDialog}
            onOpenChange={setShowEmbedDialog}
            data={data}
          />

          <ExportModal
            isOpen={showExportDialog}
            onOpenChange={setShowExportDialog}
            documentId={documentId}
          />
        </>
      )}

      {canRequestResource && (
        <RequestDialog
          isOpen={showRequestDialog}
          onOpenChange={setShowRequestDialog}
          documentId={documentId}
          isManager={isManager}
        />
      )}

      {canUpdate && isManager && (
        <>
          <LockToggleDialog
            data={data}
            isOpen={showLockDialog}
            onOpenChange={setShowLockDialog}
          />
          <MoveDocumentDialog
            documentId={data.documentation.documentationId}
            currentVaultId={data.documentation.vaultId}
            isOpen={showMoveDialog}
            onOpenChange={setShowMoveDialog}
          />
          <DuplicateDocumentDialog
            documentId={data.documentation.documentationId}
            documentName={data.documentation.documentationName}
            vaultId={data.documentation.vaultId}
            isOpen={showDuplicateDialog}
            onOpenChange={(open) =>
              open
                ? setShowDuplicateDialog(true)
                : handleModalClose(setShowDuplicateDialog)
            }
          />
        </>
      )}
      {canDelete && (
        <DeleteDocModal
          baseDocumentId={documentId}
          isOpen={showDeleteDialog}
          onOpenChange={setShowDeleteDialog}
        />
      )}
    </>
  );
}
