export const VaultIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    stroke="currentColor"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <rect x="1.5" y="1.5" width="13" height="13" rx="1.5" ry="1.5" />
    <circle cx="8" cy="8" r="2.5" />
    <line x1="4.5" y1="4.5" x2="6" y2="6" />
    <line x1="11.5" y1="4.5" x2="10" y2="6" />
    <line x1="4.5" y1="11.5" x2="6" y2="10" />
    <line x1="11.5" y1="11.5" x2="10" y2="10" />
    <line x1="13" y1="6.5" x2="13" y2="9.5" />
  </svg>
);
