import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@fluency/ui/components/ui/use-toast';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { Documentation, DocumentationResponse } from './types/master';
import {
  InfiniteQueryResponse,
  ResourceItem,
  DocumentationResource,
  PageResponse,
} from '../vaults/types/types';
import { ResourceType } from '../fga/types/fga';

type UpdateType = 'title' | 'description';

interface UpdateDocumentOptions {
  id: string;
  type: UpdateType;
}

interface UpdateContext {
  previousDocInfo?: DocumentationResponse;
  previousVaultResources?: InfiniteQueryResponse;
  vaultId?: string;
}

const convertDocumentToResource = (
  doc: Documentation
): DocumentationResource => ({
  documentationId: doc.documentationId,
  documentationName: doc.documentationName,
  createdDate: doc.createdDate,
  duration: doc.duration,
  totalSteps: doc.totalSteps,
  description: doc.description,
  createdBy: doc.createdBy,
  shareId: doc.shareId,
  updatedAt: doc.updatedAt,
  vaultId: doc.vaultId,
  isPublic: doc.isPublic,
  isGlobal: doc.isGlobal,
  isLocked: doc.isLocked,
  vault: doc.vault,
  userInfo: doc.userInfo?.map((user) => ({ userId: user.email })) || [],
  versionInfo: doc.versionInfo,
  permissions: {
    role: doc.permissions.role,
    source: doc.permissions.source,
    details: doc.permissions.details,
  },
});

export const useUpdateDoc = ({ id, type }: UpdateDocumentOptions) => {
  const { toast } = useToast();
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  const mutation = useMutation<string, Error, string, UpdateContext>({
    mutationFn: async (newValue) => {
      if (!id) {
        throw new Error('Document ID is required');
      }

      const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
      const endpoint = `/documents/update`;

      const response = await fetch(`${apiBaseUrl}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          [type === 'title' ? 'documentationName' : 'documentationDescription']:
            newValue,
          documentationId: id,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(
          data.message || `HTTP error! status: ${response.status}`
        );
      }

      return data.message || 'Document updated successfully.';
    },

    onMutate: async (newValue) => {
      // Get current document data
      const previousDocInfo = queryClient.getQueryData<DocumentationResponse>([
        'docInfo',
        id,
      ]);

      if (!previousDocInfo?.documentation) {
        throw new Error('Document not found');
      }

      const vaultId = previousDocInfo.documentation.vaultId;

      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ['docInfo', id] });
      await queryClient.cancelQueries({
        queryKey: ['vaultResources', vaultId, undefined],
      });

      // Create optimistically updated document
      const updatedDoc = {
        ...previousDocInfo.documentation,
        [type === 'title' ? 'documentationName' : 'description']: newValue,
        updatedAt: new Date().toISOString(),
      };

      // Update docInfo cache
      queryClient.setQueryData<DocumentationResponse>(['docInfo', id], {
        ...previousDocInfo,
        documentation: updatedDoc,
      });

      // Get previous vault resources with correct query key
      const previousVaultResources =
        queryClient.getQueryData<InfiniteQueryResponse>([
          'vaultResources',
          vaultId,
          undefined,
        ]);

      // Update vault resources cache with correct query key
      queryClient.setQueryData<InfiniteQueryResponse>(
        ['vaultResources', vaultId, undefined],
        (old): InfiniteQueryResponse | undefined => {
          if (!old?.pages) return old;

          const newPages = old.pages.map((page: PageResponse) => ({
            ...page,
            items: page.items.map((item: ResourceItem) => {
              if (
                item.type === ResourceType.DOCUMENTATION &&
                (item.resource as DocumentationResource).documentationId === id
              ) {
                return {
                  ...item,
                  resource: convertDocumentToResource(updatedDoc),
                } as ResourceItem;
              }
              return item;
            }),
          }));

          return {
            ...old,
            pages: newPages,
          };
        }
      );

      return {
        previousDocInfo,
        previousVaultResources,
        vaultId,
      };
    },

    onError: (error, _variables, context) => {
      // Revert docInfo cache
      if (context?.previousDocInfo) {
        queryClient.setQueryData(['docInfo', id], context.previousDocInfo);
      }

      // Revert vault resources cache with correct query key
      if (context?.vaultId && context?.previousVaultResources) {
        queryClient.setQueryData(
          ['vaultResources', context.vaultId, undefined],
          context.previousVaultResources
        );
      }

      toast({
        variant: 'destructive',
        title: 'Update failed',
        description:
          error.message || 'Failed to update document. Please try again.',
      });
    },

    onSuccess: (_message, _variables, _context) => {
      toast({
        title: 'Success',
        description: `Document ${type} has been updated successfully`,
      });
    },

    onSettled: (_data, _error, _variables, context) => {
      // Invalidate queries with correct query key
      if (context?.vaultId) {
        queryClient.invalidateQueries({
          queryKey: ['vaultResources', context.vaultId, undefined],
        });
      }
      queryClient.invalidateQueries({ queryKey: ['docInfo', id] });
    },
  });

  return {
    mutateAsync: mutation.mutateAsync,
    updateDoc: mutation.mutate,
    isLoading: mutation.isPending,
    isError: mutation.isError,
    error: mutation.error,
  };
};

export default useUpdateDoc;
