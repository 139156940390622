import { RefreshCw } from 'lucide-react';
import { useToggleWorkflowLocking } from '@fluency/ui/hooks/workflows/usePermissionToggles';
import { Workflow } from '@fluency/ui/hooks/workflows/types/types';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@fluency/ui/components/ui/alert-dialog';

interface LockToggleDialogProps {
  data: Workflow;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

const LockToggleDialog = ({
  data,
  isOpen,
  onOpenChange,
}: LockToggleDialogProps) => {
  const toggleLocking = useToggleWorkflowLocking();

  const onToggleLock = () => {
    toggleLocking.mutate({
      workflowId: data.id,
      value: !data.isLocked,
    });
    onOpenChange(false);
  };

  const isLocked = data.isLocked;

  return (
    <AlertDialog open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {isLocked ? 'Unlock Process Map?' : 'Lock Process Map?'}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {isLocked
              ? 'Are you sure? This will allow anyone with edit permissions to make changes to this process map.'
              : 'Locking a process map will lock for changes, and changes can only be made if unlocked by someone with sufficient permission to do so.'}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={onToggleLock}
            disabled={toggleLocking.isPending}
          >
            {toggleLocking.isPending ? (
              <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
            ) : null}
            {isLocked ? 'Unlock' : 'Lock'}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default LockToggleDialog;
