import { useState } from 'react';
import { Minus, Maximize2 } from 'lucide-react';
import ProgressBar from '@fluency/ui/features/Vaults/components/ProgressBar';
import useUploadDocs from '@fluency/ui/features/Vaults/hooks/useUploadDocs';

interface ElectronAPI {
  ipcRenderer: {
    invoke(channel: string, ...args: unknown[]): Promise<unknown>;
    on(channel: string, callback: (...args: any[]) => void): void;
    removeListener(channel: string, callback: (...args: any[]) => void): void;
  };
  on(channel: string, callback: (...args: any[]) => void): void;
  removeListener(channel: string, callback: (...args: any[]) => void): void;
}

declare global {
  interface Window {
    electron?: ElectronAPI;
  }
}

export default function DocumentUploading() {
  const [isMinimized, setIsMinimized] = useState(false);
  const { docsInProgress, isUploading } = useUploadDocs();

  // Get the first document in progress
  const currentDoc = Object.values(docsInProgress)[0];
  const progress = currentDoc?.progress ?? 0;
  const docName = currentDoc?.name ?? 'Document';

  if (!isUploading || !currentDoc) {
    return null;
  }

  if (isMinimized) {
    return (
      <div
        onClick={() => setIsMinimized(false)}
        className="flex items-center gap-2 p-2 text-sm font-medium border bg-white rounded-lg absolute right-4 bottom-4 cursor-pointer hover:bg-gray-50 transition-all shadow-sm"
      >
        <Maximize2 className="w-4 h-4 text-gray-500" />
        <span className="text-gray-700">{progress}%</span>
      </div>
    );
  }

  return (
    <div className="flex justify-start items-start flex-col h-fit px-4 py-2 gap-2 text-sm font-medium border bg-white rounded-lg absolute right-4 bottom-4 w-96 shadow-sm">
      <div className="w-full flex justify-between items-center">
        <div className="flex flex-col">
          <span className="text-gray-700">
            Document creation in progress...
          </span>
          <span
            className="text-xs text-gray-500 truncate max-w-[280px]"
            title={docName}
          >
            {docName}
          </span>
        </div>
        <button
          onClick={() => setIsMinimized(true)}
          className="text-gray-500 hover:text-gray-700 transition-colors p-1 rounded hover:bg-gray-100"
          title="Minimize"
        >
          <Minus className="w-4 h-4" />
        </button>
      </div>
      <ProgressBar progress={progress} />
      <div className="text-gray-500 text-xs">{progress}% complete</div>
    </div>
  );
}
