import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { OrgFormSubmittedData } from '../../OrgForm';
import { Logger } from '@fluency/ui/features/Logger';

interface CreateOrganisationResponse {
  data: {
    tenantId: string;
    stripeCustomerId: string;
    companyLogoKey: string | null;
    createdBy: string;
    owner: string;
  };
  workos: {
    object: string;
    id: string;
    name: string;
    allowProfilesOutsideOrganization: boolean;
    domains: string[];
    createdAt: string;
    updatedAt: string;
  };
}

async function createOrganisation(
  formData: OrgFormSubmittedData,
  accessToken: string
): Promise<CreateOrganisationResponse> {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const endpoint = `${apiBaseUrl}/organisation`;

  const urlencoded = new URLSearchParams();
  Object.entries(formData).forEach(([key, value]) => {
    urlencoded.append(key, value);
  });

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${accessToken}`,
    },
    body: urlencoded,
    redirect: 'follow',
  };

  const response = await fetch(endpoint, requestOptions);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
}

export const useCreateOrganisation = (): UseMutationResult<
  CreateOrganisationResponse,
  Error,
  OrgFormSubmittedData,
  unknown
> => {
  const { accessToken } = useAuth();

  return useMutation<CreateOrganisationResponse, Error, OrgFormSubmittedData>({
    mutationKey: ['createOrganisation'],
    mutationFn: async (formData) => {
      Logger.log('[useCreateOrganisation] Submitting form data:', formData);
      Logger.log('[useCreateOrganisation] Access token:', accessToken);

      const response = await createOrganisation(formData, accessToken || '');

      Logger.log(
        '[useCreateOrganisation] Raw response from createOrganisation:',
        response
      );
      Logger.log('[useCreateOrganisation] Response type:', typeof response);
      Logger.log(
        '[useCreateOrganisation] Response stringified:',
        JSON.stringify(response, null, 2)
      );

      if (response.data) {
        Logger.log('[useCreateOrganisation] response.data:', response.data);
        Logger.log(
          '[useCreateOrganisation] Tenant ID:',
          response.data.tenantId
        );
      } else {
        Logger.log('[useCreateOrganisation] response.data is undefined');
      }

      if (response.workos) {
        Logger.log('[useCreateOrganisation] response.workos:', response.workos);
        Logger.log(
          '[useCreateOrganisation] Organisation name:',
          response.workos.name
        );
      } else {
        Logger.log('[useCreateOrganisation] response.workos is undefined');
      }

      return response;
    },
    onError: (error) => {
      Logger.error(
        '[useCreateOrganisation] Error creating organisation:',
        error
      );
      Logger.error(
        '[useCreateOrganisation] Error details:',
        JSON.stringify(error, null, 2)
      );
      toast({
        variant: 'destructive',
        title: 'Something went wrong.',
        description: 'There was a problem creating your organisation.',
      });
    },
    onSuccess: (response) => {
      Logger.log(
        '[useCreateOrganisation] Organisation created successfully:',
        response
      );
    },
  });
};
