import { Logger } from '@fluency/ui/features/Logger';
import moment from 'moment';
import {
  ApiNotification,
  ApiNotificationResponse,
  NotificationItem,
  NotificationResponse,
} from '../types/notifications.type';

function getResourceTypeFromString(
  type: string | null
): 'document' | 'workflow' {
  if (!type) return 'document';

  const lowercaseType = type.toLowerCase();
  switch (lowercaseType) {
    case 'documentation':
      return 'document';
    case 'workflow':
      return 'workflow';
    default:
      return 'document';
  }
}

export function transformApiNotification(
  apiNotification: ApiNotification | null
): NotificationItem {
  if (!apiNotification) {
    throw new Error('Invalid notification data received');
  }

  try {
    // Map the resource type
    const resourceType = getResourceTypeFromString(
      apiNotification.relatedResourceType
    );

    return {
      id: apiNotification.id,
      type: apiNotification.type,
      userId: apiNotification.userId,
      message: apiNotification.message || '',
      read: apiNotification.isRead,
      time: apiNotification.createdAt
        ? moment(apiNotification.createdAt).fromNow()
        : 'recently',
      createdAt: apiNotification.createdAt,
      resource: {
        id: apiNotification.relatedResourceId || '',
        name: apiNotification.relatedResourceName || 'Not Found',
        type: resourceType,
      },
      link: apiNotification.link || '',
    };
  } catch (error) {
    Logger.error('Error transforming notification:', error);
    throw new Error(`Failed to transform notification: ${error}`);
  }
}

export function transformApiResponse(
  apiResponse: ApiNotificationResponse | null
): NotificationResponse {
  if (!apiResponse) {
    throw new Error('Invalid API response received');
  }

  try {
    return {
      message: apiResponse.message || '',
      data: (apiResponse.data || [])
        .filter((notification) => notification !== null)
        .map(transformApiNotification),
      metadata: {
        total: apiResponse.metadata?.total || 0,
        unreadCount: apiResponse.metadata?.unreadCount || 0,
        page: apiResponse.metadata?.page || 1,
        limit: apiResponse.metadata?.limit || 10,
        totalPages: apiResponse.metadata?.totalPages || 1,
      },
    };
  } catch (error) {
    Logger.error('Error transforming API response:', error);
    throw new Error(`Failed to transform API response: ${error}`);
  }
}
