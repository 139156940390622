import { Button } from '@fluency/ui/components/ui/button';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
  SelectSeparator,
} from '@fluency/ui/components/ui/select';
import { Input } from '@fluency/ui/components/ui/input';
import { Users, UserCircle } from 'lucide-react';
import { useState } from 'react';
import { cn } from '@fluency/ui/lib/utils';
import {
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@fluency/ui/components/ui/dialog';
import { RoleName } from '@fluency/ui/hooks/fga/types/fga';
import { UserDetails } from '@fluency/ui/hooks/fga/types/types';
import useGetOrgUsers from '@fluency/ui/hooks/organisation/useGetOrgUsers';
import useGetTeams from '@fluency/ui/hooks/teams/useGetTeams';

interface AddNewAccessProps {
  onAddTeam: (teamId: string) => void;
  onAddUser: (userId: string) => void;
  existingTeamIds: string[];
  existingUserIds: string[];
  selectedRole: VaultRoleName;
  onRoleChange: (role: VaultRoleName) => void;
  onCancel: () => void;
  user: UserDetails;
}

const getUserDisplayName = (
  firstName: string | null,
  lastName: string | null,
  email: string
): string => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (firstName) {
    return firstName;
  } else if (lastName) {
    return lastName;
  }
  return email;
};

type VaultRoleName = Extract<RoleName, 'VIEWER' | 'EDITOR' | 'MANAGER'>;

const roleDescriptions: Record<VaultRoleName, string> = {
  VIEWER: 'Can view but not modify content',
  EDITOR: 'Can view and edit content',
  MANAGER: 'Full access including managing permissions',
} as const;

const roles: Record<VaultRoleName, string> = {
  VIEWER: 'Viewer',
  EDITOR: 'Editor',
  MANAGER: 'Manager',
} as const;

export const AddNewAccess = ({
  onAddTeam,
  onAddUser,
  existingTeamIds,
  existingUserIds,
  selectedRole,
  onRoleChange,
  onCancel,
  user,
}: AddNewAccessProps) => {
  const [selectedId, setSelectedId] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { data: usersData } = useGetOrgUsers();
  const { data: teamsData } = useGetTeams();

  // Filter out current user and existing users
  const availableUsers =
    usersData?.memberships
      .filter(
        (m) => !existingUserIds.includes(m.userId) && m.userId !== user.userId
      )
      .map((m) => ({
        id: m.userId,
        type: 'user' as const,
        label: getUserDisplayName(
          m.user.firstName,
          m.user.lastName,
          m.user.email
        ),
        email: m.user.email,
        value: `user-${m.userId}`,
        searchValue: `${getUserDisplayName(
          m.user.firstName,
          m.user.lastName,
          m.user.email
        )} ${m.user.email}`.toLowerCase(),
      })) ?? [];

  const availableTeams =
    teamsData
      ?.filter((t) => !existingTeamIds.includes(t.teamId))
      .map((t) => ({
        id: t.teamId,
        type: 'team' as const,
        label: t.teamName,
        value: `team-${t.teamId}`,
        searchValue: t.teamName.toLowerCase(),
      })) ?? [];

  const filteredTeams = availableTeams.filter((team) =>
    team.searchValue.includes(searchQuery.toLowerCase())
  );

  const filteredUsers = availableUsers.filter((user) =>
    user.searchValue.includes(searchQuery.toLowerCase())
  );

  const handleSelect = (value: string) => {
    setSelectedId(value);
    setIsOpen(false);
    setSearchQuery('');
  };

  const handleAdd = () => {
    if (!selectedId) return;

    if (selectedId.startsWith('team-')) {
      const teamId = selectedId.replace('team-', '');
      onAddTeam(teamId);
    } else if (selectedId.startsWith('user-')) {
      const userId = selectedId.replace('user-', '');
      onAddUser(userId);
    }
    setSelectedId('');
  };

  const selectedItem = [...availableTeams, ...availableUsers].find(
    (item) => item.value === selectedId
  );

  return (
    <div>
      <DialogHeader>
        <DialogTitle>Add New Permission</DialogTitle>
        <DialogDescription>
          Grant access to users or teams with specific roles
        </DialogDescription>
      </DialogHeader>

      <div className="space-y-6 py-6">
        <div className="space-y-4">
          <div>
            <h3 className="text-sm font-medium mb-2">Select User or Team</h3>
            <Select
              value={selectedId}
              onValueChange={handleSelect}
              open={isOpen}
              onOpenChange={setIsOpen}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Search and select...">
                  {selectedItem && (
                    <div className="flex items-center gap-2">
                      {selectedItem.type === 'team' ? (
                        <Users className="h-4 w-4 shrink-0 text-muted-foreground" />
                      ) : (
                        <UserCircle className="h-4 w-4 shrink-0 text-muted-foreground" />
                      )}
                      <span>{selectedItem.label}</span>
                    </div>
                  )}
                </SelectValue>
              </SelectTrigger>
              <SelectContent
                className="p-0"
                onCloseAutoFocus={(e) => e.preventDefault()}
              >
                <div className="p-2 pb-0">
                  <Input
                    placeholder="Search users and teams..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="h-8"
                  />
                </div>
                <div className="px-1 py-2">
                  {filteredTeams.length > 0 && (
                    <SelectGroup>
                      <SelectLabel className="normal-case text-xs text-muted-foreground px-2 py-1.5">
                        Teams
                      </SelectLabel>
                      {filteredTeams.map((team) => (
                        <SelectItem
                          key={team.value}
                          value={team.value}
                          className={cn(
                            'flex items-center justify-between cursor-pointer',
                            selectedId === team.value && 'bg-accent'
                          )}
                        >
                          <div className="flex items-center gap-2">
                            <Users className="h-4 w-4 shrink-0 text-muted-foreground" />
                            <span>{team.label}</span>
                          </div>
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  )}

                  {filteredTeams.length > 0 && filteredUsers.length > 0 && (
                    <SelectSeparator className="my-2" />
                  )}

                  {filteredUsers.length > 0 && (
                    <SelectGroup>
                      <SelectLabel className="normal-case text-xs text-muted-foreground px-2 py-1.5">
                        Users
                      </SelectLabel>
                      {filteredUsers.map((user) => (
                        <SelectItem
                          key={user.value}
                          value={user.value}
                          className={cn(
                            'flex items-center justify-between cursor-pointer',
                            selectedId === user.value && 'bg-accent'
                          )}
                        >
                          <div className="flex flex-col">
                            <div className="flex items-center gap-2">
                              <UserCircle className="h-4 w-4 shrink-0 text-muted-foreground" />
                              <div>
                                <div>{user.label}</div>
                                <div className="text-sm text-muted-foreground">
                                  {user.email}
                                </div>
                              </div>
                            </div>
                          </div>
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  )}

                  {filteredTeams.length === 0 && filteredUsers.length === 0 && (
                    <div className="py-6 text-center text-sm text-muted-foreground">
                      No results found
                    </div>
                  )}
                </div>
              </SelectContent>
            </Select>
          </div>

          <div>
            <h3 className="text-sm font-medium mb-2">Select Role</h3>
            <Select value={selectedRole} onValueChange={onRoleChange}>
              <SelectTrigger className="w-full">
                <SelectValue>
                  <div className="flex items-center gap-2">
                    <span className="capitalize">
                      {selectedRole.toLowerCase()}
                    </span>
                  </div>
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {(Object.entries(roles) as [VaultRoleName, string][]).map(
                  ([value, label]) => (
                    <SelectItem key={value} value={value}>
                      <div className="flex flex-col">
                        <div className="flex items-center gap-2">
                          <div>
                            <div>{label}</div>
                            <div className="text-sm text-muted-foreground">
                              {roleDescriptions[value]}
                            </div>
                          </div>
                        </div>
                      </div>
                    </SelectItem>
                  )
                )}
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleAdd} disabled={!selectedId}>
          Grant Access
        </Button>
      </div>
    </div>
  );
};
