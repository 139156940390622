import { useGetWorkflow } from '@fluency/ui/hooks/workflows/useGetWorkflow';

export const useWorkflowName = (workflowId: string) => {
  // Only fetch if we have a valid workflowId
  const { data: workflow, isLoading } = useGetWorkflow(
    workflowId ? workflowId : '' // or however your hook handles disabled states
  );

  const getWorkflowName = (): string => {
    if (!workflowId || isLoading) return '';
    return workflow?.name || `Workflow ${workflowId}`;
  };

  const getWorkflowVaultId = (): string | undefined => {
    if (!workflowId) return undefined;
    return workflow?.vault?.id;
  };

  return { getWorkflowName, getWorkflowVaultId, isLoading };
};
