import { forwardRef } from 'react';
import { CropCanvasProps } from '../types';
import { getScaledStyle } from '../utils/cropUtils';
import { transitionStyles } from '../styles';

export const CropCanvas = forwardRef<HTMLCanvasElement, CropCanvasProps>(
  ({ onClick, naturalSize, children, disabled = false }, ref) => {
    return (
      <div className="relative">
        <canvas
          ref={ref}
          className={`h-auto ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
          onClick={disabled ? undefined : onClick}
          style={{
            ...(naturalSize
              ? getScaledStyle(naturalSize.width)
              : { maxWidth: '100%' }),
            ...transitionStyles.base,
          }}
        />
        {children}
      </div>
    );
  }
);

CropCanvas.displayName = 'CropCanvas';
