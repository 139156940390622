// function MyComponent() {
//   const { isElectron } = useElectron();

//   return (
//     <div>
//       {isElectron ? 'Running in Electron' : 'Running in web browser'}
//     </div>
//   );
// }

import React, { createContext, useContext, ReactNode } from 'react';

interface ElectronContextType {
  isElectron: boolean;
}

const ElectronContext = createContext<ElectronContextType | undefined>(
  undefined
);

interface ElectronProviderProps {
  isElectron: boolean;
  children: ReactNode;
}

export const ElectronProvider: React.FC<ElectronProviderProps> = ({
  isElectron,
  children,
}) => {
  return (
    <ElectronContext.Provider value={{ isElectron }}>
      {children}
    </ElectronContext.Provider>
  );
};

export const useElectron = () => {
  const context = useContext(ElectronContext);
  if (context === undefined) {
    throw new Error('useElectron must be used within an ElectronProvider');
  }
  return context;
};
