import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { PermissionGateProvider } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { RefreshCw } from 'lucide-react';
import UserTable from './UserTable/UserTable';
import InvitationsTable from './InviteTable/InvitationsTable';

export const MembersSettings = () => {
  const { isLoading, organisationName } = useAuth();

  if (isLoading) {
    return (
      <div className="h-[200px] flex items-center justify-center">
        <RefreshCw className="w-6 h-6 animate-spin text-muted-foreground" />
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Header */}
      <div>
        <h2 className="text-lg font-medium mb-1">Members & Invites</h2>
        <p className="text-sm text-muted-foreground">
          Manage your members in {organisationName}
        </p>
      </div>

      {/* Members Section */}
      <div className="space-y-8">
        {/* Members Management Card */}
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-md font-medium mb-1">Members</h3>
              <p className="text-sm text-muted-foreground">
                View and manage organisation members
              </p>
            </div>
          </div>

          {/* Members Table */}
          <div className="border rounded-lg">
            <UserTable />
          </div>
        </div>

        {/* Invitations Section */}
        <PermissionGateProvider permissions={['org:update']}>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-md font-medium mb-1">Invitations</h3>
                <p className="text-sm text-muted-foreground">
                  Manage pending invitations
                </p>
              </div>
            </div>

            {/* Invitations Table */}
            <div className="border rounded-lg">
              <InvitationsTable />
            </div>
          </div>
        </PermissionGateProvider>
      </div>
    </div>
  );
};

export default MembersSettings;
