import { useState } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import { Trash } from 'lucide-react';
import useOrgInvites from '@fluency/ui/hooks/organisation/useOrgInvites';

interface RevokeInviteModalProps {
  inviteId: string;
  email: string;
}

export default function RevokeInviteModal({
  inviteId,
  email,
}: RevokeInviteModalProps) {
  const [open, setOpen] = useState(false);
  const { revokeInvite } = useOrgInvites();

  const handleRevokeInvite = () => {
    revokeInvite(inviteId, {
      onSuccess: () => {
        setOpen(false);
      },
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Trash className="h-5 w-5 hover:cursor-pointer" />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Revoke Invitation</DialogTitle>
          <DialogDescription>
            Are you sure you want to revoke the invitation for {email}?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="destructive" onClick={handleRevokeInvite}>
            Revoke Invitation
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
