import { Button } from '@fluency/ui/components/ui/button';
import { Input } from '@fluency/ui/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@fluency/ui/components/ui/select';
import { useConfluence } from '@fluency/ui/hooks/documentation-export/useConfluence';
import { RefreshCw } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { Logger } from '@fluency/ui/features/Logger';

interface ConfluenceExportProps {
  documentId: string;
}

const ConfluenceExport: React.FC<ConfluenceExportProps> = ({ documentId }) => {
  const [selectedSpace, setSelectedSpace] = useState('');
  const [selectedPage, setSelectedPage] = useState('');
  const [selectedSpaceId, setSelectedSpaceId] = useState('');
  const [newPageTitle, setNewPageTitle] = useState('');
  const [isSpacesOpen, setIsSpacesOpen] = useState(false);
  const [isPagesOpen, setIsPagesOpen] = useState(false);

  const spacesEndRef = useRef<HTMLDivElement>(null);
  const pagesEndRef = useRef<HTMLDivElement>(null);

  const {
    getSpacesQuery,
    getPagesQuery,
    exportToConfluence,
    isExporting,
    exportError,
  } = useConfluence();

  const {
    data: spacesData,
    isLoading: isLoadingSpaces,
    error: spacesError,
    isFetchingNextPage: isFetchingNextSpacesPage,
    fetchNextPage: fetchNextSpacesPage,
    hasNextPage: hasNextSpacesPage,
  } = getSpacesQuery();

  const {
    data: pagesData,
    isLoading: isLoadingPages,
    error: pagesError,
    isFetchingNextPage: isFetchingNextPagesPage,
    fetchNextPage: fetchNextPagesPage,
    hasNextPage: hasNextPagesPage,
  } = getPagesQuery(selectedSpaceId);

  useEffect(() => {
    const spacesObserver = new IntersectionObserver(
      (entries) => {
        if (
          entries[0]?.isIntersecting &&
          hasNextSpacesPage &&
          isSpacesOpen &&
          !isFetchingNextSpacesPage
        ) {
          fetchNextSpacesPage();
        }
      },
      { threshold: 1 }
    );

    const pagesObserver = new IntersectionObserver(
      (entries) => {
        if (
          entries[0]?.isIntersecting &&
          hasNextPagesPage &&
          isPagesOpen &&
          !isFetchingNextPagesPage
        ) {
          fetchNextPagesPage();
        }
      },
      { threshold: 1 }
    );

    if (spacesEndRef.current) spacesObserver.observe(spacesEndRef.current);
    if (pagesEndRef.current) pagesObserver.observe(pagesEndRef.current);

    return () => {
      if (spacesEndRef.current) spacesObserver.unobserve(spacesEndRef.current);
      if (pagesEndRef.current) pagesObserver.unobserve(pagesEndRef.current);
    };
  }, [
    fetchNextSpacesPage,
    fetchNextPagesPage,
    hasNextSpacesPage,
    hasNextPagesPage,
    isSpacesOpen,
    isPagesOpen,
    isFetchingNextSpacesPage,
    isFetchingNextPagesPage,
  ]);

  const handleExport = async () => {
    try {
      await exportToConfluence({
        documentId,
        spaceId: selectedSpaceId,
        parentPageId: selectedPage === 'new' ? '' : selectedPage,
        newPageTitle: selectedPage === 'new' ? newPageTitle : undefined,
      });
    } catch (error) {
      Logger.error('Export failed:', error);
    }
  };

  const handleSpaceChange = (value: string) => {
    const selectedSpaceObject = spacesList.find((space) => space.id === value);
    if (selectedSpaceObject) {
      setSelectedSpace(value);
      setSelectedSpaceId(selectedSpaceObject.id);
      setSelectedPage('');
    }
  };

  const handlePageChange = (value: string) => {
    setSelectedPage(value);
  };

  if (isLoadingSpaces) {
    return (
      <div className="h-24 flex justify-center items-center text-gray-400 text-sm">
        <RefreshCw className="mr-1 w-4 animate-spin" />
        Loading spaces...
      </div>
    );
  }

  if (spacesError) {
    return (
      <div className="h-24 flex justify-center items-center text-red-500 text-sm">
        Error loading spaces
      </div>
    );
  }

  const spacesList = spacesData?.pages.flatMap((page) => page.spaces) || [];
  const pagesList = pagesData?.pages.flatMap((page) => page.pages) || [];

  return (
    <div className="flex flex-col gap-4">
      <Select
        value={selectedSpace}
        onValueChange={handleSpaceChange}
        onOpenChange={setIsSpacesOpen}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select Confluence Space" />
        </SelectTrigger>
        <SelectContent>
          {spacesList.map((space) => (
            <SelectItem key={space.id} value={space.id}>
              {space.name}
            </SelectItem>
          ))}
          {(isFetchingNextSpacesPage || hasNextSpacesPage) && (
            <div
              ref={spacesEndRef}
              className="h-8 flex items-center justify-center"
            >
              {isFetchingNextSpacesPage && (
                <RefreshCw className="w-4 h-4 animate-spin" />
              )}
            </div>
          )}
        </SelectContent>
      </Select>

      {selectedSpace && (
        <Select
          value={selectedPage}
          onValueChange={handlePageChange}
          onOpenChange={setIsPagesOpen}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select Confluence Page" />
          </SelectTrigger>
          <SelectContent>
            {isLoadingPages ? (
              <SelectItem value="loading" disabled>
                Loading pages...
              </SelectItem>
            ) : pagesError ? (
              <SelectItem value="error" disabled>
                Error loading pages
              </SelectItem>
            ) : (
              pagesList?.map((page) => (
                <SelectItem key={page.id} value={page.id}>
                  {page.title}
                </SelectItem>
              ))
            )}
            {(isFetchingNextPagesPage || hasNextPagesPage) && (
              <div
                ref={pagesEndRef}
                className="h-8 flex items-center justify-center"
              >
                {isFetchingNextPagesPage && (
                  <RefreshCw className="w-4 h-4 animate-spin" />
                )}
              </div>
            )}
          </SelectContent>
        </Select>
      )}

      {selectedPage === 'new' && (
        <Input
          placeholder="New Page Title"
          value={newPageTitle}
          onChange={(e) => setNewPageTitle(e.target.value)}
        />
      )}

      <Button
        onClick={handleExport}
        disabled={
          isExporting ||
          !selectedSpace ||
          (!selectedPage && selectedPage !== 'new') ||
          (selectedPage === 'new' && !newPageTitle)
        }
      >
        {isExporting ? 'Exporting...' : 'Export to Confluence'}
      </Button>

      {exportError && (
        <div className="text-red-500 w-full flex text-center text-sm justify-center">
          An error occurred when exporting to Confluence.
        </div>
      )}
    </div>
  );
};

export default ConfluenceExport;
