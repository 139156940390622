import React, { useEffect, useState } from 'react';

interface ProgressBarProps {
  progress: number;
}

const getTransitionDuration = (
  newProgress: number,
  oldProgress: number
): string => {
  const progressDifference = Math.abs(newProgress - oldProgress);

  // Adjust the multiplier and max duration as needed for your specific case
  const duration = Math.min(progressDifference * 0.2, 5); // 0.2 seconds per 1% difference, max 5 seconds

  return `${duration}s`;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  const [displayProgress, setDisplayProgress] = useState(progress);
  const [transitionDuration, setTransitionDuration] = useState('0s');

  useEffect(() => {
    let animationFrame: number;

    const animateProgress = () => {
      setDisplayProgress((prevProgress) => {
        if (prevProgress < progress) {
          return Math.min(prevProgress + 1, progress);
        }
        return prevProgress;
      });

      if (displayProgress < progress) {
        animationFrame = requestAnimationFrame(animateProgress);
      }
    };

    animateProgress();

    return () => cancelAnimationFrame(animationFrame);
  }, [progress]);

  useEffect(() => {
    setTransitionDuration(getTransitionDuration(progress, displayProgress));
  }, [progress, displayProgress]);

  return (
    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 overflow-hidden">
      <div
        className="bg-fluency-400 h-2.5 rounded-full transition-all ease-in-out"
        style={{
          width: `${displayProgress}%`,
          transitionDuration: transitionDuration,
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
