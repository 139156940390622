import { NodeTypes } from 'reactflow';
import DocumentNode from '../components/nodes/DocumentNode';
import StartNode from '../components/nodes/StartNode';
import EndNode from '../components/nodes/EndNode';
import DecisionNode from '../components/nodes/DecisionNode';
import TextNode from '../components/nodes/Text';
import ActivityNode from '../components/nodes/Activity';
import SwimlaneNode from '../components/nodes/Swimlane';

const nodeTypes: NodeTypes = {
  documentNode: DocumentNode,
  startNode: StartNode,
  endNode: EndNode,
  decisionNode: DecisionNode,
  text: TextNode,
  activityNode: ActivityNode,
  swimlaneNode: SwimlaneNode,
};

export default nodeTypes;
