import * as Sentry from '@sentry/react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { WorkosRole } from '@fluency/ui/hooks/fga/types/types';

interface UserOrganisationsResponse {
  organisationId: string;
  organisationName: string;
  status: 'active' | 'inactive' | 'pending';
  userId: string;
  createdAt: string;
  updatedAt: string;
  role: WorkosRole;
}

const useGetUserOrganisations = (): UseQueryResult<
  UserOrganisationsResponse[],
  Error
> => {
  const { accessToken } = useAuth();

  return useQuery<UserOrganisationsResponse[], Error>({
    queryKey: ['userOrganisations'],
    queryFn: async (): Promise<UserOrganisationsResponse[]> => {
      const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', `Bearer ${accessToken}`);

      const requestOptions: RequestInit = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      const response = await fetch(
        `${apiBaseUrl}/user/getUserOrganisations`,
        requestOptions
      );

      if (!response.ok) {
        Sentry.captureException('Failed to get user organisations');
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result as UserOrganisationsResponse[];
    },
  });
};

export default useGetUserOrganisations;
