import { toast } from '@fluency/ui/components/ui/use-toast';
import { Logger } from '@fluency/ui/features/Logger';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import * as Sentry from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

interface User {
  object: string;
  id: string;
  email: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  profilePictureUrl: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface Membership {
  object: string;
  id: string;
  userId: string;
  organizationId: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  role: {
    slug: string;
  };
  user: User;
}

interface OrgUsersResponse {
  success: boolean;
  memberships: Membership[];
}

export const useGetOrgUsers = () => {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const { accessToken, orgId } = useAuth();
  const queryClient = useQueryClient();

  const fetchOrgUsers = async (): Promise<OrgUsersResponse> => {
    if (!accessToken) {
      throw new Error('Access token is missing');
    }

    const response = await fetch(`${apiBaseUrl}/organisation/memberships`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch organization users');
    }

    return await response.json();
  };

  const query = useQuery<OrgUsersResponse, Error>({
    queryKey: ['orgUsers', orgId],
    queryFn: fetchOrgUsers,
    enabled: !!orgId,
  });

  const changeUserRole = useMutation({
    mutationFn: async ({
      membershipId,
      newRole,
    }: {
      membershipId: string;
      newRole: string;
    }) => {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
      myHeaders.append('Authorization', `Bearer ${accessToken}`);

      const urlencoded = new URLSearchParams();
      urlencoded.append('newRole', newRole);
      urlencoded.append('membershipId', membershipId);

      const requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow' as RequestRedirect,
      };

      const response = await fetch(
        `${apiBaseUrl}/organisation/membership/role`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Failed to change user role');
      }

      return response.text();
    },
    onMutate: async ({ membershipId, newRole }) => {
      await queryClient.cancelQueries({ queryKey: ['orgUsers', orgId] });

      const previousData = queryClient.getQueryData<OrgUsersResponse>([
        'orgUsers',
        orgId,
      ]);

      queryClient.setQueryData<OrgUsersResponse>(
        ['orgUsers', orgId],
        (old) => ({
          ...old!,
          memberships: old!.memberships.map((membership) =>
            membership.id === membershipId
              ? { ...membership, role: { ...membership.role, slug: newRole } }
              : membership
          ),
        })
      );

      return { previousData };
    },
    onError: (_, __, context) => {
      Sentry.captureException('Failed to change user role');
      queryClient.setQueryData(['orgUsers', orgId], context?.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgUsers', orgId] });
    },
  });

  const deleteMembership = useMutation({
    mutationFn: async (membershipId: string) => {
      const response = await fetch(
        `${apiBaseUrl}/organisation/membership/${membershipId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          redirect: 'follow',
        }
      );

      if (!response.ok) {
        const error = await response.json();
        Logger.error(error);
        throw new Error('Failed to delete membership');
      }

      return response.text();
    },
    onMutate: async (membershipId) => {
      await queryClient.cancelQueries({ queryKey: ['orgUsers', orgId] });

      const previousData = queryClient.getQueryData<OrgUsersResponse>([
        'orgUsers',
        orgId,
      ]);

      queryClient.setQueryData<OrgUsersResponse>(
        ['orgUsers', orgId],
        (old) => ({
          ...old!,
          memberships: old!.memberships.filter(
            (membership) => membership.id !== membershipId
          ),
        })
      );

      return { previousData };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(['orgUsers', orgId], context?.previousData);
      Sentry.captureException('Failed to remove user');
      toast({
        title: 'Error',
        description: 'Failed to remove user',
      });
    },
    onSuccess: () => {
      toast({
        title: 'User Removed',
        description: 'User has been removed from the organisation',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgUsers', orgId] });
    },
  });

  return {
    ...query,
    changeUserRole: changeUserRole.mutate,
    isChangingRole: changeUserRole.isPending,
    deleteMembership: deleteMembership.mutate,
    isDeletingMembership: deleteMembership.isPending,
  };
};

export default useGetOrgUsers;
