import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@fluency/ui/components/ui/dialog';
import browserExtensionLogo from '@fluency/ui/assets/onboarding/extension-install.png';
import { StepRenderer } from './StepRenderer';
import {
  DownloadButton,
  OpenExtensionButton,
  ConnectButton,
} from './ActionButtons';
import { StatusAlert } from './StatusAlert';
import { ExtensionStatus, SyncStatus } from './types';

interface ExtensionStatusDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  extensionStatus: ExtensionStatus;
  syncStatus: SyncStatus;
  handleDownload: () => void;
  sendAuthData: () => void;
}

export const ExtensionStatusDialog: React.FC<ExtensionStatusDialogProps> = ({
  isOpen,
  onOpenChange,
  extensionStatus,
  syncStatus,
  handleDownload,
  sendAuthData,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[700px] gap-6">
        <DialogHeader>
          <DialogTitle>Fluency Extension Setup</DialogTitle>
          <DialogDescription>
            Install the Fluency Extension for the best experience.
          </DialogDescription>
        </DialogHeader>
        <img
          className="w-full"
          src={browserExtensionLogo}
          alt="Fluency Browser Extension"
        />
        <div className="space-y-4">
          <StepRenderer
            stepNumber={1}
            title="Download"
            description="Get the extension from the Chrome Web Store."
            action={
              <DownloadButton
                extensionStatus={extensionStatus}
                handleDownload={handleDownload}
              />
            }
            isActive={extensionStatus === 'not_installed'}
          />
          <StepRenderer
            stepNumber={2}
            title="Open Extension"
            description="Open the extension to complete installation."
            action={<OpenExtensionButton extensionStatus={extensionStatus} />}
            isActive={extensionStatus === 'installed_not_connected'}
          />
          <StepRenderer
            stepNumber={3}
            title="Connect"
            description="If needed, manually sync with the extension."
            action={
              <ConnectButton
                extensionStatus={extensionStatus}
                syncStatus={syncStatus}
                sendAuthData={sendAuthData}
              />
            }
            isActive={extensionStatus === 'connected' || syncStatus !== 'idle'}
          />
        </div>
        <div className="flex items-center justify-center">
          <StatusAlert syncStatus={syncStatus} />
        </div>
      </DialogContent>
    </Dialog>
  );
};
