import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';

export const Skeleton = () => (
  <Card className="h-full border-none rounded-none flex flex-col">
    <CardHeader className="shrink-0 flex flex-row items-center justify-between space-y-0 pb-4">
      <CardTitle className="text-xl font-semibold flex items-center gap-2">
        <div className="h-7 w-48 bg-gray-200 animate-pulse rounded" />
      </CardTitle>
      <div className="flex gap-2">
        <div className="h-8 w-24 bg-gray-200 animate-pulse rounded" />
        <div className="h-8 w-32 bg-gray-200 animate-pulse rounded" />
      </div>
    </CardHeader>
    <CardDescription className="shrink-0 text-sm text-muted-foreground px-6 pb-4">
      <div className="h-4 w-3/4" />
    </CardDescription>
    <CardContent className="flex flex-col flex-1 min-h-0">
      <div className="shrink-0 space-y-3">
        <div className="flex gap-4">
          <div className="h-8 w-full bg-gray-200 animate-pulse rounded" />
        </div>
        <div className="flex gap-4">
          <div className="h-6 w-32 bg-gray-200 animate-pulse rounded-full" />
          <div className="h-6 w-32 bg-gray-200 animate-pulse rounded-full" />
          <div className="h-6 w-32 bg-gray-200 animate-pulse rounded-full" />
        </div>
      </div>
      <div className="flex-1 min-h-0 mt-8 space-y-6">
        {[1, 2, 3].map((i) => (
          <div key={i} className="space-y-4">
            <div className="h-8 w-full bg-gray-200 animate-pulse rounded" />
            <div className="h-48 w-full bg-gray-200 animate-pulse rounded" />
          </div>
        ))}
      </div>
    </CardContent>
  </Card>
);
