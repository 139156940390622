import { DashboardShell } from '@fluency/ui/components';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@fluency/ui/components/ui/alert-dialog';
import { usePermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { SquareArrowUpRight } from 'lucide-react';

export const Deactivated = () => {
  const { hasPermissions } = usePermissionGate();
  return (
    <DashboardShell>
      <AlertDialog open={true}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>No licence found</AlertDialogTitle>
            {hasPermissions(['subscription:read']) ? (
              <AlertDialogDescription>
                You need a valid licence to continue using Fluency. Please
                subscribe to a plan to continue using Fluency.
              </AlertDialogDescription>
            ) : (
              <AlertDialogDescription>
                You need a valid licence to continue using Fluency. Please
                contact your organisation's administrator to get a licence.
              </AlertDialogDescription>
            )}
          </AlertDialogHeader>
          <AlertDialogFooter>
            {hasPermissions(['subscription:read']) && (
              <AlertDialogAction className="bg-gray-500 hover:bg-gray-400">
                Manage subscription <SquareArrowUpRight className="w-5 ml-2" />
              </AlertDialogAction>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </DashboardShell>
  );
};

export default Deactivated;
