import {
  FileText,
  Workflow as WorkflowIcon,
  AlertCircle,
  FolderOpen,
} from 'lucide-react';
import { Badge } from '@fluency/ui/components/ui/badge';
import { Link } from '@tanstack/react-router';
import { useRecentAssets, isDocument } from '../hooks/useRecentAssets';
import type { RecentItem } from '../hooks/useRecentAssets';

const EmptyItemCard = () => (
  <div className="w-48 bg-gray-50 h-32 rounded relative border border-dashed">
    <div className="absolute inset-0 flex items-center justify-center flex-col gap-2">
      <FolderOpen className="w-16 h-16 text-gray-300" strokeWidth={1.5} />
    </div>
  </div>
);

const LoadingItemCard = () => (
  <div className="w-48 bg-gray-100 h-32 rounded relative border">
    <div className="absolute top-2 left-2 h-6 w-20 bg-gray-200 animate-pulse rounded" />
    <div className="w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/4 -translate-y-1/2 bg-gray-200 animate-pulse rounded" />
    <div className="absolute bottom-0 left-0 right-0 p-2 bg-white">
      <div className="h-4 w-32 mb-1 bg-gray-200 animate-pulse rounded" />
      <div className="h-3 w-24 bg-gray-200 animate-pulse rounded" />
    </div>
  </div>
);

const ErrorItemCard = () => (
  <div className="w-48 border border-dashed h-32 rounded flex items-center justify-center">
    <div className="flex gap-2 items-center text-gray-400">
      <AlertCircle className="w-5 h-5" />
      <span className="text-sm">Error loading asset</span>
    </div>
  </div>
);

export default function RecentItems() {
  const { data: allRecentItems, isLoading, error } = useRecentAssets();

  // Helper function to get the creation date consistently
  const getItemDate = (item: RecentItem) => item.createdDate;

  const renderItemCard = (item: RecentItem) => (
    <div
      key={item.id}
      className={`w-48 bg-gray-100 h-32 border rounded relative group  transition-colors ${
        isDocument(item)
          ? 'bg-primary/10 text-fluency-600/50'
          : 'bg-purple-500/10 text-purple-600/40'
      }`}
    >
      <Badge
        variant="outline"
        className="absolute top-2 left-2 z-10 bg-white border-black/5"
      >
        {isDocument(item) ? 'Document' : 'Workflow'}
      </Badge>
      <div className="absolute inset-0 flex items-center justify-center">
        {isDocument(item) ? (
          <FileText className="w-20 h-20 ml-20 mb-6" strokeWidth={1.5} />
        ) : (
          <WorkflowIcon className="w-20 h-20 ml-20 mb-6" strokeWidth={1.5} />
        )}
      </div>
      <div className="absolute bottom-0 left-0 right-0 p-2 bg-white backdrop-blur-sm">
        <p className="font-medium text-sm truncate text-gray-700">
          {item.name}
        </p>
        <p className="text-xs text-gray-500">
          {new Date(getItemDate(item)).toLocaleDateString()}
        </p>
      </div>
      <Link
        to={isDocument(item) ? '/document/$id' : '/workflow/$id'}
        params={{ id: item.id }}
        className="absolute inset-0 z-20"
      >
        <span className="sr-only">View</span>
      </Link>
    </div>
  );

  const renderContent = () => {
    if (isLoading) {
      return Array(3)
        .fill(null)
        .map((_, index) => <LoadingItemCard key={`loading-${index}`} />);
    }

    if (error) {
      return Array(3)
        .fill(null)
        .map((_, index) => <ErrorItemCard key={`error-${index}`} />);
    }

    // Create array of 3 slots, filling empty slots with null
    const itemSlots = Array(3)
      .fill(null)
      .map((_, index) => allRecentItems?.[index] || null);

    return itemSlots.map((item, index) =>
      item ? renderItemCard(item) : <EmptyItemCard key={`empty-${index}`} />
    );
  };

  return (
    <div className="flex flex-col p-4 rounded font-medium text-gray-500 gap-2">
      <span>Recent assets</span>
      <div className="flex gap-4">{renderContent()}</div>
    </div>
  );
}
