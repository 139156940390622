export const convertToPng = (file: File): Promise<File> =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const img = new Image();
          img.onload = () => {
            try {
              const canvas = document.createElement('canvas');
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext('2d');
              if (ctx) {
                ctx.drawImage(img, 0, 0);
                canvas.toBlob((blob) => {
                  if (blob) {
                    const pngFile = new File([blob], `${file.name}.png`, {
                      type: 'image/png',
                    });
                    resolve(pngFile);
                  } else {
                    reject(new Error('Conversion to PNG failed'));
                  }
                }, 'image/png');
              } else {
                reject(new Error('Canvas context is not available'));
              }
            } catch (error) {
              reject(error);
            }
          };
          img.src = e.target?.result as string;
        } catch (error) {
          reject(error);
        }
      };
      reader.readAsDataURL(file);
    } catch (error) {
      reject(error);
    }
  });
