import React from 'react';
import { CircleIcon } from '@radix-ui/react-icons';
import { ScreenshotType } from '@fluency/ui/hooks/documentation/types/master';

interface ReadAnnotationOverlayProps {
  screenshot: ScreenshotType;
}

export const ReadAnnotationOverlay: React.FC<ReadAnnotationOverlayProps> = ({
  screenshot,
}) => {
  if (!screenshot.annotations.length || !screenshot.annotation) return null;

  const renderShape = (annotation: ScreenshotType['annotations'][0]) => {
    const coords = {
      x1: annotation.x1Coordinate,
      y1: annotation.y1Coordinate,
      x2: annotation.x2Coordinate ?? annotation.x1Coordinate + 20,
      y2: annotation.y2Coordinate ?? annotation.y1Coordinate + 20,
    };

    const { x1, y1, x2, y2 } = coords;

    switch (annotation.shapeType) {
      case 'CIRCLE':
        return (
          <CircleIcon
            className="absolute w-7 h-7"
            style={{
              left: `${x1}%`,
              top: `${y1}%`,
              transform: 'translate(-50%, -50%)',
              color: annotation.color,
              pointerEvents: 'none',
            }}
          />
        );

      case 'LINE':
      case 'ARROW': {
        const id = `read-arrowhead-${annotation.screenshotAnnotationId}`;

        return (
          <svg
            width="100%"
            height="100%"
            className="absolute inset-0"
            style={{ overflow: 'visible', pointerEvents: 'none' }}
          >
            {annotation.shapeType === 'ARROW' && (
              <defs>
                <marker
                  id={id}
                  markerWidth="10"
                  markerHeight="7"
                  refX="9"
                  refY="3.5"
                  orient="auto"
                >
                  <polygon points="0 0, 10 3.5, 0 7" fill={annotation.color} />
                </marker>
              </defs>
            )}
            <line
              x1={`${x1}%`}
              y1={`${y1}%`}
              x2={`${x2}%`}
              y2={`${y2}%`}
              stroke={annotation.color}
              strokeWidth="2"
              markerEnd={
                annotation.shapeType === 'ARROW' ? `url(#${id})` : undefined
              }
              vectorEffect="non-scaling-stroke"
            />
          </svg>
        );
      }

      case 'RECTANGLE': {
        const width = Math.abs(x2 - x1);
        const height = Math.abs(y2 - y1);
        const left = Math.min(x1, x2);
        const top = Math.min(y1, y2);

        return (
          <div
            className="absolute border-2"
            style={{
              left: `${left}%`,
              top: `${top}%`,
              width: `${width}%`,
              height: `${height}%`,
              borderColor: annotation.color,
              pointerEvents: 'none',
              zIndex: 10,
            }}
          />
        );
      }

      default:
        return null;
    }
  };

  return (
    <div className="absolute inset-0 pointer-events-none">
      {screenshot.annotations.map((annotation) => (
        <React.Fragment key={annotation.screenshotAnnotationId}>
          {renderShape(annotation)}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ReadAnnotationOverlay;
