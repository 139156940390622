import React from 'react';
import type { Documentation } from '@fluency/ui/hooks/documentation/types/GetDocs';
import { FileText, Grip } from 'lucide-react';

interface DocumentItemProps {
  doc: Documentation;
  searchTerm: string;
}

const DocumentItem: React.FC<DocumentItemProps> = ({ doc, searchTerm }) => {
  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('application/reactflow', 'documentNode');
    event.dataTransfer.setData('application/documentId', doc.documentationId);
    event.dataTransfer.setData(
      'application/documentName',
      doc.documentationName
    );
    event.dataTransfer.setData('application/vaultId', doc.vaultId);
    event.dataTransfer.setData('application/vaultName', doc.vault.name);
    event.dataTransfer.setData(
      'application/totalSteps',
      (doc.totalSteps || 0).toString()
    );
    event.dataTransfer.setData('application/createdBy', doc.createdBy || '');
    event.dataTransfer.effectAllowed = 'move';
  };

  const highlightSearchTerm = (text: string, term: string) => {
    if (!term.trim()) return text;

    const regex = new RegExp(`(${term})`, 'gi');
    return text
      .split(regex)
      .map((part, index) =>
        regex.test(part) ? <mark key={index}>{part}</mark> : part
      );
  };

  return (
    <div
      className="cursor-pointer mb-2 p-2 border rounded flex justify-between items-center hover:cursor-grab mr-3  hover:bg-accent"
      draggable
      onDragStart={onDragStart}
    >
      <div className="flex items-center text-sm">
        <FileText className="mr-2 text-gray-400 w-5" strokeWidth={1.5} />
        <div className="flex flex-col items-start truncate max-w-60 w-60">
          <p className="font-semibold truncate max-w-60">
            {highlightSearchTerm(doc.documentationName, searchTerm)}
          </p>
          <p className="text-xs italic text-gray-600  truncate max-w-60">
            {doc.description}
          </p>
        </div>
      </div>
      <Grip className="w-4 text-gray-500" />
    </div>
  );
};

export default DocumentItem;
