import { Building2, Globe } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';

interface Resource {
  isPublic: boolean;
  isGlobal: boolean;
  isLocked: boolean;
}

const ResourceStatusIcons = ({ resource }: { resource: Resource }) => {
  const activeIconClass = 'h-5 w-5 text-fluency-600';

  return (
    <TooltipProvider>
      <div className="flex gap-4 justify-end items-center h-full">
        {resource.isPublic && (
          <Tooltip delayDuration={100}>
            <TooltipTrigger asChild>
              <div>
                <Globe className={activeIconClass} />
              </div>
            </TooltipTrigger>
            <TooltipContent side="top">
              <p>Public sharing enabled</p>
            </TooltipContent>
          </Tooltip>
        )}

        {resource.isGlobal && (
          <Tooltip delayDuration={100}>
            <TooltipTrigger asChild>
              <div>
                <Building2 className={activeIconClass} />
              </div>
            </TooltipTrigger>
            <TooltipContent side="top">
              <p>Organisation-wide resource</p>
            </TooltipContent>
          </Tooltip>
        )}
      </div>
    </TooltipProvider>
  );
};

export default ResourceStatusIcons;
