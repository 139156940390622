export interface Screenshot {
  downloadUrl: string;
}

export interface Step {
  stepId: string;
  stepNumber: number;
  stepDescription: string;
  stepType: keyof typeof stepTypeColors;
  screenshots: Screenshot[];
  linkedDocumentation: string;
  screenshotKey: string;
  xCoordinate: number | null;
  yCoordinate: number | null;
  width: number | null;
  height: number | null;
  zoom?: number;
}

export interface SummaryProps {
  documentId: string;
  handleJumpToStep: (stepId: string) => void;
}

export const stepTypeColors = {
  ERROR: 'bg-red-100',
  WARNING: 'bg-yellow-100',
  INFO: 'bg-blue-100',
  NOTE: 'bg-yellow-50',
  TIP: 'bg-teal-100',
  EXAMPLE: 'bg-purple-100',
  CODE: 'bg-gray-100',
  IMAGE: 'bg-gray-100',
  SUCCESS: 'bg-green-100',
  HEADLINE: 'bg-slate-100',
  SUBHEADLINE: 'bg-slate-100',
  STEP: 'bg-white',
} as const;
