import { Button } from '@fluency/ui/components/ui/button';
import { useToast } from '@fluency/ui/components/ui/use-toast';
import { Trash } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@fluency/ui/components/ui/alert-dialog';
import { useVaultOperations, Vault } from '../hooks/useVaultOperations';

const ManageVaultsPage = ({ vault }: { vault: Vault }) => {
  const { useDeleteVault } = useVaultOperations();
  const deleteVaultMutation = useDeleteVault();
  const { toast } = useToast();

  const handleDeleteVault = async (vaultId: string) => {
    try {
      await deleteVaultMutation.mutateAsync(vaultId);
      toast({
        title: 'Success',
        description: 'Vault deleted successfully',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete vault',
        variant: 'destructive',
      });
    }
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          variant="destructive"
          size="sm"
          disabled={vault.isGlobal}
          className="gap-2 bg-gray-200 text-gray-900 hover:text-white"
        >
          Delete Vault
          <Trash className="h-4 w-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete this
            vault and all its contents.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={() => handleDeleteVault(vault.id)}
            className="bg-red-600 hover:bg-red-700"
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ManageVaultsPage;
