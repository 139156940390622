import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

interface Invoice {
  id: string;
  amountDue: number;
  amountPaid: number;
  status: string;
  created: string;
  pdfLink: string;
  dueDate: string | null;
}

interface InvoicesResponse {
  invoices: Invoice[];
  hasMore: boolean;
}

interface InvoicesQueryParams {
  limit?: number;
  startingAfter?: string;
}

export const useInvoices = (params: InvoicesQueryParams = { limit: 10 }) => {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const workOSContext = useAuth();

  const invoicesQuery = useQuery<InvoicesResponse, Error>({
    queryKey: ['invoices', params],
    queryFn: async (): Promise<InvoicesResponse> => {
      if (!workOSContext || !workOSContext.accessToken) {
        Sentry.captureException('Access token is missing');
        throw new Error('Access token is missing');
      }

      const queryParams = new URLSearchParams({
        limit: params.limit?.toString() || '10',
      });

      if (params.startingAfter) {
        queryParams.append('startingAfter', params.startingAfter);
      }

      const response = await fetch(
        `${apiBaseUrl}/billing/invoices?${queryParams}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${workOSContext.accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        Sentry.captureException('Failed to fetch invoices');
        throw new Error(`Failed to fetch invoices: ${response.statusText}`);
      }

      const data: InvoicesResponse = await response.json();
      return data;
    },
    enabled: !!workOSContext?.accessToken,
  });

  return {
    invoices: invoicesQuery.data?.invoices,
    hasMore: invoicesQuery.data?.hasMore,
    isLoading: invoicesQuery.isLoading,
    error: invoicesQuery.error,
    refetch: invoicesQuery.refetch,
  };
};

export default useInvoices;
