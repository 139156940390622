import { FileIcon } from 'lucide-react';
import { Button } from '@fluency/ui/components/ui/button';

interface EmptyStateProps {
  onGetStarted: () => void;
}

export const EmptyState: React.FC<EmptyStateProps> = ({ onGetStarted }) => (
  <div className="flex flex-col items-center justify-center h-64 border rounded-lg bg-background p-6">
    <FileIcon className="h-8 w-8 mb-2 text-muted-foreground" />
    <h2 className="font-semibold mb-2">No items found</h2>
    <p className="text-muted-foreground mb-2 max-w-sm text-center text-sm">
      Create your first document or workflow to get started
    </p>
    <Button variant="link" onClick={onGetStarted}>
      How do I get started?
    </Button>
  </div>
);

export default EmptyState;
