import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from '@fluency/ui/components/ui/card';

export const SkeletonCard = () => (
  <Card className="w-[16rem] flex flex-col justify-between py-1 border-0 opacity-50">
    <div>
      <CardHeader className="pb-8 pt-4">
        <div className="h-6 w-24 animate-pulse bg-gray-200 rounded-md" />
        <div className="h-8 w-32 mt-2 animate-pulse bg-gray-200 rounded-md" />
      </CardHeader>
      <CardContent className="flex flex-col gap-2">
        {[1, 2, 3, 4, 5].map((i) => (
          <div
            key={i}
            className="h-5 w-full animate-pulse bg-gray-200 rounded-md"
          />
        ))}
      </CardContent>
    </div>
    <CardFooter className="mt-2 flex flex-col items-start w-full">
      <div className="h-10 w-full mt-4 animate-pulse bg-gray-200 rounded-md" />
    </CardFooter>
  </Card>
);

export default SkeletonCard;
