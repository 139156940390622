import React, { useState, useEffect, useCallback, KeyboardEvent } from 'react';
import { Textarea } from '@fluency/ui/components/ui/textarea';
import { Button } from '@fluency/ui/components/ui/button';
import { EditableComponentProps, useClickOutside } from './shared';

export const EditableDescription: React.FC<EditableComponentProps> = ({
  initialValue,
  isEditMode,
  onSave,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [editValue, setEditValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
    setEditValue(initialValue);
  }, [initialValue]);

  const handleStartEdit = () => {
    if (isEditMode) {
      setIsEditing(true);
      setEditValue(value);
    }
  };

  const handleSave = useCallback(() => {
    setIsEditing(false);
    setValue(editValue);
    onSave(editValue);
  }, [editValue, onSave]);

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    setEditValue(value);
  }, [value]);

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSave();
    } else if (e.key === 'Escape') {
      handleCancel();
    }
  };

  // Now using handleSave instead of handleCancel for click outside
  const ref = useClickOutside(handleSave);

  if (isEditing) {
    return (
      <div ref={ref} className="flex items-start mt-2 mb-8 mx-6">
        <Textarea
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          onKeyDown={handleKeyDown}
          className="text-sm text-gray-600 w-full max-w-[calc(100%-6rem)] mr-2"
          rows={2}
          autoFocus
          placeholder="Add a description..."
        />
        <Button size="sm" onClick={handleSave}>
          Save
        </Button>
      </div>
    );
  }

  return (
    <div className="flex mt-2 mb-8 mx-6">
      <p
        className={`text-sm text-gray-500 w-full ${
          isEditMode
            ? 'cursor-pointer hover:bg-gray-100 transition-colors duration-200 -m-1 p-1 rounded'
            : ''
        } ${!value.trim() ? 'italic' : ''}`}
        onClick={handleStartEdit}
      >
        {value.trim() || 'Add a description...'}
      </p>
    </div>
  );
};
