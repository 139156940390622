import { createFileRoute } from '@tanstack/react-router';
import Subscription from '@fluency/ui/features/Subscription';
import { RolePermissionHandler } from '@fluency/ui/features/AuthGuard/RolePermissionHandler';
import NoSubscriptionMessage from '@fluency/ui/components/NoSubscriptionMessage';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { Logger } from '@fluency/ui/features/Logger';

export const Route = createFileRoute('/subscription')({
  component: SubscriptionWrapper,
});

function SubscriptionWrapper() {
  const { permissions } = useAuth();

  Logger.log(`[/subscription] ${permissions}`);

  if (permissions.includes('subscription:update')) {
    return (
      <RolePermissionHandler requiredPermissions={['subscription:update']}>
        <Subscription />
      </RolePermissionHandler>
    );
  } else {
    return <NoSubscriptionMessage />;
  }
}
