import React, { ReactNode } from 'react';
import { usePermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { useNavigate } from '@tanstack/react-router';
import { Logger } from '@fluency/ui/features/Logger';

interface RolePermissionHandlerProps {
  requiredPermissions: string[];
  children: ReactNode;
}

export const RolePermissionHandler: React.FC<RolePermissionHandlerProps> = ({
  requiredPermissions,
  children,
}) => {
  const { hasPermissions } = usePermissionGate();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!hasPermissions(requiredPermissions)) {
      Logger.log('Required permissions: ', requiredPermissions);
      Logger.log('Insufficient permissions, navigating to unauthorized');
      navigate({ to: '/unauthorized', replace: true });
    }
  }, [hasPermissions, requiredPermissions, navigate]);

  return <>{children}</>;
};
