import React, { useState, useEffect, useCallback, KeyboardEvent } from 'react';
import { Input } from '@fluency/ui/components/ui/input';
import { Button } from '@fluency/ui/components/ui/button';
import { EditableComponentProps, useClickOutside } from './shared';

export const EditableTitle: React.FC<EditableComponentProps> = ({
  initialValue,
  isEditMode,
  onSave,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [editValue, setEditValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
    setEditValue(initialValue);
  }, [initialValue]);

  const handleStartEdit = () => {
    if (isEditMode) {
      setIsEditing(true);
      setEditValue(value);
    }
  };

  const handleSave = useCallback(() => {
    if (editValue.trim() === '') {
      return; // Prevent saving empty values
    }
    setIsEditing(false);
    setValue(editValue);
    onSave(editValue);
  }, [editValue, onSave]);

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    setEditValue(value);
  }, [value]);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSave();
    } else if (e.key === 'Escape') {
      handleCancel();
    }
  };

  // Now using handleSave instead of handleCancel for click outside
  const ref = useClickOutside(handleSave);

  if (isEditing) {
    return (
      <div ref={ref} className="flex items-center mt-2">
        <Input
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          onKeyDown={handleKeyDown}
          className="text-xl font-semibold w-96 mr-2"
          autoFocus
        />
        <Button size="sm" onClick={handleSave}>
          Save
        </Button>
      </div>
    );
  }

  return (
    <h1
      className={`text-xl font-semibold truncate max-w-1/2 ${
        isEditMode
          ? 'cursor-pointer hover:bg-gray-100 -m-1 p-1 rounded transition-colors duration-200'
          : ''
      }`}
      onClick={handleStartEdit}
    >
      {value}
    </h1>
  );
};
