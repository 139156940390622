import { useToast } from '@fluency/ui/components/ui/use-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  DocumentationResponse,
  ScreenshotType,
  Step,
} from '../../../hooks/documentation/types/master';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { Logger } from '@fluency/ui/features/Logger';

interface CropCoordinates {
  x1Coordinate: number | undefined;
  y1Coordinate: number | undefined;
  x2Coordinate: number | undefined;
  y2Coordinate: number | undefined;
}

interface ScreenshotCropUpdate {
  screenshotId: string;
  cropInfo: CropCoordinates;
  isCropped: boolean;
}

interface UpdateScreenshotCropProps {
  screenshotCropUpdates: ScreenshotCropUpdate[];
}

interface UseScreenshotCropProps {
  documentationId: string;
  onMutate?: () => void;
}

async function updateScreenshotCrop(
  screenshotCropUpdates: ScreenshotCropUpdate[],
  accessToken: string
): Promise<void> {
  const endpoint = `/screenshot/toggleCrop`;
  const apiUrl = import.meta.env.VITE_SERVER_API_URL;
  const fullUrl = apiUrl + endpoint;

  const response = await fetch(fullUrl, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      screenshotCropUpdates: screenshotCropUpdates.map((update) => ({
        ...update,
        cropInfo: {
          x1Coordinate: update.cropInfo.x1Coordinate ?? null,
          y1Coordinate: update.cropInfo.y1Coordinate ?? null,
          x2Coordinate: update.cropInfo.x2Coordinate ?? null,
          y2Coordinate: update.cropInfo.y2Coordinate ?? null,
        },
      })),
    }),
  });

  if (!response.ok) {
    const body = await response.json();
    Logger.error('Error updating screenshot crop', body);
    throw new Error(`HTTP error! status: ${response.status}`);
  }
}

const useScreenshotCrop = ({
  documentationId,
  onMutate: onMutateCallback,
}: UseScreenshotCropProps) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation<
    void,
    Error,
    UpdateScreenshotCropProps,
    { previousDocInfo: DocumentationResponse | undefined }
  >({
    mutationFn: ({ screenshotCropUpdates }) => {
      if (!accessToken) {
        throw new Error('Access token is missing');
      }
      return updateScreenshotCrop(screenshotCropUpdates, accessToken);
    },
    mutationKey: ['screenshot-crop'],
    onMutate: async (variables) => {
      // Call the callback if provided
      onMutateCallback?.();

      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey: ['docInfo', documentationId],
      });

      // Snapshot the previous value
      const previousDocInfo = queryClient.getQueryData<DocumentationResponse>([
        'docInfo',
        documentationId,
      ]);

      if (previousDocInfo) {
        const updateCache = (oldData: DocumentationResponse | undefined) => {
          if (!oldData) return oldData;

          const updatedSteps = oldData.documentation.steps.map(
            (step: Step) => ({
              ...step,
              screenshots: step.screenshots.map(
                (screenshot: ScreenshotType) => {
                  const update = variables.screenshotCropUpdates.find(
                    (s) => s.screenshotId === screenshot.screenshotId
                  );
                  if (update) {
                    return {
                      ...screenshot,
                      x1Coordinate: update.cropInfo.x1Coordinate ?? null,
                      y1Coordinate: update.cropInfo.y1Coordinate ?? null,
                      x2Coordinate: update.cropInfo.x2Coordinate ?? null,
                      y2Coordinate: update.cropInfo.y2Coordinate ?? null,
                      isCropped: update.isCropped,
                    } as ScreenshotType;
                  }
                  return screenshot;
                }
              ),
            })
          );

          return {
            ...oldData,
            documentation: {
              ...oldData.documentation,
              steps: updatedSteps,
            },
          };
        };

        queryClient.setQueryData<DocumentationResponse>(
          ['docInfo', documentationId],
          (oldData) => updateCache(oldData)
        );
      }

      return { previousDocInfo };
    },
    onError: (err, _newCrop, context) => {
      if (context?.previousDocInfo) {
        queryClient.setQueryData(
          ['docInfo', documentationId],
          context.previousDocInfo
        );
      }

      Logger.error('Error updating screenshot crop', err);
      toast({
        title: 'Error',
        description: 'Failed to update screenshot crop. Please try again.',
        variant: 'destructive',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['docInfo', documentationId] });
    },
  });
};

export default useScreenshotCrop;
