import { useEffect, useRef, useState } from 'react';

interface Screenshot {
  downloadUrl: string;
  x1Coordinate: number | null;
  y1Coordinate: number | null;
  x2Coordinate: number | null;
  y2Coordinate: number | null;
  isCropped: boolean;
  annotation: boolean;
  annotations?: AnnotationType[];
}

interface AnnotationType {
  screenshotAnnotationId: string;
  shapeType: 'CIRCLE' | 'RECTANGLE' | 'LINE' | 'ARROW';
  x1Coordinate: number;
  y1Coordinate: number;
  x2Coordinate: number | null;
  y2Coordinate: number | null;
  annotationIndex?: number | null;
  color: string;
}

interface PublicScreenshotViewerProps {
  screenshot: Screenshot;
}

const PublicScreenshotViewer = ({
  screenshot,
}: PublicScreenshotViewerProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [naturalSize, setNaturalSize] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const [image] = useState(() => new Image());
  const [imageLoadFailed, setImageLoadFailed] = useState(false);

  const cropData = {
    x1: screenshot.x1Coordinate,
    y1: screenshot.y1Coordinate,
    x2: screenshot.x2Coordinate,
    y2: screenshot.y2Coordinate,
  };

  const hasCropData =
    cropData.x1 !== null &&
    cropData.y1 !== null &&
    cropData.x2 !== null &&
    cropData.y2 !== null &&
    screenshot.isCropped;

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !hasCropData) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    image.src = screenshot.downloadUrl;
    image.onload = () => {
      const cropX = (cropData.x1! / 100) * image.naturalWidth;
      const cropY = (cropData.y1! / 100) * image.naturalHeight;
      const cropWidth =
        ((cropData.x2! - cropData.x1!) / 100) * image.naturalWidth;
      const cropHeight =
        ((cropData.y2! - cropData.y1!) / 100) * image.naturalHeight;

      canvas.width = cropWidth;
      canvas.height = cropHeight;
      setNaturalSize({ width: cropWidth, height: cropHeight });

      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );
    };

    image.onerror = () => setImageLoadFailed(true);

    return () => {
      image.onload = null;
      image.onerror = null;
    };
  }, [screenshot.downloadUrl, cropData, image, hasCropData]);

  if (imageLoadFailed) return null;

  return (
    <div className="relative flex items-start w-fit pb-6">
      <div className="flex-1 flex justify-center overflow-hidden rounded-lg print:mt-0">
        <div
          className="relative flex justify-center items-center transition-all duration-300 ease-in-out"
          ref={containerRef}
          style={{ width: '100%', height: '100%' }}
        >
          <div className="relative transition-transform duration-300 ease-in-out">
            {hasCropData ? (
              <div className="relative">
                <canvas
                  ref={canvasRef}
                  className="h-auto cursor-pointer transition-all duration-300 ease-in-out"
                  style={{
                    display: 'block',
                    maxWidth: naturalSize ? `${naturalSize.width}px` : '100%',
                    width: '100%',
                    transition: 'all 0.3s ease-in-out',
                  }}
                />
                {screenshot.annotation && screenshot.annotations && (
                  <Annotation
                    annotations={screenshot.annotations}
                    annotationVisible={screenshot.annotation}
                    cropData={cropData}
                  />
                )}
              </div>
            ) : (
              <>
                <img
                  className="w-full h-auto object-cover rounded"
                  src={screenshot.downloadUrl}
                  alt="Screenshot"
                  onLoad={(e) => {
                    const img = e.target as HTMLImageElement;
                    setNaturalSize({
                      width: img.naturalWidth,
                      height: img.naturalHeight,
                    });
                  }}
                  onError={() => setImageLoadFailed(true)}
                />
                {screenshot.annotation && screenshot.annotations && (
                  <Annotation
                    annotations={screenshot.annotations}
                    annotationVisible={screenshot.annotation}
                    cropData={{
                      x1: screenshot.x1Coordinate || 0,
                      x2: screenshot.x2Coordinate || 100,
                      y1: screenshot.y1Coordinate || 0,
                      y2: screenshot.y2Coordinate || 100,
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface AnnotationsProps {
  annotations: AnnotationType[];
  annotationVisible: boolean;
  cropData: {
    x1: number | null;
    y1: number | null;
    x2: number | null;
    y2: number | null;
  };
}

const Annotation = ({
  annotations,
  annotationVisible,
  cropData,
}: AnnotationsProps) => {
  if (!annotationVisible) return null;

  const adjustCoordinate = (coord: number, isX: boolean): number => {
    if (!cropData.x1 || !cropData.y1 || !cropData.x2 || !cropData.y2) {
      return coord;
    }

    const cropStart = isX ? cropData.x1 : cropData.y1;
    const cropEnd = isX ? cropData.x2 : cropData.y2;
    const cropSize = cropEnd - cropStart;

    return ((coord - cropStart) / cropSize) * 100;
  };

  const validAnnotations = annotations.filter((annotation) => {
    if (annotation.x1Coordinate === 0 && annotation.y1Coordinate === 0) {
      return false;
    }

    if (
      cropData.x1 !== null &&
      cropData.y1 !== null &&
      cropData.x2 !== null &&
      cropData.y2 !== null
    ) {
      const isStartInBounds =
        annotation.x1Coordinate >= cropData.x1 &&
        annotation.x1Coordinate <= cropData.x2 &&
        annotation.y1Coordinate >= cropData.y1 &&
        annotation.y1Coordinate <= cropData.y2;

      if (
        annotation.x2Coordinate !== null &&
        annotation.y2Coordinate !== null
      ) {
        const isEndInBounds =
          annotation.x2Coordinate >= cropData.x1 &&
          annotation.x2Coordinate <= cropData.x2 &&
          annotation.y2Coordinate >= cropData.y1 &&
          annotation.y2Coordinate <= cropData.y2;
        return isStartInBounds || isEndInBounds;
      }

      return isStartInBounds;
    }

    return true;
  });

  if (validAnnotations.length === 0) return null;

  const renderShape = (annotation: AnnotationType, index: number) => {
    const color = annotation.color || 'red';

    if (
      annotation.shapeType !== 'CIRCLE' &&
      (annotation.x2Coordinate === null || annotation.y2Coordinate === null)
    ) {
      return null;
    }

    switch (annotation.shapeType) {
      case 'CIRCLE':
        return (
          <div
            className="absolute"
            style={{
              left: `${adjustCoordinate(annotation.x1Coordinate, true)}%`,
              top: `${adjustCoordinate(annotation.y1Coordinate, false)}%`,
              transform: 'translate(-50%, -50%)',
              overflow: 'visible',
            }}
          >
            <svg
              width="30"
              height="30"
              className="overflow-visible"
              style={{ overflow: 'visible' }}
            >
              <circle
                cx="15"
                cy="15"
                r="15"
                fill={`${color}26`}
                stroke={color}
                strokeWidth="2"
              />
              {validAnnotations.length > 1 && (
                <text
                  x="15"
                  y="15"
                  fill={color}
                  className="select-none pointer-events-none"
                  style={{
                    fontSize: '14px',
                    textAnchor: 'middle',
                    dominantBaseline: 'middle',
                  }}
                >
                  {index + 1}
                </text>
              )}
            </svg>
          </div>
        );

      case 'RECTANGLE': {
        const adjustedX1 = adjustCoordinate(annotation.x1Coordinate, true);
        const adjustedY1 = adjustCoordinate(annotation.y1Coordinate, false);
        const adjustedX2 = adjustCoordinate(annotation.x2Coordinate!, true);
        const adjustedY2 = adjustCoordinate(annotation.y2Coordinate!, false);

        const width = Math.abs(adjustedX2 - adjustedX1);
        const height = Math.abs(adjustedY2 - adjustedY1);
        const left = Math.min(adjustedX1, adjustedX2);
        const top = Math.min(adjustedY1, adjustedY2);

        return (
          <div
            className="absolute border-2"
            style={{
              left: `${left}%`,
              top: `${top}%`,
              width: `${width}%`,
              height: `${height}%`,
              borderColor: color,
            }}
          />
        );
      }

      case 'LINE':
      case 'ARROW': {
        const adjustedX1 = adjustCoordinate(annotation.x1Coordinate, true);
        const adjustedY1 = adjustCoordinate(annotation.y1Coordinate, false);
        const adjustedX2 = adjustCoordinate(annotation.x2Coordinate!, true);
        const adjustedY2 = adjustCoordinate(annotation.y2Coordinate!, false);

        const id = `annotation-arrow-${annotation.screenshotAnnotationId}`;
        return (
          <svg className="absolute inset-0 w-full h-full overflow-visible">
            {annotation.shapeType === 'ARROW' && (
              <defs>
                <marker
                  id={id}
                  markerWidth="10"
                  markerHeight="7"
                  refX="9"
                  refY="3.5"
                  orient="auto"
                >
                  <polygon points="0 0, 10 3.5, 0 7" fill={color} />
                </marker>
              </defs>
            )}
            <line
              x1={`${adjustedX1}%`}
              y1={`${adjustedY1}%`}
              x2={`${adjustedX2}%`}
              y2={`${adjustedY2}%`}
              stroke={color}
              strokeWidth="2"
              markerEnd={
                annotation.shapeType === 'ARROW' ? `url(#${id})` : undefined
              }
              vectorEffect="non-scaling-stroke"
            />
          </svg>
        );
      }

      default:
        return null;
    }
  };

  return (
    <div className="absolute inset-0 pointer-events-none">
      {validAnnotations.map((annotation, index) => (
        <div
          key={annotation.screenshotAnnotationId}
          className="pointer-events-none"
        >
          {renderShape(annotation, index)}
        </div>
      ))}
    </div>
  );
};

export default PublicScreenshotViewer;
