import { Badge } from '@fluency/ui/components/ui/badge';
import { SearchResult } from '../types/search';
import { useNavigate } from '@tanstack/react-router';
import {
  getResultIcon,
  getResultStyles,
  highlightMatch,
  getResourceLabel,
} from '../utils/searchUtils';
import { Logger } from '@fluency/ui/features/Logger';

type SearchResultItemProps = {
  result: SearchResult;
  query: string;
};

const SearchResultItem = ({ result, query }: SearchResultItemProps) => {
  const navigate = useNavigate();
  const styles = getResultStyles(result.preview.matchField);

  const handleResultClick = () => {
    const { resource, matchField } = result.preview;
    Logger.log(`Navigating to ${resource} with matchField ${matchField}`);

    // Determine the navigation path based on the resource type
    if (resource === 'DOC' || resource === 'STEP') {
      navigate({
        to: '/document/$id',
        params: { id: result.docId },
        replace: true,
      });
    } else if (resource === 'workflow') {
      navigate({
        to: '/workflow/$id',
        params: { id: result.docId },
        replace: true,
      });
    } else if (resource === 'VAULT') {
      navigate({
        to: '/vault/$id',
        params: { id: result.docId },
        replace: true,
      });
    }
  };

  const renderMatch = () => {
    if (result.preview.matchField === 'stepDescription') {
      return (
        <div className={`mt-2 text-sm ${styles.match}`}>
          {highlightMatch(result.preview.match, query)}
        </div>
      );
    }
    return (
      <div className={`mt-2 text-sm ${styles.match}`}>
        {result.preview.match}
      </div>
    );
  };

  return (
    <div
      className={`p-4 rounded-lg border max-w-3xl ${styles.container} cursor-pointer hover:bg-gray-50 transition-colors duration-200`}
      onClick={handleResultClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleResultClick();
        }
      }}
    >
      <div className="flex items-start gap-3">
        {getResultIcon(result.preview.matchField)}
        <div className="flex-1">
          <div className="flex flex-wrap items-center justify-between gap-2">
            <h3 className={`font-semibold ${styles.title}`}>
              {result.docName}
            </h3>
            <div className="flex gap-2">
              <Badge variant="outline" className="text-xs">
                {getResourceLabel(result.preview.resource)}
              </Badge>
            </div>
          </div>
          <p className={`text-sm mt-1 ${styles.description}`}>
            {result.docDescription}
          </p>
          {result.preview.match && renderMatch()}
        </div>
      </div>
    </div>
  );
};

export default SearchResultItem;
