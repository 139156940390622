import { Textarea } from '@fluency/ui/components/ui/textarea';
import { useToast } from '@fluency/ui/components/ui/use-toast';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { Send } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as zod from 'zod';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';

const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
const feedbackSchema = zod.object({
  feedback: zod.string(),
});

type FeedbackFormData = zod.infer<typeof feedbackSchema>;

const SubmitFeedback = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FeedbackFormData>({
    resolver: zodResolver(feedbackSchema),
  });

  const { toast } = useToast();
  const { accessToken } = useAuth();
  const [isDialogOpen, setDialogOpen] = useState(false);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (data: FeedbackFormData) => {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
      myHeaders.append('Authorization', `Bearer ${accessToken}`);

      const urlencoded = new URLSearchParams();
      urlencoded.append('feedback', data.feedback);

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow' as RequestRedirect,
      };

      const response = await fetch(
        `${apiBaseUrl}/user/provideFeedback`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    },
    onSuccess: () => {
      toast({
        title: 'Feedback sent',
        description: 'Thank you, we will be in touch shortly.',
      });
      reset();
      setDialogOpen(false);
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Something went wrong.',
        description: 'Please try again later.',
      });
    },
  });

  const onSubmit = async (data: FeedbackFormData) => {
    await mutateAsync(data);
  };

  return (
    <Dialog
      open={isDialogOpen}
      onOpenChange={(openState) => {
        setDialogOpen(openState);
        reset();
      }}
    >
      <DialogTrigger
        onClick={() => setDialogOpen(true)}
        className="w-full flex gap-2 text-sm items-center px-2 hover:bg-muted/60 py-1.5 rounded justify-between"
      >
        Feedback <Send className="h-4 w-4" />
      </DialogTrigger>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader>
            <DialogTitle className="mb-4">Send feedback</DialogTitle>
            <DialogDescription>
              <span className="block mb-4">
                Fluency is dedicated to ongoing improvement and values your
                feedback.
              </span>

              <span className="block mb-4">
                Please use the form below to report any bugs, suggest features,
                or share general comments to help us enhance your experience.
              </span>
            </DialogDescription>

            <Textarea
              {...register('feedback')}
              id="feedback"
              placeholder="Enter your feedback here"
              required
            />
            {errors.feedback && (
              <p className="text-red-500">{errors.feedback.message}</p>
            )}
          </DialogHeader>
          <DialogFooter>
            <Button className="mt-3" type="submit" disabled={isPending}>
              {isPending ? 'Sending...' : 'Send'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SubmitFeedback;
