import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from '../auth/AuthProvider';
import { FeatureFlags } from './types/FeatureFlag.type';
import { SubscriptionStatus } from '@fluency/ui/hooks/subscription/types/Subscription';
import useSubscription from '@fluency/ui/hooks/subscription/useSubscription';
import { Logger } from '@fluency/ui/features/Logger';

interface FeatureFlagContextType {
  features: FeatureFlags;
  isFeatureEnabled: (key: keyof FeatureFlags) => boolean;
  loading: boolean;
  hasSubscription: boolean;
  subscriptionPlan: string | null;
}

const FeatureFlagContext = createContext<FeatureFlagContextType | undefined>(
  undefined
);

const getFeatureFlags = (
  subscriptionStatus: SubscriptionStatus
): {
  features: FeatureFlags;
  hasSubscription: boolean;
  subscriptionPlan: string | null;
} => {
  if (
    subscriptionStatus.status === 'active' ||
    subscriptionStatus.status === 'trialing'
  ) {
    const initialFeatures: FeatureFlags = {
      'auto-pii': 'false',
      branding: 'false',
    };

    const features =
      subscriptionStatus.subscription?.products
        ?.filter((product) => product.name !== 'Viewer Seats')
        .reduce((acc: FeatureFlags, product) => {
          // Safely typecast product metadata
          const metadata = product.metadata as Record<string, string>;

          return {
            ...acc,
            'auto-pii': metadata['auto-pii'] || acc['auto-pii'],
            branding: metadata['branding'] || acc['branding'],
            // Add any additional custom features
            ...Object.entries(metadata).reduce(
              (metaAcc, [key, value]) => {
                if (key !== 'auto-pii' && key !== 'branding' && value) {
                  metaAcc[key] = value;
                }
                return metaAcc;
              },
              {} as Record<string, string>
            ),
          };
        }, initialFeatures) || initialFeatures;

    return {
      features,
      hasSubscription: true,
      subscriptionPlan: subscriptionStatus.subscription?.plan || null,
    };
  }

  // Return default features when no subscription
  return {
    features: {
      'auto-pii': 'false',
      branding: 'false',
    },
    hasSubscription: false,
    subscriptionPlan: null,
  };
};

// Rest of the code remains the same
export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagProvider'
    );
  }
  return context;
};

export const FeatureFlagProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth();
  const [features, setFeatures] = useState<FeatureFlags>({
    'auto-pii': 'false',
    branding: 'false',
  });
  const [hasSubscription, setHasSubscription] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState<string | null>(null);
  const { subscriptionStatus, isLoadingSubscription: isLoading } =
    useSubscription();

  useEffect(() => {
    if (isAuthenticated && subscriptionStatus) {
      const {
        features: featureFlags,
        hasSubscription: hasActiveSubscription,
        subscriptionPlan: plan,
      } = getFeatureFlags(subscriptionStatus);

      Logger.log('FeatureFlagProvider', {
        featureFlags,
        hasSubscription: hasActiveSubscription,
        subscriptionPlan: plan,
      });

      setFeatures(featureFlags);
      setHasSubscription(hasActiveSubscription);
      setSubscriptionPlan(plan);
    } else {
      setFeatures({
        'auto-pii': 'false',
        branding: 'false',
      });
      setHasSubscription(false);
      setSubscriptionPlan(null);
    }
  }, [isAuthenticated, subscriptionStatus]);

  const isFeatureEnabled = (key: keyof FeatureFlags): boolean => {
    return features[key] === 'true';
  };

  return (
    <FeatureFlagContext.Provider
      value={{
        features,
        isFeatureEnabled,
        loading: isLoading,
        hasSubscription,
        subscriptionPlan,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};
