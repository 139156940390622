import { useParams } from '@tanstack/react-router';
import { Button } from '@fluency/ui/components/ui/button';
import { Lock, PenSquare } from 'lucide-react';
import { EditableTitle } from './settings/EditableTitle';
import { EditableDescription } from './settings/EditableDescription';
import ResourceStatusIcons from '../../Vaults/components/ResourceIconStates';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { FileSettings } from './settings/FileSettings';
import { useResourcePermissions } from '@fluency/ui/hooks/fga/useResourcePermissions';
import { useUpdateWorkflow } from '@fluency/ui/hooks/workflows/useUpdateWorkflow';
import { useGetWorkflow } from '@fluency/ui/hooks/workflows/useGetWorkflow';
import { Logger } from '@fluency/ui/features/Logger';
import { WorkflowModeType } from '../types/workflow';

interface WorkflowHeaderProps {
  mode: WorkflowModeType;
  setMode: (mode: WorkflowModeType) => void;
  onSave?: () => Promise<void>;
  isVaultLocked: boolean;
}

const SkeletonHeader = () => (
  <div className="w-full h-fit">
    <div className="flex items-center justify-between px-6 pt-4">
      <div className="h-8 w-1/3 bg-gray-200 rounded animate-pulse" />
      <div className="flex items-center gap-2">
        <div className="h-8 w-8 rounded animate-pulse bg-gray-200" />
        <div className="h-8 w-8 bg-gray-200 rounded animate-pulse" />
        <div className="h-8 w-8 bg-gray-200 rounded animate-pulse" />
      </div>
    </div>
    <div className="px-6 py-4">
      <div className="h-4 w-2/3 bg-gray-200 rounded animate-pulse" />
    </div>
  </div>
);

export default function WorkflowHeader({
  mode,
  setMode,
  onSave,
  isVaultLocked,
}: WorkflowHeaderProps) {
  const { id } = useParams({ from: '/workflow/$id' });
  const { data: workflow, isLoading, error } = useGetWorkflow(id);
  const updateWorkflow = useUpdateWorkflow();
  const { data: permissions } = useResourcePermissions({
    resourceType: 'WORKFLOW',
    resourceId: id,
  });
  const isManager = permissions?.data.bearer.effectiveRole.role === 'MANAGER';
  const isWorkflowLocked = workflow?.isLocked;
  const isLocked = isVaultLocked || isWorkflowLocked;

  const handleUpdateTitle = async (newTitle: string) => {
    if (!workflow || newTitle === workflow.name) return;
    const nodes = JSON.parse(localStorage.getItem('nodes')!) ?? [];
    const edges = JSON.parse(localStorage.getItem('edges')!) ?? [];
    const processedNodes = nodes.map(({ style, resizing, ...node }: any) => {
      return {
        ...node,
      };
    });

    try {
      await updateWorkflow.mutateAsync({
        id: workflow.id,
        name: newTitle,
        // TEMPORARY: add local storage nodes and edges to update workflow
        // Need to be changed later
        nodes: processedNodes,
        edges: edges,
      });
    } catch (error) {
      Logger.error('Failed to update workflow title:', error);
    }
  };

  const handleUpdateDescription = async (newDescription: string) => {
    if (!workflow || newDescription === workflow.description) return;

    const nodes = JSON.parse(localStorage.getItem('nodes')!) ?? [];
    const edges = JSON.parse(localStorage.getItem('edges')!) ?? [];
    const processedNodes = nodes.map(({ style, resizing, ...node }: any) => {
      return {
        ...node,
      };
    });

    try {
      await updateWorkflow.mutateAsync({
        id: workflow.id,
        description: newDescription,
        // TEMPORARY: add local storage nodes and edges to update workflow
        // Need to be changed later
        nodes: processedNodes,
        edges: edges,
      });
    } catch (error) {
      Logger.error('Failed to update workflow description:', error);
    }
  };

  const handleSave = async () => {
    if (onSave) {
      try {
        await onSave();
        setMode('View');
      } catch (error) {
        Logger.error('Failed to save workflow:', error);
      }
    }
  };

  const getLockMessage = () => {
    if (isVaultLocked && isWorkflowLocked)
      return 'Cannot edit - Vault and Workflow are locked';
    if (isVaultLocked) return 'Cannot edit - Vault is locked';
    if (isWorkflowLocked) return 'Cannot edit - Workflow is locked';
    return '';
  };

  if (isLoading) return <SkeletonHeader />;
  if (error) return <div>Error: {error.message}</div>;
  if (!workflow) return null;

  return (
    <div className="w-full shrink-0">
      <div className="flex items-center justify-between px-6">
        <EditableTitle
          initialValue={workflow.name}
          isEditMode={mode === 'Edit'}
          onSave={handleUpdateTitle}
        />
        <div className="flex items-center gap-2">
          <ResourceStatusIcons resource={workflow} />
          {mode === 'View' ? (
            <>
              {isLocked ? (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="secondary"
                        size="sm"
                        className="flex gap-2 opacity-50 cursor-default"
                      >
                        <Lock className="w-4" /> Locked
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent side="top">
                      {getLockMessage()}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => setMode('Edit')}
                  className="flex gap-2"
                >
                  Edit <PenSquare className="w-4" />
                </Button>
              )}
              <FileSettings
                data={workflow}
                isManager={isManager}
                isVaultLocked={isVaultLocked}
              />
            </>
          ) : (
            <Button size="sm" variant="secondary" onClick={handleSave}>
              Save
            </Button>
          )}
        </div>
      </div>
      <EditableDescription
        initialValue={workflow.description}
        isEditMode={mode === 'Edit'}
        onSave={handleUpdateDescription}
      />
    </div>
  );
}
