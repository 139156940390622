import { useState, useEffect, useCallback } from 'react';
import { Node, Edge as ReactFlowEdge, MarkerType } from 'reactflow';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import {
  Edge as CustomEdge,
  WorkflowNode,
} from '@fluency/ui/hooks/workflows/types/types';
import { useUpdateWorkflow } from '@fluency/ui/hooks/workflows/useUpdateWorkflow';
import { useGetWorkflow } from '@fluency/ui/hooks/workflows/useGetWorkflow';
import { Logger } from '@fluency/ui/features/Logger';

interface WorkflowData {
  nodes: Node[];
  edges: ReactFlowEdge[];
}

// Convert our custom edge type to ReactFlow edge type
const convertToReactFlowEdge = (edge: CustomEdge): ReactFlowEdge => ({
  id: edge.id,
  type: edge.type,
  source: edge.source,
  target: edge.target,
  sourceHandle: edge.sourceHandle,
  targetHandle: edge.targetHandle,
  data: edge.data,
  markerEnd: {
    type: edge.markerEnd.type as MarkerType,
    color: edge.markerEnd.color,
  },
  style: edge.style,
});

// Convert ReactFlow edge type to our custom edge type
const convertToCustomEdge = (edge: ReactFlowEdge): CustomEdge => ({
  id: edge.id,
  type: edge.type as 'labeled' | undefined,
  source: edge.source,
  sourceHandle: edge.sourceHandle ?? null,
  target: edge.target,
  targetHandle: edge.targetHandle ?? null,
  data: edge.data as { label: string },
  markerEnd: {
    type: (edge.markerEnd as { type: string })?.type ?? 'arrow',
    color: (edge.markerEnd as { color: string })?.color ?? '#000000',
  },
  style: {
    strokeWidth: (edge.style as { strokeWidth: number })?.strokeWidth ?? 1,
    stroke: (edge.style as { stroke: string })?.stroke ?? '#000000',
  },
});

// Convert Node[] to WorkflowNode[]
const convertToWorkflowNodes = (nodes: Node[]): WorkflowNode[] => {
  return nodes.map((node) => {
    // Filter out unnecessary properties as the style property is only needed for client-side rendering
    // Swimlanes node has additional properties only for client-side rendering (resizing, style)
    const { style, resizing, ...cleanedNode } = node;
    return {
      ...cleanedNode,
      type: node.type as WorkflowNode['type'],
      data: node.data,
      position: node.position,
      width: node.width ?? 0,
      height: node.height ?? 0,
      selected: node.selected ?? false,
      positionAbsolute: node.positionAbsolute ?? node.position,
      dragging: node.dragging ?? false,
    };
  });
};

export const useWorkflowDatabase = (workflowId: string) => {
  const { accessToken } = useAuth();
  const [workflowData, setWorkflowData] = useState<WorkflowData | null>(null);

  const {
    data: fetchedWorkflow,
    isLoading,
    error,
    refetch: refetchWorkflow,
  } = useGetWorkflow(workflowId);

  const updateWorkflowMutation = useUpdateWorkflow();

  useEffect(() => {
    if (fetchedWorkflow) {
      setWorkflowData({
        nodes: fetchedWorkflow.nodes.map((node) => ({
          ...node,
          type: node.type,
          data: node.data,
        })),
        edges: fetchedWorkflow.edges.map(convertToReactFlowEdge),
      });
    }
  }, [fetchedWorkflow]);

  const saveWorkflow = useCallback(
    async (data: WorkflowData) => {
      if (!accessToken) {
        Logger.error('No access token available');
        return;
      }

      try {
        await updateWorkflowMutation.mutateAsync({
          id: workflowId,
          nodes: convertToWorkflowNodes(data.nodes),
          edges: data.edges.map(convertToCustomEdge),
        });
        Logger.log('Workflow saved successfully');
      } catch (error) {
        Logger.error('Error saving workflow:', error);
      }
    },
    [workflowId, updateWorkflowMutation, accessToken]
  );

  return {
    workflowData,
    isLoading,
    error,
    fetchWorkflow: refetchWorkflow,
    saveWorkflow,
  };
};
