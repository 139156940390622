import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DocumentationResponse } from '../../../hooks/documentation/types/master';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useToast } from '@fluency/ui/components/ui/use-toast';
import { Logger } from '@fluency/ui/features/Logger';

interface UpdateStepDescriptionProps {
  stepId: string;
  description: string;
}

interface UpdateStepResponse {
  message: string;
  updatedStep: {
    stepId: string;
    stepNumber: number;
    stepDescription: string;
    stepType: string;
    documentationId: string;
  };
}

async function updateStepDescription({
  stepId,
  description,
  accessToken,
}: UpdateStepDescriptionProps & {
  accessToken: string;
}): Promise<UpdateStepResponse> {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const endpoint = `/step/update`;
  const fullUrl = apiBaseUrl + endpoint;

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${accessToken}`);

  const raw = JSON.stringify({
    stepId: stepId,
    stepDescription: description,
  });

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const response = await fetch(fullUrl, requestOptions);
  if (!response.ok) {
    const data = await response.json();
    Logger.log(data);
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
}

interface UseUpdateStepDescriptionProps {
  documentationId: string;
}

const useUpdateStepDescription = ({
  documentationId,
}: UseUpdateStepDescriptionProps) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();
  const { toast } = useToast();

  return useMutation<
    UpdateStepResponse,
    Error,
    UpdateStepDescriptionProps,
    { previousDocInfo: DocumentationResponse }
  >({
    mutationFn: async (args) => {
      return updateStepDescription({
        ...args,
        accessToken: accessToken || '',
      });
    },
    mutationKey: ['update-step-description'],
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(
        ['docInfo', documentationId],
        context?.previousDocInfo
      );
      toast({
        variant: 'destructive',
        title: 'Something went wrong.',
        description: 'Please try again later.',
      });
    },
    onSuccess: (data) => {
      toast({
        title: 'Success',
        description: data.message || 'Step description successfully updated',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['docInfo', documentationId],
      });
    },
  });
};

export default useUpdateStepDescription;
