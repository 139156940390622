import { useState } from 'react';
import { Switch } from '@fluency/ui/components/ui/switch';
import {
  ToggleGroup,
  ToggleGroupItem,
} from '@fluency/ui/components/ui/toggle-group';
import {
  Circle,
  Square,
  Minus,
  ArrowRight,
  Save,
  Plus,
  Trash2,
} from 'lucide-react';
import { Button } from '@fluency/ui/components/ui/button';
import ColorPicker from '../ColorSelector';
import { useScreenshotAnnotationV2 } from '@fluency/ui/features/ViewDocument/hooks/useScreenshotAnnotationV2';
import type {
  ScreenshotType,
  AnnotationType,
} from '@fluency/ui/hooks/documentation/types/master';
import { useScreenshotAnnotation } from '@fluency/ui/features/ViewDocument/hooks';
import { Logger } from '@fluency/ui/features/Logger';

interface AnnotationSettingsProps {
  screenshot: ScreenshotType;
  onAnnotationsChange: (annotations: ScreenshotType['annotations']) => void;
  documentationId: string;
}

export const AnnotationSettings = ({
  screenshot,
  onAnnotationsChange,
  documentationId,
}: AnnotationSettingsProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [activeAnnotationId, setActiveAnnotationId] = useState<string | null>(
    screenshot.annotations[0]?.screenshotAnnotationId || null
  );

  const { updateAnnotations } = useScreenshotAnnotationV2({
    documentationId: documentationId,
  });

  const { updateScreenshot } = useScreenshotAnnotation({
    documentationId: documentationId,
  });

  const activeAnnotation = screenshot.annotations.find(
    (ann) => ann.screenshotAnnotationId === activeAnnotationId
  );

  const handleVisibilityChange = async () => {
    const newAnnotationState = !screenshot.annotation;

    if (newAnnotationState && !screenshot.annotations.length) {
      const newAnnotation = createDefaultAnnotation();
      onAnnotationsChange([newAnnotation]);
    }

    await updateScreenshot({
      screenshotsToUpdate: [
        {
          screenshotId: screenshot.screenshotId,
          annotation: newAnnotationState,
        },
      ],
    });
  };

  const createDefaultAnnotation = (): AnnotationType => ({
    screenshotAnnotationId: crypto.randomUUID(),
    screenshotId: screenshot.screenshotId,
    shapeType: 'CIRCLE',
    color: '#FF0000',
    x1Coordinate: 50,
    y1Coordinate: 50,
    x2Coordinate: 80,
    y2Coordinate: 80,
    annotationIndex: screenshot.annotations.length,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  });

  const handleAddAnnotation = () => {
    const newAnnotation = createDefaultAnnotation();
    const updatedAnnotations = [...screenshot.annotations, newAnnotation];
    onAnnotationsChange(updatedAnnotations);
    setActiveAnnotationId(newAnnotation.screenshotAnnotationId);
  };

  const handleDeleteAnnotation = (annotationId: string) => {
    // Filter out the deleted annotation
    const updatedAnnotations = screenshot.annotations
      .filter((ann) => ann.screenshotAnnotationId !== annotationId)
      .map((ann, index) => ({
        ...ann,
        annotationIndex: index,
      }));

    // Update local state
    onAnnotationsChange(updatedAnnotations);
    setActiveAnnotationId(
      updatedAnnotations[0]?.screenshotAnnotationId || null
    );
  };

  const handleColorChange = (newColor: string) => {
    if (!activeAnnotationId) return;

    const updatedAnnotations = screenshot.annotations.map((ann) =>
      ann.screenshotAnnotationId === activeAnnotationId
        ? { ...ann, color: newColor, updatedAt: new Date().toISOString() }
        : ann
    );
    onAnnotationsChange(updatedAnnotations);
  };

  const handleShapeChange = (newShape: AnnotationType['shapeType']) => {
    if (!activeAnnotationId) {
      // Create new annotation
      const newAnnotation = {
        ...createDefaultAnnotation(),
        shapeType: newShape,
      };
      onAnnotationsChange([...screenshot.annotations, newAnnotation]);
      setActiveAnnotationId(newAnnotation.screenshotAnnotationId);
    } else {
      // Update existing annotation shape
      const updatedAnnotations = screenshot.annotations.map((ann) =>
        ann.screenshotAnnotationId === activeAnnotationId
          ? { ...ann, shapeType: newShape, updatedAt: new Date().toISOString() }
          : ann
      );
      onAnnotationsChange(updatedAnnotations);
    }
  };

  const handleSave = async () => {
    if (!screenshot.screenshotId) return;

    setIsSaving(true);
    try {
      await updateAnnotations({
        annotations: screenshot.annotations.map((ann) => ({
          screenshotAnnotationId: ann.screenshotAnnotationId,
          screenshotId: screenshot.screenshotId,
          shapeType: ann.shapeType,
          x1Coordinate: Math.round(ann.x1Coordinate),
          y1Coordinate: Math.round(ann.y1Coordinate),
          x2Coordinate: Math.round(ann.x2Coordinate ?? ann.x1Coordinate),
          y2Coordinate: Math.round(ann.y2Coordinate ?? ann.y1Coordinate),
          color: ann.color,
        })),
      });
    } catch (error) {
      Logger.error('Failed to save annotations:', error);
    } finally {
      setIsSaving(false);
    }
  };

  // Rest of the component remains the same...
  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-2">
        <Switch
          checked={screenshot.annotation}
          onCheckedChange={handleVisibilityChange}
        />
        <label className="text-sm font-medium">Show Annotations</label>
      </div>

      {screenshot.annotations.length > 0 && (
        <>
          <div className="space-y-2">
            <div className="flex flex-col gap-2">
              {screenshot.annotations.map((ann, index) => (
                <div
                  key={ann.screenshotAnnotationId}
                  className="flex items-center gap-2"
                >
                  <button
                    onClick={() =>
                      setActiveAnnotationId(ann.screenshotAnnotationId)
                    }
                    className={`flex-1 text-left px-3 py-2 rounded-md text-sm border ${
                      activeAnnotationId === ann.screenshotAnnotationId
                        ? 'bg-primary text-primary-foreground'
                        : 'hover:bg-muted'
                    }`}
                  >
                    {index + 1} -{' '}
                    <span className="capitalize">
                      {ann.shapeType.toLowerCase()}
                    </span>
                  </button>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() =>
                      handleDeleteAnnotation(ann.screenshotAnnotationId)
                    }
                    disabled={isSaving}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              ))}
              <Button
                variant="outline"
                size="sm"
                onClick={handleAddAnnotation}
                className="bg-transparent"
                disabled={isSaving}
              >
                <Plus className="h-4 w-4 mr-2" />
                Add New
              </Button>
            </div>
          </div>

          {activeAnnotation && (
            <>
              <div className="space-y-2">
                <label className="text-sm font-medium">Shape Type</label>
                <ToggleGroup
                  type="single"
                  value={activeAnnotation.shapeType}
                  onValueChange={handleShapeChange}
                  className="justify-start"
                >
                  <ToggleGroupItem value="CIRCLE" aria-label="Circle">
                    <Circle className="h-4 w-4" />
                  </ToggleGroupItem>
                  <ToggleGroupItem value="RECTANGLE" aria-label="Rectangle">
                    <Square className="h-4 w-4" />
                  </ToggleGroupItem>
                  <ToggleGroupItem value="LINE" aria-label="Line">
                    <Minus className="h-4 w-4" />
                  </ToggleGroupItem>
                  <ToggleGroupItem value="ARROW" aria-label="Arrow">
                    <ArrowRight className="h-4 w-4" />
                  </ToggleGroupItem>
                </ToggleGroup>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium">Annotation Color</label>
                <ColorPicker
                  initialColor={activeAnnotation.color || '#FF0000'}
                  onColorChange={handleColorChange}
                />
              </div>
            </>
          )}

          <Button className="w-full" onClick={handleSave} disabled={isSaving}>
            <Save className="w-4 h-4 mr-2" />
            {isSaving ? 'Saving...' : 'Save Changes'}
          </Button>
        </>
      )}

      {screenshot.annotations.length === 0 && screenshot.annotation && (
        <p className="text-sm text-muted-foreground">
          Click a shape type above to add your first annotation
        </p>
      )}
    </div>
  );
};

export default AnnotationSettings;
