import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useNavigate } from '@tanstack/react-router';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { Documentation, DocumentationsResponse } from './types/GetDocs';
import {
  InfiniteQueryResponse,
  ResourceItem,
  VersionInfo,
  DocumentationResource,
  PageResponse,
} from '../vaults/types/types';
import { ResourceType } from '../fga/types/fga';

// Options interface
interface CreateEmptyDocOptions {
  disableRedirect?: boolean;
}

// Payload interface
interface CreateDocumentPayload {
  duration: number;
  name: string;
  description: string;
  createdDate: string;
  vaultId: string;
}

// Response data interface
interface CreateDocumentResponse {
  message: string;
  document: Documentation;
}

// Context interface
interface CreateDocContext {
  previousDocs?: DocumentationsResponse;
  previousVaultResources?: InfiniteQueryResponse;
  vaultId: string;
}

// Helper function to convert Documentation permissions to DocumentationResource permissions
function convertPermissions(
  permissions: Documentation['permissions']
): DocumentationResource['permissions'] {
  return {
    role: permissions.role,
    source: permissions.source,
    details: {
      // Convert the specific PermissionDetails to a Record<string, unknown>
      ...permissions.details,
      organisationRole: permissions.details.organisationRole,
    },
  };
}

// Helper function to convert Documentation to DocumentationResource
function convertToDocumentationResource(
  doc: Documentation
): DocumentationResource {
  const {
    documentationId,
    documentationName,
    createdDate,
    duration,
    totalSteps,
    description,
    createdBy,
    shareId,
    updatedAt,
    vaultId,
    isPublic,
    isGlobal,
    isLocked,
    vault,
    userInfo,
    versionInfo,
    permissions,
  } = doc;

  return {
    documentationId,
    documentationName,
    createdDate,
    duration,
    totalSteps,
    description,
    createdBy,
    shareId,
    updatedAt,
    vaultId,
    isPublic,
    isGlobal,
    isLocked,
    vault,
    userInfo,
    versionInfo,
    permissions: convertPermissions(permissions),
  };
}

export const useCreateEmptyDoc = (options: CreateEmptyDocOptions = {}) => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { disableRedirect = false } = options;

  return useMutation<
    CreateDocumentResponse,
    Error,
    CreateDocumentPayload,
    CreateDocContext
  >({
    mutationFn: async (payload) => {
      const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
      const response = await fetch(`${apiBaseUrl}/documents/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(
          errorData.message || `HTTP error! status: ${response.status}`
        );
      }

      return response.json();
    },
    onMutate: async (newDoc) => {
      await queryClient.cancelQueries({ queryKey: ['documentation'] });
      await queryClient.cancelQueries({
        queryKey: ['vaultResources', newDoc.vaultId],
      });

      const previousDocs = queryClient.getQueryData<DocumentationsResponse>([
        'documentation',
      ]);
      const previousVaultResources =
        queryClient.getQueryData<InfiniteQueryResponse>([
          'vaultResources',
          newDoc.vaultId,
        ]);

      const defaultVersionInfo: VersionInfo = {
        originalDocumentationId: '',
        versionedDocumentId: '',
        version: '1.0',
        createdAt: new Date().toISOString(),
        isLatestVersion: true,
      };

      const optimisticDoc: Documentation = {
        documentationId: `temp-${Date.now()}`,
        documentationName: newDoc.name,
        createdDate: newDoc.createdDate,
        duration: newDoc.duration,
        totalSteps: null,
        description: newDoc.description,
        createdBy: '',
        shareId: '',
        updatedAt: new Date().toISOString(),
        vaultId: newDoc.vaultId,
        isPublic: false,
        isGlobal: false,
        isLocked: false,
        vault: {
          id: newDoc.vaultId,
          name: '',
          description: '',
          tenantId: '',
          createdBy: '',
          isPublic: false,
          isLocked: false,
          isGlobal: false,
        },
        userInfo: [],
        versionInfo: defaultVersionInfo,
        permissions: {
          role: '',
          source: '',
          details: {
            organisationRole: {
              role: '',
            },
          },
        },
      };

      queryClient.setQueryData<DocumentationsResponse>(
        ['documentation'],
        (old) => {
          if (!old) {
            return {
              message: 'Documents retrieved successfully',
              documentation: [optimisticDoc],
              totalItems: 1,
              currentPage: 1,
              pageSize: 10,
              totalPages: 1,
            };
          }

          return {
            ...old,
            documentation: [optimisticDoc, ...old.documentation],
            totalItems: old.totalItems + 1,
          };
        }
      );

      const optimisticResource: ResourceItem = {
        type: ResourceType.DOCUMENTATION,
        resource: convertToDocumentationResource(optimisticDoc),
      };

      queryClient.setQueryData<InfiniteQueryResponse>(
        ['vaultResources', newDoc.vaultId],
        (old) => {
          if (!old) return old;

          const updatedPages: PageResponse[] = old.pages.map((page, index) => ({
            ...page,
            items:
              index === 0 ? [optimisticResource, ...page.items] : page.items,
          }));

          return {
            pages: updatedPages,
            pageParams: old.pageParams,
          };
        }
      );

      return {
        previousDocs,
        previousVaultResources,
        vaultId: newDoc.vaultId,
      };
    },
    onError: (error, _variables, context) => {
      if (context?.previousDocs) {
        queryClient.setQueryData(['documentation'], context.previousDocs);
      }

      if (context?.previousVaultResources) {
        queryClient.setQueryData(
          ['vaultResources', context.vaultId],
          context.previousVaultResources
        );
      }

      toast({
        variant: 'destructive',
        title: 'Error creating document',
        description: error.message || 'An unexpected error occurred.',
      });
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData<DocumentationsResponse>(
        ['documentation'],
        (old) => {
          if (!old) return old;
          return {
            ...old,
            documentation: old.documentation.map((doc) =>
              doc.documentationId.startsWith('temp-') ? data.document : doc
            ),
          };
        }
      );

      queryClient.setQueryData<InfiniteQueryResponse>(
        ['vaultResources', variables.vaultId],
        (old) => {
          if (!old) return old;

          const updatedPages: PageResponse[] = old.pages.map((page) => ({
            ...page,
            items: page.items.map((item): ResourceItem => {
              if (
                item.type === ResourceType.DOCUMENTATION &&
                (
                  item.resource as DocumentationResource
                ).documentationId.startsWith('temp-')
              ) {
                return {
                  type: ResourceType.DOCUMENTATION,
                  resource: convertToDocumentationResource(data.document),
                };
              }
              return item;
            }),
          }));

          return {
            pages: updatedPages,
            pageParams: old.pageParams,
          };
        }
      );
      if (!disableRedirect) {
        navigate({
          to: '/document/$id',
          params: { id: data.document.documentationId },
        });
      }
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({ queryKey: ['documentation'] });
      queryClient.invalidateQueries({
        queryKey: ['vaultResources', variables.vaultId],
      });
    },
  });
};
