// useBulkCrop.tsx
import { useToast } from '@fluency/ui/components/ui/use-toast';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DocumentationResponse } from '../../../hooks/documentation/types/master';

async function bulkCropScreenshots(
  documentId: string,
  cropInfo: { scaleFactor: number },
  accessToken: string
): Promise<any> {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const endpoint = '/screenshot/bulkCrop';
  const fullUrl = apiBaseUrl + endpoint;

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${accessToken}`);

  const body = JSON.stringify({
    documentId,
    cropInfo,
  });

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body,
  };

  const response = await fetch(fullUrl, requestOptions);
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(
      `HTTP error! status: ${response.status}, message: ${errorText}`
    );
  }
}

export interface CropInfo {
  scaleFactor: number;
}

const useBulkCropScreenshots = (documentId: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();
  const { toast } = useToast();

  return useMutation<
    void,
    Error,
    CropInfo,
    { previousDocInfo?: DocumentationResponse }
  >({
    mutationFn: async (cropInfo) => {
      if (!accessToken) {
        throw new Error('No access token found');
      }
      await bulkCropScreenshots(documentId, cropInfo, accessToken);
    },
    onError: (error, _variables, context) => {
      queryClient.setQueryData(
        ['docInfo', documentId],
        context?.previousDocInfo
      );
      toast({
        variant: 'destructive',
        title: 'Error cropping screenshots',
        description:
          error.message || 'There was an error cropping the screenshots',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['docInfo', documentId],
      });
      toast({
        title: 'Success',
        description: 'Screenshots cropped successfully.',
      });
    },
  });
};

export default useBulkCropScreenshots;
