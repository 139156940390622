import { useNavigate } from '@tanstack/react-router';
import { FileText, Workflow } from 'lucide-react';
import { NotificationItem as NotificationItemType } from '../types/notifications.type';
import { useMarkNotificationAsRead } from '../hooks/useNotificationMutations';
import { Logger } from '@fluency/ui/features/Logger';

interface NotificationItemProps {
  notification: NotificationItemType;
  onNotificationClick?: (notification: NotificationItemType) => void;
}

const ResourceIcon = ({ type }: { type: 'document' | 'workflow' }) => {
  const iconColor =
    type === 'document' ? 'text-fluency-500' : 'text-purple-500';

  switch (type) {
    case 'document':
      return <FileText className={`h-4 w-4 ${iconColor}`} />;
    case 'workflow':
      return <Workflow className={`h-4 w-4 ${iconColor}`} />;
  }
};

const ResourceIconContainer = ({ type }: { type: 'document' | 'workflow' }) => {
  const bgColor = type === 'document' ? 'bg-fluency-50' : 'bg-purple-50';

  return (
    <div
      className={`w-8 h-8 rounded-lg ${bgColor} flex items-center justify-center`}
    >
      <ResourceIcon type={type} />
    </div>
  );
};

export function NotificationItem({
  notification,
  onNotificationClick,
}: NotificationItemProps) {
  const navigate = useNavigate();
  const markAsRead = useMarkNotificationAsRead();

  const handleClick = async () => {
    if (!notification.read) {
      try {
        await markAsRead.mutateAsync(notification.id);
      } catch (error) {
        Logger.error('Failed to mark notification as read:', error);
      }
    }

    if (onNotificationClick) {
      onNotificationClick(notification);
    } else {
      navigate({
        to: notification.link as any,
        replace: false,
      });
    }
  };

  const borderColor =
    notification.resource.type === 'document'
      ? 'border-fluency-500'
      : 'border-purple-500';

  return (
    <div
      onClick={handleClick}
      className="flex items-start gap-4 p-4 group hover:bg-[#F3F6F8] transition-colors cursor-pointer"
    >
      <ResourceIconContainer type={notification.resource.type} />
      <div className="flex-1 min-w-0">
        <div className="text-sm leading-normal text-[#172B4D] line-clamp-3">
          {notification.message}
        </div>
        <div className="flex items-center gap-1 mt-2 text-xs text-[#44546F]">
          <span>{notification.time}</span>
        </div>
        <div
          className={`flex items-center gap-2 mt-2 p-1.5 border rounded-md w-fit ${borderColor} group-hover:border-opacity-75 transition-colors max-w-full`}
        >
          <ResourceIcon type={notification.resource.type} />
          <span className="text-xs text-primary truncate max-w-[200px]">
            {notification.resource.name}
          </span>
        </div>
      </div>
      {!notification.read && (
        <div className="w-2 h-2 rounded-full bg-fluency-500 mt-2 flex-shrink-0" />
      )}
    </div>
  );
}
