import {
  UserCog,
  Bell,
  Wallet,
  Users,
  Puzzle,
  Shield,
  Paintbrush,
  UserPlus,
  Trash2,
} from 'lucide-react';
import { NavItem } from '../types/NavItem';

export const navItems: NavItem[] = [
  // Account Settings
  {
    to: '/settings/$setting',
    setting: 'general',
    label: 'General',
    icon: UserCog,
    permissions: ['user:read'],
    type: 'account',
  },
  {
    to: '/settings/$setting',
    setting: 'notifications',
    label: 'Notifications',
    icon: Bell,
    permissions: ['user:read'],
    type: 'preferences',
  },
  {
    to: '/settings/$setting',
    setting: 'integrations',
    label: 'Integrations',
    icon: Puzzle,
    permissions: ['user:update'],
    type: 'preferences',
  },
  // Team Management
  {
    to: '/settings/$setting',
    setting: 'members',
    label: 'Members',
    icon: UserPlus,
    permissions: ['org:update'],
    type: 'team',
  },
  {
    to: '/settings/$setting',
    setting: 'teams',
    label: 'Teams',
    icon: Users,
    permissions: ['org:update'],
    type: 'team',
  },
  // Admin Controls
  {
    to: '/settings/$setting',
    setting: 'subscription',
    label: 'Subscription',
    icon: Wallet,
    permissions: ['subscription:update'],
    type: 'admin',
  },
  {
    to: '/settings/$setting',
    setting: 'pii',
    label: 'PII Protection',
    icon: Shield,
    permissions: ['org:update'],
    type: 'admin',
    featureFlag: 'auto-pii',
  },
  {
    to: '/settings/$setting',
    setting: 'branding',
    label: 'Branding',
    icon: Paintbrush,
    permissions: ['org:update'],
    type: 'admin',
    featureFlag: 'branding',
  },
  {
    to: '/settings/$setting',
    setting: 'danger',
    label: 'Danger Zone',
    icon: Trash2,
    permissions: ['org:update'],
    type: 'admin',
  },
] as const;

type NavSetting = (typeof navItems)[number]['setting'];

export const isValidSetting = (setting: string): setting is NavSetting => {
  return navItems.some((item) => item.setting === setting);
};
