import { useQuery } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { Workflow, GetWorkflowHook } from './types/types';
import { createHeaders, handleApiError } from './utils/utils';

export const useGetWorkflow: GetWorkflowHook = (workflowId: string) => {
  const { accessToken } = useAuth();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  return useQuery<Workflow, Error>({
    queryKey: ['workflow', workflowId],
    queryFn: async (): Promise<Workflow> => {
      if (!workflowId) {
        throw new Error('Workflow ID is required');
      }

      const requestOptions: RequestInit = {
        method: 'GET',
        headers: createHeaders(accessToken),
        redirect: 'follow',
      };

      try {
        const response = await fetch(
          `${apiBaseUrl}/workflows/${workflowId}`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        // Ensure the workflow has nodes and edges arrays
        const workflow = result.workflow;
        return {
          ...workflow,
          nodes: workflow.nodes || [],
          edges: workflow.edges || [],
          vault: {
            id: workflow.vault?.id || workflow.vaultId || '',
            name: workflow.vault?.name || '',
            description: workflow.vault?.description || '',
          },
        };
      } catch (error) {
        return handleApiError(error, 'Failed to get workflow');
      }
    },
    enabled: !!workflowId,
  });
};
