import React from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import { ExternalLink } from 'lucide-react';
import { useGetDocumentData } from '@fluency/ui/features/ViewDocument/hooks';
import { useNavigate } from '@tanstack/react-router';
import { Skeleton } from './Skeleton';
import ViewDocument from '@fluency/ui/features/ViewDocument/ViewDocument';

interface PreviewPanelProps {
  document: {
    label: string;
    steps: number;
    id: string;
  } | null;
  onClose: () => void;
}

const PreviewPanel: React.FC<PreviewPanelProps> = ({ document }) => {
  const navigate = useNavigate();
  if (!document) return null;
  const { isLoading } = useGetDocumentData(document.id);

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-end -mt-[18px] mb-4">
        <div className="flex-1 w-full h-10" />
        <Button
          variant="ghost"
          size="sm"
          onClick={() => {
            navigate({
              to: '/document/$id',
              params: { id: document.id },
              replace: true,
            });
          }}
          className="mr-[218px] mt-[14px] gap-2 absolute"
        >
          <ExternalLink className="h-4 w-4" /> Open
        </Button>
      </div>
      <div className="flex-1 overflow-auto">
        <ViewDocument id={document.id} isPreview={true} />
      </div>
    </div>
  );
};

export default PreviewPanel;
