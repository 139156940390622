import { useState, ReactNode } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import { useToast } from '@fluency/ui/components/ui/use-toast';
import { Button } from '@fluency/ui/components/ui/button';
import { KeyRound, XCircle } from 'lucide-react';
import { useResourcePermissions } from '@fluency/ui/hooks/fga/useResourcePermissions';
import { useResourceAccessControl } from '@fluency/ui/hooks/fga/useResourceAccessControl';
import { AddNewAccess } from './AddNewAccess';
import PermissionsTable from './PermissionsTable';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import {
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@fluency/ui/components/ui/dialog';
import { ResourceType, RoleName } from '@fluency/ui/hooks/fga/types/fga';
import { Vault } from '@fluency/ui/features/Vaults/ManageVaults/types/types';
import { usePermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import PermissionsSkeleton from './PermissionsSkeleton';

interface ResourceConfig {
  type: ResourceType;
  title: string;
  description: string;
}

const RESOURCE_CONFIGS: Record<ResourceType, ResourceConfig> = {
  VAULT: {
    type: 'VAULT',
    title: 'Vault Permissions',
    description: 'Manage teams and users access to this vault',
  },
  DOCUMENTATION: {
    type: 'DOCUMENTATION',
    title: 'Document Permissions',
    description: 'Manage teams and users access to this document',
  },
  WORKFLOW: {
    type: 'WORKFLOW',
    title: 'Process Map Permissions',
    description: 'Manage teams and users access to this process map',
  },
};

type VaultRoleName = Extract<RoleName, 'VIEWER' | 'EDITOR' | 'MANAGER'>;

interface PermissionsDialogProps {
  resourceId: string;
  resourceType: ResourceType;
  trigger?: ReactNode;
  showTooltip?: boolean;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
  vault?: Vault | null;
}

type ViewState = 'list' | 'add';

export const PermissionsDialog = ({
  resourceId,
  resourceType,
  trigger,
  showTooltip = true,
  onOpenChange,
  open,
  vault,
}: PermissionsDialogProps) => {
  const { createAccessRecord } = useResourceAccessControl();
  const { data, isLoading, error } = useResourcePermissions({
    resourceType,
    resourceId,
  });
  const { toast } = useToast();
  const [selectedRole, setSelectedRole] = useState<VaultRoleName>('VIEWER');
  const [view, setView] = useState<ViewState>('list');
  const { hasPermissions } = usePermissionGate();

  const config = RESOURCE_CONFIGS[resourceType];

  // If user doesn't have read permission, don't render anything
  if (!hasPermissions(['fga:read'])) {
    return null;
  }

  const handleAddTeam = async (teamId: string) => {
    try {
      await createAccessRecord.mutateAsync({
        resourceId,
        resourceType,
        teamId,
        roleName: selectedRole,
      });
      toast({
        title: 'Success',
        description: 'Team permissions updated successfully',
      });
      setView('list');
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update team permissions',
        variant: 'destructive',
      });
    }
  };

  const handleAddUser = async (userId: string) => {
    if (!data) return;

    if (userId === data.data.bearer.user.userId) {
      toast({
        title: 'Error',
        description: 'Cannot modify your own permissions',
        variant: 'destructive',
      });
      return;
    }

    try {
      await createAccessRecord.mutateAsync({
        resourceId,
        resourceType,
        userId,
        roleName: selectedRole,
      });
      toast({
        title: 'Success',
        description: 'User permissions updated successfully',
      });
      setView('list');
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update user permissions',
        variant: 'destructive',
      });
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <PermissionsSkeleton />;
    }

    if (error || !data) {
      return (
        <div className="flex flex-col items-center justify-center min-h-[300px] gap-4">
          <div className="p-4 rounded-full bg-red-50">
            <XCircle className="h-8 w-8 text-red-500" />
          </div>
          <div className="text-center">
            <p className="font-medium text-red-900">
              Failed to load permissions
            </p>
            <p className="text-sm text-muted-foreground mt-1">
              Please try again later or contact support if the problem persists.
            </p>
          </div>
          <Button
            variant="outline"
            onClick={() => window.location.reload()}
            className="mt-4"
          >
            Retry
          </Button>
        </div>
      );
    }

    const existingTeamIds = data.data.assignments.teams.map((t) => t.teamId);
    const existingUserIds = data.data.assignments.users.map((u) => u.userId);

    if (view === 'add') {
      return (
        <AddNewAccess
          onAddTeam={handleAddTeam}
          onAddUser={handleAddUser}
          existingTeamIds={existingTeamIds}
          existingUserIds={existingUserIds}
          selectedRole={selectedRole}
          onRoleChange={setSelectedRole}
          onCancel={() => setView('list')}
          user={data.data.bearer.user}
        />
      );
    }

    const combinedItems = [
      ...data.data.assignments.teams.map((team) => ({
        id: team.teamId,
        name: team.team.teamName,
        role: team.role,
        type: 'team' as const,
      })),
      ...data.data.assignments.users.map((user) => ({
        id: user.userId,
        name: user.user.fullName,
        role: user.role,
        type: 'user' as const,
        email: user.user.email,
      })),
    ];

    // Safely determine if vault is global
    const isGlobal = vault?.isGlobal ?? false;

    return (
      <>
        <DialogHeader>
          <DialogTitle>{config.title}</DialogTitle>
          <DialogDescription>{config.description}</DialogDescription>
        </DialogHeader>

        <div>
          <PermissionsTable
            items={combinedItems}
            onUpdateRole={(id) => {
              if (id === data.data.bearer.user.userId) {
                toast({
                  title: 'Error',
                  description: 'Cannot modify your own permissions',
                  variant: 'destructive',
                });
                return;
              }

              if (existingTeamIds.includes(id)) {
                handleAddTeam(id);
              } else {
                handleAddUser(id);
              }
            }}
            isLoading={createAccessRecord.isPending}
            effectiveRole={{
              role: data.data.bearer.effectiveRole.role,
              source: data.data.bearer.effectiveRole.source || null,
              details: {
                organisationRole:
                  data.data.bearer.sources.organisation.workosRole,
              },
            }}
            onAddNew={() => setView('add')}
            resourceId={resourceId}
            resourceType={resourceType}
            isGlobal={isGlobal}
          />
        </div>
      </>
    );
  };

  const renderTrigger = () => {
    const defaultButton = (
      <Button variant="secondary" size="sm">
        <KeyRound className="w-4" />
      </Button>
    );

    if (!trigger) {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <DialogTrigger asChild>{defaultButton}</DialogTrigger>
            </TooltipTrigger>
            <TooltipContent side="top">
              <p>Manage permissions</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }

    if (showTooltip) {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <DialogTrigger asChild>{trigger}</DialogTrigger>
            </TooltipTrigger>
            <TooltipContent side="top">
              <p>Manage permissions</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }

    return <DialogTrigger asChild>{trigger || defaultButton}</DialogTrigger>;
  };

  return (
    <Dialog {...(open !== undefined ? { open, onOpenChange } : {})}>
      {renderTrigger()}
      <DialogContent className="max-w-3xl min-h-[30rem] flex flex-col">
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
};

export type { PermissionsDialogProps };
