import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { createHeaders, showErrorToast, showSuccessToast } from './utils/utils';
import { WorkflowNode, Edge } from './types/types';
import { ResourceType } from '@fluency/ui/hooks/fga/types/fga';
import {
  InfiniteQueryResponse,
  ResourceItem,
  WorkflowResource,
  PageResponse,
} from '../vaults/types/types';
import { Logger } from '@fluency/ui/features/Logger';

type UpdateWorkflowPayload = {
  id: string;
  name?: string;
  description?: string;
  nodes?: WorkflowNode[];
  edges?: Edge[];
  isPublic?: boolean;
  isGlobal?: boolean;
};

interface UpdateWorkflowContext {
  previousWorkflow?: WorkflowResource;
  previousVaultResources?: InfiniteQueryResponse;
  vaultId?: string;
  snapshot?: {
    nodes: WorkflowNode[];
    edges: Edge[];
  };
}

export const useUpdateWorkflow = () => {
  const { accessToken } = useAuth();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const queryClient = useQueryClient();

  return useMutation<
    WorkflowResource,
    Error,
    UpdateWorkflowPayload,
    UpdateWorkflowContext
  >({
    mutationFn: async (payload): Promise<WorkflowResource> => {
      const { id, ...updateData } = payload;

      // Ensure nodes and edges are always included in the payload
      const dataToUpdate = {
        ...updateData,
        ...(updateData.nodes && { nodes: updateData.nodes }),
        ...(updateData.edges && { edges: updateData.edges }),
      };

      const response = await fetch(`${apiBaseUrl}/workflows/${id}`, {
        method: 'PUT',
        headers: createHeaders(accessToken),
        body: JSON.stringify(dataToUpdate),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message
            ? Array.isArray(errorData.message)
              ? errorData.message.join(', ')
              : errorData.message
            : 'Failed to update workflow'
        );
      }

      const result = await response.json();
      return result.workflow;
    },

    onMutate: async (payload): Promise<UpdateWorkflowContext> => {
      // Get current workflow data
      const previousWorkflow = queryClient.getQueryData<WorkflowResource>([
        'workflow',
        payload.id,
      ]);

      const vaultId = previousWorkflow?.vault?.id;
      if (!vaultId) return {};

      // Take a snapshot of current nodes and edges
      const snapshot = {
        nodes: previousWorkflow?.nodes || [],
        edges: previousWorkflow?.edges || [],
      };

      // Cancel any outgoing refetches to prevent them from overwriting our optimistic update
      await Promise.all([
        queryClient.cancelQueries({
          queryKey: ['workflow', payload.id],
        }),
        queryClient.cancelQueries({
          queryKey: ['vaultResources', vaultId],
        }),
      ]);

      // Create optimistically updated workflow
      const optimisticWorkflow: WorkflowResource = {
        ...previousWorkflow!,
        ...payload,
        // Ensure nodes and edges are preserved if not included in the update
        nodes: payload.nodes || previousWorkflow?.nodes || [],
        edges: payload.edges || previousWorkflow?.edges || [],
        updatedAt: new Date().toISOString(),
      };

      // Update workflow cache
      queryClient.setQueryData<WorkflowResource>(
        ['workflow', payload.id],
        optimisticWorkflow
      );

      // Get previous vault resources
      const previousVaultResources =
        queryClient.getQueryData<InfiniteQueryResponse>([
          'vaultResources',
          vaultId,
        ]);

      // Update vault resources cache
      if (previousVaultResources) {
        queryClient.setQueryData<InfiniteQueryResponse>(
          ['vaultResources', vaultId],
          (old): InfiniteQueryResponse | undefined => {
            if (!old?.pages) return old;

            const newPages = old.pages.map((page: PageResponse) => ({
              ...page,
              items: page.items.map((item: ResourceItem) => {
                if (
                  item.type === ResourceType.WORKFLOW &&
                  (item.resource as WorkflowResource).id === payload.id
                ) {
                  return {
                    ...item,
                    type: ResourceType.WORKFLOW,
                    resource: optimisticWorkflow,
                  };
                }
                return item;
              }),
            }));

            return {
              ...old,
              pages: newPages,
            };
          }
        );
      }

      return {
        previousWorkflow,
        previousVaultResources,
        vaultId,
        snapshot,
      };
    },

    onError: (error, variables, context) => {
      Logger.error('Error updating workflow:', error);

      // Revert workflow cache using snapshot
      if (context?.previousWorkflow && context.snapshot) {
        queryClient.setQueryData<WorkflowResource>(['workflow', variables.id], {
          ...context.previousWorkflow,
          nodes: context.snapshot.nodes,
          edges: context.snapshot.edges,
        });
      }

      // Revert vault resources cache
      if (context?.vaultId && context?.previousVaultResources) {
        queryClient.setQueryData(
          ['vaultResources', context.vaultId],
          context.previousVaultResources
        );
      }

      showErrorToast('Failed to update workflow. Changes have been reverted.');
    },

    onSuccess: (updatedWorkflow, variables, context) => {
      // Verify the response contains nodes and edges
      if (!updatedWorkflow.nodes || !updatedWorkflow.edges) {
        Logger.warn('Server response missing nodes or edges, using snapshot');
        updatedWorkflow = {
          ...updatedWorkflow,
          nodes: context?.snapshot?.nodes || [],
          edges: context?.snapshot?.edges || [],
        };
      }

      // Update workflow cache with server data
      queryClient.setQueryData<WorkflowResource>(
        ['workflow', variables.id],
        updatedWorkflow
      );

      if (context?.vaultId) {
        queryClient.invalidateQueries({
          queryKey: ['vaultResources', context.vaultId, undefined],
        });
      }

      if (context?.vaultId) {
        // Update vault resources with server data
        queryClient.setQueryData<InfiniteQueryResponse>(
          ['vaultResources', context.vaultId],
          (old): InfiniteQueryResponse | undefined => {
            if (!old?.pages) return old;

            const newPages = old.pages.map((page) => ({
              ...page,
              items: page.items.map((item) => {
                if (
                  item.type === ResourceType.WORKFLOW &&
                  (item.resource as WorkflowResource).id === variables.id
                ) {
                  return {
                    ...item,
                    type: ResourceType.WORKFLOW,
                    resource: updatedWorkflow,
                  };
                }
                return item;
              }),
            }));

            return {
              ...old,
              pages: newPages,
            };
          }
        );
      }

      showSuccessToast('Workflow updated successfully.');
    },
  });
};
