import { Link, useRouterState } from '@tanstack/react-router';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@fluency/ui/components/ui/breadcrumb';
import React, { useMemo } from 'react';
import { matchUrlPattern, getDynamicValue } from './breadcrumbConfig';
import { useTeamName } from './teamUtils';
import { useVaultName } from './vaultUtils';
import { useDocumentDetails } from './documentUtils';
import { useWorkflowName } from './workflowUtils';

const DynamicBreadcrumbs = () => {
  const routerState = useRouterState();
  const currentPath = routerState.location.pathname;

  // Find matching configuration for current path
  const match = matchUrlPattern(currentPath);
  const params = match?.params ?? {};

  // Initialise hooks with null/empty values when not needed
  const { getTeamName, isLoading: teamLoading } = useTeamName();
  const { getVaultName, isLoading: vaultLoading } = useVaultName();
  const {
    getDocumentName,
    getDocumentVaultId,
    isLoading: docLoading,
  } = useDocumentDetails(
    match && match.config && match.config.pattern.startsWith('/document/')
      ? params.id ?? ''
      : ''
  );
  const {
    getWorkflowName,
    getWorkflowVaultId,
    isLoading: workflowLoading,
  } = useWorkflowName(
    match && match.config && match.config.pattern.startsWith('/workflow/')
      ? params.id ?? ''
      : ''
  );

  // Memoize the hooks object to prevent unnecessary rerenders
  const hooks = useMemo(
    () => ({
      getTeamName,
      teamLoading,
      getVaultName,
      vaultLoading,
      getDocumentName,
      getDocumentVaultId,
      docLoading,
      getWorkflowName,
      getWorkflowVaultId,
      workflowLoading,
    }),
    [
      getTeamName,
      teamLoading,
      getVaultName,
      vaultLoading,
      getDocumentName,
      getDocumentVaultId,
      docLoading,
      getWorkflowName,
      getWorkflowVaultId,
      workflowLoading,
    ]
  );

  // If no match or only one breadcrumb, don't render anything
  if (!match || match.config.breadcrumbs.length <= 1) {
    return null;
  }

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {match.config.breadcrumbs.map((crumb, index) => {
          const isLast = index === match.config.breadcrumbs.length - 1;
          const label = getDynamicValue(crumb.label, match.params, hooks);
          const path = getDynamicValue(crumb.path, match.params, hooks);

          // Create a unique key by combining index and path
          const uniqueKey = `${index}-${path}`;

          // Wrap each breadcrumb item in a fragment with unique key
          return (
            <React.Fragment key={uniqueKey}>
              <BreadcrumbItem key={`item-${uniqueKey}`}>
                {isLast ? (
                  <BreadcrumbPage className="font-semibold capitalize">
                    {label}
                  </BreadcrumbPage>
                ) : (
                  <BreadcrumbLink asChild>
                    <Link to={crumb.linkTo || path}>{label}</Link>
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
              {!isLast && (
                <BreadcrumbSeparator key={`separator-${uniqueKey}`} />
              )}
            </React.Fragment>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export default DynamicBreadcrumbs;
