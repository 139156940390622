import React, { useRef, useState, useEffect } from 'react';

interface Position {
  x: number;
  y: number;
}

interface PixelCropData {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface NormalizedCropData {
  x: number;
  y: number;
  width: number;
  height: number;
}

type ResizeHandle = '' | 'nw' | 'ne' | 'sw' | 'se' | 'n' | 's' | 'w' | 'e';

const HANDLE_POSITIONS = {
  nw: {
    top: '0',
    left: '0',
    cursor: 'nw-resize',
    transform: 'translate(-50%, -50%)',
  },
  ne: {
    top: '0',
    right: '0',
    cursor: 'ne-resize',
    transform: 'translate(50%, -50%)',
  },
  sw: {
    bottom: '0',
    left: '0',
    cursor: 'sw-resize',
    transform: 'translate(-50%, 50%)',
  },
  se: {
    bottom: '0',
    right: '0',
    cursor: 'se-resize',
    transform: 'translate(50%, 50%)',
  },
  n: {
    top: '0',
    left: '50%',
    cursor: 'n-resize',
    transform: 'translate(-50%, -50%)',
  },
  s: {
    bottom: '0',
    left: '50%',
    cursor: 's-resize',
    transform: 'translate(-50%, 50%)',
  },
  w: {
    left: '0',
    top: '50%',
    cursor: 'w-resize',
    transform: 'translate(-50%, -50%)',
  },
  e: {
    right: '0',
    top: '50%',
    cursor: 'e-resize',
    transform: 'translate(50%, -50%)',
  },
} as const;

interface CropOverlayProps {
  imageRef: React.RefObject<HTMLImageElement>;
  cropData: NormalizedCropData;
  aspectRatio: number | null;
  onCropChange: (newCrop: NormalizedCropData) => void;
  containerDimensions: { width: number; height: number };
}

export const CropOverlay: React.FC<CropOverlayProps> = ({
  imageRef,
  cropData,
  aspectRatio,
  onCropChange,
  containerDimensions,
}) => {
  const [pixelCrop, setPixelCrop] = useState<PixelCropData | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [resizeHandle, setResizeHandle] = useState<ResizeHandle>('');
  const startPosRef = useRef<Position>({ x: 0, y: 0 });
  const startCropRef = useRef<PixelCropData | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const getImageDimensions = () => {
    if (!imageRef.current) {
      return {
        width: 0,
        height: 0,
        naturalWidth: 0,
        naturalHeight: 0,
        scale: 1,
        rect: null,
      };
    }

    const rect = imageRef.current.getBoundingClientRect();
    const { naturalWidth, naturalHeight } = imageRef.current;
    const { width, height } = containerDimensions;

    // Calculate scale while maintaining aspect ratio
    const scale = Math.min(width / naturalWidth, height / naturalHeight);
    const actualWidth = naturalWidth * scale;
    const actualHeight = naturalHeight * scale;

    return {
      width: actualWidth,
      height: actualHeight,
      naturalWidth,
      naturalHeight,
      scale,
      rect,
    };
  };

  const denormalize = (normalized: NormalizedCropData): PixelCropData => {
    const { width: imgWidth, height: imgHeight } = getImageDimensions();
    return {
      x: Math.round((normalized.x * imgWidth) / 100),
      y: Math.round((normalized.y * imgHeight) / 100),
      width: Math.round((normalized.width * imgWidth) / 100),
      height: Math.round((normalized.height * imgHeight) / 100),
    };
  };

  const normalize = (pixel: PixelCropData): NormalizedCropData => {
    const { width: imgWidth, height: imgHeight } = getImageDimensions();
    return {
      x: Number(((pixel.x / imgWidth) * 100).toFixed(2)),
      y: Number(((pixel.y / imgHeight) * 100).toFixed(2)),
      width: Number(((pixel.width / imgWidth) * 100).toFixed(2)),
      height: Number(((pixel.height / imgHeight) * 100).toFixed(2)),
    };
  };

  const constrainCrop = (crop: PixelCropData): PixelCropData => {
    const { width: imgWidth, height: imgHeight } = getImageDimensions();
    const minSize = Math.max(20, Math.min(imgWidth, imgHeight) * 0.05);

    let newCrop = { ...crop };

    // Constrain size to minimum
    newCrop.width = Math.max(minSize, newCrop.width);
    newCrop.height = Math.max(minSize, newCrop.height);

    // Maintain aspect ratio if needed
    if (aspectRatio) {
      if (newCrop.width / newCrop.height > aspectRatio) {
        newCrop.width = newCrop.height * aspectRatio;
      } else {
        newCrop.height = newCrop.width / aspectRatio;
      }
    }

    // Ensure crop doesn't exceed image bounds
    if (newCrop.x + newCrop.width > imgWidth) {
      // If we're resizing, adjust width to fit
      if (resizeHandle.includes('e')) {
        newCrop.width = imgWidth - newCrop.x;
        if (aspectRatio) {
          newCrop.height = newCrop.width / aspectRatio;
        }
      } else {
        // If we're moving, adjust x to fit
        newCrop.x = imgWidth - newCrop.width;
      }
    }

    if (newCrop.y + newCrop.height > imgHeight) {
      // If we're resizing, adjust height to fit
      if (resizeHandle.includes('s')) {
        newCrop.height = imgHeight - newCrop.y;
        if (aspectRatio) {
          newCrop.width = newCrop.height * aspectRatio;
        }
      } else {
        // If we're moving, adjust y to fit
        newCrop.y = imgHeight - newCrop.height;
      }
    }

    // Ensure crop doesn't go negative
    newCrop.x = Math.max(0, newCrop.x);
    newCrop.y = Math.max(0, newCrop.y);

    // Final size constraints after all adjustments
    newCrop.width = Math.min(newCrop.width, imgWidth - newCrop.x);
    newCrop.height = Math.min(newCrop.height, imgHeight - newCrop.y);

    return newCrop;
  };

  useEffect(() => {
    if (imageRef.current) {
      const newPixelCrop = denormalize(cropData);
      setPixelCrop(constrainCrop(newPixelCrop));
    }
  }, [cropData, containerDimensions.width, containerDimensions.height]);

  const getMousePos = (e: React.MouseEvent | MouseEvent): Position => {
    const { rect } = getImageDimensions();
    if (!rect) return { x: 0, y: 0 };

    return {
      x: Math.min(
        Math.max(0, e.clientX - rect.left),
        containerDimensions.width
      ),
      y: Math.min(
        Math.max(0, e.clientY - rect.top),
        containerDimensions.height
      ),
    };
  };

  const updateCrop = (newPixelCrop: PixelCropData) => {
    const constrained = constrainCrop(newPixelCrop);
    setPixelCrop(constrained);
    onCropChange(normalize(constrained));
  };

  useEffect(() => {
    if (!isDragging && !resizeHandle) return;

    const handleMouseMove = (e: MouseEvent) => {
      if (!pixelCrop || !startCropRef.current) return;

      const pos = getMousePos(e);
      const deltaX = pos.x - startPosRef.current.x;
      const deltaY = pos.y - startPosRef.current.y;
      let newCrop = { ...startCropRef.current };

      if (isDragging) {
        newCrop.x += deltaX;
        newCrop.y += deltaY;
      } else if (resizeHandle) {
        const isEast = resizeHandle.includes('e');
        const isWest = resizeHandle.includes('w');
        const isNorth = resizeHandle.includes('n');
        const isSouth = resizeHandle.includes('s');

        if (isEast) newCrop.width += deltaX;
        if (isWest) {
          newCrop.width -= deltaX;
          newCrop.x += deltaX;
        }
        if (isNorth) {
          newCrop.height -= deltaY;
          newCrop.y += deltaY;
        }
        if (isSouth) newCrop.height += deltaY;
      }

      updateCrop(newCrop);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setResizeHandle('');
      startCropRef.current = null;
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, resizeHandle, aspectRatio, pixelCrop, containerDimensions]);

  if (!pixelCrop) return null;

  return (
    <div
      ref={containerRef}
      className="absolute inset-0"
      style={{
        width: containerDimensions.width,
        height: containerDimensions.height,
        overflow: 'hidden',
      }}
    >
      {/* Dark overlay */}
      <div
        className="absolute inset-0 bg-black/50"
        style={{
          clipPath: `path('M 0 0 H ${containerDimensions.width} V ${
            containerDimensions.height
          } H 0 V 0 M ${pixelCrop.x} ${pixelCrop.y} H ${
            pixelCrop.x + pixelCrop.width
          } V ${pixelCrop.y + pixelCrop.height} H ${pixelCrop.x} V ${
            pixelCrop.y
          } Z')`,
        }}
      />

      {/* Crop rectangle */}
      <div
        className="absolute border-2 border-white"
        style={{
          left: `${pixelCrop.x}px`,
          top: `${pixelCrop.y}px`,
          width: `${pixelCrop.width}px`,
          height: `${pixelCrop.height}px`,
          cursor: isDragging ? 'grabbing' : 'grab',
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          setIsDragging(true);
          setResizeHandle('');
          startPosRef.current = getMousePos(e);
          startCropRef.current = pixelCrop;
        }}
      >
        {/* Grid lines */}
        <div className="absolute inset-0 pointer-events-none">
          {[1 / 3, 2 / 3].map((fraction, i) => (
            <React.Fragment key={i}>
              <div
                className="absolute top-0 bottom-0 w-px bg-white/50"
                style={{ left: `${fraction * 100}%` }}
              />
              <div
                className="absolute left-0 right-0 h-px bg-white/50"
                style={{ top: `${fraction * 100}%` }}
              />
            </React.Fragment>
          ))}
        </div>

        {/* Resize handles */}
        {Object.entries(HANDLE_POSITIONS).map(([handle, style]) => (
          <div
            key={handle}
            className="absolute w-3 h-3 bg-white border border-gray-800 rounded-full"
            style={style}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setResizeHandle(handle as ResizeHandle);
              startPosRef.current = getMousePos(e);
              startCropRef.current = pixelCrop;
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CropOverlay;
