import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  DocumentationResponse,
  Step,
} from '../../../hooks/documentation/types/master';
import { Logger } from '@fluency/ui/features/Logger';

interface InsertStepResponse {
  message: string;
  steps: {
    stepId: string;
    stepNumber: number;
    stepDescription: string;
    stepType: string;
    documentationId: string;
  }[];
}

interface InsertStepPayload {
  stepId: string;
  stepDescription: string;
  stepType: string;
  newIndex: number;
}

async function insertSteps(
  documentationId: string,
  stepBeforeId: string | null,
  insertedSteps: InsertStepPayload[],
  token: string
): Promise<InsertStepResponse> {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  const response = await fetch(`${apiBaseUrl}/step/insert`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      documentationId,
      stepBeforeId,
      insertedSteps,
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to insert steps');
  }
  const data = await response.json();
  return data;
}

interface MutationContext {
  previousDoc?: DocumentationResponse;
}

export function useInsertSteps() {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation<
    InsertStepResponse,
    Error,
    {
      documentationId: string;
      stepBeforeId: string | null;
      insertedSteps: InsertStepPayload[];
    },
    MutationContext
  >({
    mutationKey: ['insertSteps'],
    mutationFn: async ({ documentationId, stepBeforeId, insertedSteps }) => {
      return insertSteps(
        documentationId,
        stepBeforeId,
        insertedSteps,
        accessToken as string
      );
    },
    onMutate: async ({ documentationId, stepBeforeId, insertedSteps }) => {
      await queryClient.cancelQueries({
        queryKey: ['docInfo', documentationId],
      });

      const previousDoc = queryClient.getQueryData<DocumentationResponse>([
        'docInfo',
        documentationId,
      ]);

      if (!previousDoc) {
        return { previousDoc: undefined };
      }

      const oldSteps = [...previousDoc.documentation.steps];
      let newSteps: Step[] = [];
      let currentIndex = 1;

      const createNewStep = (
        newStep: InsertStepPayload,
        index: number
      ): Step => ({
        stepId: newStep.stepId,
        stepNumber: index,
        stepDescription: newStep.stepDescription,
        linkedDocumentation: documentationId,
        stepType: newStep.stepType,
        documentationId: documentationId,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        screenshots: [],
      });

      if (stepBeforeId === null) {
        // Insert new steps at the beginning
        insertedSteps.forEach((newStep) => {
          newSteps.push(createNewStep(newStep, currentIndex++));
        });

        // Add existing steps after the new steps
        oldSteps.forEach((step) => {
          newSteps.push({ ...step, stepNumber: currentIndex++ });
        });
      } else {
        oldSteps.forEach((step) => {
          if (step.stepId === stepBeforeId) {
            newSteps.push({ ...step, stepNumber: currentIndex++ });
            insertedSteps.forEach((newStep) => {
              newSteps.push(createNewStep(newStep, currentIndex++));
            });
          } else {
            newSteps.push({ ...step, stepNumber: currentIndex++ });
          }
        });
      }

      queryClient.setQueryData<DocumentationResponse>(
        ['docInfo', documentationId],
        (old: DocumentationResponse | undefined) => {
          if (!old) return old;
          return {
            ...old,
            steps: newSteps,
            message: old.message,
            documentation: {
              ...old.documentation,
              steps: newSteps,
            },
          };
        }
      );

      return { previousDoc };
    },
    onError: (_error, variables, context) => {
      Logger.log('error', _error);
      toast({
        variant: 'destructive',
        title: 'Something went wrong.',
        description: 'There was a problem with your request.',
      });

      if (context?.previousDoc) {
        queryClient.setQueryData(
          ['docInfo', variables.documentationId],
          context.previousDoc
        );
      }
    },
    onSuccess: (data) => {
      toast({
        variant: 'default',
        title: 'Success',
        description: data.message,
      });
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['docInfo', variables.documentationId],
      });
    },
  });
}

export default useInsertSteps;
