interface OrganisationDetails {
  organisationRole: string;
}

interface EffectiveRole {
  role: 'MANAGER' | 'VIEWER' | 'EDITOR';
  source: 'organisation' | 'direct' | 'team' | 'inherited';
  details?: {
    organisationRole?: string;
  };
}

interface OrganisationPermission {
  role: string;
  effectiveRole: 'MANAGER' | 'VIEWER' | 'EDITOR';
}

interface DirectPermission {
  roles: Array<'MANAGER' | 'VIEWER' | 'EDITOR'>;
}

interface InheritedPermission {
  sourceResourceId: string;
  sourceResourceType: 'VAULT' | 'DOCUMENTATION' | 'WORKFLOW';
  roles: Array<'MANAGER' | 'VIEWER' | 'EDITOR'>;
}

interface AllPermissions {
  organisation?: OrganisationPermission;
  direct: DirectPermission;
  team: Array<any>;
  inherited: InheritedPermission[];
}

interface ResourcePermission {
  effectiveRole: EffectiveRole;
  allPermissions: AllPermissions;
}

export const RoleName = {
  MANAGER: 'MANAGER',
  EDITOR: 'EDITOR',
  VIEWER: 'VIEWER',
  COMMENTER: 'COMMENTER',
} as const;

export type RoleName = (typeof RoleName)[keyof typeof RoleName];

export const ResourceType = {
  VAULT: 'VAULT',
  DOCUMENTATION: 'DOCUMENTATION',
  WORKFLOW: 'WORKFLOW',
} as const;

export type ResourceType = (typeof ResourceType)[keyof typeof ResourceType];

export type {
  ResourcePermission,
  EffectiveRole,
  OrganisationPermission,
  DirectPermission,
  InheritedPermission,
  AllPermissions,
  OrganisationDetails,
};
