import { Button } from '@fluency/ui/components/ui/button';
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@fluency/ui/components/ui/dialog';
import { Input } from '@fluency/ui/components/ui/input';
import { Switch } from '@fluency/ui/components/ui/switch';
import { useResourcePermissions } from '@fluency/ui/hooks/fga/useResourcePermissions';
import { cn } from '@fluency/ui/lib/utils';
import { Dialog } from '@radix-ui/react-dialog';
import { CheckCircle, Copy, Globe, Lock, RefreshCw } from 'lucide-react';
import React, { useRef, useState } from 'react';
import { useToggleSharing } from '../hooks/usePermissionToggles';
import { DocumentationResponse } from '../../../hooks/documentation/types/master';
import { Logger } from '@fluency/ui/features/Logger';

interface EmbedModalProps {
  data: DocumentationResponse;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

const EmbedModal = ({ data, isOpen, onOpenChange }: EmbedModalProps) => {
  const [showCopied, setShowCopied] = useState(false);
  const toggleSharing = useToggleSharing();
  const linkRef = useRef<HTMLInputElement>(null);
  const resourcePermissions = useResourcePermissions({
    resourceType: 'DOCUMENTATION',
    resourceId: data.documentation.documentationId,
  });

  const isAdmin =
    resourcePermissions.data?.data.bearer.effectiveRole.role === 'MANAGER';

  const iFrameWidth = '100';

  const iFrame = `<iframe src="${import.meta.env.VITE_APP_URL}/share/${
    data.documentation.shareId
  }" width="${iFrameWidth}%" height="600px" allowfullscreen frameborder="0"></iframe>`;

  const handleCopyIFrame = async () => {
    if (!iFrame || !data.documentation.isPublic) return;
    try {
      await navigator.clipboard.writeText(iFrame);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    } catch (err) {
      Logger.error('Failed to copy:', err);
    }
  };

  const handleIFrameFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (data.documentation.isPublic) {
      e.target.select();
    }
  };

  const onTogglePublic = () => {
    toggleSharing.mutate({
      documentationId: data.documentation.documentationId,
      value: !data.documentation.isPublic,
    });
  };
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Embed '{data.documentation.documentationName}' anywhere
          </DialogTitle>
          <DialogDescription>
            Embed this document into any HTML page using the iFrame below
          </DialogDescription>
        </DialogHeader>

        <div className="flex flex-col gap-4 py-2">
          <div className="flex items-center justify-between px-4 py-2 bg-gray-100 rounded-lg">
            <div className="flex items-center gap-4">
              <Globe className="h-5 w-5 text-gray-500" />
              <div className="flex flex-col">
                <span className="text-sm font-medium">Public sharing</span>
                <span className="text-xs text-gray-500">
                  {isAdmin
                    ? 'Anyone with the link can view this document'
                    : 'Only administrators can enable public sharing'}
                </span>
              </div>
            </div>
            {isAdmin ? (
              toggleSharing.isPending ? (
                <RefreshCw className="h-4 w-4 animate-spin text-gray-500" />
              ) : (
                <Switch
                  checked={data.documentation.isPublic}
                  onCheckedChange={onTogglePublic}
                  aria-label="Toggle public sharing"
                />
              )
            ) : (
              <Lock className="h-4 w-4 text-gray-500" />
            )}
          </div>

          <div className="flex items-center gap-2 p-2 border rounded-lg bg-gray-50">
            <Input
              ref={linkRef}
              className={cn(
                'flex-1 border-0 bg-transparent focus-visible:ring-0',
                !data.documentation.isPublic &&
                  'text-gray-400 cursor-not-allowed'
              )}
              value={
                data.documentation.isPublic
                  ? iFrame
                  : 'Enable public sharing to access iFrame embed'
              }
              readOnly
              disabled={!data.documentation.isPublic || toggleSharing.isPending}
              onFocus={handleIFrameFocus}
            />
            <Button
              variant="ghost"
              size="sm"
              className="gap-2 hover:bg-gray-200"
              onClick={handleCopyIFrame}
              disabled={!data.documentation.isPublic || toggleSharing.isPending}
            >
              {showCopied ? (
                <>
                  <CheckCircle className="h-4 w-4" />
                  <span className="text-sm">Copied!</span>
                </>
              ) : (
                <>
                  <Copy className="h-4 w-4" />
                  <span className="text-sm">Copy</span>
                </>
              )}
            </Button>
          </div>
          <span className="text-xs text-gray-400">
            Your document needs to be public for your iFrame to render it
            properly
          </span>
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button
              disabled={toggleSharing.isPending}
              onClick={() => onOpenChange(false)}
            >
              Done
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EmbedModal;
