import { Documents, Header } from './components/index';

interface DocumentPublicProps {
  documentId: string;
}

const DocumentPublic: React.FC<DocumentPublicProps> = () => (
  <>
    <Header />
    <Documents />
  </>
);

export default DocumentPublic;
