import { RefreshCw } from 'lucide-react';
import ProgressBar from '@fluency/ui/features/Vaults/components/ProgressBar';
import useUploadDocs from '@fluency/ui/features/Vaults/hooks/useUploadDocs';

interface InProgressProps {
  docId: string;
}

export default function InProgress({ docId }: InProgressProps) {
  const { docsInProgress } = useUploadDocs();
  const currentDoc = docsInProgress[docId];
  const progress = currentDoc?.progress ?? 0;

  return (
    <div className="flex justify-center text-sm font-medium items-center flex-col h-[90vh]">
      <p className="mt-4 text-gray-600 flex gap-2 items-center">
        <RefreshCw className="w-4 animate-spin" />{' '}
        <span>Upload in progress</span>
      </p>
      <div className="mt-4 w-64">
        <ProgressBar progress={progress} />
        <p className="text-sm text-gray-500 text-center mt-2">
          {progress}% complete
        </p>
      </div>
    </div>
  );
}
