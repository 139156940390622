import { RefreshCw } from 'lucide-react';
import { StepItem } from './StepItem';
import type { Step } from '../types/index';

interface StepListProps {
  steps: Step[];
  isLoading: boolean;
  error: any;
  activeStepId: string | null;
  selectedSteps: string[];
  draggedItem: Step | null;
  dragOverIndex: number | null;
  onSelect: (stepId: string, e: React.MouseEvent) => void;
  onDragStart: (e: React.DragEvent<HTMLDivElement>, step: Step) => void;
  onDragEnd: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  onStepDragOver: (e: React.DragEvent<HTMLDivElement>, index: number) => void;
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  onJump: (stepId: string) => void;
}

const getStepNumber = (steps: Step[], currentIndex: number): number => {
  return steps
    .slice(0, currentIndex + 1)
    .filter((step) => step.stepType === 'STEP').length;
};

export function StepList({
  steps,
  isLoading,
  error,
  activeStepId,
  selectedSteps,
  draggedItem,
  dragOverIndex,
  onSelect,
  onDragStart,
  onDragEnd,
  onDragOver,
  onStepDragOver,
  onDrop,
  onJump,
}: StepListProps) {
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-40 text-gray-500">
        <RefreshCw className="animate-spin w-4 mr-2" />
        Loading steps...
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-4 text-red-500">
        An error occurred while loading steps
      </div>
    );
  }

  const hasSelectedSteps = selectedSteps.length > 0;

  return (
    <div className="space-y-0.5 pb-16 pt-1" onDragOver={onDragOver}>
      {steps.map((step, index) => {
        const stepNumber =
          step.stepType === 'STEP' ? getStepNumber(steps, index) : null;
        return (
          <div key={step.stepId} onDragOver={(e) => onStepDragOver(e, index)}>
            <StepItem
              step={{
                ...step,
                stepNumber: stepNumber || step.stepNumber,
              }}
              isActive={activeStepId === step.stepId}
              isSelected={selectedSteps.includes(step.stepId)}
              isDragged={draggedItem?.stepId === step.stepId}
              hasSelectedSteps={hasSelectedSteps}
              onSelect={onSelect}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onDrop={onDrop}
              onClick={() => onJump(step.stepId)}
              showDragIndicator={
                dragOverIndex === index || dragOverIndex === index + 1
              }
              position={dragOverIndex === index ? 'top' : 'bottom'}
            />
          </div>
        );
      })}
    </div>
  );
}
