import { toast } from '@fluency/ui/components/ui/use-toast';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import {
  UseQueryResult,
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { Logger } from '@fluency/ui/features/Logger';

export interface DocumentVersion {
  versionId: string;
  major: number;
  minor: number;
  patch: number;
  editedBy: string;
  createdAt: string;
  changeSummary: string;
  originalDocumentationId: string;
  versionedDocumentId: string;
}

interface ApiResponse {
  message: string;
  versions: DocumentVersion[];
}

interface CreateVersionParams {
  originalDocumentationId: string;
  previousVersionedDocumentId: string;
  changeSummary: string;
  major: number;
  minor: number;
  patch: number;
  createdAt: string;
}

interface CreateVersionResponse {
  message: string;
  versionedDocumentId: string;
}

export const useFetchDocVersions = (
  documentId: string | null | undefined
): {
  query: UseQueryResult<DocumentVersion[], Error>;
  createVersion: (params: CreateVersionParams) => Promise<void>;
} => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const query = useQuery<DocumentVersion[], Error>({
    queryKey: ['versions', documentId],
    queryFn: async () => {
      if (!documentId) {
        Logger.error('Document ID is not provided');
        throw new Error('Document ID is not provided');
      }

      try {
        const apiUrl = import.meta.env.VITE_SERVER_API_URL;
        const endpoint = '/documents/listVersions';
        const fullUrl = `${apiUrl}${endpoint}?originalDocumentationId=${documentId}`;

        const response = await fetch(fullUrl, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          redirect: 'follow',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result: ApiResponse = await response.json();
        Logger.log('result:', result);
        return result.versions;
      } catch (error) {
        Logger.error('Error in queryFn:', error);
        throw new Error('Unable to fetch document versions.');
      }
    },
    enabled: !!documentId && !!accessToken,
  });

  const createVersionMutation = useMutation<
    CreateVersionResponse,
    Error,
    CreateVersionParams
  >({
    mutationFn: async (params: CreateVersionParams) => {
      const apiUrl = import.meta.env.VITE_SERVER_API_URL;
      const endpoint = '/documents/createVersion';
      const fullUrl = `${apiUrl}${endpoint}`;

      const urlencoded = new URLSearchParams();
      urlencoded.append(
        'originalDocumentationId',
        params.originalDocumentationId
      );
      urlencoded.append(
        'previousVersionedDocumentId',
        params.previousVersionedDocumentId
      );
      urlencoded.append('changeSummary', params.changeSummary);
      urlencoded.append('major', params.major.toString());
      urlencoded.append('minor', params.minor.toString());
      urlencoded.append('patch', params.patch.toString());
      urlencoded.append('createdAt', params.createdAt);

      const response = await fetch(fullUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${accessToken}`,
        },
        body: urlencoded,
        redirect: 'follow',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return response.json();
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['versions', documentId] });
      const newVersion = `${variables.major}.${variables.minor}.${variables.patch}`;
      toast({
        title: `Successfully created version ${newVersion}`,
        description: data.message,
        duration: 5000,
      });

      if (data.versionedDocumentId) {
        navigate({
          to: '/document/$id',
          params: { id: data.versionedDocumentId },
          replace: true,
        });
      }
    },
    onError: (error) => {
      Logger.error('Mutation error:', error);
      toast({
        title: 'Error',
        description: 'Failed to create new version. Please try again.',
        variant: 'destructive',
      });
    },
  });

  const createVersion = async (params: CreateVersionParams) => {
    await createVersionMutation.mutateAsync(params);
  };

  return { query, createVersion };
};

export default useFetchDocVersions;
