import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@fluency/ui/components/ui/table';
import { Button } from '@fluency/ui/components/ui/button';
import { Badge } from '@fluency/ui/components/ui/badge';
import { Input } from '@fluency/ui/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@fluency/ui/components/ui/select';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import {
  Building2,
  Plus,
  Search,
  User,
  User2,
  Users,
  UsersRound,
} from 'lucide-react';
import { useState } from 'react';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { EffectiveRoleInfo } from '@fluency/ui/hooks/fga/types/types';
import { RevokePermissionDialog } from './RevokeModal';
import { UpdateRoleDialog } from './UpdateModal';
import { ResourceType, RoleName } from '@fluency/ui/hooks/fga/types/fga';
import { usePermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { useResourcePermissions } from '@fluency/ui/hooks/fga/useResourcePermissions';

// Types
interface PermissionItem {
  id: string;
  name: string;
  role: RoleName;
  type: 'user' | 'team';
  email?: string;
}

// Helper Components
const GlobalBanner = () => (
  <div className="mb-4 border rounded py-2 px-4 text-sm font-medium bg-fluency-50 border-fluency-200 text-fluency-700 flex items-center gap-2">
    <Building2 className="w-5 text-fluency-600" />
    This vault is shared with your entire organisation.
  </div>
);

const UserAccessCard = ({
  user,
  effectiveRole,
  formatRoleDisplay,
  formatSourceDisplay,
}: {
  user: any;
  effectiveRole: EffectiveRoleInfo;
  formatRoleDisplay: (role: RoleName | null) => string;
  formatSourceDisplay: (source: string | null) => string;
}) => (
  <Card className="border-0">
    <CardHeader className="pb-3 pt-0 px-0">
      <CardTitle className="text-base font-semibold">Your Access</CardTitle>
    </CardHeader>
    <CardContent className="p-0 bg-gray-50 border rounded-lg">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="font-medium flex items-center gap-4">
              <User2 className="h-8 w-8 text-muted-foreground" />
              <div className="flex items-start flex-col">
                <span>
                  {user?.firstName && user?.lastName
                    ? `${user.firstName} ${user.lastName}`
                    : user?.email}
                </span>
                <p className="font-normal text-gray-500">
                  {formatRoleDisplay(effectiveRole.role)} (via{' '}
                  {formatSourceDisplay(effectiveRole.source)})
                </p>
              </div>
            </TableCell>
            <TableCell className="text-right"></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </CardContent>
  </Card>
);

const FilterBar = ({
  searchTerm,
  setSearchTerm,
  filterType,
  setFilterType,
  canCreate,
  onAddNew,
}: {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  filterType: string;
  setFilterType: (type: string) => void;
  canCreate: boolean;
  onAddNew: () => void;
}) => (
  <div className="flex items-center justify-between">
    <div className="flex items-center gap-2">
      <div className="relative">
        <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
        <Input
          placeholder="Search by name or email"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-[300px] pl-8"
        />
      </div>

      <Select value={filterType} onValueChange={setFilterType}>
        <SelectTrigger className="w-[120px]">
          <SelectValue placeholder="Filter by type" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="all">All</SelectItem>
          <SelectItem value="user">Users</SelectItem>
          <SelectItem value="team">Teams</SelectItem>
        </SelectContent>
      </Select>
    </div>
    {canCreate && (
      <Button onClick={onAddNew} className="gap-2">
        <Plus className="h-4 w-4" />
        Add Permission
      </Button>
    )}
  </div>
);

const EmptyState = ({
  canCreate,
  onAddNew,
}: {
  canCreate: boolean;
  onAddNew: () => void;
}) => (
  <TableRow>
    <TableCell colSpan={5}>
      <div className="flex flex-col items-center justify-center py-12 space-y-3">
        <div className="p-3 rounded-full bg-gray-50">
          <Users className="w-6 h-6 text-gray-400" />
        </div>
        <div className="text-center space-y-1">
          <p className="text-sm font-medium text-gray-900">
            No additional permissions assigned
          </p>
          <p className="text-sm text-gray-500">
            {canCreate
              ? 'Share access by adding permissions for users or teams'
              : 'No users or teams have been given access yet'}
          </p>
        </div>
        {canCreate && (
          <Button onClick={onAddNew} className="gap-2 mt-4">
            <Plus className="h-4 w-4" />
            Add Permission
          </Button>
        )}
      </div>
    </TableCell>
  </TableRow>
);

const PermissionRow = ({
  item,
  canUpdate,
  canDelete,
  resourceId,
  resourceType,
  formatRoleDisplay,
}: {
  item: PermissionItem;
  canUpdate: boolean;
  canDelete: boolean;
  resourceId: string;
  resourceType: ResourceType;
  formatRoleDisplay: (role: RoleName | null) => string;
}) => (
  <TableRow key={item.id} className="flex gap-2 items-center justify-between">
    <TableCell className="font-medium gap-2 flex items-center h-[68px]">
      {item.type === 'user' ? (
        <User className="h-4 w-4 text-muted-foreground" />
      ) : (
        <UsersRound className="h-4 w-4 text-muted-foreground" />
      )}
      {item.name}
      <Badge className="font-medium">{formatRoleDisplay(item.role)}</Badge>
      <Badge variant="secondary" className="font-medium capitalize">
        {item.type}
      </Badge>
    </TableCell>
    <TableCell className="text-right items-center flex gap-2">
      {canUpdate && (
        <UpdateRoleDialog
          id={item.id}
          resourceId={resourceId}
          resourceType={resourceType}
          name={item.name}
          currentRole={item.role}
          type={item.type}
        />
      )}
      {canDelete && (
        <RevokePermissionDialog
          id={item.id}
          name={item.name}
          resourceId={resourceId}
          resourceType={resourceType}
          type={item.type}
        />
      )}
    </TableCell>
  </TableRow>
);

interface PermissionsTableProps {
  items: PermissionItem[];
  onUpdateRole: (id: string) => void;
  isLoading: boolean;
  effectiveRole: EffectiveRoleInfo;
  onAddNew: () => void;
  resourceId: string;
  resourceType: ResourceType;
  isGlobal: boolean;
}

// Main Component
export const PermissionsTable = ({
  items,
  effectiveRole,
  onAddNew,
  resourceId,
  resourceType,
  isGlobal,
}: PermissionsTableProps) => {
  const { user } = useAuth();
  const { hasPermissions } = usePermissionGate();
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all');

  const { data: permissions } = useResourcePermissions({
    resourceType,
    resourceId,
  });

  const isManager = permissions?.data.bearer.effectiveRole.role === 'MANAGER';
  const canCreate = hasPermissions(['fga:create']) && isManager;
  const canDelete = hasPermissions(['fga:delete']) && isManager;
  const canUpdate = hasPermissions(['fga:update']) && isManager;

  const currentUserItem: PermissionItem = {
    id: user?.id as string,
    name:
      user?.firstName && user?.lastName
        ? `${user?.firstName} ${user?.lastName}`
        : (user?.email as string),
    role: effectiveRole.role as RoleName,
    type: 'user' as const,
    email: user?.email,
  };

  const formatRoleDisplay = (role: RoleName | null) => {
    if (!role) return 'No Access';
    return role.charAt(0) + role.slice(1).toLowerCase();
  };

  const formatSourceDisplay = (source: string | null) => {
    if (!source) return '';
    return source.charAt(0) + source.slice(1).toLowerCase();
  };

  const filteredItems = items
    .filter((item) => item.id !== currentUserItem.id)
    .filter(
      (item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.email?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((item) => filterType === 'all' || item.type === filterType)
    .sort((a, b) => {
      if (a.type !== b.type) {
        return a.type === 'user' ? -1 : 1;
      }
      return a.name.localeCompare(b.name);
    });

  return (
    <div className="flex flex-col gap-4">
      {isGlobal && <GlobalBanner />}
      <UserAccessCard
        user={user}
        effectiveRole={effectiveRole}
        formatRoleDisplay={formatRoleDisplay}
        formatSourceDisplay={formatSourceDisplay}
      />
      <Card className="border-0">
        <CardHeader className="pb-3 p-0 border-0">
          <CardTitle className="text-base font-semibold">Permissions</CardTitle>
          {filteredItems.length > 0 && (
            <FilterBar
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              filterType={filterType}
              setFilterType={setFilterType}
              canCreate={canCreate}
              onAddNew={onAddNew}
            />
          )}
        </CardHeader>
        <CardContent className="p-0 border-t border-b mt-4">
          <Table>
            <TableBody>
              {filteredItems.length > 0 ? (
                filteredItems.map((item) => (
                  <PermissionRow
                    key={item.id}
                    item={item}
                    canUpdate={canUpdate}
                    canDelete={canDelete}
                    resourceId={resourceId}
                    resourceType={resourceType}
                    formatRoleDisplay={formatRoleDisplay}
                  />
                ))
              ) : (
                <EmptyState canCreate={canCreate} onAddNew={onAddNew} />
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default PermissionsTable;
