import { Badge } from '@fluency/ui/components/ui/badge';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHeader,
  TableHead,
} from '@fluency/ui/components/ui/table';
import InviteUsers from './InviteUser';
import RevokeInviteModal from './RevokeInviteModal';
import { RefreshCw } from 'lucide-react';
import useOrgInvites from '@fluency/ui/hooks/organisation/useOrgInvites';
import { Invitation } from '@fluency/ui/hooks/organisation/types/Invitation';

export default function InvitationsTable() {
  const { invites, isLoadingInvites } = useOrgInvites();

  const activeInvites = invites.filter(
    (invite) => invite.state !== 'accepted' && invite.state !== 'revoked'
  );

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Invitations</TableHead>
          <TableHead className="text-right">
            <InviteUsers />
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isLoadingInvites ? (
          <TableRow>
            <TableCell colSpan={2} className="text-center py-8">
              <RefreshCw className="h-6 w-6 animate-spin mx-auto text-gray-400" />
            </TableCell>
          </TableRow>
        ) : activeInvites.length === 0 ? (
          <TableRow>
            <TableCell colSpan={2} className="text-center py-8 text-gray-400">
              No active invitations
            </TableCell>
          </TableRow>
        ) : (
          activeInvites.map((invite: Invitation) => (
            <TableRow key={invite.id}>
              <TableCell>
                <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                  <span>{invite.email}</span>
                  <div className="flex flex-wrap gap-2">
                    <Badge
                      variant={
                        invite.state === 'pending' ? 'secondary' : 'outline'
                      }
                    >
                      {invite.state.charAt(0).toUpperCase() +
                        invite.state.slice(1)}
                    </Badge>
                    <Badge
                      variant={
                        invite.state === 'pending' ? 'secondary' : 'outline'
                      }
                    >
                      Expires {new Date(invite.expiresAt).toLocaleDateString()}
                    </Badge>
                  </div>
                </div>
              </TableCell>
              <TableCell className="text-gray-500 text-right items-end justify-end flex">
                {invite.state === 'pending' && (
                  <RevokeInviteModal
                    inviteId={invite.id}
                    email={invite.email}
                  />
                )}
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
}
