import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import { Button } from '@fluency/ui/components/ui/button';
import { useResourceAccessControl } from '@fluency/ui/hooks/fga/useResourceAccessControl';
import { Loader2, X } from 'lucide-react';
import { ResourceType } from '@fluency/ui/hooks/fga/types/fga';
import { Logger } from '@fluency/ui/features/Logger';

interface RevokePermissionDialogProps {
  id: string;
  resourceId: string;
  resourceType: ResourceType;
  name: string;
  type: 'user' | 'team';
}

export const RevokePermissionDialog = ({
  id,
  resourceId,
  resourceType,
  name,
  type,
}: RevokePermissionDialogProps) => {
  const { deleteAccessRecord } = useResourceAccessControl();

  const handleRevoke = async () => {
    try {
      if (type === 'user') {
        await deleteAccessRecord.mutateAsync({
          userId: id,
          resourceId,
          resourceType,
        });
      } else {
        await deleteAccessRecord.mutateAsync({
          teamId: id,
          resourceId,
          resourceType,
        });
      }
    } catch (error) {
      Logger.error('Failed to revoke access:', error);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost" size="sm">
          <X className="w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Revoke Access Permission</DialogTitle>
          <DialogDescription>
            Are you sure you want to revoke access permissions for{' '}
            {type === 'team' ? 'team' : ''} {name}? This action cannot be
            undone.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button
            variant="destructive"
            onClick={handleRevoke}
            disabled={deleteAccessRecord.isPending}
          >
            {deleteAccessRecord.isPending && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            Revoke Access
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
