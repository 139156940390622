import * as Sentry from '@sentry/react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { DocumentationsResponse } from './types/GetDocs';

interface GetDocsParams {
  page?: number;
  pageSize?: number;
  vaultId: string;
}

const useGetDocs = ({
  page = 1,
  pageSize = 200,
  vaultId,
}: GetDocsParams): UseQueryResult<DocumentationsResponse, Error> => {
  const { accessToken } = useAuth();

  return useQuery<DocumentationsResponse, Error>({
    queryKey: ['documentation', page, pageSize, vaultId],
    queryFn: async (): Promise<DocumentationsResponse> => {
      const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', `Bearer ${accessToken}`);

      const requestOptions: RequestInit = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      // Build URL with optional vaultId parameter
      const baseUrl = `${apiBaseUrl}/documents/list?pageSize=${pageSize}&page=${page}`;
      const url = vaultId ? `${baseUrl}&vaultId=${vaultId}` : baseUrl;

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        Sentry.captureException('Failed to get documentation');
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result as DocumentationsResponse;
    },
    // Disable the query if vaultId is an empty string
    enabled: vaultId !== '',
  });
};

export default useGetDocs;
