import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Building2, Lock, Unlock, PencilIcon } from 'lucide-react';
import { useToast } from '@fluency/ui/components/ui/use-toast';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '@fluency/ui/components/ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@fluency/ui/components/ui/form';
import { Input } from '@fluency/ui/components/ui/input';
import { Button } from '@fluency/ui/components/ui/button';
import { Switch } from '@fluency/ui/components/ui/switch';
import { Textarea } from '@fluency/ui/components/ui/textarea';
import { Separator } from '@fluency/ui/components/ui/separator';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import type { Vault } from '../types/types';
import DeleteVaultButton from './DeleteVaultButton';
import {
  updateVaultSchema,
  UpdateVaultFormData,
} from '../../utils/vaultSchema';
import { usePermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';

interface VaultSwitchProps {
  id: keyof Pick<UpdateVaultFormData, 'isPublic' | 'isGlobal' | 'isLocked'>;
  label: string;
  description: string;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  icon: React.ReactNode;
  disabled?: boolean;
}

const VaultSwitch = ({
  id,
  label,
  description,
  checked,
  onCheckedChange,
  icon,
  disabled,
}: VaultSwitchProps) => (
  <div className="items-center mb-4 flex justify-between">
    <div className="flex gap-4 items-center min-w-[200px]">
      <Switch
        id={id}
        checked={checked}
        onCheckedChange={onCheckedChange}
        disabled={disabled}
      />
      <div className="flex items-start justify-start flex-col gap-1">
        <FormLabel className="font-medium" htmlFor={id}>
          {label}
        </FormLabel>
        <FormDescription>{description}</FormDescription>
      </div>
    </div>
    <div className={checked ? 'text-fluency-500' : 'text-muted-foreground'}>
      {icon}
    </div>
  </div>
);

const getVaultSwitches = (isLocked: boolean) => [
  {
    id: 'isGlobal' as const,
    label: 'Add entire organisation',
    description: 'Grant access to everyone in your organisation automatically',
    icon: <Building2 className="h-5 w-5" />,
  },
  {
    id: 'isLocked' as const,
    label: isLocked ? 'Vault is locked' : 'Vault is unlocked',
    description: isLocked
      ? 'Vault is currently locked. All changes are prevented.'
      : 'Lock the vault to prevent any changes to resources',
    icon: isLocked ? (
      <Lock className="h-5 w-5" />
    ) : (
      <Unlock className="h-5 w-5" />
    ),
  },
];

interface EditVaultDialogProps {
  vault: Vault;
  onEditVault: (data: UpdateVaultFormData) => Promise<void>;
}

export const EditVaultDialog = ({
  vault,
  onEditVault,
}: EditVaultDialogProps) => {
  const { hasPermissions } = usePermissionGate();
  const { toast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // If user doesn't have read permission, don't render anything
  if (!hasPermissions(['vaults:read'])) {
    return null;
  }

  const canUpdate = hasPermissions(['vaults:update']);
  const canDelete = hasPermissions(['vaults:delete']);

  const form = useForm<UpdateVaultFormData>({
    resolver: zodResolver(updateVaultSchema),
    defaultValues: {
      id: vault.id,
      name: vault.name,
      description: vault.description,
      isPublic: vault.isPublic,
      isGlobal: vault.isGlobal,
      isLocked: vault.isLocked,
    },
  });

  const isLocked = form.watch('isLocked');

  useEffect(() => {
    form.reset({
      id: vault.id,
      name: vault.name,
      description: vault.description,
      isPublic: vault.isPublic,
      isGlobal: vault.isGlobal,
      isLocked: vault.isLocked,
    });
  }, [vault, form]);

  const onSubmit = async (data: UpdateVaultFormData) => {
    if (!canUpdate) return;

    setIsLoading(true);
    try {
      await onEditVault(data);
      setIsOpen(false);
      toast({
        title: 'Success',
        description: 'Vault updated successfully',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update vault',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    form.reset({
      id: vault.id,
      name: vault.name,
      description: vault.description,
      isPublic: vault.isPublic,
      isGlobal: vault.isGlobal,
      isLocked: vault.isLocked,
    });
  };

  const editButton = (
    <Button variant="secondary" size="sm" disabled={!canUpdate}>
      <PencilIcon className="h-4 w-4" />
    </Button>
  );

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            {canUpdate ? (
              <DialogTrigger asChild>{editButton}</DialogTrigger>
            ) : (
              editButton
            )}
          </TooltipTrigger>
          <TooltipContent side="top">
            <p>
              {canUpdate
                ? 'Edit vault settings'
                : "You don't have permission to edit this vault"}
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DialogContent className="sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle>Edit Vault</DialogTitle>
          <DialogDescription>
            {isLocked ? (
              <span className="text-red-600 font-medium">
                Vault is locked. Unlock to edit all settings.
              </span>
            ) : (
              <span>Modify the vault's settings and properties</span>
            )}
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Vault Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="e.g., Project Documentation, Team Resources..."
                      disabled={isLoading || isLocked || !canUpdate}
                    />
                  </FormControl>
                  <FormDescription>
                    {field.value.length}/50 characters
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder="What will this vault be used for?"
                      disabled={isLoading || isLocked || !canUpdate}
                    />
                  </FormControl>
                  <FormDescription>
                    {field.value?.length || 0}/200 characters
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="space-y-3 pt-2">
              {getVaultSwitches(isLocked).map(
                ({ id, label, description, icon }) => (
                  <FormField
                    key={id}
                    control={form.control}
                    name={id}
                    render={({ field }) => (
                      <VaultSwitch
                        id={id}
                        label={label}
                        description={description}
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        icon={icon}
                        disabled={
                          isLoading ||
                          !canUpdate ||
                          (isLocked && id !== 'isLocked')
                        }
                      />
                    )}
                  />
                )
              )}
            </div>

            <DialogFooter>
              <DialogClose onClick={handleClose} asChild>
                <Button variant="outline" disabled={isLoading}>
                  Cancel
                </Button>
              </DialogClose>
              <Button
                type="submit"
                disabled={
                  !form.formState.isValid ||
                  isLoading ||
                  !form.formState.isDirty ||
                  !canUpdate
                }
              >
                {isLoading ? 'Saving...' : 'Save Changes'}
              </Button>
            </DialogFooter>
          </form>
        </Form>

        <Separator />
        {canDelete && <DeleteVaultButton vault={vault} />}
      </DialogContent>
    </Dialog>
  );
};

export default EditVaultDialog;
