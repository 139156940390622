import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import * as Sentry from '@sentry/react';

interface CopyDocumentData {
  documentId: string;
  vaultId: string;
}

interface CopyDocumentResponse {
  message: string;
  success: boolean;
  copiedDocumentId: string;
}

export const useCopyDocument = () => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  const getHeaders = () => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken}`);
    headers.append('Content-Type', 'application/json');
    return headers;
  };

  return useMutation<CopyDocumentResponse, Error, CopyDocumentData>({
    mutationFn: async ({ documentId }: CopyDocumentData) => {
      const response = await fetch(`${apiBaseUrl}/documents/copy`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ documentId }),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.message || 'Failed to copy document');
      }

      return response.json();
    },
    onSuccess: (_, variables) => {
      queryClient.removeQueries({
        queryKey: ['vaultResources', variables.vaultId],
      });
    },
    onError: (error) => {
      Sentry.captureException(error);
    },
  });
};

export type { CopyDocumentData, CopyDocumentResponse };
