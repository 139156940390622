import { useNavigate } from '@tanstack/react-router';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { ArrowLeft } from 'lucide-react';
import { Button } from '@fluency/ui/components/ui/button';
import logo from '@fluency/ui/assets/icon-blue.svg';

const UnauthorizedMessage = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = async () => {
    await logout();
    navigate({ to: '/logout', replace: true });
  };

  return (
    <div className="flex flex-col items-center justify-center h-full max-h-screen w-full bg-white">
      <img src={logo} className="h-14 w-14 mb-6" alt="Fluency Logo" />
      <div className="border rounded-lg p-8 max-w-md w-full">
        <h1 className="text-2xl font-semibold mb-4">Access Denied</h1>
        <p className="text-gray-600 mb-6">
          You don't have permission to view this page. Contact your
          administrator for access or try signing in with a different account.
        </p>
        <div className="space-y-4">
          <Button
            onClick={() => navigate({ to: '/home', replace: true })}
            className="w-full text-md"
            variant="outline"
          >
            Back to Home
          </Button>
          <Button
            onClick={() => navigate({ to: '/home', replace: true })}
            className="w-full text-md"
            variant="secondary"
          >
            Refresh Page
          </Button>
        </div>
      </div>
      <Button
        variant="ghost"
        className="mt-6 flex items-center text-sm text-gray-600"
        onClick={handleLogout}
      >
        <ArrowLeft className="w-4 h-4 mr-1" /> Sign in with a different account
      </Button>
    </div>
  );
};

export default UnauthorizedMessage;
