import {
  createRootRoute,
  redirect,
  Outlet,
  BeforeLoadFn,
  Navigate,
} from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import DocumentUploading from '@fluency/ui/components/DocumentUploading';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { Loading } from '@fluency/ui/components';
import Deactivated from '@fluency/ui/components/Deactivated';
import NoSubscriptionMessage from '@fluency/ui/components/NoSubscriptionMessage';
import { RouterContext } from '../types/RouterContext';
import { useEffect } from 'react';
import AuthDevTool from '../AuthDevTool';
import { FeatureFlagProvider } from '@fluency/ui/providers/feature-flags/FeatureFlagProvider';
import { Logger } from '@fluency/ui/features/Logger';

const queryClient = new QueryClient();
const isDevelopment = import.meta.env.MODE === 'development';

const logger = (message: string, data?: any) => {
  Logger.log(`[routes/__root] ${message}`, data ? data : '');
};

export const Route = createRootRoute({
  component: RootComponent,
  notFoundComponent: () => <Navigate to="/404" />,
  beforeLoad: (({ location, context }) => {
    logger('beforeLoad started', { location, context });

    const {
      isAuthenticated,
      orgId,
      subscription,
      hasCompletedOrgForm,
      isInitialised,
      logout,
      isLoading,
    } = context.auth;

    logger('Auth state', {
      isAuthenticated,
      orgId,
      subscription,
      hasCompletedOrgForm,
      isInitialised,
      isLoading,
    });

    if (isLoading || !isInitialised) {
      return;
    }

    const publicRoutes = [
      '/login',
      '/logout',
      '/callback',
      '/callback/',
      '/share',
      '/share/',
      '/subscription',
    ];

    if (location.pathname === '/logout') {
      logger('Logout route detected, executing logout');
      logout();
      throw redirect({ to: '/login' });
    }

    if (publicRoutes.includes(location.pathname)) {
      return;
    }

    if (isAuthenticated) {
      if (!orgId && !hasCompletedOrgForm && location.pathname !== '/orgform') {
        logger('Redirecting to orgform');
        throw redirect({ to: '/orgform' });
      }

      if (
        (orgId || hasCompletedOrgForm) &&
        (!subscription || subscription === 'absent') &&
        location.pathname !== '/subscription'
      ) {
        logger('Redirecting to subscription');
        throw redirect({ to: '/subscription' });
      }

      return;
    }

    logger('Redirecting unauthenticated user to login page');
    throw redirect({ to: '/logout' });
  }) as BeforeLoadFn<any, {}, {}, RouterContext, unknown>,
});

function RootComponent() {
  const { isLoading, isInitialised, subscription, isAuthenticated, logout } =
    useAuth();

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'auth_broadcast') {
        try {
          const data = JSON.parse(e.newValue || '');
          if (data.type === 'LOGOUT' && isAuthenticated) {
            logger('Logout event received from another tab');
            logout();
          }
        } catch (error) {
          logger('Error parsing auth broadcast:', error);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [isAuthenticated, logout]);

  if (isLoading || !isInitialised) {
    return <Loading />;
  }

  if (subscription === 'deactivated') {
    return <Deactivated />;
  }

  if (
    subscription &&
    subscription !== 'active' &&
    subscription !== 'trialing' &&
    subscription !== 'absent'
  ) {
    return <NoSubscriptionMessage />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <FeatureFlagProvider>
        {isDevelopment ? (
          <AuthDevTool>
            <Outlet />
          </AuthDevTool>
        ) : (
          <Outlet />
        )}
        <div>
          <DocumentUploading />
          {isDevelopment && <TanStackRouterDevtools />}
          {isDevelopment && <ReactQueryDevtools initialIsOpen={false} />}
        </div>
      </FeatureFlagProvider>
    </QueryClientProvider>
  );
}

export default Route;
