import { ScreenshotType } from '@fluency/ui/hooks/documentation/types/master';

export interface ImageEditorProps {
  screenshotId: string;
  screenshotUrl: string;
  documentationId: string;
  imageKey: string;
  stepId: string;
  screenshot: ScreenshotType;
}

export interface SidebarProps {
  imageKey: string;
  stepId: string;
  documentId: string;
}

export type EditorFeature = 'crop' | 'redact' | 'annotate' | 'change' | null;

export interface CropData {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface RedactionRectangle {
  x: number;
  y: number;
  width: number;
  height: number;
  type: 'blur' | 'draw';
  colour?: string;
}

export const REDACTION_COLORS = [
  'blur',
  '#ef4444',
  '#f97316',
  '#fbbf24',
  '#a3e635',
  '#4ade80',
  '#22d3ee',
  '#2563eb',
  '#8b5cf6',
  '#d946ef',
  '#f9a8d4',
  '#ffffff',
  '#000000',
] as const;

export type RedactionColor = (typeof REDACTION_COLORS)[number];

export interface AnnotationData {
  annotationId: string;
  x1Coordinate: number;
  y1Coordinate: number;
  color: string;
  isVisible: boolean;
}

export interface ImageEditorContextType {
  activeFeature: EditorFeature;
  setActiveFeature: (feature: EditorFeature) => void;

  cropData: CropData | null;
  setCropData: (data: CropData | null) => void;
  aspectRatio: number | null;
  setAspectRatio: (ratio: number | null) => void;

  redactionRectangles: RedactionRectangle[];
  setRedactionRectangles: (rectangles: RedactionRectangle[]) => void;
  selectedRedactionRectangles: number[];
  setSelectedRedactionRectangles: (indices: number[]) => void;
  currentRedactionColor: string;
  setCurrentRedactionColor: (color: string) => void;

  annotationData: AnnotationData | null;
  setAnnotationData: (data: AnnotationData | null) => void;

  previewImage: string | null;
  setPreviewImage: (url: string | null) => void;

  isDirty: boolean;
  setIsDirty: (dirty: boolean) => void;
  isSaving: boolean;
  setIsSaving: (saving: boolean) => void;

  saveChanges: () => Promise<void>;
  resetEditor: () => void;
}

export interface NormalizedCropData {
  x: number; // percentage from left (0-100)
  y: number; // percentage from top (0-100)
  width: number; // percentage of image width (0-100)
  height: number; // percentage of image height (0-100)
}

export interface PixelCropData {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface Position {
  x: number;
  y: number;
}

export type ResizeHandle =
  | ''
  | 'nw'
  | 'ne'
  | 'sw'
  | 'se'
  | 'n'
  | 's'
  | 'w'
  | 'e';

export type HandleStyles = {
  [key in Exclude<ResizeHandle, ''>]: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
    transform?: string;
    cursor: string;
  };
};

export interface AnnotationV2Request {
  screenshotAnnotationId: string;
  screenshotId: string;
  shapeType: 'CIRCLE' | 'RECTANGLE' | 'LINE' | 'ARROW';
  x1Coordinate: number;
  y1Coordinate: number;
  x2Coordinate?: number;
  y2Coordinate?: number;
  color: string;
}

export interface AnnotationV2Response {
  message: string;
  success: boolean;
  annotations: AnnotationV2Request[];
}
