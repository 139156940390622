import { AnnotationType } from '@fluency/ui/hooks/documentation/types/master';

interface AnnotationsProps {
  annotations: AnnotationType[];
  annotationVisible: boolean;
  cropData: {
    x1: number | null;
    y1: number | null;
    x2: number | null;
    y2: number | null;
  };
  isCropped: boolean;
}

const Annotation = ({
  annotations,
  annotationVisible,
  cropData,
  isCropped = false,
}: AnnotationsProps) => {
  if (!annotationVisible) {
    return null;
  }

  // Helper function to check if a point is within crop bounds
  const isPointInCrop = (x: number, y: number): boolean => {
    if (
      !isCropped ||
      !cropData ||
      cropData.x1 === null ||
      cropData.y1 === null ||
      cropData.x2 === null ||
      cropData.y2 === null
    ) {
      return true;
    }
    return (
      x >= cropData.x1 &&
      x <= cropData.x2 &&
      y >= cropData.y1 &&
      y <= cropData.y2
    );
  };

  // Helper function to transform coordinates based on crop state
  const transformCoordinate = (coord: number, isX: boolean): number => {
    // If not cropped, return original coordinate
    if (!isCropped) {
      return coord;
    }

    // If cropped but missing crop data, return original coordinate
    if (
      !cropData ||
      cropData.x1 === null ||
      cropData.y1 === null ||
      cropData.x2 === null ||
      cropData.y2 === null
    ) {
      return coord;
    }

    const cropStart = isX ? cropData.x1 : cropData.y1;
    const cropEnd = isX ? cropData.x2 : cropData.y2;
    const cropSize = cropEnd - cropStart;

    // Handle edge case where crop size is 0
    if (cropSize === 0) return 0;

    // Transform coordinate to cropped space percentage
    return ((coord - cropStart) / cropSize) * 100;
  };

  // Filter annotations based on crop state
  const visibleAnnotations = annotations.filter((annotation) => {
    // Filter out invalid annotations
    if (annotation.x1Coordinate === 0 && annotation.y1Coordinate === 0) {
      return false;
    }

    // If not cropped, show all valid annotations
    if (!isCropped) {
      return true;
    }

    // For cropped state, check if annotations are within bounds
    const startPointInCrop = isPointInCrop(
      annotation.x1Coordinate,
      annotation.y1Coordinate
    );

    if (annotation.x2Coordinate !== null && annotation.y2Coordinate !== null) {
      const endPointInCrop = isPointInCrop(
        annotation.x2Coordinate,
        annotation.y2Coordinate
      );
      return startPointInCrop || endPointInCrop;
    }

    return startPointInCrop;
  });

  if (visibleAnnotations.length === 0) {
    return null;
  }

  const renderShape = (annotation: AnnotationType) => {
    const color = annotation.color || 'red';
    const x1 = transformCoordinate(annotation.x1Coordinate, true);
    const y1 = transformCoordinate(annotation.y1Coordinate, false);

    switch (annotation.shapeType) {
      case 'CIRCLE':
        return (
          <div
            className="absolute"
            style={{
              left: `${x1}%`,
              top: `${y1}%`,
              transform: 'translate(-50%, -50%)',
              overflow: 'visible',
            }}
          >
            <svg
              width="30"
              height="30"
              className="overflow-visible"
              style={{ overflow: 'visible' }}
            >
              <circle
                cx="15"
                cy="15"
                r="15"
                fill={color}
                fillOpacity="0.15"
                stroke={color}
                strokeWidth="2"
              />
            </svg>
          </div>
        );

      case 'RECTANGLE': {
        if (
          annotation.x2Coordinate === null ||
          annotation.y2Coordinate === null
        ) {
          return null;
        }

        const x2 = transformCoordinate(annotation.x2Coordinate, true);
        const y2 = transformCoordinate(annotation.y2Coordinate, false);

        const width = Math.abs(x2 - x1);
        const height = Math.abs(y2 - y1);
        const left = Math.min(x1, x2);
        const top = Math.min(y1, y2);

        return (
          <div
            className="absolute border-2"
            style={{
              left: `${left}%`,
              top: `${top}%`,
              width: `${width}%`,
              height: `${height}%`,
              borderColor: color,
            }}
          />
        );
      }

      case 'LINE':
      case 'ARROW': {
        if (
          annotation.x2Coordinate === null ||
          annotation.y2Coordinate === null
        ) {
          return null;
        }

        const x2 = transformCoordinate(annotation.x2Coordinate, true);
        const y2 = transformCoordinate(annotation.y2Coordinate, false);

        const id = `annotation-arrow-${annotation.screenshotAnnotationId}`;
        return (
          <svg className="absolute inset-0 w-full h-full overflow-visible">
            {annotation.shapeType === 'ARROW' && (
              <defs>
                <marker
                  id={id}
                  markerWidth="10"
                  markerHeight="7"
                  refX="9"
                  refY="3.5"
                  orient="auto"
                >
                  <polygon points="0 0, 10 3.5, 0 7" fill={color} />
                </marker>
              </defs>
            )}
            <line
              x1={`${x1}%`}
              y1={`${y1}%`}
              x2={`${x2}%`}
              y2={`${y2}%`}
              stroke={color}
              strokeWidth="2"
              markerEnd={
                annotation.shapeType === 'ARROW' ? `url(#${id})` : undefined
              }
              vectorEffect="non-scaling-stroke"
            />
          </svg>
        );
      }

      default:
        return null;
    }
  };

  return (
    <div className="absolute inset-0 pointer-events-none">
      {visibleAnnotations.map((annotation) => (
        <div
          key={annotation.screenshotAnnotationId}
          className="pointer-events-none"
        >
          {renderShape(annotation)}
        </div>
      ))}
    </div>
  );
};

export default Annotation;
