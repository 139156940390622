import type { NotificationResponse } from '../types/notifications.type';

const API_BASE_URL = import.meta.env.VITE_SERVER_API_URL;

export async function markNotificationAsRead(
  accessToken: string,
  notificationId: string
): Promise<NotificationResponse> {
  const response = await fetch(
    `${API_BASE_URL}/notifications/${notificationId}/read`,
    {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error('Failed to mark notification as read');
  }

  return response.json();
}
