import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import * as Sentry from '@sentry/react';
import { VaultPermissionResponse } from '../types/types';
import { useNavigate } from '@tanstack/react-router';

interface AppUser {
  userId: string;
}

interface Role {
  name: string;
}

interface VaultUserDetail {
  id: string;
  resourceId: string;
  resourceType: string;
  roleId: string;
  userId: string;
  teamId: string | null;
  createdAt: string;
  updatedAt: string;
  AppUser: AppUser;
  Role: Role;
}

interface VaultUserPermission {
  userId: string;
  role: string;
}

interface VaultUsersResponse {
  message: string;
  users: {
    users: VaultUserDetail[];
    permissions: VaultUserPermission[];
  };
}

interface VaultTeamPermission {
  teamId?: string;
  role: string;
}

interface VaultTeamsResponse {
  message: string;
  teams: {
    teams: any[];
    permissions: VaultTeamPermission[];
  };
}

interface Vault {
  id: string;
  name: string;
  description: string;
  tenantId: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  isPublic: boolean;
  isLocked: boolean;
  isGlobal: boolean;
  effectiveRole: {
    role: string;
    source: string;
    details: {
      organisationRole: string;
    };
  };
  creator: {
    id: string;
    metadata: {
      teamSize: string;
      storageMethod: string;
      documentationMethod: string;
    };
  };
}

interface VaultPermissions {
  teams: Array<{ id: string; role: string }>;
  users: Array<{ id: string; role: string }>;
}

interface CreateVaultData {
  name: string;
  description: string;
  isPublic: boolean;
  isGlobal: boolean;
  isLocked: boolean;
}

export interface CreateVaultResponse {
  message: string;
  vault: {
    id: string;
    name: string;
    description: string;
    tenantId: string;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
    isPublic: boolean;
    isLocked: boolean;
    isGlobal: boolean;
  };
}

interface UpdateVaultData extends CreateVaultData {
  id: string;
}

interface AddTeamsData {
  vaultId: string;
  teamIds: string[];
  roleName: string;
}

interface AddUsersData {
  vaultId: string;
  userIds: string[];
  roleName: string;
}

interface MoveItemsData {
  sourceVaultId: string;
  targetVaultId: string;
  itemIds: string[];
  itemType: 'DOCUMENTATION' | 'WORKFLOW';
}

interface MoveItemsResponse {
  message: string;
  movedItems: {
    id: string;
    vaultId: string;
  }[];
}

export const useVaultOperations = () => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const navigate = useNavigate();

  const getHeaders = () => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken}`);
    headers.append('Content-Type', 'application/json');
    return headers;
  };

  // Fetch all vaults
  const useGetVaults = () =>
    useQuery<{ message: string; vaults: Vault[] }>({
      queryKey: ['vaults'],
      queryFn: async () => {
        const response = await fetch(`${apiBaseUrl}/vaults`, {
          headers: getHeaders(),
        });
        if (!response.ok) {
          throw new Error('Failed to fetch vaults');
        }
        return response.json();
      },
    });

  // Create new vault
  const useCreateVault = () =>
    useMutation<CreateVaultResponse, Error, CreateVaultData>({
      mutationFn: async (data: CreateVaultData) => {
        const response = await fetch(`${apiBaseUrl}/vaults`, {
          method: 'POST',
          headers: getHeaders(),
          body: JSON.stringify(data),
        });
        if (!response.ok) {
          throw new Error('Failed to create vault');
        }
        return response.json();
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['vaults'] });
      },
      onError: (error) => {
        Sentry.captureException(error);
      },
    });

  // Update vault
  const useUpdateVault = () =>
    useMutation({
      mutationFn: async ({ id, ...data }: UpdateVaultData) => {
        const response = await fetch(`${apiBaseUrl}/vaults/${id}`, {
          method: 'PUT',
          headers: getHeaders(),
          body: JSON.stringify(data),
        });
        if (!response.ok) {
          throw new Error('Failed to update vault');
        }
        return response.json();
      },
      onMutate: async ({ id, ...newData }) => {
        await queryClient.cancelQueries({ queryKey: ['vaults'] });
        const previousVaults = queryClient.getQueryData<{
          message: string;
          vaults: Vault[];
        }>(['vaults']);

        // Optimistically update to the new value
        queryClient.setQueryData<{ message: string; vaults: Vault[] }>(
          ['vaults'],
          (old) => {
            if (!old) return { message: '', vaults: [] };

            return {
              ...old,
              vaults: old.vaults.map((vault) =>
                vault.id === id
                  ? {
                      ...vault,
                      ...newData,
                      updatedAt: new Date().toISOString(),
                    }
                  : vault
              ),
            };
          }
        );

        return { previousVaults };
      },
      onError: (err, _newVault, context) => {
        if (context?.previousVaults) {
          queryClient.setQueryData(['vaults'], context.previousVaults);
        }
        Sentry.captureException(err);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ['vaults'] });
      },
    });

  // Delete vault
  const useDeleteVault = () =>
    useMutation({
      mutationFn: async (vaultId: string) => {
        const response = await fetch(`${apiBaseUrl}/vaults/${vaultId}`, {
          method: 'DELETE',
          headers: getHeaders(),
        });
        if (!response.ok) {
          throw new Error('Failed to delete vault');
        }
        return response.json();
      },
      onMutate: async (vaultId) => {
        await queryClient.cancelQueries({ queryKey: ['vaults'] });
        const previousVaults = queryClient.getQueryData<{
          message: string;
          vaults: Vault[];
        }>(['vaults']);
        queryClient.setQueryData<{ message: string; vaults: Vault[] }>(
          ['vaults'],
          (old) => {
            if (!old) return { message: '', vaults: [] };
            return {
              ...old,
              vaults: old.vaults.filter((vault) => vault.id !== vaultId),
            };
          }
        );

        return { previousVaults };
      },

      onError: (err, _vaultId, context) => {
        if (context?.previousVaults) {
          queryClient.setQueryData(['vaults'], context.previousVaults);
        }
        Sentry.captureException(err);
      },

      onSuccess: () => {
        navigate({
          to: '/vault/$id',
          params: { id: 'manage' },
          replace: true,
        });
      },

      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ['vaults'] });
      },
    });

  // Get vault permissions
  const useGetVaultPermissions = (vaultId: string) =>
    useQuery<VaultPermissionResponse>({
      queryKey: ['vault-permissions', vaultId],
      queryFn: async () => {
        const response = await fetch(
          `${apiBaseUrl}/vaults/getPermissions/${vaultId}`,
          {
            headers: getHeaders(),
          }
        );
        if (!response.ok) throw new Error('Failed to fetch vault permissions');
        const data = response.json();
        return data;
      },
      enabled: !!vaultId,
    });

  // Add teams to vault
  const useAddTeamsToVault = () =>
    useMutation({
      mutationFn: async ({ vaultId, teamIds, roleName }: AddTeamsData) => {
        const response = await fetch(
          `${apiBaseUrl}/vaults/addTeams/${vaultId}`,
          {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ teamIds, roleName }),
          }
        );
        if (!response.ok) {
          throw new Error('Failed to add teams to vault');
        }
        return response.json();
      },
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries({
          queryKey: ['vault-permissions', variables.vaultId],
        });
      },
      onError: (error) => {
        Sentry.captureException(error);
      },
    });

  // Add users to vault
  const useAddUsersToVault = () =>
    useMutation({
      mutationFn: async ({ vaultId, userIds, roleName }: AddUsersData) => {
        const response = await fetch(
          `${apiBaseUrl}/vaults/addUsers/${vaultId}`,
          {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ userIds, roleName }),
          }
        );
        if (!response.ok) {
          throw new Error('Failed to add users to vault');
        }
        return response.json();
      },
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries({
          queryKey: ['vault-permissions', variables.vaultId],
        });
      },
      onError: (error) => {
        Sentry.captureException(error);
      },
    });

  // Move items between vaults
  const useMoveItems = () =>
    useMutation<MoveItemsResponse, Error, MoveItemsData>({
      mutationFn: async (data: MoveItemsData) => {
        const response = await fetch(`${apiBaseUrl}/vaults/moveItems`, {
          method: 'POST',
          headers: getHeaders(),
          body: JSON.stringify({
            sourceVaultId: data.sourceVaultId,
            targetVaultId: data.targetVaultId,
            itemIds: data.itemIds,
            itemType: data.itemType,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json().catch(() => null);
          throw new Error(
            errorData?.message || 'Failed to move items between vaults'
          );
        }

        return response.json();
      },
      onError: (error) => {
        Sentry.captureException(error);
      },
    });

  const useGetVaultUsers = (vaultId: string) =>
    useQuery<VaultUsersResponse[]>({
      queryKey: ['vault-users', vaultId],
      queryFn: async () => {
        const response = await fetch(
          `${apiBaseUrl}/vaults/getUsers/${vaultId}`,
          {
            headers: getHeaders(),
          }
        );
        if (!response.ok) throw new Error('Failed to fetch vault users');
        return response.json();
      },
      enabled: !!vaultId,
    });

  // Get vault teams
  const useGetVaultTeams = (vaultId: string) =>
    useQuery<VaultTeamsResponse[]>({
      queryKey: ['vault-teams', vaultId],
      queryFn: async () => {
        const response = await fetch(
          `${apiBaseUrl}/vaults/getTeams/${vaultId}`,
          {
            headers: getHeaders(),
          }
        );
        if (!response.ok) throw new Error('Failed to fetch vault teams');
        return response.json();
      },
      enabled: !!vaultId,
    });

  return {
    useGetVaults,
    useCreateVault,
    useUpdateVault,
    useDeleteVault,
    useGetVaultPermissions,
    useAddTeamsToVault,
    useAddUsersToVault,
    useMoveItems,
    useGetVaultUsers,
    useGetVaultTeams,
  };
};

export type {
  Vault,
  VaultPermissions,
  CreateVaultData,
  UpdateVaultData,
  AddTeamsData,
  AddUsersData,
  MoveItemsData,
  VaultUserDetail,
};
