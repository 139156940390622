import { Logger } from '@fluency/ui/features/Logger';

const url = import.meta.env.VITE_SERVER_API_URL;

export const getSubscriptionStatus = async (token: string): Promise<any> => {
  try {
    const response = await fetch(`${url}/subscription/getSubscriptionStatus`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    Logger.error('Error fetching subscription status:', error);
    throw error;
  }
};
