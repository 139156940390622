import React, { useRef, useState, useEffect } from 'react';
import { DashboardShell } from '@fluency/ui/components';
import WorkflowHeader from './components/WorkflowHeader';
import ViewOnlyWorkflow from './ViewWorkflow';
import { useParams } from '@tanstack/react-router';
import { RefreshCw } from 'lucide-react';
import { Edge, Node, ReactFlowProvider } from '@reactflow/core';
import useGetVaults from '../Home/hooks/useGetVaults';
import { useGetWorkflow } from '@fluency/ui/hooks/workflows/useGetWorkflow';
import { Logger } from '@fluency/ui/features/Logger';
import { WorkflowModeType } from './types/workflow';
import BPMNWorkflowBuilder, {
  BPMNWorkflowBuilderRef,
} from './BPMNWorkflowBuilder';
import { bpmnElements } from './utils/bpmnElements';

const WorkflowMapping: React.FC = () => {
  const [mode, setMode] = useState<WorkflowModeType>('View');
  const { id } = useParams({ from: '/workflow/$id' });
  const workflowId = id;
  const { data, isLoading } = useGetWorkflow(workflowId);
  const { data: vaultsData } = useGetVaults();
  const vault = vaultsData?.vaults.find((vault) => vault.id === data?.vault.id);
  const isVaultLocked = vault?.isLocked || false;
  const isWorkflowLocked = data?.isLocked || false;
  const workflowRef = useRef<BPMNWorkflowBuilderRef>(null);

  // effect to push back to view mode if either vault or workflow becomes locked
  useEffect(() => {
    if (mode !== 'View' && (isVaultLocked || isWorkflowLocked)) {
      setMode('View');
    }
  }, [isVaultLocked, isWorkflowLocked, mode]);

  const handleSave = async () => {
    if (workflowRef.current) {
      try {
        await workflowRef.current.saveToDatabase();
        setMode('View');
      } catch (error) {
        Logger.error('Error saving workflow');
      }
    }
  };

  const handleSetMode = (newMode: WorkflowModeType) => {
    if (newMode !== 'View' && (isVaultLocked || isWorkflowLocked)) {
      return; // Don't allow changing to Edit/Collaborate if locked
    }
    setMode(newMode);
  };

  const renderWorkflow = () => {
    if (!data) return null;

    // Process data
    const processeddata = {
      ...data,
      nodes: data.nodes.map((node) => {
        return {
          ...node,
          // Set swimlane nodes to be behind other nodes
          ...(node.type === 'swimlaneNode' && { style: { zIndex: -10 } }),
        };
      }),
    };

    switch (mode) {
      case 'View':
        return (
          <ViewOnlyWorkflow
            workflowData={{
              nodes: processeddata.nodes as Node[],
              edges: processeddata.edges as Edge[],
            }}
            isLocked={isWorkflowLocked}
            isVaultLocked={isVaultLocked}
            onEditClick={() => handleSetMode('Edit')}
          />
        );
      case 'Edit':
        return (
          <BPMNWorkflowBuilder
            ref={workflowRef}
            vaultId={processeddata.vault.id}
            workflowId={workflowId}
            bpmnElements={bpmnElements}
            setMode={setMode}
          />
        );
    }
  };

  return (
    <DashboardShell>
      <ReactFlowProvider>
        <WorkflowHeader
          mode={mode}
          setMode={handleSetMode}
          onSave={handleSave}
          isVaultLocked={isVaultLocked}
        />
        {isLoading ? (
          <div className="h-[calc(100vh-600px)] border-t flex items-center justify-center gap-2 font-medium text-sm text-gray-400">
            <RefreshCw className="text-gray-400 animate-spin" /> Loading Process
            Map
          </div>
        ) : !data ? (
          <div className="flex items-center justify-center h-full">
            No process map data found.
          </div>
        ) : (
          <div className="relative">{renderWorkflow()}</div>
        )}
      </ReactFlowProvider>
    </DashboardShell>
  );
};

export default WorkflowMapping;
