import React from 'react';
import { DashboardShell } from '@fluency/ui/components';
import {
  PermissionGateProvider,
  usePermissionGate,
} from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { Link } from '@tanstack/react-router';
import { Building2, UserCog, Users } from 'lucide-react';
import AdvancedSettings from './components/Advanced/AdvancedSettings';
import Billing from './components/Billing/Billing';
import Branding from './components/Branding';
import { GeneralSettings } from './components/GeneralCard/GeneralCard';
import { NotificationPreferences } from './components/NotificationPreferences';
import { IntegrationsSettings } from './components/Integrations';
import { MembersSettings } from './components/Members';
import PIICard from './components/PII/PII';
import { cn } from '@fluency/ui/lib/utils';
import TeamSettings from './components/Teams';
import { getColumns } from './components/Teams/data/columns';
import { useFeatureFlags } from '@fluency/ui/providers/feature-flags/FeatureFlagProvider';
import { FeatureFlags } from '@fluency/ui/providers/feature-flags/types/FeatureFlag.type';
import { NavItem } from './types/NavItem';
import { navItems } from './utils/navItems';

interface UserProfileProps {
  setting: string;
}

interface NavSectionProps {
  title: string;
  icon: React.ElementType;
  items: NavItem[];
  currentSetting: string;
  requiredPermissions?: string[];
}

const NavSection: React.FC<NavSectionProps> = ({
  title,
  items,
  currentSetting,
  requiredPermissions = [],
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { hasPermissions } = usePermissionGate();

  // First filter by feature flags
  const featureFlagFilteredItems = items.filter(
    (item) =>
      !item.featureFlag ||
      isFeatureEnabled(item.featureFlag as keyof FeatureFlags)
  );

  // Then filter by section-level permissions
  if (requiredPermissions.length > 0 && !hasPermissions(requiredPermissions)) {
    return null;
  }

  // Finally filter by individual item permissions
  const visibleItems = featureFlagFilteredItems.filter((item) => {
    if (!item.permissions?.length) return true;
    return hasPermissions(item.permissions);
  });

  if (visibleItems.length === 0) return null;

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2 px-2 py-2">
        <span className="text-xs font-medium uppercase tracking-wider text-slate-500">
          {title}
        </span>
      </div>
      <nav className="grid gap-1">
        {visibleItems.map((item) => (
          <Link
            key={item.setting}
            to={item.to}
            params={{ setting: item.setting }}
            className={cn(
              'flex items-center gap-2 rounded-md px-2 py-1.5',
              'text-sm font-normal transition-colors',
              item.setting === 'danger'
                ? cn(
                    'text-red-600 hover:bg-red-50',
                    currentSetting === item.setting ? 'bg-red-50' : ''
                  )
                : cn(
                    'hover:bg-accent hover:text-accent-foreground',
                    currentSetting === item.setting
                      ? 'bg-accent text-accent-foreground'
                      : 'text-foreground'
                  )
            )}
          >
            <item.icon
              className={cn(
                'h-4 w-4',
                item.setting === 'danger' ? 'text-red-600' : ''
              )}
            />
            {item.label}
          </Link>
        ))}
      </nav>
    </div>
  );
};

const Settings: React.FC<UserProfileProps> = ({ setting }) => {
  const items = {
    account: navItems.filter((item) => item.type === 'account'),
    preferences: navItems.filter((item) => item.type === 'preferences'),
    team: navItems.filter((item) => item.type === 'team'),
    admin: navItems.filter((item) => item.type === 'admin'),
  };

  const columns = React.useMemo(() => getColumns(), []);
  const { isFeatureEnabled } = useFeatureFlags();
  const shouldShowPII = isFeatureEnabled('auto-pii');
  const shouldShowBranding = isFeatureEnabled('branding');

  return (
    <DashboardShell>
      <main className="flex h-full border-t">
        <div className="relative flex-shrink-0 border-r">
          <div className="sticky top-0 w-[240px] h-[calc(100vh-65px-10px)] overflow-y-auto px-2 py-4">
            <div className="space-y-6">
              <NavSection
                title="Account"
                icon={UserCog}
                items={items.account}
                currentSetting={setting}
              />
              <NavSection
                title="Preferences"
                icon={Settings}
                items={items.preferences}
                currentSetting={setting}
              />
              <NavSection
                title="Team"
                icon={Users}
                items={items.team}
                currentSetting={setting}
                requiredPermissions={['org:read']}
              />
              <NavSection
                title="Administration"
                icon={Building2}
                items={items.admin}
                currentSetting={setting}
                requiredPermissions={['org:read']}
              />
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto">
          <div className="px-8 py-6 h-full">
            <div className="mx-auto max-w-5xl space-y-6 h-full">
              {setting === 'general' && <GeneralSettings />}
              {setting === 'notifications' && <NotificationPreferences />}
              {setting === 'integrations' && <IntegrationsSettings />}
              {setting === 'members' && (
                <PermissionGateProvider permissions={['org:update']}>
                  <MembersSettings />
                </PermissionGateProvider>
              )}
              {setting === 'subscription' && (
                <PermissionGateProvider permissions={['subscription:read']}>
                  <Billing />
                </PermissionGateProvider>
              )}
              {setting === 'pii' && shouldShowPII && (
                <PermissionGateProvider permissions={['org:update']}>
                  <PIICard />
                </PermissionGateProvider>
              )}
              {setting === 'teams' && (
                <PermissionGateProvider permissions={['org:update']}>
                  <TeamSettings columns={columns} />
                </PermissionGateProvider>
              )}
              {setting === 'branding' && shouldShowBranding && (
                <PermissionGateProvider permissions={['org:update']}>
                  <Branding />
                </PermissionGateProvider>
              )}
              {setting === 'danger' && (
                <PermissionGateProvider permissions={['org:update']}>
                  <AdvancedSettings />
                </PermissionGateProvider>
              )}
            </div>
          </div>
        </div>
      </main>
    </DashboardShell>
  );
};

export default Settings;
