import { Badge } from '@fluency/ui/components/ui/badge';
import { useGetDocumentData } from '../hooks';
import {
  CalendarDays,
  Check,
  Clock,
  FileText,
  History,
  Lock,
  User2,
} from 'lucide-react';

const formatDate = (dateString: string | number | Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  return new Date(dateString).toLocaleDateString('en-GB', options);
};

interface DocumentSummaryProps {
  id: string;
}

const DocumentSummary = ({ id }: DocumentSummaryProps) => {
  const { data } = useGetDocumentData(id);

  const formattedCreatedDate = data?.documentation.createdDate
    ? new Date(data.documentation.createdDate).toISOString()
    : '';

  const formatDuration = (duration: number | string) => {
    const durationStr =
      typeof duration === 'number' ? duration.toString() : duration;
    const parts = durationStr.split('.');
    const minutes = parts[0];
    const seconds =
      parts.length > 1
        ? Math.round(parseFloat(`0.${parts[1]}`) * 60).toString()
        : '0';
    return `${minutes} min ${seconds} sec`;
  };

  return (
    <div className="flex flex-wrap gap-x-2 gap-y-3 mb-6 w-full">
      <Badge variant="secondary">
        <FileText className="h-4 w-4 my-0.5 mr-2" />
        {data?.documentation.totalSteps || 0} Steps
      </Badge>
      {data?.documentation.userInfo[0]?.firstName && (
        <Badge variant="secondary">
          <User2 className="h-4 w-4 my-0.5 mr-2" />
          {data?.documentation.userInfo[0]?.firstName +
            ' ' +
            data?.documentation.userInfo[0]?.lastName?.[0]}{' '}
        </Badge>
      )}
      <Badge variant="secondary">
        <Clock className="h-4 w-4 my-0.5 mr-2" />
        {data?.documentation.duration &&
          formatDuration(data.documentation.duration)}
      </Badge>
      <Badge variant="secondary">
        <CalendarDays className="h-4 w-4 my-0.5 mr-2" />
        {formattedCreatedDate && (
          <>
            <p className="mr-1">Created on</p>
            <time dateTime={formattedCreatedDate}>
              {data?.documentation.createdDate &&
                formatDate(data.documentation.createdDate)}
            </time>
          </>
        )}
      </Badge>
      <Badge variant="secondary">
        <History className="h-4 w-4 my-0.5 mr-2" />
        {'Version ' + data?.documentation.versionInfo.version}
      </Badge>
      {data?.documentation.versionInfo.isLatestVersion ? (
        <Badge variant="secondary" className="hover:cursor-default">
          <Check className="h-4 w-4 my-0.5 mr-2" />
          Latest version
        </Badge>
      ) : (
        <Badge variant="secondary">
          <Lock className="h-4 w-4 my-0.5 mr-2" />
          Superseded version
        </Badge>
      )}
    </div>
  );
};

export default DocumentSummary;
