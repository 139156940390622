import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@fluency/ui/components/ui/table';
import { Button } from '@fluency/ui/components/ui/button';
import { useToast } from '@fluency/ui/components/ui/use-toast';
import { ArrowUpRight } from 'lucide-react';
import { Skeleton } from '@fluency/ui/components/ui/skeleton';
import {
  CreateVaultResponse,
  useVaultOperations,
} from '../hooks/useVaultOperations.tsx';
import { CreateVaultDialog } from './CreateVaultDialog.tsx';
import type { CreateVaultData, UpdateVaultData } from '../types/types.ts';
import { EditVaultDialog } from './EditVaultDialog.tsx';
import { VaultIcon } from '@fluency/ui/components/DashboardShell/VaultIcon';
import { VaultHelpDialog } from '../../components/VaultHelpDialog.tsx';
import VaultStatusIcons from './VaultIconStates.tsx';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { useNavigate } from '@tanstack/react-router';
import PageLayout from '@fluency/ui/templates/PageLayout.tsx';
import { PermissionsDialog } from '@fluency/ui/features/ManagePermissions/index.ts';

const LoadingTableContent = () => (
  <TableBody>
    {[...Array(5)].map((_, index) => (
      <TableRow key={index}>
        <TableCell>
          <Skeleton className="h-4 w-[150px]" />
        </TableCell>
        <TableCell>
          <Skeleton className="h-4 w-[200px]" />
        </TableCell>
        <TableCell>
          <div className="flex gap-2 items-center">
            <Skeleton className="h-4 w-4 rounded-full" />
            <Skeleton className="h-6 w-[60px]" />
          </div>
        </TableCell>
        <TableCell>
          <Skeleton className="h-4 w-[100px]" />
        </TableCell>
        <TableCell>
          <div className="flex gap-2">
            <Skeleton className="h-8 w-8" />
            <Skeleton className="h-8 w-8" />
          </div>
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
);

const ManageVaultsPage = () => {
  const { useGetVaults, useCreateVault, useUpdateVault } = useVaultOperations();
  const { data: vaultsData, isLoading } = useGetVaults();
  const createVaultMutation = useCreateVault();
  const updateVaultMutation = useUpdateVault();
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleCreateVault = async (
    data: CreateVaultData
  ): Promise<CreateVaultResponse> => {
    return await createVaultMutation.mutateAsync(data);
  };

  const handleEditVault = async (data: UpdateVaultData) => {
    try {
      await updateVaultMutation.mutateAsync(data);
      toast({
        title: 'Success',
        description: 'Vault updated successfully',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update vault',
        variant: 'destructive',
      });
    }
  };

  return (
    <>
      <PageLayout
        maxWidth="5xl"
        header={{
          title: 'Manage Vaults',
          description: 'Create and manage your vaults',
          breadcrumbs: {
            items: [
              { label: 'Vault', to: '/vault' },
              { label: 'Manage Vaults' },
            ],
          },
          headerActions: (
            <div className="flex items-center gap-4">
              <CreateVaultDialog onCreateVault={handleCreateVault} />
            </div>
          ),
          titleMetadata: (
            <>
              <VaultHelpDialog />
            </>
          ),
        }}
        content={{
          children: (
            <Table>
              {isLoading ? (
                <LoadingTableContent />
              ) : (
                <TableBody>
                  {vaultsData?.vaults.map((vault) => (
                    <TableRow key={vault.id} className="hover:bg-gray-50">
                      <TableCell
                        className="pl-0 cursor-pointer"
                        onClick={() =>
                          navigate({
                            to: '/vault/$id',
                            replace: true,
                            params: { id: vault.id },
                          })
                        }
                      >
                        <div className="flex items-center gap-3">
                          <VaultIcon
                            strokeWidth={0.8}
                            className="w-8 h-8 min-w-8 text-gray-500"
                          />
                          <div className="flex flex-col gap-0.5">
                            <span className="font-medium line-clamp-1">
                              {vault.name}
                            </span>
                            <span className="text-xs text-gray-500 line-clamp-1 max-w-full">
                              Created{' '}
                              {new Date(vault.createdAt).toLocaleDateString()} |
                              <i> {vault.description}</i>
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <VaultStatusIcons vault={vault} />
                      </TableCell>
                      <TableCell className="w-24 px-0">
                        <TooltipProvider>
                          <div className="flex gap-2 justify-end">
                            <EditVaultDialog
                              vault={vault}
                              onEditVault={handleEditVault}
                            />
                            <PermissionsDialog
                              vault={vault}
                              resourceId={vault.id}
                              resourceType="VAULT"
                            />
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button
                                  variant="secondary"
                                  size="sm"
                                  onClick={() =>
                                    navigate({
                                      to: '/vault/$id',
                                      replace: true,
                                      params: { id: vault.id },
                                    })
                                  }
                                >
                                  <ArrowUpRight
                                    className="h-5 w-5"
                                    strokeWidth={1.7}
                                  />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent side="top">
                                <p>Open</p>
                              </TooltipContent>
                            </Tooltip>
                          </div>
                        </TooltipProvider>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          ),
        }}
      />
    </>
  );
};

export default ManageVaultsPage;
