import { RefreshCw } from 'lucide-react';
import { useState } from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@fluency/ui/components/ui/alert-dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@fluency/ui/components/ui/select';
import { useVaultOperations } from '@fluency/ui/features/Vaults/ManageVaults/hooks/useVaultOperations';
import { useToast } from '@fluency/ui/components/ui/use-toast';
import { useNavigate } from '@tanstack/react-router';
import { useQueryClient } from '@tanstack/react-query';

interface MoveWorkflowDialogProps {
  workflowId: string;
  currentVaultId: string;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

const MoveWorkflowDialog = ({
  workflowId,
  currentVaultId,
  isOpen,
  onOpenChange,
}: MoveWorkflowDialogProps) => {
  const [selectedVaultId, setSelectedVaultId] = useState<string>('');
  const { useGetVaults, useMoveItems } = useVaultOperations();
  const { data: vaultsData } = useGetVaults();
  const moveItems = useMoveItems();
  const { toast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const vaults = vaultsData?.vaults || [];
  interface Vault {
    id: string;
    name: string;
  }

  const otherVaults: Vault[] = vaults.filter(
    (vault: Vault) => vault.id !== currentVaultId
  );

  const selectedVault = otherVaults.find(
    (vault) => vault.id === selectedVaultId
  );

  const handleMove = () => {
    if (!selectedVaultId) return;

    moveItems.mutate(
      {
        sourceVaultId: currentVaultId,
        targetVaultId: selectedVaultId,
        itemIds: [workflowId],
        itemType: 'WORKFLOW',
      },
      {
        onSuccess: () => {
          setSelectedVaultId('');
          toast({
            title: 'Document moved',
            description: `Successfully moved document to ${selectedVault?.name}`,
          });
          navigate({
            to: '/vault/$id',
            replace: true,
            params: { id: selectedVaultId },
          });
          queryClient.removeQueries({
            queryKey: ['vaultResources', currentVaultId],
          });
          queryClient.removeQueries({
            queryKey: ['vaultResources', selectedVaultId],
          });
          queryClient.removeQueries({
            queryKey: ['workflow', workflowId],
          });
        },
        onError: (error) => {
          toast({
            variant: 'destructive',
            title: 'Failed to move document',
            description: error.message || 'Please try again',
          });
        },
      }
    );
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Move Process Map</AlertDialogTitle>
          <AlertDialogDescription className="space-y-4">
            <p>Select a vault to move this process map to.</p>
            <div className="pt-2">
              <Select
                value={selectedVaultId}
                onValueChange={setSelectedVaultId}
                disabled={moveItems.isPending}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select destination vault" />
                </SelectTrigger>
                <SelectContent>
                  {otherVaults.map((vault) => (
                    <SelectItem key={vault.id} value={vault.id}>
                      {vault.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="gap-2">
          <AlertDialogCancel disabled={moveItems.isPending}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={handleMove}
            disabled={moveItems.isPending || !selectedVaultId}
            className="gap-2"
          >
            {moveItems.isPending ? (
              <RefreshCw className="h-4 w-4 animate-spin" />
            ) : null}
            {moveItems.isPending ? 'Moving...' : 'Move'}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default MoveWorkflowDialog;
