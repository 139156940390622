import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { Documentation, DocumentationsResponse } from './types/GetDocs';
import {
  InfiniteQueryResponse,
  ResourceItem,
  DocumentationResource,
  PageResponse,
} from '../vaults/types/types';
import { ResourceType } from '../fga/types/fga';

interface DeleteResponse {
  message: string;
}

interface MutationContext {
  previousDocs?: DocumentationsResponse;
  previousVaultResources?: Record<string, InfiniteQueryResponse>;
  vaultIds: Set<string>;
  previousDocuments?: Record<string, Documentation>;
}

async function deleteDocs(
  documentIds: string[],
  token: string
): Promise<DeleteResponse> {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const endpoint = `${apiBaseUrl}/documents/delete`;
  const data = { documentIdList: documentIds };

  const response = await fetch(endpoint, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(
      errorData.message || `HTTP error! status: ${response.status}`
    );
  }

  return response.json();
}

const useDeleteDocs = () => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation<DeleteResponse, Error, string[], MutationContext>({
    mutationFn: (documentIds) => deleteDocs(documentIds, accessToken ?? ''),
    onMutate: async (deletedDocumentIds) => {
      // Get all affected vault IDs and documents
      const docsData = queryClient.getQueryData<DocumentationsResponse>([
        'documentation',
      ]);
      const affectedDocs =
        docsData?.documentation.filter((doc) =>
          deletedDocumentIds.includes(doc.documentationId)
        ) ?? [];

      const vaultIds = new Set(affectedDocs.map((doc) => doc.vaultId));

      // Cancel queries
      await queryClient.cancelQueries({ queryKey: ['documentation'] });
      for (const vaultId of vaultIds) {
        await queryClient.cancelQueries({
          queryKey: ['vaultResources', vaultId],
        });
      }
      for (const docId of deletedDocumentIds) {
        await queryClient.cancelQueries({ queryKey: ['document', docId] });
      }

      // Snapshot current states
      const previousDocs = queryClient.getQueryData<DocumentationsResponse>([
        'documentation',
      ]);

      const previousVaultResources: Record<string, InfiniteQueryResponse> = {};
      for (const vaultId of vaultIds) {
        const vaultData = queryClient.getQueryData<InfiniteQueryResponse>([
          'vaultResources',
          vaultId,
        ]);
        if (vaultData) {
          previousVaultResources[vaultId] = vaultData;
        }
      }

      const previousDocuments: Record<string, Documentation> = {};
      for (const docId of deletedDocumentIds) {
        const docData = queryClient.getQueryData<Documentation>([
          'document',
          docId,
        ]);
        if (docData) {
          previousDocuments[docId] = docData;
        }
      }

      // Update documentation cache
      queryClient.setQueryData<DocumentationsResponse>(
        ['documentation'],
        (old) => {
          if (!old) return old;

          const filteredDocs = old.documentation.filter((doc) => {
            if (deletedDocumentIds.includes(doc.documentationId)) {
              return false;
            }
            if (
              doc.versionInfo &&
              deletedDocumentIds.includes(
                doc.versionInfo.originalDocumentationId
              )
            ) {
              return false;
            }
            return true;
          });

          return {
            ...old,
            documentation: filteredDocs,
            totalItems:
              old.totalItems - (old.documentation.length - filteredDocs.length),
            totalPages: Math.ceil(
              (old.totalItems -
                (old.documentation.length - filteredDocs.length)) /
                old.pageSize
            ),
          };
        }
      );

      // Update vault resources cache for each affected vault
      for (const vaultId of vaultIds) {
        queryClient.setQueryData<InfiniteQueryResponse>(
          ['vaultResources', vaultId],
          (old) => {
            if (!old) return old;

            const updatedPages: PageResponse[] = old.pages.map((page) => ({
              ...page,
              items: page.items.filter((item): item is ResourceItem => {
                if (item.type !== ResourceType.DOCUMENTATION) return true;
                const doc = item.resource as DocumentationResource;
                return !deletedDocumentIds.includes(doc.documentationId);
              }),
            }));

            return {
              pages: updatedPages,
              pageParams: old.pageParams,
            };
          }
        );
      }

      // Remove individual doc caches
      for (const docId of deletedDocumentIds) {
        queryClient.removeQueries({ queryKey: ['document', docId] });
      }

      return {
        previousDocs,
        previousVaultResources,
        vaultIds,
        previousDocuments,
      };
    },
    onError: (error, variables, context) => {
      // Restore documentation cache
      if (context?.previousDocs) {
        queryClient.setQueryData(['documentation'], context.previousDocs);
      }

      // Restore vault resources cache
      if (context?.previousVaultResources) {
        for (const [vaultId, data] of Object.entries(
          context.previousVaultResources
        )) {
          queryClient.setQueryData(['vaultResources', vaultId], data);
        }
      }

      // Restore individual document caches
      if (context?.previousDocuments) {
        for (const docId of variables) {
          if (context.previousDocuments[docId]) {
            queryClient.setQueryData(
              ['document', docId],
              context.previousDocuments[docId]
            );
          }
        }
      }

      if (error instanceof Error) {
        if (error.message.includes('403')) {
          toast({
            variant: 'destructive',
            title: 'Unable to delete document',
            description: 'This document is in a team and cannot be deleted.',
          });
        } else {
          toast({
            variant: 'destructive',
            title: 'Something went wrong.',
            description:
              'An error occurred while deleting the document. Please try again later.',
          });
        }
      }
    },
    onSuccess: () => {
      toast({
        variant: 'default',
        title: 'Success',
        description: 'Document(s) deleted successfully.',
      });
    },
    onSettled: (_data, _error, _variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['documentation'] });
      if (context?.vaultIds) {
        for (const vaultId of context.vaultIds) {
          queryClient.invalidateQueries({
            queryKey: ['vaultResources', vaultId],
          });
        }
      }
    },
  });
};

export default useDeleteDocs;
