import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';

const containerClasses =
  'relative w-12 h-12 border rounded flex items-center justify-center cursor-pointer hover:bg-accent hover:cursor-grab';

export const StartIcon = () => (
  <TooltipProvider>
    <Tooltip delayDuration={100}>
      <TooltipTrigger asChild>
        <div className={containerClasses}>
          <div className="w-8 h-8 rounded-full border-[3px] border-black" />
        </div>
      </TooltipTrigger>
      <TooltipContent side="top">Start Event</TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export const EndIcon = () => (
  <TooltipProvider>
    <Tooltip delayDuration={100}>
      <TooltipTrigger asChild>
        <div className={containerClasses}>
          <div className="w-8 h-8 rounded-full border-[4px] border-black" />
        </div>
      </TooltipTrigger>
      <TooltipContent side="top">End Event</TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export const DecisionIcon = () => (
  <TooltipProvider>
    <Tooltip delayDuration={100}>
      <TooltipTrigger asChild>
        <div className={containerClasses}>
          <div className="w-6 h-6 border-[3px] border-black rotate-45" />
        </div>
      </TooltipTrigger>
      <TooltipContent side="top">Decision Node</TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export const TextIcon = () => (
  <TooltipProvider>
    <Tooltip delayDuration={100}>
      <TooltipTrigger asChild>
        <div className={containerClasses}>
          <span
            style={{
              fontFamily: 'Times New Roman',
              fontSize: '24px',
              fontWeight: 'bold',
            }}
          >
            T
          </span>
        </div>
      </TooltipTrigger>
      <TooltipContent side="top">Text Annotation</TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export const ActivityIcon = () => (
  <TooltipProvider>
    <Tooltip delayDuration={100}>
      <TooltipTrigger asChild>
        <div className={containerClasses}>
          <div className="w-8 h-6 border-2 border-black rounded flex items-center justify-center">
            ...
          </div>
        </div>
      </TooltipTrigger>
      <TooltipContent side="top">Activity</TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export const SwimlaneIcon = () => (
  <TooltipProvider>
    <Tooltip delayDuration={100}>
      <TooltipTrigger asChild>
        <div className={containerClasses}>
          <div className="w-8 h-6 border-2 border-black">
            <div className="w-full h-1/2 border-b border-black"></div>
          </div>
        </div>
      </TooltipTrigger>
      <TooltipContent side="top">Swimlane</TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export const DefaultIcon = () => (
  <TooltipProvider>
    <Tooltip delayDuration={100}>
      <TooltipTrigger asChild>
        <div className={containerClasses}>
          <div className="w-8 h-8">
            <svg viewBox="0 0 64 64">
              <rect
                x="2"
                y="2"
                width="60"
                height="60"
                fill="#E5E7EB"
                stroke="black"
                strokeWidth="4"
              />
            </svg>
          </div>
        </div>
      </TooltipTrigger>
      <TooltipContent side="top">Default Element</TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export default {
  StartIcon,
  EndIcon,
  DecisionIcon,
  TextIcon,
  ActivityIcon,
  SwimlaneIcon,
  DefaultIcon,
};
