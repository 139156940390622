import { Button } from '@fluency/ui/components/ui/button';
import { Trash2, X } from 'lucide-react';

interface StepActionButtonsProps {
  selectedCount: number;
  onDelete: () => void;
  onDeselectAll: () => void;
  isDeleteDisabled: boolean;
}

export function StepActionButtons({
  selectedCount,
  onDelete,
  onDeselectAll,
  isDeleteDisabled,
}: StepActionButtonsProps) {
  if (selectedCount === 0) return null;

  return (
    <div className="absolute bottom-4 left-0 right-0 z-10 px-4 space-y-2">
      <Button
        variant="outline"
        size="sm"
        className="w-full bg-white"
        onClick={onDeselectAll}
      >
        <X className="w-4 h-4 mr-2" />
        Deselect {selectedCount} step{selectedCount > 1 ? 's' : ''}
      </Button>
      <Button
        variant="destructive"
        size="sm"
        className="w-full"
        onClick={onDelete}
        disabled={isDeleteDisabled}
      >
        <Trash2 className="w-4 h-4 mr-2" />
        Delete {selectedCount} step{selectedCount > 1 ? 's' : ''}
      </Button>
    </div>
  );
}
