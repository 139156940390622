import { useState, useEffect } from 'react';
import { Plus, Minus, CreditCard, RefreshCw } from 'lucide-react';
import { Button } from '@fluency/ui/components/ui/button';
import { Card, CardContent } from '@fluency/ui/components/ui/card';
import { Separator } from '@fluency/ui/components/ui/separator';
import { Input } from '@fluency/ui/components/ui/input';
import { ProrationResponse } from '@fluency/ui/hooks/subscription/types/AdjustSeats';
import useSubscription from '@fluency/ui/hooks/subscription/useSubscription';
import { Logger } from '@fluency/ui/features/Logger';

function calculateProrationAmounts(prorationResponse: ProrationResponse) {
  const { prorationPreview } = prorationResponse;

  // Calculate the proration credit (negative amount)
  const prorationCredit = prorationPreview.lines.data
    .filter((line) => line.type === 'invoiceitem' && line.amount < 0)
    .reduce((total, line) => total + line.amount, 0);

  // Calculate the new charge for the upgraded plan
  const newCharge = prorationPreview.lines.data
    .filter((line) => line.type === 'invoiceitem' && line.amount > 0)
    .reduce((total, line) => total + line.amount, 0);

  // Calculate the net amount due today
  const amountDueToday = Math.max(0, newCharge + prorationCredit);

  // Amount due at next billing date
  const nextBillingAmount =
    prorationPreview.lines.data.find((line) => line.type === 'subscription')
      ?.amount || 0;

  return {
    prorationCredit: Math.abs(prorationCredit) / 100,
    newCharge: newCharge / 100,
    amountDueToday: amountDueToday / 100,
    nextBillingAmount: nextBillingAmount / 100,
  };
}

interface ChangeSeatsProps {
  onSaveChanges: (data: {
    newSeats: number;
    plan: string;
    product: string;
    prorationResponse: ProrationResponse | null;
  }) => void;
}

export default function ChangeSeats({ onSaveChanges }: ChangeSeatsProps) {
  const {
    billingDetails,
    subscriptionStatus,
    calculateProration,
    isCalculatingProration,
  } = useSubscription();

  const currentProduct = subscriptionStatus?.subscription.products[0];
  const currentSeats = currentProduct?.quantity || 0;
  const [newSeats, setNewSeats] = useState<number>(currentSeats);
  const [prorationResponse, setProrationResponse] =
    useState<ProrationResponse | null>(null);

  useEffect(() => {
    if (subscriptionStatus?.subscription.products[0]?.quantity) {
      setNewSeats(subscriptionStatus.subscription.products[0].quantity);
    }
  }, [subscriptionStatus]);

  const handleIncrease = () => setNewSeats((prev) => prev + 1);
  const handleDecrease = () => setNewSeats((prev) => Math.max(1, prev - 1));

  useEffect(() => {
    if (newSeats !== currentSeats) {
      calculateProration(
        {
          creatorSeats: newSeats,
          plan:
            currentProduct?.prices[0]?.recurring.interval === 'year'
              ? 'annual'
              : 'monthly',
          product: subscriptionStatus?.subscription.plan || '',
        },
        {
          onSuccess: (response: ProrationResponse) => {
            setProrationResponse(response);
          },
          onError: (error: Error) =>
            Logger.error('Error calculating proration:', error),
        }
      );
    } else {
      setProrationResponse(null);
    }
  }, [
    newSeats,
    currentSeats,
    currentProduct,
    subscriptionStatus,
    calculateProration,
  ]);

  const handleSaveChanges = () => {
    const plan =
      currentProduct?.prices[0]?.recurring.interval === 'year'
        ? 'annual'
        : 'monthly';
    const product = subscriptionStatus?.subscription.plan || '';
    onSaveChanges({
      newSeats,
      plan,
      product,
      prorationResponse,
    });
  };

  const currentCost =
    currentProduct?.quantity && currentProduct?.prices[0]?.unitAmount
      ? (currentProduct.quantity * currentProduct.prices[0].unitAmount) / 100
      : 0;

  const renderCurrentPlan = () => (
    <>
      <div className="font-semibold">Current subscription</div>
      <ul className="grid gap-3">
        <li className="flex items-center justify-between">
          <span className="text-muted-foreground">
            {currentProduct?.name} (
            <span>
              {currentProduct?.quantity} x A$
              {currentProduct?.prices[0]?.unitAmount
                ? (currentProduct.prices[0].unitAmount / 100).toFixed(2)
                : '0.00'}
            </span>
            )
          </span>
          <span>
            A${currentCost.toFixed(2)} per{' '}
            {currentProduct?.prices[0]?.recurring.interval}
          </span>
        </li>
      </ul>
    </>
  );

  const renderSeatAdjustment = () => (
    <>
      <div className="font-semibold">Adjust seats</div>
      <li className="flex items-center justify-between">
        <span className="text-muted-foreground">Number of seats</span>
        <div className="flex items-center">
          <Button
            onClick={handleDecrease}
            variant="outline"
            size="icon"
            disabled={isCalculatingProration}
          >
            <Minus className="h-4 w-4" />
          </Button>
          <Input
            className="w-20 mx-2 text-center"
            value={newSeats}
            onChange={(e) => setNewSeats(parseInt(e.target.value) || 0)}
            disabled={isCalculatingProration}
          />
          <Button
            onClick={handleIncrease}
            variant="outline"
            size="icon"
            disabled={isCalculatingProration}
          >
            <Plus className="h-4 w-4" />
          </Button>
        </div>
      </li>
    </>
  );

  const renderProrationInfo = () => {
    if (!prorationResponse) return null;

    const { amountDueToday, nextBillingAmount } =
      calculateProrationAmounts(prorationResponse);

    return (
      <>
        <li className="flex items-center justify-between font-semibold">
          <span>Total due today</span>
          <span>A${amountDueToday.toFixed(2)}</span>
        </li>
        <li className="flex items-center justify-between">
          <span className="text-muted-foreground">Next monthly charge</span>
          <span className="text-muted-foreground">
            A${nextBillingAmount.toFixed(2)}
          </span>
        </li>
      </>
    );
  };

  return (
    <Card className="overflow-hidden border-0">
      <CardContent className="p-6 text-sm px-0 pt-0">
        <div className="grid gap-3">
          {renderCurrentPlan()}
          <Separator className="my-2" />
          <ul className="grid gap-3">
            {renderSeatAdjustment()}
            {isCalculatingProration ? (
              <div className="w-full flex items-center gap-1 text-gray-400 justify-center">
                <RefreshCw className="w-4 animate-spin" />
                Calculating...
              </div>
            ) : (
              renderProrationInfo()
            )}
          </ul>
        </div>
        <Separator className="my-4" />
        <div className="grid gap-3">
          <div className="font-semibold">Customer Information</div>
          <dl className="grid gap-3">
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Customer</dt>
              <dd>{billingDetails?.name}</dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Email</dt>
              <dd>
                <a href={`mailto:${billingDetails?.email}`}>
                  {billingDetails?.email}
                </a>
              </dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Phone</dt>
              <dd>
                <a href={`tel:${billingDetails?.phone}`}>
                  {billingDetails?.phone}
                </a>
              </dd>
            </div>
          </dl>
        </div>
        <Separator className="my-4" />
        {subscriptionStatus?.paymentMethod?.card?.last4 ? (
          <div className="grid gap-3">
            <div className="font-semibold">Payment Information</div>
            <dl className="grid gap-3">
              <div className="flex items-center justify-between">
                <dt className="flex items-center gap-1 text-muted-foreground capitalize">
                  <CreditCard className="h-4 w-4" />
                  {subscriptionStatus?.paymentMethod?.card?.brand}
                </dt>
                <dd>
                  **** **** ****{' '}
                  {subscriptionStatus?.paymentMethod?.card?.last4}
                </dd>
              </div>
            </dl>
          </div>
        ) : (
          <div className="grid gap-3">
            <div className="text-xs">
              Note: Add a payment method to continue using Fluency after your
              trial expires.
            </div>
          </div>
        )}
        <div className="mt-8 flex justify-end">
          <Button
            onClick={handleSaveChanges}
            disabled={isCalculatingProration || newSeats === currentSeats}
          >
            {isCalculatingProration ? 'Calculating...' : 'Save Changes'}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
