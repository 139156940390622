import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import {
  NotificationChannel,
  NotificationType,
  NotificationPreferenceResponse,
  UpdatePreferenceResponse,
} from '../types/types';

interface MutationContext {
  previousPreferences: NotificationPreferenceResponse | undefined;
}

export const useNotificationPreferences = () => {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const queryClient = useQueryClient();
  const [updateProgress, setUpdateProgress] = useState(0);
  const { accessToken } = useAuth();

  const getPreferencesQuery = useQuery<NotificationPreferenceResponse, Error>({
    queryKey: ['notificationPreferences'],
    queryFn: async () => {
      const response = await fetch(`${apiBaseUrl}/notificationPreferences`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch notification preferences');
      }

      return response.json();
    },
  });

  const updatePreferenceMutation = useMutation<
    UpdatePreferenceResponse,
    Error,
    { notificationType: NotificationType; channels: NotificationChannel[] },
    MutationContext
  >({
    mutationFn: async ({ notificationType, channels }) => {
      const response = await fetch(
        `${apiBaseUrl}/notificationPreferences/${String(notificationType)}`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            notificationType: String(notificationType),
            channels: channels,
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Update failed');
      }

      return response.json();
    },
    onMutate: async ({ notificationType, channels }) => {
      await queryClient.cancelQueries({
        queryKey: ['notificationPreferences'],
      });
      const previousPreferences =
        queryClient.getQueryData<NotificationPreferenceResponse>([
          'notificationPreferences',
        ]);

      queryClient.setQueryData<NotificationPreferenceResponse>(
        ['notificationPreferences'],
        (old) => ({
          message: old?.message ?? '',
          preferences:
            old?.preferences.map((pref) =>
              pref.notificationType === notificationType
                ? { ...pref, channels }
                : pref
            ) ?? [],
        })
      );

      return { previousPreferences };
    },
    onError: (_err, _variables, context: MutationContext | undefined) => {
      if (context?.previousPreferences) {
        queryClient.setQueryData(
          ['notificationPreferences'],
          context.previousPreferences
        );
      }
      setUpdateProgress(0);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notificationPreferences'] });
      setUpdateProgress(0);
    },
  });

  const resetPreferenceMutation = useMutation<
    UpdatePreferenceResponse,
    Error,
    NotificationType,
    MutationContext
  >({
    mutationFn: async (notificationType: NotificationType) => {
      const response = await fetch(
        `${apiBaseUrl}/notificationPreferences/${String(notificationType)}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to reset preference');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notificationPreferences'] });
    },
  });

  return {
    preferences: getPreferencesQuery.data?.preferences ?? [],
    isLoading: getPreferencesQuery.isLoading,
    error: getPreferencesQuery.error,
    updatePreference: updatePreferenceMutation.mutate,
    isUpdating: updatePreferenceMutation.isPending,
    updateProgress,
    updateError: updatePreferenceMutation.error,
    resetPreference: resetPreferenceMutation.mutate,
    isResetting: resetPreferenceMutation.isPending,
    resetError: resetPreferenceMutation.error,
  };
};
