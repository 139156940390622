import { useCallback, useRef, useState, useMemo } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { DashboardShell } from '@fluency/ui/components';
import Welcome from '@fluency/ui/components/DashboardShell/Welcome';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@fluency/ui/components/ui/select';
import { useToast } from '@fluency/ui/components/ui/use-toast';
import PageLayout from '@fluency/ui/templates/PageLayout';
import { VaultHelpDialog } from './components/VaultHelpDialog';
import { EditVaultDialog } from './ManageVaults/components/EditVaultDialog';
import VaultIconStates from './ManageVaults/components/VaultIconStates';
import {
  UpdateVaultData,
  useVaultOperations,
} from './ManageVaults/hooks/useVaultOperations';
import CreateNewCards from './components/CreateNewCards';
import { useResourceActions } from './hooks/useResourceActions';
import { useVaultResources } from '../../hooks/vaults/useVaultResources';
import { useIntersectionObserver } from './hooks/useIntersectionObserver';
import { searchResources } from './utils/searchResources';
import { ResourceItemCard } from './components/ResourceItemCard';
import { SearchInput } from './components/SearchInput';
import { LoadingSkeleton } from './components/LoadingSkeleton';
import { ErrorState } from './components/ErrorState';
import { EmptyState } from './components/EmptyState';
import {
  DocumentationResource,
  ResourceItem,
  WorkflowResource,
} from '../../hooks/vaults/types/types';
import { ResourceType } from '@fluency/ui/hooks/fga/types/fga';
import { Lock } from 'lucide-react';
import { Badge } from '@fluency/ui/components/ui/badge';
import { PermissionsDialog } from '../ManagePermissions';

interface VaultDashboardProps {
  vaultId: string;
}

export function VaultDashboard({ vaultId }: VaultDashboardProps): JSX.Element {
  const navigate = useNavigate();
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const [resourceType, setResourceType] = useState<ResourceType | undefined>();
  const [isWelcomeOpen, setIsWelcomeOpen] = useState<boolean>(false);
  const { toast } = useToast();
  const { deleteResource } = useResourceActions();
  const { useGetVaults, useUpdateVault } = useVaultOperations();
  const { data: vaultsData } = useGetVaults();
  const updateVaultMutation = useUpdateVault();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const currentVault = vaultsData?.vaults.find((vault) => vault.id === vaultId);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
    error,
    refetch,
  } = useVaultResources({
    vaultId,
    pageSize: 25,
    resourceType,
    enabled: true,
  });

  const items = useMemo(
    () => data?.pages?.flatMap((page) => page.items) ?? [],
    [data?.pages]
  );

  const filteredItems = useMemo(
    () => searchResources(items, searchTerm),
    [items, searchTerm]
  );

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    enabled: !!hasNextPage,
  });

  const handleEditVault = async (data: UpdateVaultData) => {
    try {
      await updateVaultMutation.mutateAsync(data);
      toast({
        title: 'Success',
        description: 'Vault updated successfully',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update vault',
        variant: 'destructive',
      });
    }
  };

  const handleDelete = useCallback(
    (item: ResourceItem) => {
      const resourceId =
        item.type === ResourceType.DOCUMENTATION
          ? (item.resource as DocumentationResource).documentationId
          : (item.resource as WorkflowResource).id;

      deleteResource({
        resourceId: resourceId!,
        resourceType: item.type,
        vaultId,
      });
    },
    [deleteResource, vaultId]
  );

  const handleView = useCallback(
    (item: ResourceItem) => {
      const path =
        item.type === ResourceType.DOCUMENTATION
          ? `/document/${
              (item.resource as DocumentationResource).documentationId
            }`
          : `/workflow/${(item.resource as WorkflowResource).id}`;
      navigate({ to: path });
    },
    [navigate]
  );

  return (
    <DashboardShell>
      <PageLayout
        maxWidth="full"
        header={{
          title: currentVault?.name || 'Vault',
          description: currentVault?.description,
          breadcrumbs: {
            items: [
              { label: 'Vault', to: '/vault' },
              { label: currentVault?.name || 'Vault' },
            ],
          },
          titleMetadata: (
            <div className="flex items-center gap-2">
              {currentVault?.isLocked && (
                <Badge variant="secondary">
                  <div className="items-center justify-center gap-2 flex">
                    <Lock className="w-4 mb-0.5" />
                    <span className="mt-0.5 text-sm">Locked</span>
                  </div>
                </Badge>
              )}
              <VaultHelpDialog />
            </div>
          ),
          headerActions: (
            <div className="flex items-center gap-2">
              <div className="flex gap-2">
                {currentVault && <VaultIconStates vault={currentVault} />}
                {currentVault && (
                  <EditVaultDialog
                    vault={currentVault}
                    onEditVault={handleEditVault}
                  />
                )}
                <PermissionsDialog
                  resourceId={vaultId}
                  resourceType="VAULT"
                  vault={currentVault}
                />
              </div>
            </div>
          ),
        }}
        content={{
          className: 'flex flex-col h-full relative',
          children: (
            <>
              <div className="flex-1 overflow-auto">
                <div className="space-y-4 mt-6 mb-6">
                  {currentVault && (
                    <CreateNewCards isLocked={currentVault?.isLocked} />
                  )}
                  <div className="z-10 bg-background border-t pt-4">
                    <div>
                      <div className="flex flex-col sm:flex-row gap-4">
                        <SearchInput
                          value={searchTerm}
                          onChange={setSearchTerm}
                        />
                        <div className="flex gap-2">
                          <Select
                            value={resourceType ?? 'all'}
                            onValueChange={(value: string) =>
                              setResourceType(
                                value === 'all'
                                  ? undefined
                                  : (value as ResourceType)
                              )
                            }
                          >
                            <SelectTrigger className="w-[180px]">
                              <SelectValue placeholder="All types" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="all">All types</SelectItem>
                              <SelectItem value={ResourceType.DOCUMENTATION}>
                                Documents
                              </SelectItem>
                              <SelectItem value={ResourceType.WORKFLOW}>
                                Process Maps
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="space-y-3">
                    {isLoading ? (
                      <LoadingSkeleton />
                    ) : isError ? (
                      <ErrorState
                        message={
                          error?.message ||
                          "We couldn't load the vault resources. Please try again."
                        }
                        retry={() => {
                          refetch();
                        }}
                      />
                    ) : filteredItems.length === 0 ? (
                      searchTerm ? (
                        <div className="flex justify-center items-center h-64 text-muted-foreground">
                          No results found for "{searchTerm}"
                        </div>
                      ) : (
                        <EmptyState
                          onGetStarted={() => setIsWelcomeOpen(true)}
                        />
                      )
                    ) : (
                      <>
                        {filteredItems.map((item) => {
                          // Generate a unique key that combines type and ID
                          const uniqueKey =
                            item.type === ResourceType.DOCUMENTATION
                              ? `doc-${
                                  (item.resource as DocumentationResource)
                                    .documentationId
                                }`
                              : `workflow-${
                                  (item.resource as WorkflowResource).id
                                }`;

                          return (
                            <ResourceItemCard
                              key={uniqueKey}
                              item={item}
                              onView={handleView}
                              onDelete={handleDelete}
                              isLocked={currentVault?.isLocked || false}
                            />
                          );
                        })}
                      </>
                    )}
                    {hasNextPage && !searchTerm && (
                      <div ref={loadMoreRef}>
                        <LoadingSkeleton />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ),
        }}
      />
      <Welcome
        isOpen={isWelcomeOpen}
        onOpenChange={(open: boolean) => setIsWelcomeOpen(open)}
      />
    </DashboardShell>
  );
}

export default VaultDashboard;
