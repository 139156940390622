import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useState } from 'react';

interface CollapsibleSidebarProps {
  children: React.ReactNode;
  isCollapsed: boolean;
  onToggle: () => void;
}
export const CollapsibleSidebar = ({
  children,
  isCollapsed,
  onToggle,
}: CollapsibleSidebarProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isButtonHovering, setIsButtonHovering] = useState(false);

  return (
    <div className="relative flex h-full">
      {/* Main sidebar content */}
      <div
        className={`
          transition-all duration-300 ease-in-out 
          border-r bg-card relative
          ${isCollapsed ? 'w-12' : 'w-64'} 
          shrink-0
        `}
      >
        {children}
      </div>

      {/* Collapse handle - spans full height */}
      <div className="relative">
        {/* Invisible wider click area with hover detection */}
        <div
          onClick={onToggle}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          className={`
            absolute top-0 -left-2 h-full w-4
            cursor-pointer
            z-40
          `}
        />

        {/* Visible line that shows hover state */}
        <div
          className={`
            absolute top-0 -left-px h-full w-[1px]
            transition-colors duration-200
            z-50
            ${isHovering ? 'bg-primary' : 'bg-border'}
            ${isCollapsed ? '-translate-x-px' : 'translate-x-0'}
          `}
        />

        {/* Tooltip and button container - positioned absolutely */}
        <div className="absolute -left-3 top-5 z-50">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <button
                  onClick={onToggle}
                  onMouseEnter={() => setIsButtonHovering(true)}
                  onMouseLeave={() => setIsButtonHovering(false)}
                  className={`
                    h-6 w-6 rounded-full
                    flex items-center justify-center
                    border bg-background shadow-sm
                    transition-colors duration-200
                    ${
                      isHovering || isButtonHovering
                        ? 'bg-primary text-primary-foreground'
                        : ''
                    }
                  `}
                >
                  {isCollapsed ? (
                    <ChevronRight className="h-4 w-4" />
                  ) : (
                    <ChevronLeft className="h-4 w-4" />
                  )}
                </button>
              </TooltipTrigger>
              <TooltipContent
                side="right"
                align="center"
                className="text-xs z-[100]"
              >
                {isCollapsed ? 'Expand' : 'Collapse'}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </div>
  );
};

export default CollapsibleSidebar;
