import { useVaultOperations } from '@fluency/ui/features/Vaults/ManageVaults/hooks/useVaultOperations';

export const useVaultName = () => {
  const { useGetVaults } = useVaultOperations();
  const { data: vaultsData, isLoading } = useGetVaults();

  const getVaultName = (id: string): string => {
    if (isLoading) {
      return '';
    }
    if (id === 'manage') {
      return 'Manage Vaults';
    }

    const vault = vaultsData?.vaults?.find((vault) => vault.id === id);
    const result = vault?.name || `Vault ${id}`;
    return result;
  };

  return { getVaultName, isLoading };
};
