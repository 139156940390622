import { useQuery } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { DetailedPermissionResponse } from './types/types';
import { RoleName } from './types/fga';

interface DocumentPermissionsResult {
  permissions: DetailedPermissionResponse | undefined;
  isLoading: boolean;
  error: Error | null;
  role: RoleName | null | undefined;
  isManager: boolean;
  isEditor: boolean;
  isViewer: boolean;
}

export const useDocumentPermissions = (
  documentId?: string
): DocumentPermissionsResult => {
  const { accessToken } = useAuth();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  const fetchPermissions = async (): Promise<DetailedPermissionResponse> => {
    if (!documentId) {
      throw new Error('Document ID is required');
    }

    const response = await fetch(
      `${apiBaseUrl}/fga/permissions?resourceId=${documentId}&resourceType=DOCUMENTATION`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch user permissions');
    }

    return response.json();
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ['documentPermissions', documentId],
    queryFn: fetchPermissions,
    enabled: !!documentId && !!accessToken,
  });

  // Helper function to safely check role hierarchy
  const hasRole = (
    userRole: RoleName | null | undefined,
    requiredRole: RoleName
  ): boolean => {
    if (!userRole) return false;

    const roleHierarchy: Record<RoleName, number> = {
      [RoleName.MANAGER]: 4,
      [RoleName.EDITOR]: 3,
      [RoleName.COMMENTER]: 2,
      [RoleName.VIEWER]: 1,
    };

    return roleHierarchy[userRole] >= roleHierarchy[requiredRole];
  };

  // Get the current role from the bearer's effectiveRole
  const currentRole = data?.data.bearer.effectiveRole.role;

  return {
    permissions: data,
    isLoading,
    error: error as Error | null,
    role: currentRole,
    isManager: hasRole(currentRole, RoleName.MANAGER),
    isEditor: hasRole(currentRole, RoleName.EDITOR),
    isViewer: hasRole(currentRole, RoleName.VIEWER),
  };
};
