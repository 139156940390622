import { MAX_SCALE } from '../constant';
import { transitionStyles } from '../styles';
import { CropData } from '../types';

export const hasCropData = (crop: CropData): boolean => {
  return (
    crop.x1 !== null && crop.x2 !== null && crop.y1 !== null && crop.y2 !== null
  );
};

export const getScaledStyle = (
  naturalWidth: number,
  maxScale: number = MAX_SCALE
) => {
  return {
    display: 'block',
    maxWidth: `${naturalWidth * maxScale}px`,
    width: '100%',
    ...transitionStyles.base,
  };
};

export const calculateScale = (cropData: CropData): number => {
  if (!hasCropData(cropData)) return 1;

  // Calculate scale based on selected area
  const width = cropData.x2! - cropData.x1!;
  const height = cropData.y2! - cropData.y1!;

  // Use the smaller dimension to determine scale
  const scale = 100 / Math.min(width, height);
  return Math.min(scale, MAX_SCALE); // Don't exceed max scale
};
