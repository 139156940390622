import React, { useState } from 'react';
import { Position, NodeProps, useReactFlow } from 'reactflow';
import { X } from 'lucide-react';
import { createNodeHandles } from '../../utils/createNodeHandles';
import Resizer from './Resizer';

interface EndNodeData {
  onDelete: (id: string) => void;
  isViewMode?: boolean;
}

const EndNode: React.FC<NodeProps<EndNodeData>> = ({ id, data }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { getNode } = useReactFlow();

  const node = getNode(id);
  const width = node?.width ?? 64;
  const height = node?.height ?? 64;

  // Choose the bigger between width and height to set the const size
  const size = width > height ? width : height;

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    data.onDelete(id);
  };

  return (
    <div
      className="relative"
      onMouseEnter={() => !data.isViewMode && setIsHovered(true)}
      onMouseLeave={() => !data.isViewMode && setIsHovered(false)}
      style={{
        width: size,
        height: size,
      }}
    >
      {isHovered && !data.isViewMode && (
        <button
          onClick={handleDelete}
          className="absolute -top-4 -right-4 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors z-10"
        >
          <X size={16} />
        </button>
      )}

      {/* Node Resizer */}
      {isHovered && !data.isViewMode && (
        <Resizer className="absolute bottom-1 right-1" />
      )}
      <div className="w-full h-full rounded-full flex items-center justify-center border-8 border-black font-semibold bg-transparent"></div>
      {createNodeHandles({
        id,
        isViewMode: data.isViewMode,
        isHovered,
        hideSourceHandles: [
          Position.Top,
          Position.Bottom,
          Position.Left,
          Position.Right,
        ],
      })}
    </div>
  );
};

export default EndNode;
