import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { DocumentationResponse } from '../../../hooks/documentation/types/master';
import { Step } from '../../../hooks/documentation/types/master';
import { Logger } from '@fluency/ui/features/Logger';

export interface ReorderStepResponse {
  message: string;
  reorderedSteps: {
    stepId: string;
    stepNumber: number;
    stepDescription: string;
    stepType: string;
    documentationId: string;
  }[];
}

export interface ReorderStepPayload {
  documentationId: string;
  stepIds: {
    stepId: string;
    stepNumber: number;
  }[];
}

async function reorderSteps(
  payload: ReorderStepPayload,
  token: string
): Promise<ReorderStepResponse> {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  const response = await fetch(`${apiBaseUrl}/step/reorder`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error('Failed to reorder steps');
  }

  const data = await response.json();
  return data;
}

interface MutationContext {
  previousDoc?: DocumentationResponse;
}

export function useReorderSteps() {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation<
    ReorderStepResponse,
    Error,
    ReorderStepPayload,
    MutationContext
  >({
    mutationKey: ['reorderSteps'],
    mutationFn: async (payload) => {
      return reorderSteps(payload, accessToken as string);
    },
    onMutate: async (payload) => {
      await queryClient.cancelQueries({
        queryKey: ['docInfo', payload.documentationId],
      });

      const previousDoc = queryClient.getQueryData<DocumentationResponse>([
        'docInfo',
        payload.documentationId,
      ]);

      if (!previousDoc) {
        return { previousDoc: undefined };
      }

      const newSteps = payload.stepIds.map((step) => {
        const originalStep = previousDoc.documentation.steps.find(
          (s) => s.stepId === step.stepId
        );
        return {
          ...originalStep,
          stepNumber: step.stepNumber,
        } as Step;
      });

      queryClient.setQueryData<DocumentationResponse>(
        ['docInfo', payload.documentationId],
        (old: DocumentationResponse | undefined) => {
          if (!old) return old;
          return {
            ...old,
            documentation: {
              ...old.documentation,
              steps: newSteps,
            },
          };
        }
      );

      return { previousDoc };
    },
    onError: (_error, variables, context) => {
      Logger.error('Error reordering steps:', _error);
      toast({
        variant: 'destructive',
        title: 'Something went wrong.',
        description: 'There was a problem reordering the steps.',
      });

      if (context?.previousDoc) {
        queryClient.setQueryData(
          ['docInfo', variables.documentationId],
          context.previousDoc
        );
      }
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['docInfo', variables.documentationId],
      });
    },
  });
}

export default useReorderSteps;
