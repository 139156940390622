import { Badge } from '@fluency/ui/components/ui/badge';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import { Loader2, SparklesIcon } from 'lucide-react';
import React from 'react';
import CheckItem from './CheckItem';
import SeatsInput from './SeatsInput';
import useSubscription from '@fluency/ui/hooks/subscription/useSubscription';

interface PricingCardProps {
  title: string;
  price: string | JSX.Element;
  features: string[];
  badge?: boolean;
  badgeText?: string;
  seats?: number;
  setSeats?: React.Dispatch<React.SetStateAction<number>>;
  onButtonClick?: () => void;
  onAlternativeClick?: () => void;
  isGettingCheckoutUrl?: boolean;
  buttonText?: string;
  alternativeText?: string;
  productName: string;
  pricePerSeat: number;
  isAnnual: boolean;
  isLoading: boolean;
}

export const PricingCard: React.FC<PricingCardProps> = ({
  title,
  price,
  features,
  badge,
  badgeText,
  seats,
  setSeats,
  onButtonClick,
  onAlternativeClick,
  isGettingCheckoutUrl,
  buttonText,
  alternativeText,
  productName,
  pricePerSeat,
  isAnnual,
  isLoading,
}) => {
  const { subscriptionStatus } = useSubscription();

  const renderBadge = () => {
    if (!badge || !badgeText || subscriptionStatus?.hasActivatedTrial)
      return null;
    return (
      <Badge className="h-6 ml-2 bg-fluency-600">
        <SparklesIcon className="h-3 w-3 mr-1 -ml-1" /> {badgeText}
      </Badge>
    );
  };

  const renderButton = () => {
    if (!onButtonClick) return null;
    return (
      <Button
        className="w-full mt-4"
        onClick={onButtonClick}
        disabled={isGettingCheckoutUrl || isLoading}
      >
        {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : <></>}
        {buttonText}
      </Button>
    );
  };

  const renderAlternativeAction = () => {
    if (!onAlternativeClick || !alternativeText) return null;
    return (
      <span className="w-full flex justify-center text-sm items-center -mb-4">
        {' '}
        or{' '}
        <Button
          variant="link"
          className="m-0 p-0 pl-1"
          onClick={onAlternativeClick}
          disabled={isLoading}
        >
          {alternativeText}
        </Button>
      </span>
    );
  };

  const cardClassName = `w-[22rem] flex flex-col justify-between py-1 mx-auto sm:mx-0 animate-background-shine bg-white ${
    productName === 'Business Plan'
      ? 'border-2 border-fluency-600'
      : 'border-0 border-gray-400'
  }`;

  return (
    <Card className={cardClassName}>
      <div>
        <CardHeader className="pb-8 pt-4">
          <CardTitle className="text-zinc-700 text-lg">
            <div className="w-full flex justify-between">
              <span>{title.split(' ')[0]}</span>
              {renderBadge()}
            </div>
          </CardTitle>
          <div className="flex gap-0.5 h-fit items-end">
            <h3 className="text-3xl font-semibold">{price}</h3>
            {isAnnual && productName != 'Enterprise Plan' && (
              <span className="text-xs text-gray-600 mb-1 ml-1">
                (billed annually)
              </span>
            )}
          </div>
        </CardHeader>
        <CardContent className="flex flex-col gap-2">
          {features.map((feature: string) => (
            <CheckItem key={feature} text={feature} />
          ))}
        </CardContent>
      </div>
      <CardFooter className="mt-2 flex flex-col items-start w-full">
        {seats !== undefined && setSeats && (
          <SeatsInput
            seats={seats}
            setSeats={setSeats}
            pricePerSeat={pricePerSeat}
            isAnnual={isAnnual}
          />
        )}
        {renderButton()}
        {(!subscriptionStatus?.hasActivatedTrial ||
          productName === 'Enterprise Plan') &&
          renderAlternativeAction()}
      </CardFooter>
    </Card>
  );
};

export default PricingCard;
