import { RefreshCw } from 'lucide-react';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@fluency/ui/components/ui/dialog';
import { useToast } from '@fluency/ui/components/ui/use-toast';
import { useCopyDocument } from '@fluency/ui/hooks/documentation/useCopyDocument';
import { Button } from '@fluency/ui/components/ui/button';

interface DuplicateDocumentDialogProps {
  documentId: string;
  documentName: string;
  vaultId: string;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

const DuplicateDocumentDialog = ({
  documentId,
  documentName,
  vaultId,
  isOpen,
  onOpenChange,
}: DuplicateDocumentDialogProps) => {
  const copyDocument = useCopyDocument();
  const { toast } = useToast();

  const handleDuplicate = () => {
    copyDocument.mutate(
      { documentId, vaultId },
      {
        onSuccess: () => {
          onOpenChange(false);
          toast({
            title: 'Document duplicated',
            description: `Successfully duplicated "${documentName}"`,
          });
        },
        onError: (error) => {
          toast({
            variant: 'destructive',
            title: 'Failed to duplicate document',
            description: error.message || 'Please try again',
          });
        },
      }
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Duplicate Document</DialogTitle>
          <DialogDescription>
            Are you sure you want to duplicate "{documentName}"? A copy will be
            created in the same vault.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="gap-2">
          <DialogClose asChild>
            <Button
              size="sm"
              variant="outline"
              disabled={copyDocument.isPending}
              onClick={() => onOpenChange(false)}
            >
              Cancel
            </Button>
          </DialogClose>

          <Button
            onClick={handleDuplicate}
            disabled={copyDocument.isPending}
            className="gap-2"
            size="sm"
          >
            {copyDocument.isPending ? (
              <RefreshCw className="h-4 w-4 animate-spin" />
            ) : null}
            {copyDocument.isPending ? 'Duplicating...' : 'Duplicate'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DuplicateDocumentDialog;
