import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import { Button } from '@fluency/ui/components/ui/button';
import { Card, CardContent } from '@fluency/ui/components/ui/card';
import WelcomeCard from '@fluency/ui/components/DashboardShell/WelcomeCard';
import logo from '@fluency/ui/assets/icon-blue.svg';
import { useSyncStatus } from '@fluency/ui/components/ExtensionStatusButton/hooks/useSyncStatus';
import { ExtensionStatusDialog } from '@fluency/ui/components/ExtensionStatusButton/ExtensionStatusDialog';
import ExtensionHowTo from './ExtensionHowTo';
import { useExtensionStatus } from '@fluency/ui/components/ExtensionStatusButton/hooks/useExtensionStatus';

const ModifiedWelcome: React.FC = () => {
  const { extensionStatus, checkExtensionStatus } = useExtensionStatus();
  const { syncStatus, sendAuthData } = useSyncStatus(
    extensionStatus,
    checkExtensionStatus
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const [showSyncSettings, setShowSyncSettings] = React.useState(false);

  const handleDownload = () => {
    window.open(
      'https://chromewebstore.google.com/detail/fluency/nhadgomabaphpfplmbfemfnagnhhghei',
      '_blank'
    );
  };

  const getDialogContent = () => {
    if (extensionStatus === 'connected') {
      return (
        <Card className="bg-transparent border-0">
          <CardContent className="flex flex-col items-center justify-center gap-4">
            <div className="flex justify-center gap-4 items-center">
              <ExtensionHowTo />
            </div>
          </CardContent>
        </Card>
      );
    }

    // Show welcome cards for all other cases, including 'not_installed' and 'installed_not_connected'
    return (
      <Card className="border-0 min-w-[800px]">
        <CardContent className="flex flex-col items-center justify-center gap-2">
          <p className="text-gray-800 font-medium text-lg">
            Fluency needs the desktop app or browser extension to record your
            processes.
          </p>
          <p className="text-gray-600 font-medium mb-2">
            Choose how you capture your processes:
          </p>
          <div className="flex justify-center gap-4 sm:flex-row items-center">
            <WelcomeCard variant="create" />
            <WelcomeCard variant="welcome" />
          </div>
        </CardContent>
      </Card>
    );
  };

  const handleOpenChange = (open: boolean) => {
    if (open) {
      if (extensionStatus === 'connected') {
        setIsOpen(true);
      } else if (extensionStatus === 'installed_not_connected') {
        setShowSyncSettings(true);
      } else {
        // This includes both 'not_installed' and any other unexpected states
        setIsOpen(true);
      }
    } else {
      setIsOpen(false);
      setShowSyncSettings(false);
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={handleOpenChange}>
        <DialogTrigger asChild>
          <Button
            onClick={() => handleOpenChange(true)}
            variant="outline"
            className="flex flex-col items-center justify-center w-32 h-32 p-2 hover:scale-105 transition-transform"
          >
            <div className="relative flex items-center flex-col">
              <img
                src={logo}
                alt="logo"
                className="w-20 h-20 min-w-16 min-h-16"
              />
              <div className="absolute -top-1 right-4 h-7 w-7 justify-center flex items-center bg-red-500  border-1 border-white rounded-full text-white font-semibold text-xs">
                REC
              </div>
              <span className="text-sm text-center font-medium">
                Capture Process
              </span>
            </div>
          </Button>
        </DialogTrigger>
        <DialogContent className="border-0 shadow-none border-gray-700 flex justify-center max-w-none w-fit">
          {getDialogContent()}
        </DialogContent>
      </Dialog>
      <ExtensionStatusDialog
        isOpen={showSyncSettings}
        onOpenChange={(open) => {
          setShowSyncSettings(open);
          if (!open) setIsOpen(false);
        }}
        extensionStatus={extensionStatus}
        syncStatus={syncStatus}
        handleDownload={handleDownload}
        sendAuthData={sendAuthData}
      />
    </>
  );
};

export default ModifiedWelcome;
