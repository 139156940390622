import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@fluency/ui/components/ui/use-toast';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import {
  ResourceItem,
  DocumentationResource,
  WorkflowResource,
  InfiniteQueryResponse,
} from '../../../hooks/vaults/types/types';
import { ResourceType } from '@fluency/ui/hooks/fga/types/fga';

interface DeleteResourceParams {
  resourceId: string;
  resourceType: ResourceType;
  vaultId: string;
}

interface MutationContext {
  previousData: InfiniteQueryResponse | undefined;
  queryKey: readonly [string, string, ResourceType];
}

export function useResourceActions() {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const { accessToken } = useAuth();

  const deleteResourceMutation = useMutation<
    DeleteResourceParams,
    Error,
    DeleteResourceParams,
    MutationContext
  >({
    mutationFn: async ({
      resourceId,
      resourceType,
      vaultId,
    }: DeleteResourceParams): Promise<DeleteResourceParams> => {
      if (!accessToken) {
        throw new Error('No access token available');
      }

      const endpoint: string =
        resourceType === ResourceType.DOCUMENTATION
          ? `${import.meta.env.VITE_SERVER_API_URL}/documents/delete`
          : `${import.meta.env.VITE_SERVER_API_URL}/workflows/${resourceId}`;

      const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        ...(resourceType === ResourceType.DOCUMENTATION && {
          body: JSON.stringify({ documentIdList: [resourceId] }),
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to delete ${resourceType.toLowerCase()}`);
      }

      return { resourceId, resourceType, vaultId };
    },

    onMutate: async ({ resourceId, resourceType, vaultId }) => {
      // Create the exact query key
      const queryKey = ['vaultResources', vaultId, resourceType] as const;

      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey,
        exact: true,
      });

      // Snapshot current data
      const previousData =
        queryClient.getQueryData<InfiniteQueryResponse>(queryKey);

      // Function to filter out the deleted resource
      const filterResource = (item: ResourceItem): boolean => {
        if (resourceType === ResourceType.DOCUMENTATION) {
          return (
            (item.resource as DocumentationResource).documentationId !==
            resourceId
          );
        }
        return (item.resource as WorkflowResource).id !== resourceId;
      };

      // Immediately update all matching queries in the cache
      queryClient.setQueriesData<InfiniteQueryResponse>(
        { queryKey: ['vaultResources'], exact: false },
        (old) => {
          if (!old) return old;

          // Update each page
          const updatedPages = old.pages.map((page) => ({
            ...page,
            items: page.items.filter(filterResource),
          }));

          // Remove any empty pages
          const nonEmptyPages = updatedPages.filter(
            (page) => page.items.length > 0
          );

          return {
            ...old,
            pages:
              nonEmptyPages.length > 0
                ? nonEmptyPages
                : [{ items: [], nextPage: 1, hasMore: false }],
            pageParams: nonEmptyPages.length > 0 ? old.pageParams : [1],
          };
        }
      );

      // Force a cache update for the specific resource type
      queryClient.setQueryData<InfiniteQueryResponse>(queryKey, (old) => {
        if (!old)
          return {
            pages: [{ items: [], nextPage: 1, hasMore: false }],
            pageParams: [1],
          };

        const updatedPages = old.pages.map((page) => ({
          ...page,
          items: page.items.filter(filterResource),
        }));

        const nonEmptyPages = updatedPages.filter(
          (page) => page.items.length > 0
        );

        return {
          ...old,
          pages:
            nonEmptyPages.length > 0
              ? nonEmptyPages
              : [{ items: [], nextPage: 1, hasMore: false }],
          pageParams: nonEmptyPages.length > 0 ? old.pageParams : [1],
        };
      });

      return { previousData, queryKey };
    },

    onError: (error, _variables, context) => {
      if (context?.previousData && context?.queryKey) {
        // Restore the previous data
        queryClient.setQueryData(context.queryKey, context.previousData);

        // Also restore any other affected queries
        queryClient.setQueriesData(
          { queryKey: ['vaultResources'], exact: false },
          context.previousData
        );
      }

      toast({
        title: 'Error',
        description: error.message || 'Failed to delete resource',
        variant: 'destructive',
      });
    },

    onSuccess: (_, { resourceType }) => {
      toast({
        title: 'Success',
        description: `${
          resourceType === ResourceType.DOCUMENTATION
            ? 'Document'
            : 'Process Map'
        } deleted successfully`,
      });
    },

    onSettled: (_, __, { vaultId, resourceType }) => {
      // Remove the delay for invalidation
      queryClient.invalidateQueries({
        queryKey: ['vaultResources', vaultId, resourceType],
        exact: true,
      });
    },
  });

  return {
    deleteResource: (params: DeleteResourceParams) => {
      // Immediately update the UI
      const queryKey = [
        'vaultResources',
        params.vaultId,
        params.resourceType,
      ] as const;
      const currentData =
        queryClient.getQueryData<InfiniteQueryResponse>(queryKey);

      if (currentData) {
        queryClient.setQueryData<InfiniteQueryResponse>(queryKey, (old) => {
          if (!old) return old;
          return {
            ...old,
            pages: old.pages.map((page) => ({
              ...page,
              items: page.items.filter((item) => {
                if (params.resourceType === ResourceType.DOCUMENTATION) {
                  return (
                    (item.resource as DocumentationResource).documentationId !==
                    params.resourceId
                  );
                }
                return (
                  (item.resource as WorkflowResource).id !== params.resourceId
                );
              }),
            })),
          };
        });
      }

      // Then trigger the mutation
      return deleteResourceMutation.mutate(params);
    },
    isDeleting: deleteResourceMutation.isPending,
  };
}
