/**
 * Permission Gate System
 *
 * This module provides a flexible permission-based access control system for React applications.
 *
 * Key Components:
 * 1. PermissionGateProvider: Context provider that wraps the application or a part of it.
 *    - Accepts provider-level permissions.
 *    - If provider-level permissions are not met, nothing is rendered.
 *
 * 2. usePermissionGate: Hook for checking permissions within functional components.
 *    - Returns a `hasPermissions` function to check for specific permissions.
 *
 * 3. withPermissionGate: Higher-Order Component (HOC) for wrapping class components.
 *    - Conditionally renders the wrapped component based on specified permissions.
 *
 * Usage:
 * - Wrap your app or a section with <PermissionGateProvider permissions={['base_permission']}>
 * - Use `const { hasPermissions } = usePermissionGate()` in functional components
 * - Wrap class components with `withPermissionGate(Component, ['required_permission'])`
 *
 * This system integrates with WorkOS for actual permission data. Ensure the WorkOS context
 * is properly set up and provides the necessary user permissions.
 *
 * @example
 * <PermissionGateProvider permissions={['user']}>
 *   <App />
 * </PermissionGateProvider>
 *
 * @example
 * const { hasPermissions } = usePermissionGate();
 * if (hasPermissions(['admin'])) {
 *   // Render admin content
 * }
 *
 * @example
 * export default withPermissionGate(AdminComponent, ['admin']);
 */

import React, { createContext, useContext, ReactNode } from 'react';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import UnauthorizedMessage from '@fluency/ui/features/Unauthorized/UnauthorizedMessage';

export type PermissionGateContextType = {
  hasPermissions: (requiredPermissions: string[]) => boolean;
};

const PermissionGateContext = createContext<
  PermissionGateContextType | undefined
>(undefined);

type PermissionGateProviderProps = {
  children: ReactNode;
  permissions?: string[]; // Provider-level permissions
};

export const PermissionGateProvider: React.FC<PermissionGateProviderProps> = ({
  children,
  permissions = [],
}) => {
  const workOSContext = useAuth();

  const hasPermissions = (requiredPermissions: string[]): boolean => {
    const userPermissions = workOSContext.permissions;
    const allRequiredPermissions = [...permissions, ...requiredPermissions];
    return allRequiredPermissions.every((perm) =>
      userPermissions.includes(perm)
    );
  };

  // If provider-level permissions are not met, render nothing (or a fallback component)
  if (!hasPermissions([])) {
    return <UnauthorizedMessage />;
  }

  return (
    <PermissionGateContext.Provider value={{ hasPermissions }}>
      {children}
    </PermissionGateContext.Provider>
  );
};

export const usePermissionGate = () => {
  const context = useContext(PermissionGateContext);
  if (context === undefined) {
    throw new Error(
      'usePermissionGate must be used within a PermissionGateProvider'
    );
  }
  return context;
};

export function withPermissionGate<P extends object>(
  WrappedComponent: React.ComponentType<P>,
  requiredPermissions: string[]
) {
  return (props: P) => {
    const { hasPermissions } = usePermissionGate();

    if (hasPermissions(requiredPermissions)) {
      return <WrappedComponent {...props} />;
    } else {
      return <UnauthorizedMessage />;
    }
  };
}
