import { useState } from 'react';
import { ChevronsUpDown, Building2, RefreshCw } from 'lucide-react';
import { Avatar, AvatarFallback } from '@fluency/ui/components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@fluency/ui/components/ui/dropdown-menu';
import { SidebarMenuItem } from '@fluency/ui/components/ui/sidebar';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { matchQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import React from 'react';

interface Organisation {
  id: string;
  name: string;
  role: string;
}

interface OrganisationSwitcherProps {
  organisations: Organisation[];
  selectedOrganisationName: string;
  selectedOrganisationRole: string;
  isLoading: boolean;
  isError: boolean;
}

interface OrganisationItemProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  name: string;
  role: string;
  variant: 'activeItem' | 'default' | 'selected' | 'single';
}

const OrganisationItem = React.forwardRef<
  HTMLButtonElement,
  OrganisationItemProps
>(({ name, role, variant, ...props }, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      className="flex w-full items-center gap-2 rounded-lg py-2 mb-1 transition-colors text-gray-600"
    >
      <Avatar className="h-8 w-8 rounded-lg">
        <AvatarFallback className="rounded-lg bg-gray-200">
          <Building2 className="w-5" />
        </AvatarFallback>
      </Avatar>
      <div className="grid flex-1 text-left text-sm leading-tight">
        <span className="truncate font-medium">{name}</span>
        <span className="truncate text-xs text-muted-foreground capitalize">
          {role}
        </span>
      </div>
      {variant === 'activeItem' && (
        <ChevronsUpDown size={16} className="ml-auto" />
      )}
    </button>
  );
});

const OrganisationSwitcher = ({
  organisations,
  selectedOrganisationName,
  selectedOrganisationRole,
  isLoading,
  isError,
}: OrganisationSwitcherProps) => {
  const { refreshTokenWithOrgId } = useAuth();
  const queryClient = useQueryClient();
  const [organisationName, setOrganisationName] = useState(
    selectedOrganisationName
  );
  const [organisationRole, setOrganisationRole] = useState(
    selectedOrganisationRole
  );
  const navigate = useNavigate();

  const onlyOneOrganisation =
    organisations.length === 1 && !isLoading && !isError;

  return (
    <SidebarMenuItem>
      <DropdownMenu>
        <DropdownMenuTrigger asChild disabled={onlyOneOrganisation}>
          <OrganisationItem
            name={organisationName}
            role={organisationRole}
            variant={onlyOneOrganisation ? 'single' : 'activeItem'}
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="w-[16rem] rounded-lg"
          align="start"
          side={'right'}
          sideOffset={4}
        >
          <DropdownMenuLabel className="sticky text-sm text-muted-foreground">
            Organisations
          </DropdownMenuLabel>
          <div className="overflow-y-auto max-h-48">
            {/* When organisations is loaded correctly */}
            {!isLoading &&
              !isError &&
              !onlyOneOrganisation &&
              organisations.map((organisation) => (
                <DropdownMenuItem
                  key={organisation.name}
                  onClick={async () => {
                    setOrganisationName(organisation.name);
                    setOrganisationRole(organisation.role);
                    await refreshTokenWithOrgId(organisation.id);

                    // Clear the cached queries as the org has changed and the data should change except for the organisation query data
                    queryClient.removeQueries({
                      predicate: (query) =>
                        !matchQuery({ queryKey: ['userOrganisations'] }, query),
                    });

                    // Delay is needed as there is a race condition conflict between the cache clearing and navigation
                    // Which causes the stale data to be shown even after the org switches
                    await new Promise((resolve) => setTimeout(resolve, 100));

                    // Navigate to the home page, to prevent the user from being on a page they don't have access to
                    navigate({ to: '/home', replace: true });
                  }}
                  className="gap-2 p-2"
                  data-s
                >
                  <OrganisationItem
                    name={organisation.name}
                    role={organisation.role}
                    variant={
                      organisation.name === organisationName
                        ? 'selected'
                        : 'default'
                    }
                  />
                </DropdownMenuItem>
              ))}

            {/* When organisations is loading */}
            {isLoading && (
              <DropdownMenuItem className="flex gap-2 py-4">
                <RefreshCw className="h-4 w-4 animate-spin" />
                <span>Loading organisations...</span>
              </DropdownMenuItem>
            )}

            {/* When organisations is error */}
            {isError && (
              <DropdownMenuItem className="flex gap-2 py-4">
                <span className="text-red-500">
                  Failed to load organisations
                </span>
              </DropdownMenuItem>
            )}
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </SidebarMenuItem>
  );
};

export default OrganisationSwitcher;
