import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Logger } from '@fluency/ui/features/Logger';

// Define types
export type SearchResult = {
  docId: string;
  docName: string;
  docDescription: string;
  preview: {
    resource: string;
    matchField: string;
    match: string;
  };
};

type SearchResponse = {
  query: string;
  searchResults: SearchResult[];
};

export const useSearch = () => {
  const [query, setQuery] = useState('');
  const { accessToken } = useAuth();

  const { data, isLoading, error, refetch } = useQuery<SearchResponse, Error>({
    queryKey: ['search', query],
    queryFn: async (): Promise<SearchResponse> => {
      if (!query) return { query: '', searchResults: [] };

      const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
      const response = await fetch(`${apiBaseUrl}/search/searchAll`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ query }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const results = await response.json();
      Logger.log(results);
      return results;
    },
    enabled: false, // This ensures the query doesn't run automatically
  });

  const handleSearch = () => {
    if (query) {
      refetch();
    }
  };

  return {
    query,
    setQuery,
    results: data?.searchResults || [],
    isLoading,
    error,
    handleSearch,
  };
};

export default useSearch;
