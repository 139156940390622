import { Handle, Position, useStore } from 'reactflow';

interface HandleConfig {
  id: string;
  isHovered?: boolean;
  isViewMode?: boolean;
  hideSourceHandles?: Position[];
  hideTargetHandles?: Position[];
  customClassName?: string;
}

const handleConfigs = [
  {
    position: Position.Top,
    style: {
      transform: 'translate(-50%, -50%)',
      top: '2px',
      left: '50%',
    },
  },
  {
    position: Position.Right,
    style: {
      transform: 'translate(50%, -50%)',
      top: '50%',
      right: '2px',
    },
  },
  {
    position: Position.Bottom,
    style: {
      transform: 'translate(-50%, 50%)',
      bottom: '2px',
      left: '50%',
    },
  },
  {
    position: Position.Left,
    style: {
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '2px',
    },
  },
];

const hoverBgColor = 'bg-fluency-500';
const defaultBgColor = 'bg-transparent';

// Updated selector to use connectionStartHandle
const selector = (state: any) => state.connectionStartHandle;

export const createNodeHandles = ({
  id,
  isViewMode = false,
  isHovered = false,
  hideSourceHandles = [],
  hideTargetHandles = [],
  customClassName = '',
}: HandleConfig) => {
  const connectionStartHandle = useStore(selector);

  const getHandleId = (type: 'source' | 'target', position: Position) => {
    return `${type}-${position.toLowerCase()}`;
  };

  let shownHandle = 'source';

  if (connectionStartHandle) {
    shownHandle = connectionStartHandle?.nodeId !== id ? 'target' : 'source';
  }

  const isConnectionStart = connectionStartHandle !== null;

  return (
    <>
      {/* Source Handles */}
      {handleConfigs.map(({ position, style }) => {
        if (hideSourceHandles.includes(position)) return null;
        return (
          <Handle
            id={getHandleId('source', position)}
            key={getHandleId('source', position)}
            type="source"
            position={position}
            isConnectable={!isViewMode}
            className={`
                w-4 h-4 border-0 absolute transition-colors duration-300
                ${
                  (isHovered && !isViewMode && shownHandle === 'source') ||
                  (!isViewMode && isConnectionStart && shownHandle === 'source')
                    ? hoverBgColor
                    : defaultBgColor
                }
                ${customClassName}
              `}
            style={{
              ...style,
              zIndex: shownHandle === 'source' ? 1 : 0,
            }}
          />
        );
      })}

      {/* Target Handles */}
      {handleConfigs.map(({ position, style }) => {
        if (hideTargetHandles.includes(position)) return null;
        return (
          <Handle
            id={getHandleId('target', position)}
            key={getHandleId('target', position)}
            type="target"
            position={position}
            isConnectable={!isViewMode}
            className={`
                w-4 h-4 border-0 absolute transition-colors duration-300
                ${
                  isConnectionStart && !isViewMode && shownHandle === 'target'
                    ? hoverBgColor
                    : defaultBgColor
                }
                ${customClassName}
              `}
            style={{
              ...style,
              zIndex: shownHandle === 'target' ? 1 : 0,
            }}
          />
        );
      })}
    </>
  );
};
