import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { ReactNode } from 'react';

interface HideIfViewerProps {
  children: ReactNode;
}

function HideIfViewer({ children }: HideIfViewerProps) {
  const { user } = useAuth();

  if (user?.licenseType === 'viewer') {
    return null;
  }

  return <>{children}</>;
}

export default HideIfViewer;
