import { Textarea } from '@fluency/ui/components/ui/textarea';
import { Fragment, useEffect, useRef } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import { Step } from '@fluency/ui/hooks/documentation/types/master';

interface EditStepDescriptionInput {
  stepId: string;
  stepDescription: string;
  isStepTypeStep: boolean;
  handleEditStepDescription: (params: {
    stepId: string | null;
    description: string;
  }) => void;
  handleUpdateStepDescription: (params: {
    stepId: string;
    description: string;
  }) => void;
  editStepDescription: string;
  editingId: string | null;
  filteredSteps: Step[];
  canEdit: boolean;
  isEditMode: boolean;
}

const EditStepDescriptionInput = ({
  stepId,
  stepDescription,
  isStepTypeStep,
  handleEditStepDescription,
  handleUpdateStepDescription,
  editStepDescription,
  editingId,
  filteredSteps,
  canEdit,
  isEditMode,
}: EditStepDescriptionInput) => {
  const editContainerRef = useRef<HTMLDivElement>(null);

  const handleSave = () => {
    const descriptionToSave =
      editStepDescription.trim() || 'Enter description...';
    handleUpdateStepDescription({
      stepId: editingId!,
      description: descriptionToSave,
    });

    const updatedStepIndex = filteredSteps.findIndex(
      (e) => e.stepId === editingId
    );

    if (filteredSteps[updatedStepIndex]) {
      filteredSteps[updatedStepIndex].stepDescription = descriptionToSave;
    }

    handleEditStepDescription({
      stepId: null,
      description: '',
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSave();
    }
  };

  const handleStartEdit = () => {
    if (canEdit) {
      const initialDescription =
        stepDescription === 'Enter description...' ? '' : stepDescription;
      handleEditStepDescription({
        stepId: stepId,
        description: initialDescription,
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        editingId === stepId &&
        editContainerRef.current &&
        !editContainerRef.current.contains(event.target as Node)
      ) {
        handleSave();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editingId, stepId, editStepDescription]);

  return (
    <Fragment>
      {editingId === stepId && canEdit && isEditMode ? (
        <div ref={editContainerRef}>
          <Textarea
            value={editStepDescription}
            onChange={(e) => {
              handleEditStepDescription({
                stepId: editingId,
                description: e.target.value,
              });
            }}
            onKeyDown={handleKeyDown}
            className="resize-y max-h-64 min-h-20"
            placeholder="Enter step description..."
            autoFocus
          />
          <div className="mt-2 flex justify-end space-x-2">
            <Button
              onClick={() =>
                handleEditStepDescription({
                  stepId: null,
                  description: '',
                })
              }
              size="sm"
              variant="ghost"
            >
              Cancel
            </Button>
            <Button onClick={handleSave} size="sm" variant="secondary">
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div
          className={`text-sm ${!isStepTypeStep ? 'dark:text-gray-600' : ''} ${
            canEdit && isEditMode ? 'cursor-pointer hover:bg-gray-100' : ''
          } p-1 -m-1 rounded whitespace-pre-wrap mt-3`}
          onClick={handleStartEdit}
        >
          {stepDescription ||
            (canEdit ? 'Click to add description' : 'No description')}
        </div>
      )}
    </Fragment>
  );
};

export default EditStepDescriptionInput;
