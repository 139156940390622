import { RefreshCw } from 'lucide-react';
import { useToggleLocking } from '../hooks/usePermissionToggles';
import { DocumentationResponse } from '../../../hooks/documentation/types/master';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@fluency/ui/components/ui/alert-dialog';

interface LockToggleDialogProps {
  data: DocumentationResponse;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

const LockToggleDialog = ({
  data,
  isOpen,
  onOpenChange,
}: LockToggleDialogProps) => {
  const toggleLocking = useToggleLocking();

  const onToggleLock = () => {
    toggleLocking.mutate({
      documentationId: data.documentation.documentationId,
      value: !data.documentation.isLocked,
    });
    onOpenChange(false);
  };

  const isLocked = data.documentation.isLocked;

  return (
    <AlertDialog open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {isLocked ? 'Unlock Document?' : 'Lock Document?'}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {isLocked
              ? 'Are you sure? This will allow anyone with edit permissions to make changes to this document.'
              : 'Locking a document will lock for changes, and changes can only be made if unlocked by someone with sufficient permission to do so.'}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={onToggleLock}
            disabled={toggleLocking.isPending}
          >
            {toggleLocking.isPending ? (
              <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
            ) : null}
            {isLocked ? 'Unlock' : 'Lock'}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default LockToggleDialog;
