import { DashboardShell } from '@fluency/ui/components';
import ManageVaultsParent from '@fluency/ui/features/Vaults/ManageVaults/components/ManageVaultsParent';

const ManageVaults = () => {
  return (
    <DashboardShell>
      <ManageVaultsParent />
    </DashboardShell>
  );
};

export default ManageVaults;
