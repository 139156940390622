import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@fluency/ui/components/ui/alert-dialog';
import { Button } from '@fluency/ui/components/ui/button';
import useSubscription from '@fluency/ui/hooks/subscription/useSubscription';

export function RemoveCard({ paymentMethodId }: { paymentMethodId: string }) {
  const { removePaymentMethod, isRemovingPaymentMethod, isLoadingBilling } =
    useSubscription();

  const handleRemovePaymentMethod = () => {
    removePaymentMethod(paymentMethodId);
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          variant="secondary"
          className="h-8"
          disabled={isRemovingPaymentMethod || isLoadingBilling}
        >
          {isRemovingPaymentMethod || isLoadingBilling
            ? 'Removing...'
            : 'Remove'}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete your
            account and remove your data from our servers.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="bg-red-500 hover:bg-red-600"
            onClick={() => handleRemovePaymentMethod()}
          >
            Remove Payment Method
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
