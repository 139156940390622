import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { useAuth } from '../../../providers/auth/AuthProvider';
import { FreeTrialResponse } from '../types/FreeTrialResponse';

export const useFreeTrial = () => {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const { accessToken: defaultAccessToken, setSubscription } = useAuth();
  const queryClient = useQueryClient();

  const startFreeTrialMutation = useMutation<
    FreeTrialResponse,
    Error,
    string | undefined
  >({
    mutationFn: async (providedAccessToken?: string) => {
      const accessToken = providedAccessToken || defaultAccessToken;
      if (!accessToken) {
        throw new Error('Access token is missing');
      }

      const endpoint = `${apiBaseUrl}/subscription/startFreeTrial`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          daysCount: 7,
          action: 'start',
          product: 'business',
          plan: 'monthly',
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    },
    onSuccess: () => {
      setSubscription('trialing');
      queryClient.invalidateQueries({ queryKey: ['subscriptionStatus'] });
    },
    onError: () => {
      Sentry.captureException('Failed to start free trial');
    },
  });

  return {
    startFreeTrial: startFreeTrialMutation.mutate,
    isStartingFreeTrial: startFreeTrialMutation.isPending,
    startFreeTrialError: startFreeTrialMutation.error,
  };
};

export default useFreeTrial;
