import { Camera, LoaderCircle, Replace } from 'lucide-react';
import { useRef, useState } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { Input } from '@fluency/ui/components/ui/input';
import { Label } from '@fluency/ui/components/ui/label';
import useReplaceScreenshot from '../hooks/useReplaceScreenshot.js';
import { convertToPng } from './editor/utils/convertToPng.js';

interface ScreenshotDialogProps {
  stepId: string;
  documentId: string;
  mode?: 'add' | 'replace';
}

const ScreenshotDialog: React.FC<ScreenshotDialogProps> = ({
  stepId,
  documentId,
  mode = 'replace',
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { mutate: replaceScreenshot, isPending } =
    useReplaceScreenshot(documentId);

  const isAddMode = mode === 'add';
  const title = isAddMode ? 'Add Screenshot' : 'Replace Screenshot';
  const tooltipText = isAddMode ? 'Add Screenshot' : 'Replace Screenshot';
  const Icon = isAddMode ? Camera : Replace;

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (file) {
      const pngFile = await convertToPng(file);
      replaceScreenshot(
        {
          file: pngFile,
          stepId,
          fileType: 'png',
        },
        {
          onSuccess: () => {
            setIsOpen(false);
            setFile(null);
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
          },
        }
      );
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile =
      event.target.files && event.target.files[0]
        ? event.target.files[0]
        : null;
    setFile(newFile);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <DialogTrigger asChild>
              <Button variant="ghost" size="sm" className="group">
                <Icon className="h-4 w-4" />
              </Button>
            </DialogTrigger>
          </TooltipTrigger>
          <TooltipContent>{tooltipText}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={onSubmit}>
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
            <DialogDescription>
              Select a screenshot to upload.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="screenshot">Screenshot</Label>
              <Input
                id="screenshot"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef}
                className="cursor-pointer"
              />
            </div>
          </div>
          <DialogFooter>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => setIsOpen(false)}
                    disabled={isPending}
                  >
                    Cancel
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Cancel screenshot upload</TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button type="submit" disabled={isPending || file === null}>
                    {isPending ? (
                      <LoaderCircle className="animate-spin w-4 h-4" />
                    ) : (
                      'Upload Screenshot'
                    )}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Upload new screenshot</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ScreenshotDialog;
