import React from 'react';
import { ImageDisplayProps } from '../types';

export const ImageDisplay: React.FC<ImageDisplayProps> = ({
  src,
  onClick,
  onLoad,
  onError,
  isEditMode = false,
  disabled = false,
}) => (
  <img
    src={src}
    alt="Screenshot"
    className={`block w-full h-auto object-contain ${
      isEditMode && !disabled ? 'cursor-pointer' : 'cursor-default'
    }`}
    onClick={disabled ? undefined : onClick}
    onLoad={onLoad}
    onError={onError}
  />
);
