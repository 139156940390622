import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { PaginatedResponse } from '../types/types';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { Logger } from '@fluency/ui/features/Logger';

export const useFetchResourceRequests = (documentId: string) => {
  const { accessToken, user } = useAuth();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  const fetchRequests = async ({
    pageParam = 1,
  }): Promise<PaginatedResponse> => {
    if (!accessToken || !documentId) {
      throw new Error('Missing required parameters');
    }

    try {
      // Create URL with all params at once
      const searchParams = new URLSearchParams({
        page: pageParam.toString(),
        pageSize: '10',
        resourceId: documentId,
        resourceType: 'DOCUMENTATION',
      });

      const url = `${apiBaseUrl}/resourceRequests?${searchParams.toString()}`;

      // Use AbortController for timeout
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 second timeout

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        signal: controller.signal,
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message || 'Failed to fetch resource requests'
        );
      }

      return await response.json();
    } catch (error) {
      Logger.error('Fetch error:', error);
      throw error;
    }
  };

  const queryResult = useInfiniteQuery({
    queryKey: ['resourceRequests', documentId],
    queryFn: fetchRequests,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const totalPages = Math.ceil(lastPage.total / lastPage.pageSize);
      return lastPage.page < totalPages ? lastPage.page + 1 : undefined;
    },
    enabled: Boolean(documentId && accessToken),
    staleTime: 30000, // Cache fresh data for 30 seconds
    gcTime: 5 * 60 * 1000, // Keep in garbage collection for 5 minutes
    retry: 1, // Only retry once
    retryDelay: 1000, // Wait 1 second between retries
    networkMode: 'always', // Always try to fetch, even if offline
    refetchOnWindowFocus: false, // Don't refetch on window focus
    refetchOnReconnect: false, // Don't refetch on reconnect
  });

  // Memoize computed values to prevent unnecessary recalculations
  const allRequests = useMemo(
    () => queryResult.data?.pages.flatMap((page) => page.requests) ?? [],
    [queryResult.data?.pages]
  );

  const userRequest = useMemo(
    () =>
      allRequests.find((request) =>
        request.assignments.some(
          (assignment) => assignment.recipientId === user?.id
        )
      ),
    [allRequests, user?.id]
  );

  const userAssignment = useMemo(
    () =>
      userRequest?.assignments.find(
        (assignment) => assignment.recipientId === user?.id
      ),
    [userRequest, user?.id]
  );

  return {
    userRequest,
    userAssignment,
    ...queryResult,
  };
};
