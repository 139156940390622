import { Skeleton } from '@fluency/ui/components/ui/skeleton';

export function NotificationSkeleton() {
  return (
    <div className="flex gap-4 p-4">
      <Skeleton className="h-8 w-8 rounded-full" />
      <div className="flex-1">
        <Skeleton className="h-4 w-3/4 mb-2" />
        <Skeleton className="h-3 w-1/2 mb-2" />
        <Skeleton className="h-6 w-40 rounded-md" />
      </div>
      <Skeleton className="h-2 w-2 rounded-full mt-2" />
    </div>
  );
}
