import UnauthorizedMessage from './UnauthorizedMessage';

const UnauthorizedPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen w-full bg-white">
      <UnauthorizedMessage />
    </div>
  );
};

export default UnauthorizedPage;
