import React from 'react';
import { EdgeProps, EdgeLabelRenderer, getSmoothStepPath } from 'reactflow';

interface ViewLabeledEdgeProps extends EdgeProps {
  data?: {
    label?: string;
    showAnimation?: boolean;
  };
}

const ViewLabeledEdge: React.FC<ViewLabeledEdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  style = {},
  markerEnd,
}) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const label = data?.label || '';

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {data?.showAnimation && (
        <circle r="5" fill="#1490e3">
          <animateMotion dur="2s" repeatCount="indefinite" path={edgePath} />
        </circle>
      )}
      {label && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 12,
              backgroundColor: 'white',
              padding: '4px',
              borderRadius: '4px',
              minWidth: '50px',
              textAlign: 'center',
              color: 'black',
            }}
          >
            {label}
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export default ViewLabeledEdge;
