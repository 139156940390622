import React from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import {
  AlertCircle,
  Check,
  Loader2,
  RefreshCw,
  ArrowUpRightFromSquare,
} from 'lucide-react';
import { ExtensionStatus, SyncStatus } from './types';

interface ActionButtonsProps {
  extensionStatus: ExtensionStatus;
  syncStatus: SyncStatus;
  handleDownload: () => void;
  sendAuthData: () => void;
}

export const DownloadButton: React.FC<
  Pick<ActionButtonsProps, 'extensionStatus' | 'handleDownload'>
> = ({ extensionStatus, handleDownload }) => (
  <Button
    size="sm"
    className="w-full sm:w-auto"
    variant="outline"
    disabled={extensionStatus !== 'not_installed'}
    onClick={handleDownload}
  >
    {extensionStatus !== 'not_installed' ? (
      <>
        <Check className="mr-2 h-4 w-4" />
        <span>Downloaded</span>
      </>
    ) : (
      <>
        <ArrowUpRightFromSquare className="mr-2 h-4 w-4" />
        <span>Chrome Store</span>
      </>
    )}
  </Button>
);

export const OpenExtensionButton: React.FC<
  Pick<ActionButtonsProps, 'extensionStatus'>
> = ({ extensionStatus }) => {
  switch (extensionStatus) {
    case 'not_installed':
      return (
        <Button
          size="sm"
          className="w-full sm:w-auto"
          variant="outline"
          disabled
        >
          <AlertCircle className="mr-2 h-4 w-4" />
          <span>Extension Not Found</span>
        </Button>
      );
    case 'installed_not_connected':
      return (
        <Button
          size="sm"
          className="w-full sm:w-auto"
          variant="outline"
          onClick={() => window.location.reload()}
        >
          <RefreshCw className="mr-2 h-4 w-4" />
          <span>Click here when complete</span>
        </Button>
      );
    case 'connected':
      return (
        <Button
          size="sm"
          className="w-full sm:w-auto"
          variant="outline"
          disabled
        >
          <Check className="mr-2 h-4 w-4" />
          <span>Installed</span>
        </Button>
      );
    case 'checking':
    default:
      return (
        <Button
          size="sm"
          className="w-full sm:w-auto"
          variant="outline"
          disabled
        >
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          <span>Checking...</span>
        </Button>
      );
  }
};

export const ConnectButton: React.FC<
  Pick<ActionButtonsProps, 'extensionStatus' | 'syncStatus' | 'sendAuthData'>
> = ({ extensionStatus, syncStatus, sendAuthData }) => (
  <Button
    size="sm"
    className="w-full sm:w-auto"
    variant="outline"
    onClick={sendAuthData}
    disabled={extensionStatus === 'not_installed' || syncStatus === 'syncing'}
  >
    {syncStatus === 'syncing' ? (
      <>
        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        Connecting...
      </>
    ) : syncStatus === 'success' ? (
      <>
        <Check className="mr-2 h-4 w-4" />
        Connected
      </>
    ) : syncStatus === 'failed' ? (
      <>
        <AlertCircle className="mr-2 h-4 w-4" />
        Retry Connection
      </>
    ) : (
      <>
        <RefreshCw className="mr-2 h-4 w-4" />
        Refresh connection
      </>
    )}
  </Button>
);
