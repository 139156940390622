import type { CropData, NormalizedCropData, PixelCropData } from '../types';

export function constrainCrop(
  crop: CropData,
  imageWidth: number,
  imageHeight: number
): CropData {
  const minSize = 20;

  let { x, y, width, height } = crop;

  // Ensure minimum size
  width = Math.max(minSize, width);
  height = Math.max(minSize, height);

  // Constrain position within image bounds
  x = Math.max(0, Math.min(x, imageWidth - width));
  y = Math.max(0, Math.min(y, imageHeight - height));

  // Constrain size to image bounds
  width = Math.min(width, imageWidth - x);
  height = Math.min(height, imageHeight - y);

  return { x, y, width, height };
}

export function applyCrop(cropData: CropData) {
  // Convert display coordinates to actual image coordinates
  const naturalCrop = {
    x: Math.round(cropData.x),
    y: Math.round(cropData.y),
    width: Math.round(cropData.width),
    height: Math.round(cropData.height),
  };

  return naturalCrop;
}

const roundToTwoDecimals = (num: number): number => {
  return Number(Math.round(num * 100) / 100);
};

export const normalizeCoordinates = (
  pixelCrop: PixelCropData,
  imageWidth: number,
  imageHeight: number
): NormalizedCropData => {
  return {
    x: roundToTwoDecimals((pixelCrop.x / imageWidth) * 100),
    y: roundToTwoDecimals((pixelCrop.y / imageHeight) * 100),
    width: roundToTwoDecimals((pixelCrop.width / imageWidth) * 100),
    height: roundToTwoDecimals((pixelCrop.height / imageHeight) * 100),
  };
};

export const denormalizeCoordinates = (
  normalizedCrop: NormalizedCropData,
  imageWidth: number,
  imageHeight: number
): PixelCropData => {
  return {
    x: Math.round((normalizedCrop.x * imageWidth) / 100),
    y: Math.round((normalizedCrop.y * imageHeight) / 100),
    width: Math.round((normalizedCrop.width * imageWidth) / 100),
    height: Math.round((normalizedCrop.height * imageHeight) / 100),
  };
};

export const validateNormalizedCrop = (crop: NormalizedCropData): boolean => {
  const isValidNumber = (num: number) => {
    return (
      !isNaN(num) && num >= 0 && num <= 100 && Number(num.toFixed(2)) === num
    );
  };

  return (
    isValidNumber(crop.x) &&
    isValidNumber(crop.y) &&
    isValidNumber(crop.width) &&
    isValidNumber(crop.height)
  );
};
