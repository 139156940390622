import { Lock } from 'lucide-react';
import { Badge } from '@fluency/ui/components/ui/badge';
import { Link } from '@tanstack/react-router';
import { VaultIcon } from '../../../components/DashboardShell/VaultIcon';
import useGetVaults from '@fluency/ui/features/Home/hooks/useGetVaults';

const EmptyVaultCard = () => (
  <div className="w-48 bg-gray-50 h-32 rounded relative border border-dashed">
    <div className="absolute inset-0 flex items-center justify-center flex-col gap-2">
      <VaultIcon className="w-16 h-16 text-gray-300" strokeWidth={1} />
    </div>
  </div>
);

export default function RecentVaults() {
  const { data: vaultsData, isLoading, error } = useGetVaults();

  const renderVaultCard = (vault: {
    id: string;
    name: string;
    isPublic: boolean;
    updatedAt: string;
  }) => (
    <div
      key={vault.id}
      className="w-48 bg-gray-100 h-32 rounded relative group hover:bg-gray-200 transition-colors border"
    >
      <Badge
        variant="outline"
        className="absolute top-2 left-2 z-10 bg-white flex gap-1 items-center"
      >
        {!vault.isPublic && <Lock className="w-3 h-3" />}
        {vault.isPublic ? 'Public' : 'Private'}
      </Badge>
      <div className="absolute inset-0 flex items-center justify-center">
        <VaultIcon
          className="w-20 h-20 text-gray-400 ml-20 mb-6"
          strokeWidth={1}
        />
      </div>
      <div className="absolute bottom-0 left-0 right-0 p-2 bg-white backdrop-blur-sm">
        <p className="font-medium text-sm truncate">{vault.name}</p>
        <p className="text-xs text-gray-500">
          {new Date(vault.updatedAt).toLocaleDateString()}
        </p>
      </div>
      <Link
        to="/vault/$id"
        params={{ id: vault.id }}
        className="absolute inset-0 z-20"
      >
        <span className="sr-only">View vault</span>
      </Link>
    </div>
  );

  const renderLoadingState = () => (
    <>
      {[1, 2, 3].map((i) => (
        <div key={i} className="w-48 bg-gray-100 h-32 rounded relative border">
          <div className="absolute top-2 left-2 h-6 w-20 bg-gray-200 animate-pulse rounded" />
          <div className="w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/4 -translate-y-1/2 bg-gray-200 animate-pulse rounded" />
          <div className="absolute bottom-0 left-0 right-0 p-2 bg-white">
            <div className="h-4 w-32 mb-1 bg-gray-200 animate-pulse rounded" />
            <div className="h-3 w-24 bg-gray-200 animate-pulse rounded" />
          </div>
        </div>
      ))}
    </>
  );

  if (error) {
    return (
      <div className="flex flex-col p-4 rounded text-red-500">
        Failed to load vaults: {error.message}
      </div>
    );
  }

  const renderVaultCards = () => {
    if (!vaultsData?.vaults) return null;

    const sortedVaults = vaultsData.vaults
      .slice(0, 3)
      .sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );

    // Create an array of 3 elements, filling with null for empty slots
    const vaultSlots = Array(3)
      .fill(null)
      .map((_, index) => sortedVaults[index] || null);

    return vaultSlots.map((vault, index) =>
      vault ? renderVaultCard(vault) : <EmptyVaultCard key={`empty-${index}`} />
    );
  };

  return (
    <div className="flex flex-col p-4 rounded font-medium text-gray-500 gap-2">
      <div className="w-full flex justify-between items-end">
        <span>Recent vaults</span>

        <span className="sr-only">View vault</span>
        <Link
          to="/vault/$id"
          params={{ id: 'manage' }}
          className="font-normal text-xs"
        >
          View all
        </Link>
      </div>
      <div className="flex gap-4">
        {isLoading ? renderLoadingState() : renderVaultCards()}
      </div>
    </div>
  );
}
