import logo from '@fluency/ui/assets/icon-blue.svg';
import { Badge } from '@fluency/ui/components/ui/badge';
import { Button } from '@fluency/ui/components/ui/button';
import { Separator } from '@fluency/ui/components/ui/separator';
import { Tabs, TabsList, TabsTrigger } from '@fluency/ui/components/ui/tabs';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useNavigate } from '@tanstack/react-router';
import { ArrowLeft, ArrowRight, Check, Mail, RefreshCw } from 'lucide-react';
import React, { useMemo, useState } from 'react';
import { PricingCard } from './components/PricingCard';
import { SkeletonCard } from './components/SkeletonCard';
import { useCreateOrganisation } from './hooks/useCreateOrganisation';
import { OrgFormSubmittedData } from '../OrgForm';
import { useFreeTrial } from './hooks/useFreeTrial';
import { toast } from '@fluency/ui/components/ui/use-toast';
import useSubscription from '@fluency/ui/hooks/subscription/useSubscription';
import {
  Product,
  useProducts,
} from '@fluency/ui/hooks/subscription/useProducts';
import { Logger } from '@fluency/ui/features/Logger';

const planConfigs: Record<
  string,
  {
    badge: boolean;
    badgeText?: string;
    hasSeats: boolean;
    buttonIcon: JSX.Element;
    alternativeText: string;
  }
> = {
  'Pro Plan': {
    badge: true,
    badgeText: '7 days free, no card required',
    hasSeats: true,
    buttonIcon: <Check className="w-4 h-4 ml-1.5" />,
    alternativeText: 'Purchase now',
  },
  'Business Plan': {
    badge: true,
    badgeText: '7 days free, no card required',
    hasSeats: true,
    buttonIcon: <Check className="w-4 h-4 ml-1.5" />,
    alternativeText: 'Purchase now',
  },
  'Enterprise Plan': {
    badge: false,
    hasSeats: false,
    buttonIcon: <Mail className="w-4 h-4 ml-1.5" />,
    alternativeText: 'Email us',
  },
};

const defaultConfig = {
  badge: false,
  badgeText: undefined,
  hasSeats: false,
  buttonIcon: null,
  alternativeText: '',
};

export const Subscription = () => {
  const navigate = useNavigate();
  const {
    orgFormData,
    setHasCompletedOrgForm,
    logout,
    refreshTokenWithOrgId,
    orgId,
    accessToken,
    updateSubscriptionStatus,
    clearTemporaryPermissions,
  } = useAuth();
  const { subscriptionStatus, createCustomerPortalSession } = useSubscription();
  const { startFreeTrial, isStartingFreeTrial } = useFreeTrial();
  const { products, isLoading: loadingProducts } = useProducts();
  const createOrganisationMutation = useCreateOrganisation();
  const [isGettingCheckoutUrl, _setIsGettingCheckoutUrl] = useState(false);
  const [proSeats, setProSeats] = useState(1);
  const [businessSeats, setBusinessSeats] = useState(1);
  const [isAnnual, setIsAnnual] = useState(true);

  const [isCreatingFreeTrialLoading, setIsCreateFreeTrialLoading] =
    useState(false);

  Logger.log(
    'Subscription Status Free Trial: ',
    subscriptionStatus?.hasActivatedTrial
  );

  const planOrder = ['Pro Plan', 'Business Plan', 'Enterprise Plan'];

  const filteredProducts = useMemo(() => {
    if (!products) return [];
    return planOrder
      .map((planName) =>
        products.find((product: Product) => product.name === planName)
      )
      .filter(
        (product): product is NonNullable<typeof product> =>
          product !== undefined
      );
  }, [products]);

  const getButtonText = (productName: string) => {
    switch (productName) {
      case 'Pro Plan':
      case 'Business Plan':
        return !subscriptionStatus?.hasActivatedTrial
          ? 'Start Free Trial'
          : 'Get Started';
      case 'Enterprise Plan':
        return 'Book a meeting with Sales';
      default:
        return undefined;
    }
  };

  const handleStartFreeTrial = async (
    productName: string,
    seats: number,
    _productId: string,
    isFreeTrial: boolean
  ) => {
    setIsCreateFreeTrialLoading(true);

    try {
      if (isFreeTrial) {
        let currentAccessToken = accessToken;

        if (!currentAccessToken) {
          throw new Error(
            'Access token is null. User may not be properly authenticated.'
          );
        }

        let orgCreated = false;
        if (!orgId) {
          Logger.log('[Subscription] Creating organization');
          const orgResult = await createOrganisationMutation.mutateAsync(
            orgFormData as OrgFormSubmittedData
          );

          if (orgResult && orgResult.data && orgResult.data.tenantId) {
            Logger.log('[Subscription] Organization created successfully');
            Logger.log(
              '[Subscription] Refreshing access token with new org ID'
            );
            const freshTokens = await refreshTokenWithOrgId(
              orgResult.data.tenantId
            );
            Logger.log('[Subscription] Access token refreshed successfully');
            currentAccessToken = freshTokens.accessToken;

            if (!currentAccessToken) {
              throw new Error(
                'Failed to obtain a valid access token after refreshing.'
              );
            }
            orgCreated = true;
          } else {
            throw new Error(
              'Invalid response structure from createOrganisation'
            );
          }
        }

        startFreeTrial(currentAccessToken, {
          onSuccess: async () => {
            Logger.log('[Subscription] Free trial started successfully');
            setHasCompletedOrgForm(true);
            await updateSubscriptionStatus(currentAccessToken);

            clearTemporaryPermissions();

            navigate({ to: '/home', replace: true });

            toast({
              variant: 'default',
              title: 'Success',
              description: orgCreated
                ? 'Organisation created and free trial started successfully.'
                : 'Free trial started successfully.',
            });
          },
          onError: (error) => {
            Logger.error('[Subscription] Failed to start free trial:', error);
            toast({
              variant: 'destructive',
              title: 'Error',
              description: 'Failed to start free trial. Please try again.',
            });
          },
        });
      } else {
        // Handle non-free trial subscriptions
        await createCustomerPortalSession(
          { seats, productName, isAnnual },
          {
            onSuccess: (data: { sessionUrl: string }) => {
              window.open(data.sessionUrl, '_blank');
              toast({
                variant: 'default',
                title: 'Success',
                description: 'Subscription process initiated successfully.',
              });
            },
            onError: (error: Error) => {
              Logger.error('Failed to create customer portal session:', error);
              toast({
                variant: 'destructive',
                title: 'Error',
                description: 'Failed to create subscription. Please try again.',
              });
            },
          }
        );
      }
    } catch (error) {
      Logger.error('[Subscription] Error in handleStartFreeTrial:', error);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'An error occurred. Please try again.',
      });
    } finally {
      setIsCreateFreeTrialLoading(false);
    }
  };

  const handleAlternativeAction = async (
    productName: string,
    _productId: string,
    seats?: number
  ) => {
    setIsCreateFreeTrialLoading(true);

    try {
      if (productName === 'Enterprise Plan') {
        window.location.href = 'mailto:sales@usefluency.com';
      } else {
        Logger.log(`buy now ${productName} ${seats ?? 1}`);
        await createCustomerPortalSession(
          {
            seats: seats ?? 1,
            productName,
            isAnnual,
          },
          {
            onSuccess: (data: { sessionUrl: string }) => {
              window.open(data.sessionUrl, '_blank');
              toast({
                variant: 'default',
                title: 'Success',
                description: 'Subscription process initiated successfully.',
              });
            },
            onError: (error: Error) => {
              Logger.error('Failed to create customer portal session:', error);
              toast({
                variant: 'destructive',
                title: 'Error',
                description: 'Failed to create subscription. Please try again.',
              });
            },
          }
        );
      }
    } catch (error) {
      Logger.error('[Subscription] Error in handleAlternativeAction:', error);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'An error occurred. Please try again.',
      });
    } finally {
      setIsCreateFreeTrialLoading(false);
    }
  };

  const getPrice = (product: Product) => {
    const price = product.prices.find(
      (p) => p.recurring?.interval === (isAnnual ? 'year' : 'month')
    );
    return price ? price.unitAmount / 100 : null;
  };

  if (subscriptionStatus?.status === 'active') {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen w-full bg-slate-50">
        <div className="w-[480px] bg-white rounded-lg p-8">
          <img
            src={logo}
            className="h-14 w-14 mb-8 mx-auto"
            alt="Fluency Logo"
          />

          <h1 className="text-2xl font-normal text-slate-900 mb-2 text-center">
            Your subscription has been activated! 🎉
          </h1>

          <button
            onClick={() => navigate({ to: '/home' })}
            className="w-full flex items-center justify-center bg-fluency-600 hover:bg-fluency-700 text-white font-normal text-[15px] px-4 py-2.5 rounded-[6px] transition-colors mt-8"
          >
            Continue <ArrowRight className="ml-1.5 h-4 w-4" />
          </button>
        </div>

        <button
          onClick={async () => {
            await logout();
            navigate({ to: '/home', replace: true });
          }}
          className="mt-6 flex items-center text-[15px] text-slate-600 hover:text-slate-900 transition-colors"
        >
          <ArrowLeft className="w-4 h-4 mr-1.5" /> Sign in with a different
          account
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen w-full overflow-x-auto bg-slate-100 py-8">
      <img src={logo} className="h-14 w-14" alt="Fluency Logo" />
      {!isGettingCheckoutUrl && (
        <>
          <span className="font-semibold pb-4">
            {orgFormData
              ? `Select a free trial for ${orgFormData?.organisationName} to continue`
              : `Select a free trial to continue`}
          </span>
          <Separator className="w-96 mb-4" />
        </>
      )}
      <div className="flex items-center justify-center mb-2">
        <Tabs
          value={isAnnual ? 'annual' : 'monthly'}
          className="w-full"
          onValueChange={(value) => setIsAnnual(value === 'annual')}
        >
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="monthly">Monthly</TabsTrigger>
            <TabsTrigger value="annual" className="relative">
              Annual
              {isAnnual && (
                <Badge className="absolute -top-3 -right-12 text-xs whitespace-nowrap">
                  20% off
                </Badge>
              )}
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {isGettingCheckoutUrl && (
          <div className="flex justify-center items-center h-24">
            <RefreshCw className="h-5 w-5 mr-2 animate-spin text-gray-600" />
            Opening in browser
          </div>
        )}

        <div className="flex justify-center w gap-8">
          {loadingProducts ? (
            <>
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
            </>
          ) : (
            <>
              {filteredProducts.map((product) => {
                const price = getPrice(product);
                const features = product.features
                  ? product.features.map(
                      (feature: { name: string }) => feature.name
                    )
                  : [];
                const config = planConfigs[product.name] || defaultConfig;

                let seats: number | undefined,
                  setSeats:
                    | React.Dispatch<React.SetStateAction<number>>
                    | undefined;
                if (product.name === 'Pro Plan') {
                  seats = proSeats;
                  setSeats = setProSeats;
                } else if (product.name === 'Business Plan') {
                  seats = businessSeats;
                  setSeats = setBusinessSeats;
                }

                return (
                  <PricingCard
                    key={product.id}
                    title={product.name}
                    price={
                      product.name === 'Enterprise Plan'
                        ? 'Custom pricing'
                        : price
                        ? `$${
                            isAnnual
                              ? (price / 12).toFixed(2)
                              : price.toFixed(0)
                          }/month`
                        : 'N/A'
                    }
                    pricePerSeat={price ?? 0}
                    features={features}
                    badge={config.badge}
                    badgeText={config.badgeText}
                    seats={config.hasSeats ? seats : undefined}
                    setSeats={config.hasSeats ? setSeats : undefined}
                    onButtonClick={
                      product.name === 'Enterprise Plan'
                        ? () =>
                            window.open(
                              'https://meetings.hubspot.com/lroubos/intro',
                              '_blank'
                            )
                        : () =>
                            handleStartFreeTrial(
                              product.name,
                              seats ?? 1,
                              product.id,
                              !subscriptionStatus?.hasActivatedTrial
                            )
                    }
                    onAlternativeClick={() =>
                      handleAlternativeAction(product.name, product.id, seats)
                    }
                    isLoading={isCreatingFreeTrialLoading}
                    isGettingCheckoutUrl={isStartingFreeTrial}
                    buttonText={getButtonText(product.name)}
                    alternativeText={config.alternativeText}
                    productName={product.name}
                    isAnnual={isAnnual}
                  />
                );
              })}
            </>
          )}
        </div>
        <div className="w-full items-center flex flex-col justify-center pt-6">
          <Separator className="w-96 -mt-2" />

          {orgId ? (
            <Button
              variant="ghost"
              className="mt-2"
              onClick={async () => {
                navigate({ to: '/logout', replace: true });
              }}
            >
              <ArrowLeft className="w-5 h-5 mr-1.5" /> Sign in with a different
              account
            </Button>
          ) : (
            <Button
              variant="ghost"
              className="mt-2"
              onClick={async () => {
                navigate({ to: '/orgform', replace: true });
              }}
            >
              <ArrowLeft className="w-5 h-5 mr-1.5" /> Back
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Subscription;
