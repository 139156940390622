import { DashboardShell } from '@fluency/ui/components';
import NotificationPage from './components/NotificationPage';

export default function Notifications() {
  return (
    <DashboardShell>
      <NotificationPage />
    </DashboardShell>
  );
}
