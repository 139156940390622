import { Button } from '@fluency/ui/components/ui/button';
import { UseMutateFunction } from '@tanstack/react-query';
import { EyeOff } from 'lucide-react';
import React from 'react';
import { DocumentationResponse } from '../../../hooks/documentation/types/master';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';

interface AutoPIIProps {
  imageKey: string;
  isAutoRedacting: boolean;
  setIsAutoRedacting: React.Dispatch<React.SetStateAction<boolean>>;
  mutate: UseMutateFunction<
    void,
    Error,
    { imageKey: string },
    { previousDocInfo?: DocumentationResponse }
  >;
}

const AutoPII: React.FC<AutoPIIProps> = ({
  imageKey,
  isAutoRedacting,
  setIsAutoRedacting,
  mutate,
}) => {
  const handleAutoRedact = () => {
    setIsAutoRedacting(true);
    mutate({ imageKey });
  };

  return (
    <TooltipProvider>
      <Tooltip delayDuration={100}>
        <TooltipTrigger asChild>
          <Button
            variant="secondary"
            className="active:scale-95 focus:outline-none"
            onClick={handleAutoRedact}
            disabled={isAutoRedacting}
            size="sm"
          >
            <EyeOff className="w-4 h-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right">
          <p>Auto redact</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default AutoPII;
