import React from 'react';
import { Link, useNavigate } from '@tanstack/react-router';
import { ChevronsUpDown, Cog, HelpCircle, LogOut } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@fluency/ui/components/ui/dropdown-menu';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@fluency/ui/components/ui/avatar';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import SupportDialog from './Support';
import SubmitFeedback from './SubmitFeedback';

interface UserDropdownProps {
  setIsWelcomeOpen: (isOpen: boolean) => void;
}

const UserDropdown: React.FC<UserDropdownProps> = ({ setIsWelcomeOpen }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const displayName =
    user?.firstName && user?.lastName
      ? `${capitalize(user.firstName)} ${capitalize(user.lastName)}`
      : user?.email;

  const initials =
    user?.firstName && user?.lastName
      ? `${user.firstName[0]}`.toUpperCase()
      : user?.email
      ? user?.email[0]?.toUpperCase()
      : '?';

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className="flex w-full items-center gap-2 rounded-lg py-2 hover:bg-accent/50 transition-colors text-gray-600">
          <Avatar className="h-8 w-8 rounded-lg">
            <AvatarImage src={user?.profilePictureUrl} alt={displayName} />
            <AvatarFallback className="rounded-lg bg-gray-200">
              {initials}
            </AvatarFallback>
          </Avatar>
          <div className="grid flex-1 text-left text-sm leading-tight">
            <span className="truncate font-medium">{displayName}</span>
            <span className="truncate text-xs text-muted-foreground">
              {user?.email}
            </span>
          </div>
          <ChevronsUpDown className="ml-auto h-4 w-4 text-muted-foreground" />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent side={'right'} align={'end'}>
        <DropdownMenuLabel className="font-normal">
          <div className="flex items-center gap-2 p-1">
            <Avatar className="h-8 w-8 rounded-lg">
              <AvatarImage src={user?.profilePictureUrl} alt={displayName} />
              <AvatarFallback className="rounded-lg">{initials}</AvatarFallback>
            </Avatar>
            <div className="grid flex-1 text-left text-sm leading-tight">
              <span className="truncate font-medium">{displayName}</span>
              <span className="truncate text-xs text-muted-foreground">
                {user?.email}
              </span>
            </div>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="hover:cursor-pointer justify-between"
          onClick={() => setIsWelcomeOpen(true)}
        >
          How-to
          <HelpCircle className="h-4 w-4" />
        </DropdownMenuItem>
        <SupportDialog />
        <SubmitFeedback />
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <Link
            to="/settings/$setting"
            params={{ setting: 'general' }}
            className="flex w-full justify-between items-center cursor-pointer"
          >
            <span>Settings</span>
            <Cog className="h-4 w-4" />
          </Link>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="flex w-full justify-between items-center cursor-pointer"
          onClick={() => {
            navigate({ to: '/logout', replace: true });
          }}
        >
          <span>Log out</span>
          <LogOut className="h-4 w-4" />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserDropdown;
