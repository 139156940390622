import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import browserExtensionLogo from '@fluency/ui/assets/onboarding/extension.png';
import { Button } from '@fluency/ui/components/ui/button';
import { Separator } from '@fluency/ui/components/ui/separator';
import { Download } from 'lucide-react';

export default function ExtensionHowTo() {
  return (
    <Card className="w-full max-w-lg border-0">
      <CardHeader className="p-0">
        <img
          className="w-full mb-4"
          src={browserExtensionLogo}
          alt="Fluency Browser Extension Icon"
        />
        <CardTitle>Record with the browser extension</CardTitle>
        <CardDescription className="flex flex-col text-left">
          <ol className="list-decimal list-inside text-left mt-2 mb-4">
            <li>Open the Fluency browser extension</li>
            <li>Follow the prompts to initiate recording</li>
            <li>Begin your process</li>
          </ol>{' '}
        </CardDescription>
        <CardContent className="flex flex-col items-center p-0">
          <div className="flex w-fit items-center gap-2 mb-4 justify-center">
            <Separator />
            <span className="text-sm font-medium">OR</span>
            <Separator />
          </div>
          <div className="flex gap-2">
            <Button
              className="w-full"
              variant="secondary"
              onClick={() =>
                window.open('https://usefluency.com/Download-Windows', '_blank')
              }
            >
              <Download className="w-5 mr-2" /> Download Fluency for Windows
            </Button>
          </div>
        </CardContent>
      </CardHeader>
    </Card>
  );
}
