import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useNavigate } from '@tanstack/react-router';
import { createHeaders, showErrorToast } from './utils/utils';
import { CreateWorkflowPayload } from './types/types';
import {
  InfiniteQueryResponse,
  ResourceItem,
  WorkflowResource,
} from '@fluency/ui/hooks/vaults/types/types';
import { ResourceType } from '../fga/types/fga';

export const useCreateWorkflow = () => {
  const { accessToken } = useAuth();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<WorkflowResource, Error, CreateWorkflowPayload>({
    mutationFn: async (payload): Promise<WorkflowResource> => {
      const response = await fetch(`${apiBaseUrl}/workflows`, {
        method: 'POST',
        headers: createHeaders(accessToken),
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message
            ? Array.isArray(errorData.message)
              ? errorData.message.join(', ')
              : errorData.message
            : 'Failed to create workflow'
        );
      }

      const result = await response.json();
      return result.workflow;
    },
    onMutate: async (newWorkflow) => {
      // Cancel queries
      await queryClient.cancelQueries({
        queryKey: ['vaultResources', newWorkflow.vaultId],
      });

      // Create optimistic workflow
      const optimisticWorkflow: WorkflowResource = {
        id: `temp-${Date.now()}`,
        name: newWorkflow.name,
        description: newWorkflow.description,
        nodes: newWorkflow.nodes || [],
        edges: newWorkflow.edges || [],
        isPublic: newWorkflow.isPublic || false,
        isGlobal: newWorkflow.isGlobal || false,
        isLocked: false,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        vault: {
          id: newWorkflow.vaultId,
          name: '',
          description: '',
        },
        creator: {
          id: '',
          metadata: {},
        },
      };

      // Create optimistic resource item
      const optimisticResource: ResourceItem = {
        type: ResourceType.WORKFLOW,
        resource: optimisticWorkflow,
      };

      // Update infinite query data
      queryClient.setQueryData<InfiniteQueryResponse>(
        ['vaultResources', newWorkflow.vaultId],
        (old) => {
          if (!old?.pages) return old;

          const newPages = [...old.pages];
          if (newPages[0]) {
            newPages[0] = {
              ...newPages[0],
              items: [optimisticResource, ...newPages[0].items],
            };
          }

          return {
            ...old,
            pages: newPages,
          };
        }
      );

      return { vaultId: newWorkflow.vaultId };
    },
    onSuccess: (data, variables) => {
      // Create actual resource item
      const newResource: ResourceItem = {
        type: ResourceType.WORKFLOW,
        resource: data,
      };

      // Update infinite query with actual data
      queryClient.setQueryData<InfiniteQueryResponse>(
        ['vaultResources', variables.vaultId],
        (old) => {
          if (!old?.pages) return old;

          const newPages = old.pages.map((page) => ({
            ...page,
            items: page.items.map((item) =>
              item.type === ResourceType.WORKFLOW &&
              (item.resource as WorkflowResource).id.startsWith('temp-')
                ? newResource
                : item
            ),
          }));

          return {
            ...old,
            pages: newPages,
          };
        }
      );
      navigate({ to: '/workflow/$id', params: { id: data.id } });
    },
    onError: (_, variables) => {
      // Remove optimistic update
      queryClient.setQueryData<InfiniteQueryResponse>(
        ['vaultResources', variables.vaultId],
        (old) => {
          if (!old?.pages) return old;

          const newPages = old.pages.map((page) => ({
            ...page,
            items: page.items.filter(
              (item) =>
                !(
                  item.type === ResourceType.WORKFLOW &&
                  (item.resource as WorkflowResource).id.startsWith('temp-')
                )
            ),
          }));

          return {
            ...old,
            pages: newPages,
          };
        }
      );

      showErrorToast('Failed to create workflow. Please try again.');
    },
    onSettled: (_, __, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['vaultResources', variables.vaultId],
      });
    },
  });
};
