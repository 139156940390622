import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@fluency/ui/components/ui/dialog';
import { RequestsList } from './RequestsList';
import CreateRequestDialog from './CreateRequest';
import { ScrollArea } from '@fluency/ui/components/ui/scroll-area';
import { Button } from '@fluency/ui/components/ui/button';
import { Plus, X } from 'lucide-react';

interface RequestDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  documentId: string;
  isManager: boolean;
}

export const RequestDialog: React.FC<RequestDialogProps> = ({
  isOpen,
  onOpenChange,
  documentId,
  isManager,
}) => {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-4xl p-0 gap-0 [&>button]:hidden">
        <div className="flex flex-col h-[700px]">
          <div className="flex items-center justify-between p-4 border-b">
            <DialogHeader>
              <DialogTitle className="font-medium text-lg">
                {isManager
                  ? 'Manage Document Requests'
                  : 'Complete Document Requests'}
              </DialogTitle>
            </DialogHeader>
            <div className="flex items-center gap-2">
              {isManager && (
                <Button
                  variant="default"
                  size="sm"
                  onClick={() => setIsCreateDialogOpen(true)}
                  className="gap-1"
                >
                  <Plus className="h-4 w-4" />
                  New Request
                </Button>
              )}
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8"
                onClick={() => onOpenChange(false)}
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          </div>

          <ScrollArea className="flex-1">
            <div className="flex flex-col h-full px-4 py-2 space-y-4">
              <div className="flex-1">
                <RequestsList documentId={documentId} isManager={isManager} />
              </div>
            </div>
          </ScrollArea>
        </div>
      </DialogContent>

      {isManager && (
        <CreateRequestDialog
          isOpen={isCreateDialogOpen}
          onOpenChange={setIsCreateDialogOpen}
          documentId={documentId}
        />
      )}
    </Dialog>
  );
};

export default RequestDialog;
