import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

interface Feature {
  name: string;
}

interface Price {
  id: string;
  currency: string;
  unitAmount: number;
  recurring: {
    interval: 'month' | 'year';
    intervalCount: number;
  } | null;
}

export interface Product {
  id: string;
  name: string;
  description: string | null;
  active: boolean;
  metadata: Record<string, unknown>;
  features: Feature[];
  prices: Price[];
}
export const useProducts = () => {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const { accessToken } = useAuth();

  const productQuery = useQuery<Product[], Error>({
    queryKey: ['subscriptionProducts'],
    queryFn: async (): Promise<Product[]> => {
      if (!accessToken) {
        Sentry.captureException('Access token is missing [useGetProducts]');
        throw new Error('Access token is missing');
      }

      const response = await fetch(`${apiBaseUrl}/subscription/products`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        Sentry.captureException('Failed to fetch subscription products');
        throw new Error('Failed to fetch subscription products');
      }

      const data: Product[] = await response.json();
      return data;
    },
  });

  return {
    products: productQuery.data,
    isLoading: productQuery.isLoading,
    error: productQuery.error,
    refetch: productQuery.refetch,
  };
};

export default useProducts;
