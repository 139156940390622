import { Logger } from '@fluency/ui/features/Logger';

async function getOrgAutoUpdate(accessToken: string): Promise<{
  message: string;
  allowAutoUpdate: boolean;
}> {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  try {
    const response = await fetch(`${apiBaseUrl}/organisation/allowAutoUpdate`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      Logger.log('Failed to get org auto update state');
      throw new Error('Failed to get org auto update state');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    Logger.error('Failed to get org auto update state', error);
    return {
      message: 'Failed to get org auto update state',
      allowAutoUpdate: false,
    };
  }
}

export default getOrgAutoUpdate;
