import { Button } from '@fluency/ui/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import { Check, Puzzle, Rocket } from 'lucide-react';

// Import new images
import windowsLogo from '@fluency/ui/assets/onboarding/windows.png';
import browserExtensionLogo from '@fluency/ui/assets/onboarding/extension.png';

const variants = {
  create: {
    title: 'Fluency for Windows',
    description: 'Document processes anywhere.',
    items: [
      'Record any process',
      'No integrations needed',
      'Download via website',
    ],
    buttonText: 'Download for Windows',
    buttonIcon: Rocket,
    logo: windowsLogo,
    buttonUrl: 'https://usefluency.com/Download-Windows',
  },
  welcome: {
    title: 'Fluency Browser Extension',
    description: 'Document web-based processes.',
    items: [
      'More accurate browser-based recordings',
      'Mac and Windows compatible',
      'No download required',
    ],
    buttonText: 'Get the extension',
    buttonIcon: Puzzle,
    logo: browserExtensionLogo,
    buttonUrl:
      'https://chromewebstore.google.com/detail/fluency/nhadgomabaphpfplmbfemfnagnhhghei',
  },
};

type VariantType = 'create' | 'welcome';

interface WelcomeCardProps {
  variant?: VariantType;
}

export default function WelcomeCard({ variant = 'create' }: WelcomeCardProps) {
  const content = variants[variant];
  const ButtonIcon = content.buttonIcon;

  return (
    <Card className="w-full max-w-md">
      <CardHeader>
        <img className="w-full mb-4" src={content.logo} alt={content.title} />
        <CardTitle>{content.title}</CardTitle>
        <CardDescription>{content.description}</CardDescription>
      </CardHeader>

      <CardContent>
        <div className="flex flex-col gap-4">
          {content.items.map((item, index) => (
            <div
              key={index}
              className="flex items-center w-full font-semibold text-sm gap-2"
            >
              <Check />
              {item}
            </div>
          ))}
        </div>
      </CardContent>
      <CardFooter className="flex">
        <Button
          className="w-full"
          onClick={() => window.open(content.buttonUrl, '_blank')}
        >
          <ButtonIcon className="mr-2 h-4 w-4" /> {content.buttonText}
        </Button>
      </CardFooter>
    </Card>
  );
}
