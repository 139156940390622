import { ScrollArea } from '@fluency/ui/components/ui/scroll-area';
import { Step, stepTypeColors } from './types/index';
import { useStepManagement } from './hooks/useStepManagement';
import { useScrollSync } from './hooks/useScrollSync';
import { RefreshCw } from 'lucide-react';
import { useRef } from 'react';
import { getStepSummaryTypeIcon } from '../../util/iconHelper';

interface MiniSummaryProps {
  documentId: string;
  handleJumpToStep: (stepId: string) => void;
}

export const MiniSummary = ({
  documentId,
  handleJumpToStep,
}: MiniSummaryProps) => {
  const { steps, activeStepId, setActiveStepId, isLoading, error } =
    useStepManagement(documentId);

  const scrollAreaRef = useRef<HTMLDivElement>(null);
  useScrollSync(scrollAreaRef, setActiveStepId);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full w-12 text-gray-500">
        <RefreshCw className="animate-spin w-4" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-full w-12 text-red-500">
        !
      </div>
    );
  }

  return (
    <div className="h-[calc(100vh-85px)]">
      <ScrollArea ref={scrollAreaRef} className="h-full px-1 pt-2">
        <div className="space-y-0.5 pb-16 pt-1">
          {steps.map((step, index) => (
            <div key={step.stepId} data-step-id={step.stepId}>
              <MiniStepItem
                step={step}
                isActive={activeStepId === step.stepId}
                onClick={() => handleJumpToStep(step.stepId)}
                stepNumber={getStepNumber(steps, index)}
              />
            </div>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
};

export default MiniSummary;

interface MiniStepItemProps {
  step: Step;
  isActive: boolean;
  onClick: () => void;
  stepNumber: number | null;
}

function MiniStepItem({
  step,
  isActive,
  onClick,
  stepNumber,
}: MiniStepItemProps) {
  return (
    <div
      onClick={onClick}
      className={`
        relative flex items-center justify-center
        w-8 h-8 mx-auto rounded-md
        cursor-pointer
        transition-all duration-200
        ${stepTypeColors[step.stepType] || 'bg-white'}
        ${isActive ? '!bg-gray-200 ring-2 !ring-blue-500' : ''}
        hover:bg-gray-50
        group
      `}
      title={step.stepDescription}
    >
      <span
        className={`
          text-xs font-medium 
          flex items-center justify-center
          ${isActive ? 'text-gray-900' : 'text-gray-600'}
          group-hover:text-gray-900
        `}
      >
        {step.stepType === 'STEP' ? (
          stepNumber
        ) : (
          <span className="-mr-2">{getStepSummaryTypeIcon(step.stepType)}</span>
        )}
      </span>
    </div>
  );
}

const getStepNumber = (steps: Step[], currentIndex: number): number => {
  return steps
    .slice(0, currentIndex + 1)
    .filter((step) => step.stepType === 'STEP').length;
};
