import { useState, useEffect } from 'react';
import { Step } from '../types/index';
import { useGetDocumentData } from '@fluency/ui/features/ViewDocument/hooks';

export function useStepManagement(documentId: string) {
  const { data, isLoading, error } = useGetDocumentData(documentId);
  const [steps, setSteps] = useState<Step[]>([]);
  const [selectedSteps, setSelectedSteps] = useState<string[]>([]);
  const [draggedItem, setDraggedItem] = useState<Step | null>(null);
  const [dragOverIndex, setDragOverIndex] = useState<number | null>(null);
  const [activeStepId, setActiveStepId] = useState<string | null>(null);

  useEffect(() => {
    if (!isLoading && data?.documentation?.steps) {
      const processedSteps = data.documentation.steps.map((step: any) => ({
        ...step,
        stepId: String(step.stepId),
        stepNumber: Number(step.stepNumber),
      }));
      setSteps(processedSteps);
    }
  }, [data, isLoading]);

  return {
    steps,
    setSteps,
    selectedSteps,
    setSelectedSteps,
    draggedItem,
    setDraggedItem,
    dragOverIndex,
    setDragOverIndex,
    activeStepId,
    setActiveStepId,
    isLoading,
    error,
  };
}
