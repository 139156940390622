import {
  useInfiniteQuery,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { fetchNotifications } from '../api/fetchNotifications';
import {
  NotificationQueryParams,
  NotificationResponse,
} from '../types/notifications.type';
import { Logger } from '@fluency/ui/features/Logger';

export const NOTIFICATIONS_KEY = ['notifications'] as const;

export function useNotifications(params: NotificationQueryParams = {}) {
  const { accessToken } = useAuth();

  return useInfiniteQuery({
    queryKey: ['infiniteNotifications', params],
    queryFn: async ({ pageParam = 1 }) => {
      if (!accessToken) throw new Error('No access token available');
      return fetchNotifications(accessToken, { ...params, page: pageParam });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.metadata.page < lastPage.metadata.totalPages) {
        return lastPage.metadata.page + 1;
      }
      return undefined;
    },
    enabled: !!accessToken,
    gcTime: 30 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
  });
}

export function usePopupNotifications(): UseQueryResult<
  NotificationResponse,
  Error
> {
  const { accessToken } = useAuth();

  return useQuery({
    queryKey: ['popupNotifications'],
    queryFn: async () => {
      if (!accessToken) throw new Error('No access token available');
      const response = await fetchNotifications(accessToken, {
        limit: 30,
        page: 1,
      });

      return {
        ...response,
        data: response.data.slice(0, 5),
      };
    },
    enabled: !!accessToken,
    staleTime: 5 * 60 * 1000,
  });
}

export function useUnreadNotificationsCount(
  pollingInterval: number = 60000
): number {
  const { accessToken } = useAuth();

  const { data, error } = useQuery<number, Error>({
    queryKey: ['notifications', 'unread-count'],
    queryFn: async () => {
      if (!accessToken) return 0;
      try {
        const result = await fetchNotifications(accessToken, { limit: 1 });
        return result.metadata.unreadCount;
      } catch (error) {
        Logger.error('Failed to fetch unread count:', error);
        return 0;
      }
    },
    enabled: !!accessToken,
    gcTime: 30 * 60 * 1000, // 30 minutes
    staleTime: 5 * 60 * 1000,
    refetchInterval: pollingInterval,
    refetchIntervalInBackground: true,
  });

  if (error) {
    Logger.error('Unread count error:', error);
  }

  return data ?? 0;
}
