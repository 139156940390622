import { createFileRoute, useParams } from '@tanstack/react-router';
import { DocumentPublicPage } from '@fluency/ui/features';

const Page = () => {
  const { document } = useParams({ from: '/share/$document' });
  return <DocumentPublicPage documentId={document} />;
};

export const Route = createFileRoute('/share/$document')({
  component: Page,
});
