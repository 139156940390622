import {
  useMutation,
  useQueryClient,
  type UseMutationResult,
} from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { toast } from '@fluency/ui/components/ui/use-toast';
import type {
  NotificationResponse,
  NotificationItem,
} from '../types/notifications.type';
import { markNotificationAsRead } from '../api/markNotificationAsRead';
import { deleteNotification } from '../api/deleteNotification';
import { useNotifications } from './useNotifications';
import { Logger } from '@fluency/ui/features/Logger';

export function useMarkNotificationAsRead(): UseMutationResult<
  NotificationResponse,
  Error,
  string
> {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (notificationId: string) =>
      markNotificationAsRead(accessToken || '', notificationId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['infiniteNotifications'] });
      queryClient.invalidateQueries({ queryKey: ['popupNotifications'] });
      queryClient.invalidateQueries({
        queryKey: ['notifications', 'unread-count'],
      });
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to mark notification as read',
      });
    },
  });
}

export function useDeleteNotification(): UseMutationResult<
  NotificationResponse,
  Error,
  string
> {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (notificationId: string) =>
      deleteNotification(accessToken || '', notificationId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['infiniteNotifications'] });
      queryClient.invalidateQueries({ queryKey: ['popupNotifications'] });
      queryClient.invalidateQueries({
        queryKey: ['notifications', 'unread-count'],
      });
      toast({
        title: 'Success',
        description: 'Notification deleted successfully',
      });
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to delete notification',
      });
    },
  });
}

export function useMarkAllNotificationsAsRead(): UseMutationResult<
  void,
  Error,
  void
> {
  const { data } = useNotifications({ limit: 100 });
  const markAsRead = useMarkNotificationAsRead();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      if (!data?.pages) return;

      const notifications = data.pages.flatMap((page) => page.data);
      const unreadNotifications = notifications.filter(
        (notification: NotificationItem) => !notification.read
      );

      // Optimistically update and refetch
      queryClient.setQueriesData(
        { queryKey: ['infiniteNotifications'] },
        (oldData: any) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            pages: oldData.pages.map((page: any) => ({
              ...page,
              data: page.data.map((notification: NotificationItem) => ({
                ...notification,
                read: true,
              })),
            })),
          };
        }
      );

      queryClient.setQueriesData(
        { queryKey: ['notifications', 'unread-count'] },
        0
      );

      // Force refetch all related queries immediately
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['infiniteNotifications'],
          refetchType: 'all',
        }),
        queryClient.invalidateQueries({
          queryKey: ['popupNotifications'],
          refetchType: 'all',
        }),
        queryClient.invalidateQueries({
          queryKey: ['notifications', 'unread-count'],
          refetchType: 'all',
        }),
      ]);

      // Mark notifications as read in background
      await Promise.all(
        unreadNotifications.map((notification) =>
          markAsRead.mutateAsync(notification.id)
        )
      );
    },
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'All notifications marked as read',
      });
    },
    onError: (error) => {
      // Revert by refetching
      queryClient.invalidateQueries({ queryKey: ['infiniteNotifications'] });
      queryClient.invalidateQueries({ queryKey: ['popupNotifications'] });
      queryClient.invalidateQueries({
        queryKey: ['notifications', 'unread-count'],
      });

      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to mark all notifications as read',
      });
      Logger.error('Failed to mark all notifications as read:', error);
    },
  });
}
