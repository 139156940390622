import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import type {
  CreateResourceAccessControlDto,
  CreateResourceAccessControlResponse,
  DetailedPermissionResponse,
  UserResourceRole,
  TeamResourceRole,
  DeleteResourceAccessControlDto,
} from '@fluency/ui/hooks/fga/types/types';
import useGetOrgUsers from '../organisation/useGetOrgUsers';
import useGetTeams from '../teams/useGetTeams';
import { Team } from '../teams/types/types';

interface MutationContext {
  previousData: DetailedPermissionResponse | undefined;
}

export const useResourceAccessControl = () => {
  const { accessToken } = useAuth();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const queryClient = useQueryClient();

  // Get users and teams data
  const { data: usersData } = useGetOrgUsers();
  const { data: teamsData } = useGetTeams();

  const createAccessRecord = useMutation<
    CreateResourceAccessControlResponse,
    Error,
    CreateResourceAccessControlDto,
    MutationContext
  >({
    mutationFn: async (data: CreateResourceAccessControlDto) => {
      const response = await fetch(`${apiBaseUrl}/fga/access/control`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(
          errorData.message || 'Failed to create resource access control record'
        );
      }

      return response.json();
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries({
        queryKey: [
          'resource-permissions',
          newData.resourceType,
          newData.resourceId,
        ],
      });

      const previousData = queryClient.getQueryData<DetailedPermissionResponse>(
        ['resource-permissions', newData.resourceType, newData.resourceId]
      );

      queryClient.setQueryData<DetailedPermissionResponse>(
        ['resource-permissions', newData.resourceType, newData.resourceId],
        (old) => {
          if (!old) return old;

          const newAssignments = { ...old.data.assignments };
          const tempId = `temp-${Date.now()}`;

          if ('userId' in newData && newData.userId) {
            // Find user details from org users data
            const userDetails = usersData?.memberships.find(
              (m) => m.userId === newData.userId
            )?.user;

            const newUserRole: UserResourceRole = {
              id: tempId,
              userId: newData.userId,
              resourceId: newData.resourceId,
              resourceType: newData.resourceType,
              roleId: tempId,
              createdAt: new Date(),
              updatedAt: new Date(),
              role: newData.roleName,
              teamId: null,
              user: userDetails
                ? {
                    userId: userDetails.id,
                    firstName: userDetails.firstName || null,
                    lastName: userDetails.lastName || null,
                    fullName: `${userDetails.firstName || ''} ${
                      userDetails.lastName || ''
                    }`.trim(),
                    email: userDetails.email,
                  }
                : {
                    // Fallback if user details not found
                    userId: newData.userId,
                    firstName: null,
                    lastName: null,
                    fullName: `Loading (${newData.roleName})...`,
                    email: '',
                  },
            };
            newAssignments.users = [...newAssignments.users, newUserRole];
          } else if ('teamId' in newData && newData.teamId) {
            // Find team details from teams data
            const teamDetails = teamsData?.find(
              (t: Team) => t.teamId === newData.teamId
            );

            const newTeamRole: TeamResourceRole = {
              id: tempId,
              teamId: newData.teamId,
              userId: null,
              resourceId: newData.resourceId,
              resourceType: newData.resourceType,
              roleId: tempId,
              createdAt: new Date(),
              updatedAt: new Date(),
              role: newData.roleName,
              team: {
                teamId: newData.teamId,
                teamName:
                  teamDetails?.teamName || `Loading (${newData.roleName})...`,
              },
            };
            newAssignments.teams = [...newAssignments.teams, newTeamRole];
          }

          return {
            ...old,
            data: {
              ...old.data,
              assignments: newAssignments,
            },
          };
        }
      );

      return { previousData };
    },
    onError: (_error, _variables, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(
          [
            'resource-permissions',
            _variables.resourceType,
            _variables.resourceId,
          ],
          context.previousData
        );
      }
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          'resource-permissions',
          variables.resourceType,
          variables.resourceId,
        ],
      });
    },
  });

  const deleteAccessRecord = useMutation<
    void,
    Error,
    DeleteResourceAccessControlDto,
    MutationContext
  >({
    mutationFn: async (data: DeleteResourceAccessControlDto) => {
      const params = new URLSearchParams();
      params.append('resourceId', data.resourceId);
      params.append('resourceType', data.resourceType);
      if ('userId' in data && data.userId) {
        params.append('userId', data.userId);
      }
      if ('teamId' in data && data.teamId) {
        params.append('teamId', data.teamId);
      }

      const response = await fetch(`${apiBaseUrl}/fga/access/control`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: params,
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(
          errorData.message || 'Failed to delete resource access control record'
        );
      }
    },
    onMutate: async (deletedData) => {
      await queryClient.cancelQueries({
        queryKey: [
          'resource-permissions',
          deletedData.resourceType,
          deletedData.resourceId,
        ],
      });

      const previousData = queryClient.getQueryData<DetailedPermissionResponse>(
        [
          'resource-permissions',
          deletedData.resourceType,
          deletedData.resourceId,
        ]
      );

      queryClient.setQueryData<DetailedPermissionResponse>(
        [
          'resource-permissions',
          deletedData.resourceType,
          deletedData.resourceId,
        ],
        (old) => {
          if (!old) return old;

          const newAssignments = { ...old.data.assignments };

          if ('userId' in deletedData && deletedData.userId) {
            newAssignments.users = newAssignments.users.filter(
              (user) => user.userId !== deletedData.userId
            );
          } else if ('teamId' in deletedData && deletedData.teamId) {
            newAssignments.teams = newAssignments.teams.filter(
              (team) => team.teamId !== deletedData.teamId
            );
          }

          return {
            ...old,
            data: {
              ...old.data,
              assignments: newAssignments,
            },
          };
        }
      );

      return { previousData };
    },
    onError: (_error, _variables, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(
          [
            'resource-permissions',
            _variables.resourceType,
            _variables.resourceId,
          ],
          context.previousData
        );
      }
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          'resource-permissions',
          variables.resourceType,
          variables.resourceId,
        ],
      });
    },
  });

  return {
    createAccessRecord,
    deleteAccessRecord,
  };
};
