import { createFileRoute } from '@tanstack/react-router'
import { HomePage } from '@fluency/ui/features'
import { RolePermissionHandler } from '@fluency/ui/features/AuthGuard/RolePermissionHandler'

export const Route = createFileRoute('/home')({
  component: () => (
    <RolePermissionHandler requiredPermissions={['documents:read']}>
      <HomePage />
    </RolePermissionHandler>
  ),
})
