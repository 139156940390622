import { Card } from '@fluency/ui/components/ui/card';
import {
  FileText,
  Loader2,
  Workflow as WorkflowIcon,
  ArrowRight,
  Lock,
} from 'lucide-react';
import { useParams } from '@tanstack/react-router';
import { Badge } from '@fluency/ui/components/ui/badge';
import { useCreateEmptyDoc } from '@fluency/ui/hooks/documentation/useCreateEmptyDoc';
import { useCreateWorkflow } from '@fluency/ui/hooks/workflows/useCreateWorkflow';

export default function CreateNewCards({ isLocked }: { isLocked: boolean }) {
  const { id: vaultId } = useParams({ from: '/vault/$id' });
  const { mutate: createEmptyDoc, isPending: isDocPending } =
    useCreateEmptyDoc();
  const createWorkflowMutation = useCreateWorkflow();

  const handleCreateEmptyDoc = () => {
    if (isLocked) return;
    const newDocPayload = {
      duration: 0,
      name: 'Empty Document',
      description: 'This is a new empty document',
      createdDate: new Date().toISOString(),
      vaultId: vaultId,
    };
    createEmptyDoc(newDocPayload);
  };

  const handleCreateWorkflow = () => {
    if (isLocked) return;
    const workflowPayload = {
      vaultId,
      name: 'My New Process Map',
      description: 'My New Process Map',
      nodes: [],
      edges: [],
    };
    createWorkflowMutation.mutate(workflowPayload);
  };

  return (
    <div className="flex gap-4 mt-2">
      {/* Document Card */}
      <Card className="group relative overflow-hidden transition-all h-full w-80 hover:border-primary/50">
        <button
          onClick={handleCreateEmptyDoc}
          disabled={isLocked || isDocPending}
          className="p-6 flex flex-col h-[200px] w-full text-left"
        >
          {isLocked && (
            <div className="absolute inset-0 bg-gray-50/80 backdrop-blur-[2px] z-10 flex items-center justify-center px-6 cursor-not-allowed">
              <div className="flex flex-col items-center gap-2 text-center">
                <Lock className="h-5 w-5 text-gray-600" strokeWidth={1.8} />
                <p className="text-sm text-gray-600">
                  Cannot create new resources while vault is locked
                </p>
              </div>
            </div>
          )}
          <div className="absolute inset-0 bg-gradient-to-br from-primary/5 to-primary/10 opacity-0 group-hover:opacity-50 transition-opacity" />
          <div className="relative flex flex-col flex-1">
            <div>
              <div className="flex gap-3 items-center mb-3">
                <div className="bg-primary/10 w-10 h-10 rounded-lg flex items-center justify-center">
                  <FileText className="h-5 w-5 text-primary" />
                </div>
                <h3 className="text-base font-semibold">Process Document</h3>
              </div>
              <p className="text-sm text-muted-foreground line-clamp-3">
                Create a new document to capture and standardise your business
                processes
              </p>
            </div>
            <div className="mt-auto pt-6">
              <div className="w-full gap-2 flex items-center justify-center py-2.5 px-4 rounded-md bg-primary/10 text-fluency-600">
                {isDocPending ? (
                  <Loader2 className="h-4 w-4 animate-spin text-fluency-600" />
                ) : (
                  <FileText className="h-4 w-4 text-fluency-600" />
                )}
                <span className="text-sm">
                  {isDocPending ? 'Creating...' : 'Create New Document'}
                </span>
                <ArrowRight className="h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity" />
              </div>
            </div>
          </div>
        </button>
      </Card>

      {/* Workflow Card */}
      <Card className="group relative overflow-hidden transition-all h-full w-80 hover:border-purple-500/50">
        <button
          onClick={handleCreateWorkflow}
          disabled={isLocked || createWorkflowMutation.isPending}
          className="p-6 flex flex-col h-[200px] w-full text-left"
        >
          {isLocked && (
            <div className="absolute inset-0 bg-gray-50/80 backdrop-blur-[2px] z-10 flex items-center justify-center px-6 cursor-not-allowed">
              <div className="flex flex-col items-center gap-2 text-center">
                <Lock className="h-5 w-5 text-gray-600" strokeWidth={1.8} />
                <p className="text-sm text-gray-600">
                  Cannot create new resources while vault is locked
                </p>
              </div>
            </div>
          )}
          <div className="absolute inset-0 bg-gradient-to-br from-purple-500/5 to-purple-500/10 opacity-0 group-hover:opacity-50 transition-opacity" />
          <div className="relative flex flex-col flex-1">
            <div>
              <div className="flex gap-3 items-center mb-3">
                <div className="bg-purple-500/10 w-10 h-10 rounded-lg flex items-center justify-center">
                  <WorkflowIcon className="h-5 w-5 text-purple-500" />
                </div>
                <h3 className="text-base font-semibold flex gap-2 items-center">
                  Process Map{' '}
                  <Badge className="bg-purple-500/10 text-purple-700 hover:bg-purple-500/10 cursor-pointer">
                    beta
                  </Badge>
                </h3>
              </div>
              <p className="text-sm text-muted-foreground line-clamp-3">
                Design and visualise your workflow processes with an interactive
                map
              </p>
            </div>
            <div className="mt-auto pt-6">
              <div className="w-full gap-2 flex items-center justify-center py-2.5 px-4 rounded-md bg-purple-500/10 text-purple-700">
                {createWorkflowMutation.isPending ? (
                  <Loader2 className="h-4 w-4 animate-spin" />
                ) : (
                  <WorkflowIcon className="h-4 w-4" />
                )}
                <span className="text-sm">
                  {createWorkflowMutation.isPending
                    ? 'Creating...'
                    : 'Create New Map'}
                </span>
                <ArrowRight className="h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity" />
              </div>
            </div>
          </div>
        </button>
      </Card>
    </div>
  );
}
