import { useEffect, useState, useCallback, useRef } from 'react';

interface DocProgress {
  id: string;
  name: string;
  status: string;
  steps: number;
  progress: number;
  targetProgress?: number;
}

const useUploadDocs = () => {
  const [docsInProgress, setDocsInProgress] = useState<{
    [key: string]: DocProgress;
  }>({});

  const animationInterval = useRef<NodeJS.Timeout | null>(null);

  const handleDocProgressUpdate = useCallback((data: DocProgress) => {
    setDocsInProgress((prev) => {
      const updatedDocs = { ...prev };

      if (data.progress >= 100) {
        delete updatedDocs[data.id];
      } else {
        const currentDoc = updatedDocs[data.id] || {
          id: data.id,
          name: data.name,
          status: data.status,
          steps: data.steps,
          progress: 0,
          targetProgress: data.progress,
        };

        updatedDocs[data.id] = {
          ...currentDoc,
          id: data.id,
          name: data.name,
          status: data.status,
          steps: data.steps,
          targetProgress: data.progress,
        };
      }

      return updatedDocs;
    });
  }, []);

  // Animation effect
  useEffect(() => {
    if (animationInterval.current === null) {
      const anyDocNeedsUpdate = Object.values(docsInProgress).some(
        (doc) => doc.progress < (doc.targetProgress || 0)
      );

      if (anyDocNeedsUpdate) {
        animationInterval.current = setInterval(() => {
          setDocsInProgress((prevDocs) => {
            const updatedDocs = { ...prevDocs };
            let anyUpdate = false;

            Object.keys(updatedDocs).forEach((key) => {
              const doc = updatedDocs[key];

              if (doc && doc.progress < (doc.targetProgress || 0)) {
                const increment = 1;
                const newProgress = Math.min(
                  doc.progress + increment,
                  doc.targetProgress!
                );
                updatedDocs[key] = {
                  ...doc,
                  progress: newProgress,
                };
                anyUpdate = true;
              }
            });

            if (!anyUpdate) {
              if (animationInterval.current) {
                clearInterval(animationInterval.current);
                animationInterval.current = null;
              }
              return prevDocs;
            }

            return updatedDocs;
          });
        }, 100);
      }
    }

    // Cleanup interval on unmount or when docs change
    return () => {
      if (animationInterval.current) {
        clearInterval(animationInterval.current);
        animationInterval.current = null;
      }
    };
  }, [docsInProgress]);

  // Electron event listener setup and cleanup
  useEffect(() => {
    if (window.electron) {
      window.electron.on('doc-progress-update', handleDocProgressUpdate);
    }

    return () => {
      if (window.electron) {
        window.electron.removeListener(
          'doc-progress-update',
          handleDocProgressUpdate
        );
      }
    };
  }, [handleDocProgressUpdate]);

  const isUploading = Object.keys(docsInProgress).length > 0;

  return { docsInProgress, isUploading };
};

export default useUploadDocs;
