import { forwardRef } from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { cn } from '@fluency/ui/lib/utils';
import {
  Info,
  Pencil,
  CheckCircle,
  AlertTriangle,
  XCircle,
  AlignLeft,
} from 'lucide-react';
import { stepTypeColors } from '@fluency/ui/features/DocumentPublic/components/iconHelper';

type IconBarProps = {
  onIconClick: (action: string) => void;
};

const icons = [
  {
    Icon: AlignLeft,
    action: 'STEP',
    label: 'Step',
    color: stepTypeColors.CODE,
    textColor: 'text-gray-600',
  },
  {
    Icon: Info,
    action: 'INFO',
    label: 'Info',
    color: stepTypeColors.INFO,
    textColor: 'text-blue-600',
  },
  {
    Icon: Pencil,
    action: 'NOTE',
    label: 'Note',
    color: stepTypeColors.NOTE,
    textColor: 'text-yellow-600',
  },
  {
    Icon: CheckCircle,
    action: 'SUCCESS',
    label: 'Success',
    color: stepTypeColors.SUCCESS,
    textColor: 'text-green-600',
  },
  {
    Icon: AlertTriangle,
    action: 'WARNING',
    label: 'Warning',
    color: stepTypeColors.WARNING,
    textColor: 'text-yellow-600',
  },
  {
    Icon: XCircle,
    action: 'ERROR',
    label: 'Error',
    color: stepTypeColors.ERROR,
    textColor: 'text-red-600',
  },
];

const IconBar = forwardRef<HTMLDivElement, IconBarProps>(
  ({ onIconClick }, ref) => (
    <TooltipProvider>
      <div className="flex flex-col gap-0.5 bg-background mt-4">
        <div ref={ref} className="flex space-x-2 px-2 rounded-lg">
          {icons.map(({ Icon, action, label, color, textColor }, _index) => (
            <Tooltip key={action}>
              <TooltipTrigger asChild>
                <button
                  type="button"
                  className={cn(
                    `p-2 rounded-md transition-colors duration-200 ${color}`,
                    'focus:bg-primary/10',
                    'focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2',
                    textColor,
                    'hover:scale-110'
                  )}
                  onClick={() => onIconClick(action)}
                >
                  <Icon className="h-4 w-4" />
                  <span className="sr-only">{label}</span>
                </button>
              </TooltipTrigger>
              <TooltipContent>
                <p>{label}</p>
              </TooltipContent>
            </Tooltip>
          ))}
        </div>
        <span className="text-xs text-gray-400">
          Select step type to insert
        </span>
      </div>
    </TooltipProvider>
  )
);

IconBar.displayName = 'IconBar';

export default IconBar;
