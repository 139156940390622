import { useState } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import { useQueryClient } from '@tanstack/react-query';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '@fluency/ui/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@fluency/ui/components/ui/form';
import { Input } from '@fluency/ui/components/ui/input';
import { Textarea } from '@fluency/ui/components/ui/textarea';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useNavigate } from '@tanstack/react-router';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import logo from '@fluency/ui/assets/icon-blue.svg';
import * as Sentry from '@sentry/react';
import {
  Video as VideoCameraIcon,
  StopCircle,
  Play,
  Pause,
} from 'lucide-react';
import { useGetDocumentData } from '../hooks';
import { Logger } from '@fluency/ui/features/Logger';

const FormSchema = z.object({
  name: z
    .string()
    .min(3, { message: 'Process name must be at least 3 characters.' })
    .max(60, { message: 'Process name must not exceed 60 characters.' }),
  description: z
    .string()
    .min(3, { message: 'Process description must be at least 3 characters.' })
    .max(240, {
      message: 'Process description must not exceed 240 characters.',
    }),
});

type FormData = z.infer<typeof FormSchema>;

interface RecordButtonProps {
  documentId: string;
  className?: string;
  iconClassName?: string;
  labelClassName?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export default function RecordButton({
  documentId,
  className,
  labelClassName,
  onMouseEnter,
  onMouseLeave,
}: RecordButtonProps) {
  const queryClient = useQueryClient();
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const { user, accessToken, orgId } = useAuth();
  const navigate = useNavigate();
  const { refetch, data } = useGetDocumentData(documentId);

  // Function to clean default values
  const getCleanValue = (value: string | undefined, defaultValue: string) => {
    return value === defaultValue ? '' : value || '';
  };

  const form = useForm<FormData>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: getCleanValue(
        data?.documentation.documentationName,
        'Empty Document'
      ),
      description: getCleanValue(
        data?.documentation.description,
        'This is a new empty document'
      ),
    },
  });

  const handleRecording = async (data: FormData) => {
    if (isRecording) {
      setIsRecording(false);
      setIsLoading(true);
      navigate({ to: '/home', replace: true });
      if (window.electron && user) {
        try {
          const result = (await window.electron.ipcRenderer.invoke(
            'run-steps',
            {
              processName: data.name,
              processDescription: data.description,
              token: accessToken,
              tenantId: orgId,
              userId: user.id,
              documentId,
            }
          )) as { success: boolean; docId?: string; error?: string };

          if (result.success && result.docId) {
            queryClient.removeQueries({ queryKey: ['docInfo', documentId] });
            refetch();
            navigate({
              to: '/document/$id',
              replace: true,
              params: { id: result.docId },
            });
          } else {
            toast({
              variant: 'destructive',
              title: 'Error processing recording',
              description: result.error || 'Please try again.',
            });
            Logger.error('Error in run-steps:', result.error);
          }
        } catch (error) {
          Sentry.captureException(error);
          Logger.error('Error invoking run-steps:', error);
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      if (window.electron) {
        try {
          await window.electron.ipcRenderer.invoke('start-record');
          setIsRecording(true);
        } catch (error) {
          toast({
            variant: 'destructive',
            title: 'Error starting recording',
            description: 'Please try again.',
          });
          Logger.error('Error starting recording:', error);
        }
      }
    }
  };

  const handlePauseResume = () => {
    if (window.electron) {
      if (isPaused) {
        window.electron.ipcRenderer.invoke('resume-record');
      } else {
        window.electron.ipcRenderer.invoke('pause-record');
      }
      setIsPaused(!isPaused);
    }
  };

  function onSubmit(data: FormData) {
    handleRecording(data);
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant={isRecording ? 'destructive' : 'outline'}
          className={className}
          disabled={isLoading}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {isLoading ? (
            'Processing...'
          ) : (
            <div className="relative flex items-center flex-col">
              <img
                src={logo}
                alt="logo"
                className="w-20 h-20 min-w-16 min-h-16"
              />
              <div className="absolute -top-1 right-4 h-7 w-7 justify-center flex items-center bg-red-500  border-1 border-white rounded-full text-white font-semibold text-xs">
                REC
              </div>
              <span className={labelClassName}>
                {isRecording ? 'Recording' : 'Capture Process'}
              </span>
            </div>
          )}
        </Button>
      </DialogTrigger>
      <DialogContent>
        {!isRecording ? (
          <>
            <DialogHeader>
              <DialogTitle>Provide context for the process</DialogTitle>
            </DialogHeader>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-6"
              >
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Process name</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter a clear, descriptive title..."
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Process description</FormLabel>
                      <FormControl>
                        <Textarea
                          {...field}
                          className="h-32 max-h-64"
                          placeholder="Describe the process in detail for the best results..."
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="w-full flex justify-end">
                  <Button type="submit">
                    <VideoCameraIcon className="h-5 mr-2" />
                    Start Recording
                  </Button>
                </div>
              </form>
            </Form>
          </>
        ) : (
          <>
            <DialogHeader>
              <DialogTitle>Recording in Progress</DialogTitle>
            </DialogHeader>
            <p>
              You are currently recording a process. Click the button below to
              stop recording.
            </p>
            <div className="flex justify-between">
              <DialogClose>
                <Button
                  onClick={() => handleRecording(form.getValues())}
                  variant="destructive"
                >
                  <StopCircle className="h-5 mr-2" />
                  Stop Recording
                </Button>
              </DialogClose>
              <Button onClick={handlePauseResume} variant="secondary">
                {isPaused ? (
                  <>
                    <Play className="h-5 mr-2" />
                    Resume Process
                  </>
                ) : (
                  <>
                    <Pause className="h-5 mr-2" />
                    Pause Process
                  </>
                )}
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
