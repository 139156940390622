import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { AlertTriangle } from 'lucide-react';
import { Loading } from '@fluency/ui/components';
import { Logger } from '@fluency/ui/features/Logger';

const Callback = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const navigate = useNavigate();
  const { handleManualTokenLogin, needsToCompleteOrgForm, logout } = useAuth();

  const authenticate = useCallback(async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');

    if (!token) {
      Logger.error('[Callback] No token provided in URL');
      setError(new Error('No token provided'));
      setIsLoading(false);
      return;
    }

    try {
      Logger.log('Callback token found:', token);
      await handleManualTokenLogin(token);
      if (needsToCompleteOrgForm()) {
        Logger.log('Auth Success, redirecting to /orgform');
        navigate({ to: '/orgform', replace: true });
      } else {
        Logger.log('Auth Success, redirecting to /home');
        navigate({ to: '/home', replace: true });
      }
    } catch (e) {
      Logger.error('[Callback] Authentication error:', e);
      setError(e instanceof Error ? e : new Error('Unknown error occurred'));
    } finally {
      setIsLoading(false);
    }
  }, [navigate, handleManualTokenLogin, needsToCompleteOrgForm]);

  useEffect(() => {
    authenticate();
  }, [authenticate]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage error={error.message} logout={logout} />;
  }

  return null;
};

export default Callback;

interface ErrorMessageProps {
  error: string;
  logout: () => Promise<void>;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error, logout }) => (
  <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 px-4 py-8">
    <div className="max-w-md w-full bg-white rounded-lg p-8">
      <div className="flex flex-col items-center">
        <AlertTriangle className="h-12 w-12 text-yellow-500 mb-4" />
        <h1 className="text-2xl font-bold text-gray-900 mb-2">
          Failed to Login
        </h1>
        <p className="text-gray-600 text-center mb-6">{error}</p>
        <button
          onClick={async () => {
            await logout();
          }}
          className="w-full bg-fluency-600 text-white py-2 px-4 rounded hover:bg-fluency-700 transition-colors"
        >
          Logout
        </button>
      </div>
    </div>
  </div>
);
