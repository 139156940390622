// NotificationPage.tsx
import { useEffect, useRef, useState } from 'react';
import { Search, Filter } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@fluency/ui/components/ui/select';
import { Input } from '@fluency/ui/components/ui/input';
import PageLayout from '@fluency/ui/templates/PageLayout';
import { Button } from '@fluency/ui/components/ui/button';
import { useNotifications } from '../hooks/useNotifications';
import { useMarkAllNotificationsAsRead } from '../hooks/useNotificationMutations';
import { NotificationItem } from './NotificationItem';
import { NotificationSkeleton } from './NotificationSkeleton';
import { ErrorState } from './ErrorState';
import { Logger } from '@fluency/ui/features/Logger';

export default function NotificationPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
    refetch,
  } = useNotifications({ limit: 20 });

  const markAllAsRead = useMarkAllNotificationsAsRead();
  const notifications = data?.pages.flatMap((page) => page.data) || [];
  const unreadCount = data?.pages[0]?.metadata?.unreadCount || 0;

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (
          firstEntry &&
          firstEntry.isIntersecting &&
          hasNextPage &&
          !isFetchingNextPage
        ) {
          fetchNextPage();
        }
      },
      { threshold: 0.5 }
    );

    const container = containerRef.current;
    if (container) {
      observer.observe(container);
    }

    return () => {
      if (container) {
        observer.unobserve(container);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const handleMarkAllAsRead = async () => {
    try {
      await markAllAsRead.mutateAsync();
    } catch (error) {
      Logger.error('Failed to mark all notifications as read:', error);
    }
  };

  const filteredNotifications = notifications.filter((notification) => {
    const matchesSearch = notification.resource.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesStatus =
      filterStatus === 'all' ||
      (filterStatus === 'unread' && !notification.read) ||
      (filterStatus === 'read' && notification.read);

    return matchesSearch && matchesStatus;
  });

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="bg-white rounded-lg border border-border my-6">
          <NotificationSkeleton />
          <NotificationSkeleton />
          <NotificationSkeleton />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="bg-white rounded-lg border border-border">
          <ErrorState onRetry={() => refetch()} />
        </div>
      );
    }

    if (filteredNotifications.length === 0) {
      return (
        <div className="bg-white rounded-lg border border-border mt-6">
          <div className="flex flex-col items-center justify-center py-8 text-[#44546F]">
            <p className="text-sm">No notifications found</p>
          </div>
        </div>
      );
    }

    return (
      <div className="py-6">
        <div className="bg-white rounded-lg border border-border">
          <div className="divide-y divide-border">
            {filteredNotifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </div>
        </div>

        <div ref={containerRef} className="py-8 text-center">
          {isFetchingNextPage ? (
            <NotificationSkeleton />
          ) : hasNextPage ? (
            <Button
              variant="ghost"
              size="sm"
              onClick={() => fetchNextPage()}
              className="text-xs hover:bg-[#F3F6F8]"
            >
              Load more
            </Button>
          ) : (
            <p className="text-[13px] text-[#44546F]">
              That's all your notifications.
            </p>
          )}
        </div>
      </div>
    );
  };

  return (
    <PageLayout
      maxWidth="5xl"
      stickyHeader
      header={{
        title: 'Notifications',
        description: 'Preview and manage notifications',
        titleMetadata: unreadCount > 0 ? `(${unreadCount})` : undefined,
        headerActions: (
          <Button
            variant="secondary"
            onClick={handleMarkAllAsRead}
            className="h-8 px-4 text-sm bg-white"
          >
            Mark all as read
          </Button>
        ),
      }}
      filterBar={{
        left: (
          <div className="relative w-[400px]">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground pointer-events-none" />
            <Input
              placeholder="Search notifications..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-8 bg-white"
            />
          </div>
        ),
        right: (
          <div className="flex items-center gap-2">
            <Select value={filterStatus} onValueChange={setFilterStatus}>
              <SelectTrigger className="w-[120px] bg-white h-9">
                <div className="flex items-center gap-2">
                  <Filter className="h-4 w-4 shrink-0" />
                  <SelectValue placeholder="All" />
                </div>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All</SelectItem>
                <SelectItem value="unread">Unread</SelectItem>
                <SelectItem value="read">Read</SelectItem>
              </SelectContent>
            </Select>
          </div>
        ),
      }}
      content={{
        children: renderContent(),
      }}
    />
  );
}
