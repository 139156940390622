import { useNavigate } from '@tanstack/react-router';
import { useGetDocumentData } from '../hooks/index';
import useFetchDocVersions from '../hooks/useFetchDocVersions';
import VersionCreate from './VersionCreate';
import { AlertTriangle, RefreshCw } from 'lucide-react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

function getVersionDotSize(version: string) {
  const [_major, minor, patch] = version.split('.').map(Number);

  if (minor === 0 && patch === 0) {
    return 'h-4 w-4'; // Major version
  } else if (patch === 0) {
    return 'h-3 w-3'; // Minor version
  } else {
    return 'h-2 w-2'; // Patch version
  }
}

interface VersionControlProps {
  id: string;
  isEditMode: boolean;
}

const VersionControl = ({ id, isEditMode }: VersionControlProps) => {
  const documentationid = id;
  const { data } = useGetDocumentData(id);
  const originalDocumentationId =
    data?.documentation.versionInfo.originalDocumentationId;
  const {
    query: { data: versionData, isLoading, error },
    createVersion,
  } = useFetchDocVersions(originalDocumentationId);
  const navigate = useNavigate();

  const handleNavigateToVersion = (versionId: string) => {
    if (typeof versionId === 'string' && versionId.trim() !== '') {
      navigate({
        to: '/document/$id',
        replace: true,
        params: { id: versionId },
      });
    }
  };

  return (
    <div className="w-64 print:hidden">
      <div className="p-2 rounded-lg flex flex-col">
        <div className="w-full h-fit flex flex-col justify-center">
          {isLoading ? (
            <div className="w-full flex items-center justify-center gap-2 py-8 text-gray-400">
              <RefreshCw className="h-4 w-4 animate-spin" />
              <p className="text-sm">Loading versions...</p>
            </div>
          ) : error ? (
            <div className="w-full flex flex-col items-center justify-center space-y-2 py-8">
              <AlertTriangle className="h-6 w-6" />
              <p className="text-sm text-gray-900 font-medium">
                Failed to load versions
              </p>
              <p className="text-xs text-gray-500">Please try again later</p>
            </div>
          ) : versionData && versionData.length > 0 ? (
            <ul role="list" className="space-y-1">
              {isEditMode && (
                <p className="text-sm font-medium mb-2">Version History</p>
              )}
              {versionData.slice().map((version, versionIdx) => {
                const isCurrentVersion =
                  version.versionedDocumentId === documentationid;
                const isLastItem = versionIdx === versionData.length - 1;
                return (
                  <li
                    key={version.versionId}
                    className={classNames(
                      'relative flex w-full justify-between cursor-pointer hover:bg-slate-100 py-1 px-2 rounded-md',
                      isCurrentVersion
                        ? 'hover:cursor-default hover:bg-transparent'
                        : ''
                    )}
                    onClick={() =>
                      handleNavigateToVersion(version.versionedDocumentId)
                    }
                  >
                    <div className="relative flex h-6 w-6 mr-2 items-center justify-center">
                      <div
                        className={classNames(
                          getVersionDotSize(
                            `${version.major}.${version.minor}.${version.patch}`
                          ),
                          'rounded-full ring-1 z-0',
                          isCurrentVersion
                            ? 'ring-fluency-500 bg-fluency-300'
                            : 'bg-gray-100 ring-gray-300'
                        )}
                      />
                      {!isLastItem && (
                        <div className="absolute w-px h-8 mt-7 -z-10 bg-gray-300" />
                      )}
                    </div>
                    <div className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                      <span className="font-medium text-gray-900">
                        v{version.major}.{version.minor}.{version.patch}
                        {isCurrentVersion && (
                          <span className="mx-2 px-2 py-0.5 bg-fluency-100 text-fluency-800 text-xs font-medium rounded">
                            Viewing
                          </span>
                        )}
                      </span>
                    </div>
                    <time
                      dateTime={version.createdAt}
                      className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                    >
                      {new Date(version.createdAt).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: '2-digit',
                      })}
                    </time>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p className="w-full flex h-8 items-center justify-center mt-4 text-gray-400 text-xs">
              No version data available
            </p>
          )}
          {originalDocumentationId && (
            <div className="mt-4">
              <VersionCreate
                originalDocumentationId={originalDocumentationId}
                versionData={versionData || []}
                createVersion={createVersion}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VersionControl;
