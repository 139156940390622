import { DashboardShell } from '@fluency/ui/components';
import { Badge } from '@fluency/ui/components/ui/badge';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { Building2 } from 'lucide-react';
import logo from '@fluency/ui/assets/icon-blue.svg';
import SearchComponent from './components/BigSearch';
import RecentAssets from './components/RecentAssets';
import RecentVaults from './components/RecentVaults';
import { useNavigate } from '@tanstack/react-router';

export default function Home() {
  const { user, organisationName, setIsLoggingOut } = useAuth();
  const navigate = useNavigate();

  setIsLoggingOut(false);

  return (
    <DashboardShell>
      <div className="flex min-h-[calc(100vh-90px)] w-full">
        <div className="flex flex-col w-full gap-4 items-center px-4 pb-12 md:pb-24">
          {/* Content wrapper with minimum padding and auto margins */}
          <div className="flex flex-col gap-4 items-center w-full max-w-5xl my-auto">
            <Badge
              variant="secondary"
              className="gap-1 h-8 min-h-8 hover:cursor-pointer font-normal"
              onClick={() => {
                navigate({
                  to: '/settings/$setting',
                  params: { setting: 'members' },
                  replace: true,
                });
              }}
            >
              <Building2 className="h-4" />
              {organisationName}
            </Badge>

            <div className="text-4xl font-semibold capitalize text-center text-gray-800 flex gap-2 items-center">
              <img src={logo} className="w-14" alt="Logo" />
              {user && user.firstName ? (
                <span>
                  Welcome, <span className="capitalize">{user.firstName}</span>
                </span>
              ) : (
                <span>Welcome</span>
              )}
            </div>

            <div className="w-full h-fit my-2">
              <SearchComponent />
            </div>

            <div className="flex flex-col rounded font-semibold text-gray-500 gap-2 w-full items-center">
              <div>
                <RecentVaults />
                <RecentAssets />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardShell>
  );
}
