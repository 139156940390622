import React, { useRef, useState, useEffect } from 'react';
import { useGetDocumentData } from '../../hooks/index';
import useAutoImagePii from '../../hooks/useAutoImagePii';
import useScreenshotCrop from '../../hooks/useScreenshotCrop';
import { ScreenshotProps, NaturalSize, CropData } from './types';
import { hasCropData } from './utils/cropUtils';
import { LoadingState } from './components/LoadingState';
import { ImageDisplay } from './components/ImageDisplay';
import { CropCanvas } from './components/CropCanvas';
import useCropTransition from './hooks/useCropTransition';
import Annotation from './components/AnnotationItem';
import AutoPII from '../AutoPII';
import Editor from '../editor/index';
import { CROP_SIZE, TRANSITION_DURATION } from './constant';
import { transitionStyles } from './styles';
import ScreenshotDialog from '../ScreenshotDialog';

const ScreenshotItem: React.FC<ScreenshotProps> = ({
  isScreenshotLoading,
  isEditMode,
  stepId,
  screenshot,
  docId,
}) => {
  const [imageLoadFailed, setImageLoadFailed] = useState(false);
  const { data } = useGetDocumentData(docId);
  const autoImagePii = useAutoImagePii(docId);
  const [isAutoRedacting, setIsAutoRedacting] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [naturalSize, setNaturalSize] = useState<NaturalSize | null>(null);
  const [currentAnnotations, setCurrentAnnotations] = useState(
    Array.isArray(screenshot.annotations) ? screenshot.annotations : []
  );
  const [image] = useState(() => new Image());
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [optimisticCropData, setOptimisticCropData] = useState<CropData>({
    x1: screenshot.x1Coordinate ?? null,
    x2: screenshot.x2Coordinate ?? null,
    y1: screenshot.y1Coordinate ?? null,
    y2: screenshot.y2Coordinate ?? null,
  });

  const screenshotCrop = useScreenshotCrop({
    documentationId: docId,
    onMutate: () => {
      setIsTransitioning(true);
      setTimeout(() => setIsTransitioning(false), TRANSITION_DURATION);
    },
  });

  useCropTransition();

  const isLatestVersion = data?.documentation.versionInfo.isLatestVersion;

  useEffect(() => {
    setOptimisticCropData({
      x1: screenshot.x1Coordinate ?? null,
      x2: screenshot.x2Coordinate ?? null,
      y1: screenshot.y1Coordinate ?? null,
      y2: screenshot.y2Coordinate ?? null,
    });
  }, [screenshot]);

  useEffect(() => {
    if (autoImagePii.isSuccess || autoImagePii.isError) {
      setIsAutoRedacting(false);
    }
  }, [autoImagePii.isSuccess, autoImagePii.isError]);

  useEffect(() => {
    setCurrentAnnotations(
      Array.isArray(screenshot.annotations) ? screenshot.annotations : []
    );
  }, [screenshot.annotations]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !hasCropData(optimisticCropData)) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    image.src = screenshot.downloadUrl;
    image.onload = () => {
      const cropX = (optimisticCropData.x1! / 100) * image.naturalWidth;
      const cropY = (optimisticCropData.y1! / 100) * image.naturalHeight;
      const cropWidth =
        ((optimisticCropData.x2! - optimisticCropData.x1!) / 100) *
        image.naturalWidth;
      const cropHeight =
        ((optimisticCropData.y2! - optimisticCropData.y1!) / 100) *
        image.naturalHeight;

      canvas.width = cropWidth;
      canvas.height = cropHeight;
      setNaturalSize({ width: cropWidth, height: cropHeight });

      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );
    };

    image.onerror = () => setImageLoadFailed(true);

    return () => {
      image.onload = null;
      image.onerror = null;
    };
  }, [screenshot.downloadUrl, optimisticCropData, image]);

  const handleImageClick = (
    e: React.MouseEvent<HTMLImageElement | HTMLCanvasElement>
  ) => {
    if (
      !isLatestVersion ||
      !isEditMode ||
      isTransitioning ||
      screenshotCrop.isPending
    )
      return;

    const rect = e.currentTarget.getBoundingClientRect();
    const centerX = Number(
      (((e.clientX - rect.left) / rect.width) * 100).toFixed(2)
    );
    const centerY = Number(
      (((e.clientY - rect.top) / rect.height) * 100).toFixed(2)
    );

    const halfSize = CROP_SIZE / 2;
    const x1 = Math.min(50, Math.max(0, centerX - halfSize));
    const y1 = Math.min(50, Math.max(0, centerY - halfSize));
    const x2 = Math.max(50, Math.min(100, centerX + halfSize));
    const y2 = Math.max(50, Math.min(100, centerY + halfSize));

    screenshotCrop.mutate({
      screenshotCropUpdates: [
        {
          screenshotId: screenshot.screenshotId,
          cropInfo: {
            x1Coordinate: x1,
            y1Coordinate: y1,
            x2Coordinate: x2,
            y2Coordinate: y2,
          },
          isCropped: !screenshot.isCropped,
        },
      ],
    });
  };

  const currentCropData = {
    x1: screenshot.x1Coordinate ?? null,
    x2: screenshot.x2Coordinate ?? null,
    y1: screenshot.y1Coordinate ?? null,
    y2: screenshot.y2Coordinate ?? null,
  };

  if (imageLoadFailed) return null;

  return (
    <div className="relative flex items-start w-fit pb-6">
      <div className="flex-1 flex justify-center overflow-hidden rounded-lg print:mt-0">
        {isScreenshotLoading ? (
          <LoadingState naturalSize={naturalSize} />
        ) : (
          <div
            className="relative flex justify-center items-center"
            ref={containerRef}
            style={{
              width: '100%',
              height: '100%',
              ...transitionStyles.container,
            }}
          >
            <div className="relative w-full">
              {screenshot.isCropped ? (
                <CropCanvas
                  ref={canvasRef}
                  onClick={handleImageClick}
                  naturalSize={naturalSize}
                  disabled={isTransitioning || screenshotCrop.isPending}
                >
                  <Annotation
                    annotations={currentAnnotations}
                    annotationVisible={screenshot.annotation}
                    cropData={currentCropData}
                    isCropped={screenshot.isCropped}
                  />
                </CropCanvas>
              ) : (
                <>
                  <ImageDisplay
                    src={screenshot.downloadUrl}
                    onClick={handleImageClick}
                    onLoad={(e) => {
                      const img = e.target as HTMLImageElement;
                      setNaturalSize({
                        width: img.naturalWidth,
                        height: img.naturalHeight,
                      });
                    }}
                    onError={() => setImageLoadFailed(true)}
                    isEditMode={isEditMode}
                    disabled={isTransitioning || screenshotCrop.isPending}
                  />
                  <Annotation
                    annotations={currentAnnotations}
                    annotationVisible={screenshot.annotation}
                    cropData={currentCropData}
                    isCropped={screenshot.isCropped ?? false}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {isLatestVersion && isEditMode && (
        <div className="bg-muted py-1 rounded-md mt-2 rounded-l-none border flex flex-col items-start">
          <Editor
            documentationId={docId}
            stepId={stepId}
            screenshot={screenshot}
          />
          <ScreenshotDialog stepId={stepId} documentId={docId} />
          <AutoPII
            imageKey={screenshot.screenshotKey}
            isAutoRedacting={isAutoRedacting || autoImagePii.isPending}
            setIsAutoRedacting={setIsAutoRedacting}
            mutate={autoImagePii.mutate}
          />
        </div>
      )}
    </div>
  );
};

export default ScreenshotItem;
